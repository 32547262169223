import { FC, useEffect } from 'react';
import { CommunityPartnerCommissionLevelMe } from 'Types';
import { clx } from 'src/helpers/Utils';
import { useTranslation } from 'react-i18next';
import styles from 'partials/page/agent-plan-price.module.scss';

interface Props {
	active: boolean;
	current: boolean;
	last: boolean;
	commissionLevel: any;
	onLevelChange: (level: CommunityPartnerCommissionLevelMe) => void;
}

export const AgentCommissionLevelBox: FC<Props> = ({
	active,
	current,
	last,
	commissionLevel,
	onLevelChange
}) => {
	const { t } = useTranslation();
	const boxClass = clx(
		styles.level,
		'd-flex flex-column gap-2 p-2 bg-gray-1 round-10',
		active ? 'bg-primary text-white' : 'text-gray-3'
	);

	useEffect(() => {
		if (active) {
			onLevelChange(commissionLevel);
		}
	}, [active, commissionLevel, onLevelChange]);

	return (
		<div className={boxClass}>
			<div className="d-flex fw-500">
				{!last
					? `${commissionLevel.minActiveAbo} - ${commissionLevel.maxActiveAbo}`
					: t('label.plan-price.more-than', {
							partnerNumber: commissionLevel.minActiveAbo
					  })}
			</div>
			<div className="d-flex align-items-center justify-content-between mt-auto">
				{current && (
					<span className="fs-small">
						({t('label.plan-price.current')})
					</span>
				)}
				<span
					className={clx(
						'ms-auto fw-500 bg-gray-1 p-1 round-5 lh-1',
						active && 'text-primary-dark'
					)}>{`${commissionLevel.commissionPercent}%`}</span>
			</div>
		</div>
	);
};
