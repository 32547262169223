import { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import { names } from 'Constants';
import { Department } from 'Types';
import { EditButton, Scrollbar } from 'Elements';
import styles from 'partials/homepage/card/department-card.module.scss';

interface Props {
	department: Department;
	preview?: boolean;
}

const ImageWrapper = styled.div`
	background-color: ${({ theme }) =>
		theme && theme.communityPartner.primaryColor};
	box-shadow: ${({ theme }) =>
		theme &&
		`-7px 7px 6px ${rgba(theme.communityPartner.primaryColor, 0.2)}`};
`;

export const ProfileDepartmentCard: FC<Props> = ({
	department,
	preview = false
}) => {
	const navigate = useNavigate();
	const { departments } = names;

	const handleNavigate = () => {
		navigate(`${departments.path}/${department.id}`, {
			state: {
				prevPath: 'homepage'
			}
		});
	};

	return (
		<article
			onClick={() => {
				if (!preview) {
					handleNavigate();
				}
			}}
			className={styles.card}>
			<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-4 h-100">
				<ImageWrapper className={styles.imageWrapper}>
					<img
						src={
							department?.image?.url ||
							'/assets/img/default-department.webp'
						}
						alt={department.title}
						className={styles.image}
					/>
				</ImageWrapper>
				<div
					className={clx(
						styles.body,
						'd-flex flex-column gap-2 flex-grow-1'
					)}>
					<h4 className="fs-4 fw-bold mb-0 lh-base">
						{department.title}
					</h4>
					{department.description && (
						<Scrollbar
							className={clx(
								styles.scrollbar,
								'flex-grow-1 mb-4 mb-lg-0'
							)}>
							<div
								className="lh-md fs-base text-gray-3"
								dangerouslySetInnerHTML={{
									__html: department.description
								}}
							/>
						</Scrollbar>
					)}
				</div>
			</div>
			{!preview && (
				<div className="edit-overlay">
					<EditButton className="edit-overlay-icon" />
				</div>
			)}
		</article>
	);
};
