import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import {
	AgentPaymentTransaction,
	AgentTransactionDetails,
	Credit,
	CreditDetails,
	DataList,
	DataQueryParams,
	Invoice,
	InvoiceList,
	Payment,
	PaymentList,
	Transaction,
	TransactionDetails
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useCommunityPartnerPayments = <T = DataList<PaymentList>>(
	{
		subscriptionId = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<PaymentList>>
): UseQueryResult<DataList<PaymentList>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.paymentsList,
			{
				subscriptionId,
				pageSize,
				pageNumber,
				keyword
			}
		],
		queryFn: () =>
			fetch.communityPartnerPaymentList(
				subscriptionId,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useBusinessPartnerInvoice = <T = DataList<InvoiceList>>(
	{
		subscriptionId = '',
		reason = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<InvoiceList>>
): UseQueryResult<DataList<InvoiceList>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.invoiceList,
			{
				subscriptionId,
				reason,
				pageSize,
				pageNumber,
				keyword
			}
		],
		queryFn: () =>
			fetch.communityPartnerInvoiceList(
				subscriptionId,
				reason,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useBusinessPartnerInvoiceDetails = <T = Invoice>(
	invoiceId: string,
	options?: UseQueryOptions<T, any, Invoice>
): UseQueryResult<Invoice, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.invoiceDetails,
			{ invoiceId }
		],
		queryFn: () =>
			fetch.businessPartnerInvoiceDetails(invoiceId),
		...config(options)
	});
};

export const useCommunityPartnerCredits = <T = DataList<Credit>>(
	{
		communityPartnerCreditStatus = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Credit>>
): UseQueryResult<DataList<Credit>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.credits,
			{ communityPartnerCreditStatus, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.communityPartnerCreditList(
				communityPartnerCreditStatus,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useCommunityPartnerCreditPayments = <T = DataList<Payment>>(
	{
		pageSize = 10,
		pageNumber = 1,
		keyword = '',
		status,
		minTotalPaymentAmount,
		maxTotalPaymentAmount,
		minTotalDonatedAmount,
		maxTotalDonatedAmount,
		year,
		month
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Payment>>
): UseQueryResult<DataList<Payment>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.payments,
			{
				pageSize,
				pageNumber,
				keyword,
				status,
				minTotalPaymentAmount,
				maxTotalPaymentAmount,
				minTotalDonatedAmount,
				maxTotalDonatedAmount,
				year,
				month
			}
		],
		queryFn: () =>
			fetch.communityPartnerCreditPayment(
				pageSize,
				pageNumber,
				keyword,
				status,
				minTotalPaymentAmount,
				maxTotalPaymentAmount,
				minTotalDonatedAmount,
				maxTotalDonatedAmount,
				year,
				month
			),
		...config(options)
	});
};

export const useCompanyTransactions = <T = DataList<Transaction>>(
	{
		status = '',
		type = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Transaction>>
): UseQueryResult<DataList<Transaction>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.transactions,
			{ status, type, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.companyTransactionList(
				status,
				type,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useTransaction = <T = TransactionDetails>(
	transactionId: string,
	options?: UseQueryOptions<T, any, TransactionDetails>
): UseQueryResult<TransactionDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.transaction, { transactionId }],
		queryFn: () => fetch.transactionDetails(transactionId),
		...config(options)
	});
};

export const useCommunityPartnerCredit = <T = CreditDetails>(
	creditId: string,
	options?: UseQueryOptions<T, any, CreditDetails>
): UseQueryResult<CreditDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.credit, { creditId }],
		queryFn: () => fetch.communityPartnerCreditDetails(creditId),
		...config(options)
	});
};

export const useCommunityPartnerPayment = <T = Payment>(
	creditpaymentId: string,
	options?: UseQueryOptions<T, any, Payment>
): UseQueryResult<Payment, unknown> => {
	return useQuery({
		queryKey: [queryKey.payment, { creditpaymentId }],
		queryFn: () => fetch.communityPartnerPaymentDetails(creditpaymentId),
		...config(options)
	});
};
