
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgentViewCompanyPayments } from 'Elements';

export const AgentViewTransaction = () => {
	const { t } = useTranslation();
	const { payment_id } = useParams();
	return <AgentViewCompanyPayments transactionId={payment_id as string} />;
};
