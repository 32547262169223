import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'Elements';
import {
	clx,
	companySettlementStatus,
	formatLocaleDateTime,
	getKeyFromValue
} from 'Utils';
import { Colors, Invoice, TransactionStatus } from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transaction: Invoice;
	baseUrl?: string;
}
export const CompanyTransactionInvoicePayment: FC<Props> = ({
	transaction
}) => {
	const { t } = useTranslation();
	const payStatus = transaction?.status;
	const transactionStatus = getKeyFromValue(TransactionStatus, payStatus);
	const badgeText = transactionStatus
		? t(`badge.${transactionStatus.toLowerCase()}`)
		: '';
	const getStatusData = () => {
		return companySettlementStatus.find((elem) => elem.value === payStatus);
	};

	return (
		<div>
			<h6 className="text-gray-3 mb-3 fs-6 fw-400 d-flex align-items-center justify-content-between">
				{t('title.payment.key')}
				<Badge color={getStatusData()?.color ?? Colors.muted}>
					{badgeText}
				</Badge>
			</h6>
			<div className={clx(styles.row, 'justify-content-between')}>
				<span className={styles.rowLabel}>
					{t('label.payment-date')}:
				</span>
				<span>
					{transaction?.createdAt
						? formatLocaleDateTime(transaction?.createdAt)
						: '---'}
				</span>
			</div>
			<div
				className={clx(styles.row, 'justify-content-between border-0')}>
				<span className={styles.rowLabel}>
					{t('label.payment-reference')}:
				</span>
				<span>{transaction?.provider?.paymentReference || '---'}</span>
			</div>
			<div className={clx(styles.row, 'justify-content-between')}>
				<span className={styles.rowLabel}>
					{t('label.payment-method')}:
				</span>
				<span>{transaction?.provider?.paymentMethodInfo || '---'}</span>
			</div>
			{/* <div className={clx(styles.row, 'justify-content-between')}>
				{payStatus === TransactionStatus.Paid && (
					<Button
						color={Colors['white-primary']}
						className="mt-2"
						label={t('button.payment-details') as string}
						onClick={() =>
							navigate(
								`/${baseUrl}/${transaction.relatedTransaction}`
							)
						}
					/>
				)}
				{payStatus === TransactionStatus.Pending && (
					<a
						href={transaction.payment?.payUrl}
						className={styles.link}>
						{t('button.pay-invoice')}
					</a>
				)} 
			</div> */}
		</div>
	);
};
