import { FC, useState, useCallback, useEffect } from 'react';
import {
	useBusinessPartnerMe,
	useCompanyCommunityPartner,
	useDispatch,
	useRegion
} from 'Hooks';
import { setSubscriptionCommunityPartner } from 'Actions';
import { Colors, SubscriptionCommunityPartner } from 'Types';
import {
	CreateSubscriptionSearchCommunityPartner,
	NoData,
	Button,
	Pagination,
	SkeletonWrapper,
	LoadingMask
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { SubscriptionCommunityPartnerCard } from 'src/components/elements/card/SubscriptionCommunityPartnerCard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { clx } from 'src/helpers/Utils';

interface Props {
	selected: SubscriptionCommunityPartner | null;
	onNext: () => void;
	goToStep: (stepId: string) => void;
}
export const CreateSubscriptionCommunityPartner: FC<Props> = ({
	selected,
	onNext,
	goToStep
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [optionsRegion, setOptionsRegion] = useState([]);
	const [regionCode, setRegionCode] = useState<any>();
	const dispatch = useDispatch();
	const [filteredList, setFilteredList] = useState<
		SubscriptionCommunityPartner[] | undefined
	>();

	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const IsPremiumParam = searchParams.get('IsPremium') || '';
	const regionCodeParam = searchParams.get('regionCode') || '';
	const queryStep = searchParams.get('step') || '';

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	useEffect(() => {
		if (queryStep) {
			goToStep(queryStep);
		}
	}, [queryStep]);

	const [state, setState] = useState({
		pageSize: 8,
		currentPage: pageParam,
		keyword: queryParam,
		IsPremium: IsPremiumParam,
		regionCode: regionCodeParam
	});

	const { data: businessPartnerMe } = useBusinessPartnerMe();

	const {
		data: communityPartner,
		isLoading,
		isFetching
	} = useCompanyCommunityPartner(
		{
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword,
			regionCode: state.regionCode ?? businessPartnerMe?.regionCode,
			IsPremium: state.IsPremium,
			published: 1
		},
		{
			enabled: businessPartnerMe ? true : false
		}
	);

	const handleSearch = useCallback(
		(query: string) => {
			setSearchParams({
				...params,
				page: '1',
				q: query
			});
			setState((prev) => ({
				...prev,
				currentPage: 1,
				keyword: query
			}));
		},
		[params, setSearchParams]
	);
	const handleFilterType = useCallback(
		(query: string) => {
			setSearchParams({
				...params,
				page: '1',
				IsPremium: query !== '' ? query : ''
			});
			setState((prev) => ({
				...prev,
				currentPage: 1,
				IsPremium: query !== '' ? query : ''
			}));
		},
		[params, setSearchParams]
	);

	const handleSelectAgent = (
		communityPartner: SubscriptionCommunityPartner
	) => {
		dispatch(setSubscriptionCommunityPartner(communityPartner));
		onNext?.();
		navigate(
			`/subscriptions/add?step=step2&communityPartnerId=${communityPartner?.id}`
		);
	};

	const { data: regionList, isLoading: isLoadingRegion } = useRegion({
		pageSize: 100,
		pageNumber: 1,
		keyword: ''
	});

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const onchangeRegion = (regionCode) => {
		setSearchParams({
			...params,
			page: '1',
			regionCode: regionCode === '' ? '' : regionCode
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			regionCode: regionCode === '' ? '' : regionCode
		}));
	};

	useEffect(() => {
		if (communityPartner) {
			setFilteredList(communityPartner?.items ?? []);
			// getPlanData();
		}
	}, [communityPartner]);

	useEffect(() => {
		setSearchParams({
			...params,
			page: '1',
			IsPremium: '',
			regionCode: regionCode
				? regionCode.label === t('text.any-region')
					? ''
					: regionCode.label
				: regionCodeParam
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			IsPremium: '',
			regionCode: regionCode
				? regionCode.label === t('text.any-region')
					? ''
					: regionCode.label
				: regionCodeParam
		}));
	}, [regionCode]);

	useEffect(() => {
		if (!isLoadingRegion && regionList) {
			const newRegionCode = businessPartnerMe?.regionCode;
			const newList: any = [];
			newList.push({ label: t('text.any-region'), value: '' });
			regionList?.items?.forEach((item) => {
				newList.push({
					label: item.code,
					value: item.id
				});
			});
			newList.forEach((element) => {
				if (element?.label === newRegionCode) {
					setRegionCode(element);
				}
			});
			setOptionsRegion(newList);
		}
	}, [isLoadingRegion, regionList, businessPartnerMe]);

	return (
		<div className={styles.form}>
			<div className="d-flex flex-column gap-4 mb-4">
				<span className="fs-base fw-500">
					{t('text.search-and-choose-community-partner')}
				</span>
				{/* <span className="fs-sm fw-400">
					{t('text.description-search-and-choose-community-partner')}
				</span> */}
				<div className={`${styles.boxVideo} flex-wrap `}>
					<div className={styles.video}>
						<img src="/assets/img/covervideo.png" />
					</div>
					<div className="fw-400 fs-sm w-70">
						{t('text.description-video-cp-list')}
					</div>
				</div>
			</div>
			<div className="mb-4">
				<CreateSubscriptionSearchCommunityPartner
					onSubmit={handleSearch}
					regionCode={regionCode}
					optionsRegion={optionsRegion}
					onchangeRegion={onchangeRegion}
					totalItem={filteredList?.length}
				/>
				<div className="d-flex flex-wrap gap-3 align-items-center mt-3">
					<span className="fs-sm fw-400">
						{t('text.filter-community-partner-type')}:
					</span>
					<Button
						color={
							!state?.IsPremium
								? Colors['primary']
								: Colors['white-gray']
						}
						onClick={() => handleFilterType('')}>
						{t('button.all')}
					</Button>
					<Button
						color={
							state?.IsPremium === 'false'
								? Colors['primary']
								: Colors['white-gray']
						}
						onClick={() => handleFilterType('false')}>
						{t('button.standard')}
					</Button>
					<Button
						color={
							state?.IsPremium === 'true'
								? Colors['primary']
								: Colors['white-gray']
						}
						onClick={() => handleFilterType('true')}>
						{t('button.premium')}
					</Button>
				</div>
			</div>
			{isLoading &&
				[1, 2, 3].map((item) => {
					return (
						<Row className="position-relative" key={item}>
							<SkeletonWrapper
								skeleton={Skeleton}
								count={1}
								wrapperClassName="row"
							/>
						</Row>
					);
				})}
			{!isFetching && (
				<div className="d-flex flex-column gap-4">
					{filteredList && filteredList?.length > 0 && (
						<div>
							{filteredList.map((communityPartner) => {
								return (
									<SubscriptionCommunityPartnerCard
										key={communityPartner.id}
										communityPartner={communityPartner}
										selected={
											selected?.id === communityPartner.id
										}
										onSelect={handleSelectAgent}
									/>
								);
							})}
						</div>
					)}
					{!isLoading &&
						communityPartner &&
						communityPartner?.totalPages > 1 && (
							<Pagination
								className="mt-3"
								totalItemsCount={communityPartner.totalItems}
								activePage={parseInt(
									state.currentPage.toString(),
									10
								)}
								itemsCountPerPage={state.pageSize}
								onChange={handlePageChange}
							/>
						)}
					{communityPartner &&
						communityPartner?.items?.length === 0 &&
						filteredList?.length === 0 && (
							<NoData iconClassName={styles.no_data} />
						)}
				</div>
			)}
		</div>
	);
};
