import { memo, FC, startTransition } from 'react';
import axios from 'Adapter';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDemand } from 'Hooks';
import { toastify, api } from 'Utils';
import { Colors, ModalProps } from 'Types';
import { Button, ModalBox, ModalBoxFooter, SubmitButton } from 'Elements';

interface Props extends ModalProps {
	title: string;
	type: 'approve' | 'reject';
}

interface RequestForm {
	approvalNote: string;
	status: number;
}

export const ModalBoxRequestDescription: FC<Props> = ({
	isOpen,
	title,
	type,
	onClose
}) => {
	const { t } = useTranslation();
	const { request_id, applicant_id } = useParams();
	const { refetch } = useDemand(request_id as string, applicant_id as string);

	const {
		register,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<RequestForm>();

	const submitRequest: (data: RequestForm) => Promise<void> = async (
		data
	) => {
		if (type === 'approve') {
			await axios
				.post(api.approveDemand(request_id as string), {
					...data,
					applicantId: applicant_id
				})
				.then((res) => {
					if (res.status === 200) {
						refetch();
						startTransition(() => {
							onClose && onClose();
							data?.status.toString() === '1'
								? toastify('toastify.applicant-approved', {
										type: 'success'
								  })
								: toastify('toastify.applicant-rejected', {
										type: 'success'
								  });
						});
					}
				});
		} else {
			await axios
				.post(api.rejectDemand(request_id as string), {
					...data,
					applicantId: applicant_id
				})
				.then((res) => {
					if (res.status === 200) {
						refetch();
						startTransition(() => {
							onClose && onClose();
							data?.status.toString() === '1'
								? toastify('toastify.applicant-approved', {
										type: 'success'
								  })
								: toastify('toastify.applicant-rejected', {
										type: 'success'
								  });
						});
					}
				});
		}
	};

	const submitHandler: SubmitHandler<RequestForm> = async (data) => {
		await submitRequest(data);
	};

	return (
		<ModalBox isOpen={isOpen} title={title} onClose={onClose}>
			<form onSubmit={handleSubmit(submitHandler)}>
				<textarea
					{...register('approvalNote')}
					id="approvalNote"
					placeholder={
						type === 'approve'
							? `${t('forms.any-desc-for-approve')} (${t(
									'placeholder.optional'
							  )})`
							: `${t('forms.any-desc-for-reject')} (${t(
									'placeholder.optional'
							  )})`
					}
					className="inputbox w-100"
					cols={10}
					rows={5}
				/>
				<ModalBoxFooter className="justify-content-between mt-3">
					<Button
						type="button"
						color={Colors['white-gray']}
						onClick={onClose}>
						{t('button.cancel')}
					</Button>
					<SubmitButton
						icon={type === 'approve' ? 'check' : 'xmark'}
						color={type === 'approve' ? 'white-green' : 'white-red'}
						isSubmitting={isSubmitting}
						label={
							type === 'approve'
								? 'button.approve'
								: 'button.reject'
						}
						savingLabel={
							type === 'approve'
								? 'button.approving'
								: 'button.rejecting'
						}
					/>
				</ModalBoxFooter>
			</form>
		</ModalBox>
	);
};
