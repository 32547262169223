import { useLocation, useParams } from 'react-router-dom';
import { Tab } from 'Types';
import { Card, LoadingContent, TabNavigation } from 'Elements';
import pageStyles from 'partials/shared/page.module.scss';

export const BranchDecoration = (props) => {
	const { pathname } = useLocation();
	const { branch_id } = useParams();
	const defaultTab =
		pathname === `/branches/${branch_id}` ||
		pathname === `/branches/${branch_id}/general`;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === `/branches/${branch_id}/address`
		}
	];

	return (
		<Card tab>
			{props?.isFetching && <LoadingContent />}
			<div className="d-flex align-items-center mb-3 position-relative">
				<div className="flex-grow-1">
					<h1 className="fs-5 fw-400 lh-base">{props?.branch?.name}</h1>
				</div>
			</div>
			<div className={pageStyles.tab}>
				{tabs?.map((tab) => {
					return (
						<TabNavigation
							key={tab.id}
							to={tab.path}
							title={tab.title}
							isActive={tab.isActive}
						/>
					);
				})}
			</div>
		</Card>
	);
};
