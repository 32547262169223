import { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { api, clx } from 'Utils';
import { SubscriptionPlanDetails } from 'Types';
import {
	useAxios,
	useResponsive,
	useSubscriptionPlan,
	useSubscriptionPlanDetails,
	useSubscriptionPlansFeature
} from 'Hooks';
import {
	SubscriptionPlanDecorationBox,
	Card,
	LoadingContent,
	SubmitButton
} from 'Elements';
import styles from 'partials/page/form.module.scss';

export const UpdateSubscriptionPlans: FC = () => {
	const { t } = useTranslation();
	const { subscriptionPlan_id } = useParams();
	const { updateSubscriptionPlan } = useSubscriptionPlan(subscriptionPlan_id);
	const { sendRequest } = useAxios<string>();

	const { isDesktopAndBelow } = useResponsive();
	const {
		data: subscriptionPlan,
		isFetching,
		refetch
	} = useSubscriptionPlanDetails(subscriptionPlan_id as string);

	const { data: subscriptionPlansFeature } = useSubscriptionPlansFeature(
		subscriptionPlan?.subscriptionPlanFeatureId as string,
		subscriptionPlan?.id as string,
		{
			enabled: !!subscriptionPlan?.subscriptionPlanFeatureId
		}
	);

	const schema = object().shape({
		name: string().required(t('validation.title.required')),
		publishedInternships: string()
			.nullable()
			.required(t('validation.published-internships.required')),
		publishedJobs: string()
			.nullable()
			.required(t('validation.published-jobs.required')),
		termsCondition: string()
			.matches(
				/^(https?:\/\/)/,
				t('validation.link-web-site')
			)
			.required(t('validation.link-to-agb.required'))
	});

	const formMethods = useForm<SubscriptionPlanDetails>({
		shouldUnregister: false,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const {
		reset,
		handleSubmit,
		formState: { isDirty, isSubmitting }
	} = formMethods;

	const submitHandler: SubmitHandler<SubscriptionPlanDetails> = async (
		data
	) => {
		await sendRequest(
			api.updateSubscriptionPlanFeatures(
				subscriptionPlan_id as string,
				data.subscriptionPlanFeatureId,
				String(data.publishedJobs),
				String(data.publishedInternships)
			),
			{
				method: 'put'
			},
			async () => {
				let newData = data;

				if (data?.contactPerson?.fullName) {
					const contactPerson: any = {
						avatarUrl: data?.contactPerson?.avatarUrl,
						description: data?.contactPerson?.description,
						fullName: data?.contactPerson?.fullName,
						published: data?.contactPerson?.published ? true : false
					};
					newData = { ...data, contactPerson: contactPerson };
				} else {
					newData = { ...data, contactPerson: null as any };
				}
				await updateSubscriptionPlan(newData as any);
			}
		);
	};

	useEffect(() => {
		reset({
			...subscriptionPlan,
			publishedJobs: subscriptionPlansFeature?.jobSetting?.maxPublishJobs,
			availableUpgradePlanIds:
				subscriptionPlan?.availableUpgradePlanList ?? [],
			availableUpgradePlanList:
				subscriptionPlan?.availableUpgradePlanIds ?? [],
			isRecommended: subscriptionPlan?.isRecommended
				? subscriptionPlan?.isRecommended
				: false,
			publishedInternships:
				subscriptionPlansFeature?.internshipSetting
					?.maxPublishInternships
		});
	}, [reset, subscriptionPlan, subscriptionPlansFeature]);

	// usePrompt(t('forms.leave-screen-massage'), isDirty);

	return (
		<FormProvider {...formMethods}>
			<form
				noValidate
				onSubmit={handleSubmit(submitHandler)}
				className={clx(
					isDesktopAndBelow && styles.form,
					'position-relative'
				)}>
				<SubscriptionPlanDecorationBox
					refetch={refetch}
					subscriptionPlan={subscriptionPlan as any}
				/>
				<Outlet context={subscriptionPlan} />
				{isFetching && <LoadingContent />}
				<div className="mt-3">
					<Card
						className={clx(
							isDesktopAndBelow && styles.sticky_action
						)}>
						<div className="d-flex align-items-center justify-content-end gap-2">
							<SubmitButton
								cancelButton="/subscription-plans"
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</div>
					</Card>
				</div>
			</form>
		</FormProvider>
	);
};
