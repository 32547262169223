import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityTag, Colors, DataList, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	FontAwesome,
	LoadingMask
} from 'Elements';
import { useActivityTags } from 'src/hooks/useJob';
import { clx, getCurrentLocale } from 'src/helpers/Utils';
import styles from 'partials/job/job.module.scss';

interface Props extends ModalProps {
	onClickActivityTags: (tags: any) => void;
	selectedActivityTags: any;
}

export const ModalBoxActivityTags: FC<Props> = ({
	isOpen,
	onClose,
	selectedActivityTags,
	onClickActivityTags
}) => {
	const { t } = useTranslation();
	const [dataActivityTagObj, setDataActivityTagsObj] =
		useState<DataList<ActivityTag>>();
	const [filterDataActivityTags, setFilterDataActivityTags] = useState([]);
	const [selectedActivityTagsId, setSelectedActivityTagsId] =
		useState<any>(selectedActivityTags);
	const [state, setState] = useState<{
		pageSize: number;
		keyword: string;
		currentPage: string | number;
	}>({
		pageSize: 1000,
		currentPage: 1,
		keyword: ''
	});

	const { data: dataActivityTags, isFetching: isFetchingActivityTags } =
		useActivityTags({
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: '',
			isActive: ''
		});

	const {
		data: dataActivityTagsActive,
		isFetching: isFetchingActivityTagsActive
	} = useActivityTags({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword,
		isActive: true
	});

	useEffect(() => {
		if (dataActivityTagsActive) {
			setFilterDataActivityTags(dataActivityTagsActive?.items as any);
			if (!dataActivityTagObj) {
				setDataActivityTagsObj(dataActivityTagsActive as any);
			}
		}
	}, [dataActivityTagsActive]);

	const getActiveJobTag = (id: string) => {
		let res = false;
		selectedActivityTagsId?.map((item) => {
			const jobTagId = item?.jobTagId ?? item;
			if (jobTagId === id) {
				res = true;
			}
		});
		return res;
	};

	const getActivityTitle = (id: any, array: any) => {
		let title = '';
		array?.items?.forEach((element) => {
			if (id === element.id) {
				title = element.title;
				element.translateTitles.find((item) => {
					if (
						item?.language.toLowerCase() ===
						getCurrentLocale().code.toLowerCase()
					) {
						title = item.value
							? item.value
							: element.translateTitles[0].value ?? element.title;
					}
				});
				if (title === '') {
					title = element.title;
				}
			}
		});
		return title;
	};

	const onSearch = (q: string) => {
		setState((prev) => ({
			...prev,
			keyword: q
		}));
	};

	return (
		<ModalBox isOpen={isOpen} size="xl">
			<header className="d-flex flex-column gap-3 pt-2 pb-4">
				<span className="fw-500 fs-base">
					{t('forms.activity-tags')}
				</span>
				<span className="fw-400 fs-base">
					{t('text.choose-tags-describe-your-job-position-better')}
				</span>
			</header>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex gap-4 w-100">
					<div
						className="bg-[#FFFFFF] d-flex flex-column gap-4 py-4 px-3 w-100"
						style={{
							boxShadow: '0px 1px 15px 0px #c2c2c2',
							borderRadius: '16px',
							height: '350px'
						}}>
						<h5 className="fw-500 fs-5 text-color-gray-5">
							{t('forms.activity-type')}
						</h5>
						<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-1">
							<div className="p-0 inputbox">
								<input
									type="search"
									value={state.keyword}
									className={clx(styles.inputSearch, 'w-100')}
									placeholder={t('menu.search')}
									onChange={(e) => {
										onSearch(e.target.value);
									}}
								/>
								<Button
									type="button"
									color={Colors.empty}
									onClick={() => onSearch('')}
									className={clx('p-0', styles.submitSearch)}>
									<FontAwesome
										size="sm"
										icon={
											state.keyword === ''
												? 'magnifying-glass'
												: 'close'
										}
									/>
								</Button>
							</div>
						</div>
						<div
							className="d-flex flex-wrap align-items-center gap-2"
							style={{
								overflow: 'auto'
							}}>
							{!isFetchingActivityTagsActive &&
								filterDataActivityTags?.map(
									(item: ActivityTag) => {
										return (
											<div
												key={item.id}
												className={`d-flex align-items-center gap-2 cursor-pointer px-3 ${
													getActiveJobTag(item.id)
														? 'bg-primary'
														: 'bg-gray-1'
												}`}
												style={{
													height: '41px',
													borderRadius: '8px'
												}}
												onClick={() => {
													if (
														!getActiveJobTag(
															item.id
														)
													) {
														setSelectedActivityTagsId(
															[
																...selectedActivityTagsId,
																item.id
															]
														);
													}
												}}>
												{!getActiveJobTag(item.id) && (
													<FontAwesome
														icon="plus"
														size="1x"
														color="text-primary"
													/>
												)}
												<span
													className={`fw-400 fs-base ${
														getActiveJobTag(
															item.id
														) && 'text-white'
													}`}>
													{getActivityTitle(
														item.id,
														dataActivityTagObj
													)}
												</span>
											</div>
										);
									}
								)}
							{isFetchingActivityTagsActive &&
								[1, 2, 3].map((item) => {
									return (
										<div key={item}>
											<LoadingMask
												className={styles.titleJobTags}
											/>
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div
					className="bg-[#FFFFFF] d-flex flex-column gap-2 py-4 px-3 w-100"
					style={{
						boxShadow: '0px 1px 15px 0px #c2c2c2',
						borderRadius: '16px',
						minHeight: '200px'
					}}>
					<h5 className="fw-500 fs-5">{t('text.your-choices')}</h5>
					{/* <p className="fw-400 fs-small">
						{t(
							'text.you-can-drag-and-drop-resort-tags-with-your-priority'
						)}
					</p> */}
					<div
						className="d-flex flex-wrap align-items-center gap-2"
						style={{
							overflow: 'auto',
							height: '90px'
						}}>
						{!isFetchingActivityTags &&
							selectedActivityTagsId?.map((item) => {
								return (
									<div
										key={item}
										className={`d-flex align-items-center gap-2  px-3 bg-primary`}
										style={{
											height: '41px',
											borderRadius: '8px'
										}}>
										<FontAwesome
											icon="close"
											size="1x"
											color="text-white cursor-pointer"
											onClick={() => {
												const list: any = [];
												selectedActivityTagsId.forEach(
													(jobTag) => {
														if (jobTag !== item) {
															list.push(jobTag);
														}
													}
												);
												setSelectedActivityTagsId(list);
											}}
										/>
										<span
											className={`fw-400 fs-base text-white`}>
											{getActivityTitle(
												item,
												dataActivityTags
											)}
										</span>
									</div>
								);
							})}
						{isFetchingActivityTags &&
							[1, 2, 3].map((item) => {
								return (
									<div key={item}>
										<LoadingMask
											className={styles.titleJobTags}
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<ModalBoxFooter className="flex-row justify-content-end pt-3 gap-2">
				<Button
					color={Colors['white-red']}
					label={t('button.cancel') as string}
					onClick={onClose}
				/>
				<Button
					color={Colors.primary}
					label={t('button.submit-tags') as string}
					onClick={() => {
						onClose && onClose();
						onClickActivityTags(selectedActivityTagsId);
					}}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
