import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { getDiscountFormat } from 'Utils';
import { InvitationForm, SelectOption, MyVoucher } from 'Types';
import {
	useInvitation,
	usePrompt,
	useAvailableVouchers,
	useVouchers
} from 'Hooks';
import {
	Card,
	LoadingContent,
	Registrant,
	SelectController,
	SubmitButtonGroup,
	SwitchController
} from 'Elements';

const CardBox = styled(Card)`
	min-height: 250px;
`;

export const UpdateInvitation = () => {
	const { t } = useTranslation();
	const { invitation_id } = useParams();
	const [voucherCode, setVoucherCode] = useState(false);
	const {
		data: fields,
		isLoading,
		isFetching,
		updateInvitation
	} = useInvitation(invitation_id as string);
	const { data: voucherList, isLoading: isLoadingVouchers } = useVouchers({
		enabled: !isLoading && !!fields
	});

	const isEditable = fields?.registrants?.length === 0;

	const schema = yup.object().shape({
		email: yup
			.string()
			.email(t('validation.email.type'))
			.required(t('validation.email.required')),
		businessPartnerName: yup
			.string()
			.required(t('validation.business-partner-name.required'))
	});

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<InvitationForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const optionsListObject = useCallback((key_obj: MyVoucher) => {
		return {
			value: key_obj?.['id'],
			code: key_obj.voucherCode,
			label: `${key_obj.voucherCode} (${getDiscountFormat(
				key_obj.discount,
				key_obj.discountType
			)})`
		};
	}, []);

	const getDefaultVoucher = useCallback(
		(voucherCode: string) => {
			const selected_voucher = voucherList?.items?.find(
				(voucher: MyVoucher) => {
					return voucher.voucherCode === voucherCode;
				}
			);
			if (selected_voucher) {
				return optionsListObject(selected_voucher);
			}
		},
		[voucherList?.items, optionsListObject]
	);

	const getVoucherListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		voucherList?.items?.forEach((voucher: MyVoucher) => {
			options.push(optionsListObject(voucher));
		});
		return options;
	}, [voucherList?.items, optionsListObject]);

	const onSetVoucher = (val: boolean) => {
		setVoucherCode(val);
	};

	const onSubmit: SubmitHandler<InvitationForm> = async (data) => {
		const api_data = {
			...data,
			voucherCode: data.voucherCode?.code?.toString() ?? ''
		};
		await updateInvitation(api_data);
	};

	useEffect(() => {
		reset({
			...fields,
			voucherCode: getDefaultVoucher(
				fields?.voucherInfo?.voucherCode ?? ''
			)
		});
		setVoucherCode(!!fields?.voucherInfo?.voucherCode);
	}, [reset, fields, getDefaultVoucher]);

	return (
		<CardBox>
			{!isLoading && isEditable && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="receiverName">
									{t('forms.receiver-name')}
								</Label>
								<input
									{...register('receiverName')}
									type="text"
									id="receiverName"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="businessPartnerName">
									{t('forms.business-partner-name')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('businessPartnerName')}
									type="text"
									id="businessPartnerName"
									aria-invalid={!!errors.businessPartnerName}
									className="inputbox w-100"
								/>
								{errors.businessPartnerName && (
									<div className="invalid-feedback d-block">
										{errors.businessPartnerName.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="email">
									{t('forms.email')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('email')}
									type="email"
									id="email"
									aria-invalid={!!errors.email}
									className="inputbox w-100"
								/>
								{errors.email && (
									<div className="invalid-feedback d-block">
										{errors.email.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="voucherCode"
									control={control}
									defaultChecked={voucherCode}
									checked={voucherCode}
									label="forms.voucher-code"
									boxClassName="py-2"
									onSwitchChange={onSetVoucher}
								/>
							</FormGroup>
							{voucherCode && (
								<FormGroup>
									<Label htmlFor="voucherCode">
										{t('forms.voucher-code')}
									</Label>
									<SelectController
										name="voucherCode"
										isDisabled={isLoadingVouchers}
										control={control}
										placeholder={
											isLoadingVouchers
												? 'placeholder.loading'
												: 'placeholder.select-voucher'
										}
										options={getVoucherListOptions}
									/>
								</FormGroup>
							)}
							<SubmitButtonGroup
								cancelButton="/invitations"
								isSubmitting={isSubmitting}
								icon="paper-plane"
								label={t('button.resend-invitation')}
								savingLabel={t('button.sending')}
							/>
						</Col>
					</Row>
				</form>
			)}
			{!isLoading && !isEditable && fields?.registrants && (
				<Registrant registrants={fields?.registrants} />
			)}
			{isFetching && <LoadingContent />}
		</CardBox>
	);
};
