import { Col, Row } from 'reactstrap';
import { clx, formatLocalAddress } from 'Utils';
import { Colors, CommunityPartnerMe, Logo } from 'Types';
import { LoadingSpinner, FontAwesome, Badge } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
	isLoadingProfile: boolean;
	communityPartnerMe: CommunityPartnerMe;
	isFetchingProfile: boolean;
	header: {
		description: string;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: string;
		themeColor: number;
	};
};

export const CommunityPartnerPreviewHeroHeader = ({
	isLoadingProfile,
	communityPartnerMe,
	isFetchingProfile,
	header
}: Props) => {
	const { t } = useTranslation();
	const defaultLogo = '/assets/img/business-partner-default.png';

	return (
		<div style={{ marginTop: '-5rem' }}>
			{isLoadingProfile && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && header && (
				<div className="ps-lg-4">
					<Row className="g-0 mb-4 ps-xxl-4">
						<Col lg={3} xxl={2}>
							<div className={styles.headerLogo}>
								<img
									src={
										(header?.logo?.url as string) ||
										defaultLogo
									}
									alt={header?.headerTitle}
									className={styles.logo}
								/>
								{isFetchingProfile && (
									<span className={styles.overlay}>
										<LoadingSpinner
											size="xl"
											color="#fff"
										/>
									</span>
								)}
							</div>
						</Col>
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<div className="d-flex flex-column gap-2 justify-content-end h-100 pt-lg-5 pe-3">
								<div className="d-flex align-items-center mt-5 pt-xl-3 mb-0 justify-content-between">
									<h3
										className={clx(
											styles.title,
											'fw-700 text-break '
										)}>
										{header?.headerTitle ?? ''}
									</h3>
									<div className="d-flex align-items-center gap-2">
										{communityPartnerMe?.commissionGroupInfo
											?.isPremium && (
											<Badge color={Colors.premium}>
												<FontAwesome
													icon={'medal'}
													size="lg"
													className="me-2"
												/>
												{
													communityPartnerMe
														?.commissionGroupInfo
														?.premiumLabel
												}
											</Badge>
										)}
										{communityPartnerMe?.partnerClass ===
											2 && (
											<Badge
												title={t(
													'title.association'
												)}
												color={Colors.association}
												className="gap-2">
												{t('forms.association')}
											</Badge>
										)}
									</div>
								</div>
								<div className="fs-base text-gray-3 lh-md text-break">
									{header?.slogan ?? ''}
								</div>
								{communityPartnerMe?.address && (
									<div className="d-flex align-items-center gap-2 text-gray-3 fs-sm lh-base position-relative w-100">
										<div>
											<FontAwesome
												icon="location-dot"
												size="1x"
												className="me-1"
											/>
											{formatLocalAddress(
												communityPartnerMe?.address
													?.street,
												communityPartnerMe?.address
													?.houseNumber,
												communityPartnerMe?.address
													?.postalCode,
												communityPartnerMe?.address
													?.city,
												communityPartnerMe?.address
													?.state
											)}
										</div>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Row className="justify-content-end g-0 ps-xxl-4">
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<Col xl={10}>
								<div
									className="fs-base text-gray-3 lh-md text-break"
									style={{ whiteSpace: 'pre-wrap' }}
									dangerouslySetInnerHTML={{
										__html: header?.description
									}}></div>
							</Col>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
};
