import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx, formatTimeToCET, formatTimeToNow } from 'Utils';
import {
	Button,
	Card,
	LoadingContent,
	ShimmerImage,
	FontAwesome
} from 'Elements';
import {
	Colors,
	CompanyEventInvitation,
	EventInvitationStatus,
	RegistrationStatus
} from 'Types';
import styles from 'partials/page/my-event.module.scss';

interface Props {
	className?: string;
	event: CompanyEventInvitation;
	isSubmitting: boolean;
	onAccept: () => void;
	onReject: () => void;
}

export const CompanyEventStatusBox: FC<Props> = memo(
	({ className, event, isSubmitting, onAccept, onReject }) => {
		const { t } = useTranslation();
		const defaultLogo = '/assets/img/agent-default.jpg';
		const eventInfo = event.eventInfo;
		const status = event.status;
		const partnerRegistrationStatus = event.partnerRegistrationStatus;

		return (
			<Card
				className={clx(
					className,
					styles.card,
					status === EventInvitationStatus.Pending && styles.pending,
					status === EventInvitationStatus.Accepted &&
					styles.approved,
					event.isFinished && styles.finished,
					(status === EventInvitationStatus.Rejected ||
						event.isCanceled) &&
					styles.rejected
				)}>
				<h6 className="fs-sm text-gray-5 fw-400">
					{t('label.hosted-by')}:
				</h6>
				<div className="d-flex align-items-center mt-2">
					<div className={styles.logoBox}>
						<ShimmerImage
							src={eventInfo.communityPartnerLogo || defaultLogo}
							width={35}
							height={35}
							defaultImage={defaultLogo}
							alt={eventInfo.communityPartnerName}
							className={styles.logo}
						/>
					</div>
					<span className="fs-small">{eventInfo.communityPartnerName}</span>
				</div>
				{partnerRegistrationStatus !== RegistrationStatus.Finished && (
					<div className="mt-3">
						{status !== EventInvitationStatus.Accepted &&
							partnerRegistrationStatus !==
							RegistrationStatus.Open && (
								<time className={styles.timeRow}>
									<span className="d-flex align-items-center">
										<FontAwesome
											icon="timer"
											size="sm"
											className="me-2 flex-shrink-0"
										/>
										{t('label.event.register-start-in')}:
									</span>
									<span className="fw-300">
										{formatTimeToNow(
											eventInfo.partnerRegistrationStartDate ||
											eventInfo.startDate
										)}
									</span>
								</time>
							)}
						{status !== EventInvitationStatus.Accepted && (
							<time className={styles.timeRow}>
								<span className="d-flex align-items-center">
									<FontAwesome
										icon="timer"
										size="sm"
										className="me-2 flex-shrink-0"
									/>
									{t('label.event.register-end-in')}:
								</span>
								<span className="fw-300">
									{formatTimeToNow(
										eventInfo.partnerRegistrationEndDate ||
										eventInfo.endDate
									)}
								</span>
							</time>
						)}
						{status === EventInvitationStatus.Accepted && (
							<time className={styles.timeRow}>
								{t('label.event.accept-in')}:
								<span className="fw-300">
									{formatTimeToCET(event.responseDate)}
								</span>
							</time>
						)}
					</div>
				)}
				{(event.isFinished || event.isCanceled) && (
					<p className="mt-3 fw-500">
						{event.isFinished && t('text.event.finished')}
						{event.isCanceled && t('text.event.canceled')}
					</p>
				)}
				{!event.isFinished &&
					!event.isCanceled &&
					partnerRegistrationStatus === RegistrationStatus.Open && (
						<div className="d-flex justify-content-between gap-3 flex-wrap mt-auto pt-3">
							{status === EventInvitationStatus.Pending && (
								<>
									<Button
										color={Colors['white-primary']}
										label={
											t('button.event.reject') as string
										}
										className="flex-xl-fill"
										onClick={onReject}
									/>
									<Button
										label={
											t('button.event.accept') as string
										}
										className="flex-xl-fill"
										onClick={onAccept}
									/>
								</>
							)}
							{status === EventInvitationStatus.Accepted && (
								<Button
									color={Colors['white-red']}
									label={t('button.event.cancel') as string}
									className="flex-xl-fill"
									onClick={onReject}
								/>
							)}
							{status === EventInvitationStatus.Rejected && (
								<Button
									label={
										t('button.event.re-accept') as string
									}
									className="flex-xl-fill"
									onClick={onAccept}
								/>
							)}
						</div>
					)}
				{isSubmitting && <LoadingContent />}
			</Card>
		);
	}
);

CompanyEventStatusBox.displayName = 'CompanyEventStatusBox';
