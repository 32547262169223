import { Address, Sector, SelectableAddress, Status } from 'Types';

export enum ApplicantApproveStatus {
	Pending = 0,
	Cancelled = 1,
	Approved = 2,
	Rejected = 3
}

export interface Applicant {
	id: string;
	birthDate: string;
	gender: number;
	mobileNumber: string;
	description: null;
	image: string;
	imageThumbnail: string;
	firstName: string;
	lastName: string;
	email: string;
	address: Address;
	applicantFiles: ApplicantFile[];
}

export interface ApplicantDemand {
	id: string;
	applicantId: string;
	description: string;
	requestDate: string;
	approveStatus: ApplicantApproveStatus;
	requestStatus: Status;
	documents: ApplicantFile;
	status: ApplicantApproveStatus;
	jobId: string;
	code: string;
	applicantInfo: Applicant;
	createdAt: string;
	updatedAt: string;
	publishedJobInfo: {
		code: string;
		description: string;
		featuredPhoto: string;
		kind: number;
		partnerLogo: number;
		partnerName: string;
		partnerType: number;
		publishedJobId: string;
		shortDescription: string;
		tenantId: string;
		title: string;
	};
	shortDescription: string;
	applicantComment: string;
	title: string;
	slugUrl: string;
	applicant: Applicant;
	sectorId: string;
	sector: Sector;
}

export interface ApplicantFile {
	id?: string;
	url: string;
	title: string;
	size?: string;
}

export interface ApplicantState {
	firstName: string;
	lastName: string;
	email: string;
	postalCode: string;
	userRegisterId: string;
	address: SelectableAddress;
	selectedSectors: string[];
	selectedJobTypes: string[];
	selectedActivities: string[];
}
