import { useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { Container, Pagination } from 'reactstrap';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { Colors, Logo } from 'Types';
import { useSubscriptionPlans } from 'Hooks';
import { Button, Carousel, ButtonProps } from 'Elements';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import { Autoplay, Navigation } from 'swiper/modules';
import CommunityPartnerPreviewCompanyOverviewPremiumDrawer from './CommunityPartnerPreviewCompanyOverviewPremiumDrawer';

type Props = {
	isLoadingProfile: boolean;
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
};

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} {...props} />
))`
	${({ theme }) =>
		theme &&
		`
			background-color: #fff !important;
			color: #000 !important;
			&:hover {
				color: #000;
				background-color: ${darken(0.07, '#fff')} !important;
			}
		`}
`;

export const CommunityPartnerPreviewCompanyOverviewPremium = ({
	becomePartner
}: Props) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(null);

	const { data: subscriptionPlans, isLoading } = useSubscriptionPlans({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		planType: ''
	});

	const breakpoints = {
		0: {
			slidesPerView: 1
		},
		768: {
			slidesPerView: 2
		},
		992: {
			slidesPerView: 2
		}
	};

	// const handleUpdate = async (field: Partial<AgentSetting>) => {
	// 	await updateAgentSetting(field);
	// };

	// const navigateTo = (url: string) => {
	// 	navigate(url, {
	// 		state: {
	// 			prevPath: 'homepage'
	// 		}
	// 	});
	// };

	return (
		<div className="py-5 pt-md-6 position-relative">
			{isOpen && (
				<CommunityPartnerPreviewCompanyOverviewPremiumDrawer
					selected={selected}
					setIsOpen={setIsOpen}
				/>
			)}
			<Container fluid={false} className={clx(styles.container)}>
				<div
					className={clx(
						styles.boxTitle,
						`d-flex flex-column gap-3 justify-content-center align-items-center text-white border-bottom pb-4`
					)}>
					<div className="fs-3 fw-700">
						{becomePartner?.headline && (
							<div>{becomePartner?.headline}</div>
						)}
					</div>
					<div className="fs-base fw-400">
						{becomePartner?.description && (
							<div>{becomePartner?.description}</div>
						)}
					</div>
					<div className="d-flex align-items-center justify-content-center mt-4 pb-4">
						<PartnerButton
							className="rounded-pill px-md-4 fs-base"
							label={t('button.become-partner') as string}
						/>
					</div>
				</div>
				<div className="mt-4 w-75 mx-auto">
					<div className="text-center  my-5">
						<span className="fs-3 fw-700 text-white">
							{t('pages.subscription-plans')}
						</span>
					</div>
					{!isLoading && subscriptionPlans?.items?.length !== 0 && (
						<Carousel
							spaceBetween={30}
							navigationGroup={true}
							darkBackground={true}
							slidesPerView="auto"
							modules={[Autoplay, Pagination, Navigation]}
							autoplay={{
								delay: 2000,
								disableOnInteraction: false
							}}
							breakpoints={breakpoints}>
							{subscriptionPlans?.items?.map((item) => {
								return (
									<SwiperSlide
										key={item.id}
										className={clx(
											styles.boxPlan,
											'h-auto'
										)}>
										<div
											key={item.id}
											className="d-flex flex-column gap-1">
											<div className="fs-4 fw-700">
												{item.name}
											</div>
											<div className="fs-5 fw-700">
												{currencyFormatter(
													item.price ?? 0
												)}
											</div>
											<div className="fs-sm fw-400">
												{item.shortDescription}
											</div>
											<div className="mt-3">
												<Button
													color={Colors['white-gray']}
													className={styles.edit}
													onClick={() => {
														setIsOpen(true);
														setSelected(
															item as any
														);
													}}>
													{t('button.more-info')}
												</Button>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Carousel>
					)}
				</div>
			</Container>
		</div>
	);
};
