import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	getDiscountFormat,
	getKeyFromValue,
	companySettlementStatus
} from 'Utils';
import { Invoice, TransactionStatus } from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transaction: Invoice;
}

export const CompanyTransactionInvoice: FC<Props> = ({ transaction }) => {
	const { t } = useTranslation();
	const status = transaction?.status;
	// const payUrl = transaction?.payment?.payUrl;
	const row_class = 'justify-content-between';
	const transactionStatus = getKeyFromValue(TransactionStatus, status);
	const badgeText = transactionStatus
		? t(`badge.${transactionStatus.toLowerCase()}`)
		: '';

	const getStatusData = () => {
		return companySettlementStatus.find((elem) => elem.value === status);
	};

	// const payInvoice = () => {
	// 	window.location.replace(transaction?.payment?.payUrl);
	// };

	return (
		<div>
			<h6 className="text-gray-3 mb-3 fs-6 fw-400 d-flex align-items-center justify-content-between">
				{t('title.invoice')}
				{/* <Badge color={getStatusData()?.color ?? Colors.muted}>
					{badgeText}
				</Badge> */}
			</h6>
			{/* <div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.transaction-number')}:
				</span>
				<span className="fs-6">
					{transaction?.invoiceInfo?.invoiceNumber ?? '---'}
				</span>
			</div> */}
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.invoice-number')}:
				</span>
				<span className="fs-6">
					{transaction?.invoiceInfo?.invoiceNumber ?? '---'}
				</span>
			</div>
			{/* <div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.payment-method')}:
				</span>
				{transaction?.provider?.paymentMethodInfo ? (
					<span>{transaction?.provider?.paymentMethodInfo?.paymentMethod}</span>
				) : (
					<span>
						{transaction?.companySubscription?.paymentMethod ===
							PaymentMethod.invoice && t('label.paid-by-invoice')}
						{transaction?.companySubscription?.paymentMethod ===
							PaymentMethod.online &&
							t('label.paid-by-credit-card')}
						{transaction?.companySubscription?.paymentMethod ===
							PaymentMethod.payPal && t('label.paid-by-paypal')}
						{transaction?.companySubscription?.paymentMethod ===
							PaymentMethod.pending &&
							t('label.waiting-for-agent-approval')}
					</span>
				)}
			</div> */}
			<div
				className={clx(styles.row, 'justify-content-between border-0')}>
				<span className={styles.rowLabel}>
					{t('label.created-at')}:
				</span>
				<span>
					{transaction?.createdAt
						? formatLocaleDateTime(transaction?.createdAt)
						: '---'}
				</span>
			</div>
			<hr className="bg-gray-2 mb-3" />
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.price')}:</span>
				<span>
					{currencyFormatter(
						transaction?.price?.subscriptionPrice ?? 0
					)}
				</span>
			</div>
			{transaction?.price?.discountAmount > 0 && (
				<div className={clx(styles.row, row_class)}>
					<div className={clx(styles.rowLabel, 'd-flex flex-column')}>
						<div className="d-flex align-items-center">
							{t('label.discount')}
							<span className="ms-1 text-gray-3 fs-small">
								(
								{getDiscountFormat(
									transaction?.price?.discountPercent
									// transaction.applyVoucher?.discountType
								)}
								)
							</span>
							:
						</div>
						{transaction?.price?.voucherCode && (
							<div className="d-flex align-items-center mt-1 lh-base text-gray-3 fs-small">
								{t('label.voucher-code')}:
								<span className="ms-1">
									{transaction?.price?.voucherCode}
								</span>
							</div>
						)}
					</div>
					<span>
						{transaction?.price?.discountAmount > 0
							? `- ${currencyFormatter(
									transaction?.price?.discountAmount
							  )}`
							: currencyFormatter(0)}
					</span>
				</div>
			)}
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.subtotal')}:</span>
				<span>
					{currencyFormatter(transaction?.price?.subTotal ?? 0)}
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.tax')}
					<span className="ms-1 text-gray-3 fs-small">
						({transaction?.price?.taxPercent ?? 0}%)
					</span>
					:
				</span>
				<span>
					{transaction?.price?.taxAmount > 0
						? `+ ${currencyFormatter(
								transaction?.price?.taxAmount
						  )}`
						: currencyFormatter(0)}
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={clx(styles.rowLabel, 'fw-500')}>
					{t('label.total')}
				</span>
				<div className="d-flex flex-column text-end">
					<span className="fs-6 fw-500">
						{currencyFormatter(
							transaction?.price?.totalAmount ?? 0
						)}
					</span>
					<span className="text-gray-3 lh-base fs-small">
						{t('label.tax-included')}
					</span>
				</div>
			</div>
			{/* {status === TransactionStatus.UnPaid && payUrl && (
				<div className="d-flex align-items-center justify-content-end mt-2">
					<Button
						color={Colors['white-primary']}
						label={t('button.pay-invoice') as string}
						onClick={payInvoice}
					/>
				</div>
			)} */}
		</div>
	);
};
