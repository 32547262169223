import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Container, Row } from 'reactstrap';

import { Button, LoadingSpinner, ProfileBenefitCard } from 'Elements';
import styles from 'partials/homepage/section/benefit-section.module.scss';
import { Colors, ColorValues } from 'Types';
import { Text, Title } from 'src/containers/Styles';
import { clx } from 'src/helpers/Utils';
import { useUpdateBenefits } from 'src/hooks/useBenefit';
import { useState } from 'react';

const GridBox = styled('div')`
	background-color: ${({ theme }) =>
		theme && rgba(theme.businessPartner.primaryColor, 0.3)};
	border-color: ${({ theme }) => theme && theme.businessPartner.primaryColor};
`;

type Props = {
	isLoadingProfile: boolean;
	benefitList: string[];
	benefits: {
		enabled: boolean;
		headline: string;
		description: string | number;
	};
};

export const BusinessPartnerPreviewBenefitSection = ({
	isLoadingProfile,
	benefits,
	benefitList
}: Props) => {
	const { t } = useTranslation();
	const perRow = 8;
	const [next, setNext] = useState(perRow);
	const { benefits: list } = useUpdateBenefits();

	const checkBenefit = (id) => {
		let check = false;
		benefitList?.forEach((item) => {
			if (item === id) {
				check = true;
			}
		});
		return check;
	};

	const handleMoreBenefit = () => {
		setNext(next + perRow);
	};

	return list?.items?.length !== 0 ? (
		<section className="position-relative mb-5 ">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && benefits && (
					<GridBox className={styles.grid}>
						<div
							className={clx(
								styles.title,
								'd-flex flex-column justify-content-center mx-auto'
							)}>
							<Title
								textColor={ColorValues['gray-4']}
								className="h3 fw-700 mb-0 text-center">
								{benefits?.headline}
							</Title>
							{benefits?.description && (
								<Text
									as="div"
									textColor={ColorValues['gray-4']}
									className="mt-3 lh-md text-center">
									{benefits?.description}
								</Text>
							)}
						</div>
						<Row className="g-0">
							{benefitList &&
								list?.items?.slice(0, next)?.map((benefit) => {
									if (checkBenefit(benefit.id)) {
										return (
											<ProfileBenefitCard
												key={benefit.id}
												benefit={benefit}
												isRemoving={true}
												onClick={() => {}}
											/>
										);
									}
								})}
						</Row>
						<div className="d-flex align-items-center justify-content-center mt-4 gap-3">
							{benefitList?.length > next && (
								<Button
									size="lg"
									color={Colors['gray-5']}
									className="fs-base rounded-pill"
									label={t('button.more-benefits') as string}
									onClick={handleMoreBenefit}
								/>
							)}
						</div>
					</GridBox>
				)}
			</Container>
		</section>
	) : (
		<></>
	);
};
