import { memo, FC, useState, useEffect } from 'react';
import axios from 'Adapter';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CompaniesToInvite } from 'Types';
import { api, getCurrentLocale } from 'Utils';
import { Button, ShimmerImage, FontAwesome } from 'Elements';
import styles from 'partials/card/invite-business-partner-card.module.scss';

interface Props {
	card: CompaniesToInvite;
	isInvited: boolean;
	onInvite?: () => void;
}

export const InviteCompanyCard: FC<Props> = memo(
	({ card, isInvited, onInvite }) => {
		const { t } = useTranslation();
		const { event_id } = useParams();
		const [invited, setInvited] = useState(false);
		const [inviting, setInviting] = useState(false);
		const defaultLogo = '/assets/img/business-partner-default.png';

		const inviteCompany: (data: {
			businessPartnerId: string;
		}) => Promise<void> = async (data) => {
			await axios
				.post(api.inviteCompany(event_id as string), data)
				.then((res) => {
					if (res.status === 200) {
						setInvited(true);
						onInvite?.();
					}
				});
		};

		const handleInvite = async () => {
			try {
				const data = {
					businessPartnerId: card.id
				};
				setInviting(true);
				await inviteCompany(data);
			} finally {
				setInviting(false);
			}
		};

		useEffect(() => {
			setInvited(isInvited);
		}, [isInvited]);

		return (
			<div className={styles.card}>
				<div className={styles.logoBox}>
					<ShimmerImage
						src={card.logo}
						width={50}
						height={50}
						alt={card.name}
						defaultImage={defaultLogo}
						className={styles.logo}
					/>
				</div>
				<div className="d-flex align-items-center gap-3 flex-grow-1">
					<div className="me-auto">
						<h2 className="fw-500 fs-sm lh-base mb-1 text-break">
							{card.name}
						</h2>
						<div className="d-flex align-items-center text-gray-3 flex-wrap fs-small">
							{card.postalCode}
							<span className="mx-2">•</span>
							{card.city ?? ''}
							<span className="mx-2">•</span>
							{new Intl.NumberFormat(getCurrentLocale().lang, {
								style: 'unit',
								unit:
									card.distance / 1000 > 1
										? 'kilometer'
										: 'meter',
								maximumFractionDigits:
									card.distance / 1000 > 1 ? 1 : 0
							}).format(
								card.distance > 1000
									? card.distance / 1000
									: card.distance
							)}
						</div>
					</div>
					<Button
						size="sm"
						disabled={inviting || invited}
						onClick={handleInvite}>
						<FontAwesome
							icon={invited ? 'check' : 'plus'}
							size="sm"
							swapOpacity={!invited}
							className="me-2"
						/>
						{!invited ? t('button.invite') : t('button.invited')}
					</Button>
				</div>
			</div>
		);
	}
);

InviteCompanyCard.displayName = 'InviteCompanyCard';
