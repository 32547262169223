import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesome } from 'Elements';
import { clx } from 'Utils';

interface Props {
	path?: string;
	pathInfo?: string;
	className?: string;
}

export const Breadcrumb: FC<Props> = memo(({ path, pathInfo, className }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const pathname = path || location.pathname.substr(1);

	let paths = pathname.split('/');
	if (paths[paths.length - 1].indexOf(':') > -1) {
		paths = paths.filter((x) => x.indexOf(':') === -1);
	}

	const getMenuTitle = (sub: string) => {
		if (`/${sub}` === '/') return t('menu.home') as string;
		if(sub.length > 25) return t('title.details') as string;
		return t(`menu.${sub}`) as string;
	};

	const getUrl: (path: string, sub: string) => string = (path, sub) => {
		return path.split(sub)[0] + sub;
	};

	return (
		<ul
			className={clx(
				'd-none d-sm-flex align-items-center text-gray-3 mt-2 flex-wrap',
				className
			)}>
			{paths.map((sub: string, index: number) => {
				return (
					<li key={index} className="lh-base">
						{paths?.length !== index + 1 ? (
							<Link
								to={`/${getUrl(pathname, sub)}`}
								className="d-flex align-items-center">
								{getMenuTitle(sub)}
								<FontAwesome
									swapOpacity
									
									strokeWidth={1}
									icon="chevron-right"
									size="xs"
									className="mx-2"
								/>
							</Link>
						) : (
							<span>
								{pathInfo ? (
									<span>{` ${pathInfo}`}</span>
								) : (
									getMenuTitle(sub)
								)}
							</span>
						)}
					</li>
				);
			})}
		</ul>
	);
});

Breadcrumb.displayName = 'Breadcrumb';
