import { FC } from 'react';
import { clx } from 'Utils';
import { ShimmerImage } from 'Elements';
import styles from 'partials/job/preview/job-organization-section.module.scss';

interface Props {
	className?: string;
	title?: string;
	logo?: string;
	slogan?: string;
	description?: string;
}

export const JobOrganizationSection: FC<Props> = ({
	title,
	logo,
	slogan,
	description,
	className
}) => {
	const defaultLogo = '/assets/img/business-partner-default.png';
	const organizationLogo = logo;

	return (
		<div
			className={clx(
				className,
				'd-flex flex-column gap-3 pb-3 position-relative'
			)}>
			<div className="d-flex gap-3">
				{organizationLogo && (
					<div className={clx(styles.logoWrapper, 'd-flex')}>
						<ShimmerImage
							src={organizationLogo}
							alt={title ?? ''}
							width={72}
							height={72}
							defaultImage={defaultLogo}
							className={styles.logo}
						/>
					</div>
				)}
				<div className="flex-grow-1 d-flex flex-column justify-content-center">
					<h5 className="fs-5 lh-base mb-0">{title}</h5>
					{slogan && (
						<span className="fw-light lh-base">{slogan}</span>
					)}
				</div>
			</div>
			{description && (
				<div
					className={clx('text', styles.description)}
					dangerouslySetInnerHTML={{
						__html: description
					}}
				/>
			)}
		</div>
	);
};
