import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { names } from 'Constants';
import { getDiscountFormat } from 'Utils';
import { InvitationForm, SelectOption, MyVoucher } from 'Types';
import {
	useInvitation,
	usePrompt,
	useAvailableVouchers,
	useVouchers
} from 'Hooks';
import {
	Card,
	FormSuccess,
	SelectController,
	SubmitButtonGroup,
	SwitchController
} from 'Elements';

export const CreateInvitation = () => {
	const { t } = useTranslation();
	const { invitations } = names;
	const { createInvitation } = useInvitation();
	const [formSuccess, setFormSuccess] = useState(false);
	const [voucherCode, setVoucherCode] = useState(false);
	// const { data: voucherList, isLoading } = useAvailableVouchers();

	const { data: voucherList, isLoading } = useVouchers({
		pageSize: 100,
		pageNumber: 1,
		keyword: ''
	});

	const schema = yup.object().shape({
		email: yup
			.string()
			.email(t('validation.email.type'))
			.required(t('validation.email.required')),
		businessPartnerName: yup
			.string()
			.required(t('validation.business-partner-name.required'))
	});

	const defaultValues: Partial<InvitationForm> = {
		receiverName: '',
		businessPartnerName: '',
		email: '',
		voucherCode: null
	};

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<InvitationForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
		setVoucherCode(false);
	};

	const optionsListObject = useCallback((key_obj: MyVoucher) => {
		return {
			value: key_obj?.voucherCode,
			label: `${key_obj.voucherCode} (${getDiscountFormat(
				key_obj.discount,
				key_obj.discountType
			)})`
		};
	}, []);

	const getVoucherListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		voucherList?.items?.forEach((voucher: MyVoucher) => {
			options.push(optionsListObject(voucher));
		});
		return options;
	}, [voucherList?.items, optionsListObject]);

	const onSetVoucher = (val: boolean) => {
		setVoucherCode(val);
	};

	const onSubmit: SubmitHandler<InvitationForm> = async (data) => {
		const api_data = {
			...data,
			voucherCode: data.voucherCode?.value?.toString() ?? ''
		};
		await createInvitation(api_data, () => {
			startTransition(() => {
				setFormSuccess(true);
				resetForm();
			});
		});
	};

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="receiverName">
									{t('forms.receiver-name')}
								</Label>
								<input
									{...register('receiverName')}
									type="text"
									id="receiverName"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="businessPartnerName">
									{t('forms.business-partner-name')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('businessPartnerName')}
									type="text"
									id="businessPartnerName"
									aria-invalid={!!errors.businessPartnerName}
									className="inputbox w-100"
								/>
								{errors.businessPartnerName && (
									<div className="invalid-feedback d-block">
										{errors.businessPartnerName.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="email">
									{t('forms.email')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('email')}
									type="email"
									id="email"
									aria-invalid={!!errors.email}
									className="inputbox w-100"
								/>
								{errors.email && (
									<div className="invalid-feedback d-block">
										{errors.email.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="voucherCode"
									control={control}
									label="forms.voucher-code"
									boxClassName="py-2"
									onSwitchChange={onSetVoucher}
								/>
							</FormGroup>
							{voucherCode && (
								<FormGroup>
									<Label htmlFor="voucherCode">
										{t('forms.voucher-code')}
									</Label>
									<SelectController
										name="voucherCode"
										isDisabled={isLoading}
										control={control}
										placeholder={
											isLoading
												? 'placeholder.loading'
												: 'placeholder.select-voucher'
										}
										options={getVoucherListOptions}
									/>
								</FormGroup>
							)}
							<SubmitButtonGroup
								cancelButton="/invitations"
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
								label={t('button.send-invitation')}
								icon="paper-plane"
								savingLabel={t('button.sending')}
							/>
						</Col>
					</Row>
				</form>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.send-new-invitation"
					listTitle="button.invitation-list"
					className="flex-grow-1 align-self-center"
					successTitle="title.invitation-sent"
					listUrl={invitations.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
