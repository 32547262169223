import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	FontAwesome,
	Button,
	Collapsible,
	PreviewProfileStaffSection,
	PreviewProfileStaffCard
} from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';
import { useModal } from 'src/hooks/useModal';
import { Colors } from 'Types';
import { useMembers } from 'src/hooks/useMember';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	members: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerMemberSection = ({
	isLoadingProfile,
	onChangeValue,
	members
}: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openModal, toggleModal] = useModal();
	const { data, isLoading } = useMembers({
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	const handleNavigate = () => {
		navigate('/members/create', { state: { prevPath: '/homepage' } });
	};

	return (
		<section className="position-relative mb-5">
			{isLoadingProfile && isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && !isLoading && (
				<Container>
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('menu.members')}
									</h5>
									<ReactSwitch
										size="md"
										checked={members?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'members',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-members-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<SectionHeadline
							title={members?.headline}
							titlePlaceholder={`[${t(
								'placeholder.our-members'
							)}]`}
							titleColor="text-gray-5"
							className="mx-auto text-center"
							onUpdateTitle={(val) =>
								onChangeValue('members', 'headline', val)
							}
						/>

						{data && data?.items?.length > 0 && (
							<PreviewProfileStaffSection
								list={data}
								profileType="communityPartner">
								<SwiperSlide className={styles.card}>
									<ProfileAddNewCard
										navigationUrl="/members/create"
										title="title.add-new-member"
									/>
								</SwiperSlide>
								{data &&
									data?.items?.reverse()?.map((member) => {
										return (
											<SwiperSlide
												key={member.id}
												className={styles.card}>
												<PreviewProfileStaffCard
													id={member.id}
													preview={false}
													name={member.fullName}
													staffType="members"
													image={
														member?.image?.url ?? ''
													}
													position={
														member.jobPosition
													}
													email={member?.email}
													phoneNumber={member?.phoneNumber}
												/>
											</SwiperSlide>
										);
									})}
							</PreviewProfileStaffSection>
						)}
						{data && data?.items?.length === 0 && (
							<div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
								<Button
									size="lg"
									className="gap-3 rounded-pill"
									onClick={handleNavigate}>
									<FontAwesome
										icon="plus"
										size="xl"
										swapOpacity
									/>
									{t('title.add-new-member')}
								</Button>
							</div>
						)}
					</Collapsible>
					<ModalBoxSectionEditProfile
						isOpen={openModal}
						onClose={toggleModal}
						title="info.members-title"
						description="info.members-description"
					/>
				</Container>
			)}
			{!isLoadingProfile && !members?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
