import { useRef, useState, useEffect } from 'react';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerProps } from 'react-hook-form';
import { api, clx } from 'Utils';
import { useModal } from 'Hooks';
import {
	AspectRatio,
	Colors,
	Event,
	FieldValues,
	Media,
	ModalProps
} from 'Types';
import {
	Button,
	LoadingSpinner,
	ModalBox,
	ModalBoxCropImage,
	ModalBoxFooter,
	FontAwesome
} from 'Elements';
import styles from 'partials/modal/modalbox-change-image.module.scss';

type Props<T extends FieldValues> = {
	title: string;
	image: string;
	mediaType?: number;
	withVideo?: boolean;
	showButtonUpload?: boolean;
	onlyView?: boolean;
	aspectRatio?: AspectRatio;
	defaultImage?: string;
	onRemove?: () => void;
	onImageChange?: (mediaData: Media) => void;
} & UseControllerProps<T> &
	ModalProps;

export const ModalBoxChangeImage = <T extends FieldValues>({
	control,
	name,
	isOpen,
	onClose,
	title,
	onlyView,
	image,
	mediaType = 0,
	withVideo = false,
	showButtonUpload = true,
	aspectRatio,
	defaultImage = '/assets/img/default-image.png',
	onImageChange,
	onRemove
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	const [openModalChange, toggleModalChange] = useModal();
	const imageFileInput = useRef<HTMLInputElement>(null);
	const [state, setState] = useState({
		imageLoading: false,
		blobImage: '',
		currentImage: image,
		currentVideo: '',
		fileName: ''
	});

	const handleRemove = () => {
		setState((prev) => ({
			...prev,
			currentImage: '',
			currentVideo: ''
		}));
		onRemove?.();
	};

	const onUploadMedia = () => {
		imageFileInput?.current?.click();
	};

	const uploadMediaRequest: (
		formData: FormData,
		onFinal: () => void
	) => Promise<Media> = async (formData, onFinal) => {
		return await axios
			.post(api.uploadMedia, formData)
			.then((res) => {
				if (res.status === 200) {
					return res.data;
				}
			})
			.catch((error) => {
				console.error('Media upload failed:', error);
			})
			.finally(() => onFinal?.());
	};

	const handleCropImage = async (image: FormData) => {
		if (image) {
			setState((prev) => ({
				...prev,
				imageLoading: true
			}));
			const res = await uploadMediaRequest(image, () =>
				setState((prev) => ({
					...prev,
					imageLoading: false
				}))
			);
			if (res) {
				onImageChange?.(res);
				setState({
					imageLoading: false,
					blobImage: '',
					currentImage: '',
					currentVideo: '',
					fileName: ''
				});
				onClose && onClose();
			}
		}
	};

	const uploadMedia = async (e: Event<HTMLInputElement>) => {
		const { files } = e.target;

		if (files && files[0]) {
			const file = files[0];
			const blob = URL.createObjectURL(file);
			const reader = new FileReader();

			reader.onload = () => {
				if (file.type.startsWith('image/')) {
					setState((prev) => ({
						...prev,
						blobImage: blob,
						fileName: file.name ?? ""
					}));
					toggleModalChange();
				} else if (file.type.startsWith('video/')) {
					setState((prev) => ({
						...prev,
						currentVideo: blob,
						fileName: file.name ?? ""
					}));
					const form = new FormData();
					form.append('formFile', file);
					handleCropImage(form);
				}
			};
			reader.readAsArrayBuffer(file);
		}
		e.target.value = '';
	};

	useEffect(() => {
		setState((prev) => ({
			...prev,
			currentImage: image,
			currentVideo: mediaType === 1 ? image : ''
		}));
	}, [isOpen, image, mediaType]);

	useEffect(() => {
		return () => {
			if (state.blobImage) {
				URL.revokeObjectURL(state.blobImage);
			}
			if (state.currentVideo) {
				URL.revokeObjectURL(state.currentVideo);
			}
		};
	}, [state.blobImage, state.currentVideo]);

	return (
		<ModalBox
			isOpen={isOpen}
			onClose={() => {
				onClose && onClose();
				setState({
					imageLoading: false,
					blobImage: '',
					currentImage: '',
					currentVideo: '',
					fileName: ''
				});
			}}
			title={onlyView ? '' : title}
			size="lg">
			{control && (
				<Controller
					control={control}
					name={name}
					render={({ field: { onChange } }) => (
						<input
							ref={imageFileInput}
							type="file"
							accept={
								withVideo
									? 'image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/mov, video/avi'
									: 'image/png, image/jpg, image/jpeg, image/webp'
							}
							className="d-none"
							onChange={(val) => {
								onChange(val);
								uploadMedia(val);
							}}
						/>
					)}
				/>
			)}
			{!control && (
				<input
					ref={imageFileInput}
					type="file"
					accept={
						withVideo
							? 'image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/mov, video/avi'
							: 'image/png, image/jpg, image/jpeg, image/webp'
					}
					className="d-none"
					onChange={(event) => uploadMedia(event)}
				/>
			)}
			<div
				className={clx(
					styles.box,
					state.imageLoading && styles.boxLoading
				)}>
				{state.currentImage && !state.currentVideo ? (
					<img
						src={state.currentImage || defaultImage}
						className={clx(
							styles.image,
							aspectRatio === '16/9' && styles.imageRatio
						)}
						alt=""
					/>
				) : state.currentVideo ? (
					<video
						controls
						style={{
							width: '100%'
						}}
						src={state.currentVideo}
						className={clx(
							styles.image,
							aspectRatio === '16/9' && styles.imageRatio
						)}
					/>
				) : (
					<img
						src={defaultImage}
						className={clx(
							styles.image,
							aspectRatio === '16/9' && styles.imageRatio
						)}
						alt=""
					/>
				)}
				{showButtonUpload && (
					<div className="text-center mt-3 text-gray-3 fs-sm">
						{withVideo
							? t('forms.media-accept-type')
							: t('forms.image-accept-type')}
					</div>
				)}
				{state.imageLoading && (
					<div className={styles.loading}>
						<LoadingSpinner size="2x" color="#fff" />
					</div>
				)}
			</div>
			<ModalBoxFooter>
				{onRemove && state.currentImage && (
					<Button
						color={Colors['white-red']}
						disabled={state.imageLoading}
						onClick={handleRemove}>
						<FontAwesome
							icon="trash-xmark"
							size="lg"
							className="me-2"
						/>
						{t('forms.remove')}
					</Button>
				)}
				{showButtonUpload && (
					<div className="ms-auto flex-shrink-0">
						<Button
							className="ms-2"
							disabled={state.imageLoading}
							onClick={onUploadMedia}>
							<FontAwesome
								icon="arrow-up-from-bracket"
								size="lg"
								className="me-2"
							/>
							{withVideo
								? t('button.upload-new-media')
								: t('button.upload-new-photo')}
						</Button>
					</div>
				)}
			</ModalBoxFooter>
			<ModalBoxCropImage
				isOpen={openModalChange}
				image={state.blobImage}
				aspectRatio={aspectRatio}
				onClose={toggleModalChange}
				onCrop={handleCropImage}
			/>
		</ModalBox>
	);
};

ModalBoxChangeImage.displayName = 'ModalBoxChangeImage';
