import { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useGeneralJobUsage, useModal, useSelector, useUpdateJob } from 'Hooks';
import { ModalBoxPublishJob, SubmitButton } from 'Elements';
import { useQueryClient } from 'react-query';

import { JobFormData, JobFormInitialData, JobStatusEnum } from 'Types';
import useGetJobKind from './tab/visibility-setting/hooks/useGetJobKind';
import { queryKey } from 'src/constants/QueryKey';

interface Props {
	formData: (data: JobFormInitialData) => JobFormData;
}

export const SaveAndPublishJob: FC<Props> = ({ formData }) => {
	const { job_id } = useParams();
	const queryClient = useQueryClient();
	const location = useLocation();
	const { data: generalUsage } = useGeneralJobUsage();
	const { getJobCapacity } = useGetJobKind(generalUsage);

	const { assetConfig } = useSelector((state) => state.marketing);
	const [publishing, setPublishing] = useState(false);
	const [openModalPublish, toggleModalPublish] = useModal();
	const {
		saveAndPublishJob,
		data: job,
		refetch
	} = useUpdateJob(job_id as string);
	const {
		watch,
		trigger,
		getValues,
		formState: { isDirty }
	} = useFormContext<
		JobFormInitialData & {
			jobVisibilities: {
				subscriptionId: string;
				communityPartnerId: string;
			}[];
		}
	>();

	const handleClick = async () => {
		try {
			const isValidForm = await trigger();
			if (isValidForm) {
				setPublishing(true);
				// if (!watchAutoPublish) {
				const values = getValues();
				await saveAndPublishJob(
					job_id as string,
					formData(values),
					() => {
						queryClient.invalidateQueries(queryKey.subscriptions);
						queryClient.invalidateQueries(queryKey.jobUsage);
						queryClient.invalidateQueries(queryKey.jobUsageGeneral);
						refetch();
						setPublishing(false);
						toggleModalPublish();
					}
				);
				// }
			}
		} finally {
			toggleModalPublish();
			setPublishing(false);
		}
	};

	const checkDisabled = () => {
		let check = false;
		// const jobKind = getJobCapacity(watch('kind') as any);
		// const isPublish = watch('jobStatus') == JobStatusEnum.Published;

		// const jobVisibilities = watch().jobVisibilities;
		// const checkUsage = !jobKind
		// 	? false
		// 	: jobKind?.usage === jobKind?.capacity;

		// if (assetConfig?.partnerType === 2 && checkUsage && jobVisibilities?.length === 0 && !isPublish) {
		// 	check = true;
		// 	return check;
		// }
		if (job?.publishedVersion !== job?.version) {
			check = false;
		}
		if (
			// watchAutoPublish ||
			job?.publishedVersion === job?.version
		) {
			check = true;
		}
		if (
			isDirty
			// && !watchAutoPublish
		) {
			check = false;
		}

		return check;
	};

	return (
		<>
			{(assetConfig?.partnerType === 1 ||
				location.pathname.includes('visibility-settings')) && (
				<SubmitButton
					type="button"
					icon="upload"
					label="button.save-publish"
					savingLabel="button.publishing"
					isDisable={checkDisabled()}
					isSubmitting={publishing}
					onClick={() => {
						toggleModalPublish();
					}}
				/>
			)}
			<ModalBoxPublishJob
				userType={
					assetConfig?.partnerType === 2
						? 'businessPartner'
						: 'communityPartner'
				}
				refetch={refetch}
				partnerStatus={assetConfig?.partnerStatus}
				type={'publish'}
				isOpen={openModalPublish}
				onSubmit={handleClick}
				onClose={toggleModalPublish}
			/>
		</>
	);
};
