import { Col, Container, Row } from 'reactstrap';
import { useTheme } from 'Hooks';
import { LoadingSpinner, FontAwesome } from 'Elements';
import { clx } from 'src/helpers/Utils';
import { useTranslation } from 'react-i18next';

type Props = {
	isLoadingProfile: boolean;
	statistics: {
		memberCount: string;
		enabled: boolean;
		establishedDate: string;
		departments: string;
	};
};

export const CommunityPartnerPreviewStatisticSection = ({
	isLoadingProfile,
	statistics
}: Props) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center my-4 py-4">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && statistics && (
					<Row className="d-flex justify-content-center mx-lg-5 pt-3">
						{statistics?.establishedDate && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="calendar"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.established-date')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.establishedDate}
									</div>
								</div>
							</Col>
						)}
						{statistics?.memberCount && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="users"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.members')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.memberCount}
									</div>
								</div>
							</Col>
						)}
						{statistics?.departments && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="building"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.departments')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.departments}
									</div>
								</div>
							</Col>
						)}
						
					</Row>
				)}
			</Container>
			{!isLoadingProfile && !statistics?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
