import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCommunityPartnerCredit, useResponsive } from 'Hooks';
import { Colors, TransactionType } from 'Types';
import {
	clx,
	CommunityPartnerCreditStatus,
	currencyFormatter,
	formatLocaleDateTime
} from 'Utils';
import {
	Card,
	LoadingContent,
	Badge,
	FontAwesome,
	ShimmerImage
} from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';

export const ViewCredit = () => {
	const { t } = useTranslation();
	const defaultImage = '/assets/img/business-partner-default.png';
	const { credit_id } = useParams();
	const { isTabletAndAbove, isDesktopAndAbove } = useResponsive();
	const { data: credit, isLoading } = useCommunityPartnerCredit(
		credit_id as string
	);

	const getStatusData = (status) => {
		return CommunityPartnerCreditStatus.find(
			(elem) => elem.value === status
		);
	};

	const getImageSize = () => {
		return isDesktopAndAbove ? 160 : isTabletAndAbove ? 136 : 112;
	};

	return (
		<>
			<Row className='mb-4'>
				<Col>
					<Card className="h-100">
						<div
							className={clx(
								styles.body,
								'd-flex flex-column flex-md-row gap-3'
							)}>
							<div className={styles.logoBox}>
								<ShimmerImage
									width={getImageSize()}
									height={getImageSize()}
									src={
										credit?.businessPartnerInfo
											?.thumbnailLogo ?? defaultImage
									}
									defaultImage={defaultImage}
									className={styles.shimmer}
									alt={
										credit?.businessPartnerInfo
											?.organizationName ?? ''
									}
								/>
							</div>
							<div className="flex-grow-1">
								<h3 className="h5 lh-sm fw-400 text-break">
									{
										credit?.businessPartnerInfo
											?.organizationName
									}
								</h3>
								<div className="mb-2">
									{credit?.businessPartnerInfo
										?.emailAddress && (
										<span>
											{
												credit?.businessPartnerInfo
													?.emailAddress
											}
										</span>
									)}
								</div>
							</div>
						</div>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card className="h-100">
						<h6
							className={clx(
								styles.row,
								'd-flex align-items-center justify-content-between text-gray-3 fs-6 fw-400'
							)}>
							<span className={styles.rowLabel}>
								{t('title.credit')}
							</span>
							<div className="d-flex align-items-center gap-2">
								<Badge
									color={
										getStatusData(credit?.creditStatus)
											?.color ?? Colors.primary
									}>
									{t(
										`${
											getStatusData(credit?.creditStatus)
												?.label
										}`
									)}
								</Badge>
								{credit?.donated ? (
									<Badge color={Colors.association}>
										{t('badge.donated')}
									</Badge>
								) : (
									<></>
								)}
							</div>
						</h6>
						<Row>
							<Col sm={12}>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.credit-number')}
									</span>
									<span className="fs-6">
										{credit?.creditNumber ?? '---'}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.current-active-abo')}
									</span>
									<span>
										{credit?.activeAboCount ?? '---'}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.created-at')}
									</span>
									<span>
										{credit?.createdAt
											? formatLocaleDateTime(
													credit?.createdAt
											  )
											: '---'}
									</span>
								</div>
								{credit?.taxAmount &&
								credit?.taxAmount !== 0 ? (
									<div
										className={clx(
											styles.row,
											'd-flex align-items-center justify-content-between'
										)}>
										<span className={styles.rowLabel}>
											{t('label.tax')}
											<span className="ms-1 text-gray-3 fs-small">
												({credit?.taxPercent ?? 0}%)
											</span>
										</span>
										<span>
											{currencyFormatter(
												credit?.taxAmount &&
													credit.taxAmount !== 0
													? credit.taxAmount
													: 0
											)}
										</span>
									</div>
								) : (
									<></>
								)}
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span
										className={clx(
											styles.rowLabel,
											'fw-500'
										)}>
										{t('label.total')}
									</span>
									<div className="d-flex align-items-center text-end">
										<span className="fs-6 fw-500">
											{currencyFormatter(
												credit?.totalCreditAmount ?? 0
											)}
										</span>
										<span className="text-gray-3 ms-2 fs-small">
											(
											{credit?.includedTax
												? t('label.tax-included')
												: t('label.no-tax')}
											)
										</span>
									</div>
								</div>
							</Col>
						</Row>
						{isLoading && <LoadingContent />}
					</Card>
				</Col>
				<Col>
					<Card>
						<h6
							className={clx(
								styles.row,
								'text-gray-3 fs-6 fw-400'
							)}>
							<span className={styles.rowLabel}>
								{t('title.subscription-plan')}
							</span>
						</h6>
						<Row>
							<Col sm={12}>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.name')}
									</span>
									<span className="fs-6">
										{credit?.subscriptionPlanInfo?.name ??
											'---'}
									</span>
								</div>
								{/* <div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.id')}
									</span>
									<span>
										{
											credit?.subscriptionPlanInfo
												?.subscriptionPlanId
										}
									</span>
								</div> */}
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.code')}
									</span>
									<span>
										{
											credit?.subscriptionPlanInfo
												?.subscriptionPlanCode
										}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.duration')}
									</span>
									<span>
										{`${
											credit?.subscriptionPlanInfo
												?.durationDays
										} ${t('title.days')}`}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.type')}
									</span>
									<span>
										<Badge
											color={
												credit?.subscriptionPlanInfo
													?.type === 1
													? Colors.premium
													: Colors.tertiary
											}
											className="gap-2">
											{credit?.subscriptionPlanInfo
												?.type === 1 && (
												<FontAwesome
													icon={'medal'}
													size="lg"
												/>
											)}
											{credit?.subscriptionPlanInfo
												?.type === 1
												? t('button.premium')
												: t('button.standard')}
										</Badge>
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span
										className={clx(
											styles.rowLabel,
											'fw-500'
										)}>
										{t('label.price')}
									</span>
									<span className="fw-500">
										{currencyFormatter(
											credit?.subscriptionPlanInfo
												?.price ?? 0
										)}
									</span>
								</div>
							</Col>
						</Row>
						{isLoading && <LoadingContent />}
					</Card>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Card className="h-100">
						<h6
							className={clx(
								styles.row,
								'd-flex align-items-center justify-content-between text-gray-3 fs-6 fw-400'
							)}>
							<span className={styles.rowLabel}>
								{t('label.commission-group')}
							</span>
							<div className="d-flex gap-2 align-items-center">
								<Badge
									color={
										credit?.commissionGroupInfo?.isPremium
											? Colors.premium
											: Colors.tertiary
									}
									className="gap-2">
									{credit?.commissionGroupInfo?.isPremium && (
										<FontAwesome icon={'medal'} size="lg" />
									)}
									{credit?.commissionGroupInfo?.isPremium
										? t('button.premium')
										: t('button.standard')}
								</Badge>
								<Badge
									color={
										credit?.commissionGroupInfo?.isActive
											? Colors.green
											: Colors.muted
									}
									className="gap-2">
									{credit?.commissionGroupInfo?.isActive
										? t('badge.active')
										: t('badge.inactive')}
								</Badge>
							</div>
						</h6>
						<Row>
							<Col sm={12}>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.title')}
									</span>
									<span>
										{credit?.commissionGroupInfo?.title ??
											'---'}
									</span>
								</div>
								{/* <div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.id')}
									</span>
									<span>
										{credit?.commissionGroupInfo
											?.commissionGroupId ?? '---'}
									</span>
								</div> */}
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.is-premium')}
									</span>
									<span>
										{credit?.commissionGroupInfo?.isPremium
											? t('label.yes')
											: t('label.no')}
									</span>
								</div>
							</Col>
						</Row>
						{isLoading && <LoadingContent />}
					</Card>
				</Col>
				<Col>
					<Card>
						<h6
							className={clx(
								styles.row,
								'text-gray-3 fs-6 fw-400'
							)}>
							<span className={styles.rowLabel}>
								{t('label.commission-level')}
							</span>
						</h6>
						<Row>
							<Col sm={12}>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.title')}
									</span>
									<span>
										{credit?.commissionLevelInfo?.title ??
											'---'}
									</span>
								</div>
								{/* <div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.id')}{' '}
									</span>
									<span>
										{
											credit?.commissionLevelInfo
												?.commissionLevelId
										}
									</span>
								</div> */}
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.min-active-abo')}
									</span>
									<span>
										{
											credit?.commissionLevelInfo
												?.minActiveAbo
										}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.max-active-abo')}
									</span>
									<span>
										{
											credit?.commissionLevelInfo
												?.maxActiveAbo
										}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.commission-percent')}
									</span>
									<span>
										{`${credit?.commissionLevelInfo?.commissionPercent}%`}
									</span>
								</div>
								<div
									className={clx(
										styles.row,
										'd-flex align-items-center justify-content-between'
									)}>
									<span className={styles.rowLabel}>
										{t('label.commission-cost')}
									</span>
									<span>
										{currencyFormatter(
											credit?.commissionLevelInfo
												?.commissionCost ?? 0
										)}
									</span>
								</div>
							</Col>
						</Row>
						{isLoading && <LoadingContent />}
					</Card>
				</Col>
			</Row>
		</>
	);
};
