import { MenuItem } from 'Types';
import { config } from 'Utils';

export const businessPartnerMenu: MenuItem[] | any[] = [
	// {
	// 	id: 'dashboard',
	// 	label: 'menu.dashboard',
	// 	icon: 'gauge-low',
	// 	to: `/`
	// },
	{
		id: 'business-partner',
		icon: 'building',
		label: 'menu.my-business-partner',
		subs: [
			{
				label: 'menu.profile',
				to: `/my-business-partner`
			},
			{
				label: 'menu.employees',
				to: '/employees'
			},
			{
				label: 'menu.branches',
				to: '/branches'
			}
		]
	},
	{
		id: 'jobs',
		icon: 'briefcase',
		label: 'menu.job',
		subs: [
			{
				label: 'menu.list-jobs',
				to: `/jobs`
			},
			{
				label: 'menu.applicants-requests',
				to: '/requests'
			},
			// {
			// 	label: 'menu.internships',
			// 	to: '/internships'
			// },
			// {
			// 	label: 'menu.internship-management',
			// 	to: '/internship-management'
			// },
			// {
			// 	label: 'menu.internship-participants',
			// 	to: '/internship-participants'
			// }
		]
	},

	{
		id: 'business_partner_events',
		icon: 'calendar-day',
		label: 'menu.event.key',
		subs: [
			{
				label: 'menu.my-events',
				to: `/events/my-events`
			}
		]
	},
	// Marketing toolbox menu
	// {
	// 	id: 'marketing',
	// 	label: 'menu.marketing',
	// 	icon: 'megaphone',
	// 	subs: [
	// 		{
	// 			label: 'menu.toolbox',
	// 			to: '/marketing/toolbox'
	// 		}
	// 	]
	// },
	// Transaction menu
	{
		id: 'subscriptions',
		label: 'menu.subscriptions',
		icon: 'arrow-up-arrow-down',
		subs: [
			{
				label: 'menu.my-subscriptions',
				to: '/subscriptions'
			},
			{
				label: 'menu.invoices',
				to: '/invoices'
			},
			{
				label: 'menu.payment-methods',
				to: '/payment-methods'
			}
		]
	},
	// User menu
	{
		id: 'user',
		icon: 'users-gear',
		label: 'menu.users',
		to: '/users'
	}
];
