import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorValues, ModalProps } from 'Types';
import { ModalBox, FontAwesome } from 'Elements';

interface Props extends ModalProps {}

export const ModalBoxHelpIcon: FC<Props> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	return (
		<ModalBox
			isOpen={isOpen}
			size="lg"
			onClose={onClose}
			icon={
				<FontAwesome
					icon="circle-info"
					size="lg"
					color={ColorValues.green}
				/>
			}
			title="button.more-info">
			<header className="d-flex flex-column gap-1">
				<span className="fs-sm fw-500">
					1- {t('label.helpIcon.open')}{' '}
					<a
						href="https://fontawesome.com/search"
						className="fw-semibold"
						rel="noreferrer"
						style={{"textDecoration":"underline"}}
						target="_blank">
						https://fontawesome.com/search
					</a>
				</span>
				<span className="fs-sm fw-500">
					2- {t('label.helpIcon.choose-icon')}
				</span>
				<span className="fs-sm fw-500">
					3- {t('label.helpIcon.copy-title')}
				</span>
				<span className="fs-sm fw-500">
					4- {t('label.helpIcon.paste-title-in-dashboard')}
				</span>
				<img
					className="mt-4"
					src="/assets/img/helpIcon.png"
					alt="icon"
				/>
			</header>
		</ModalBox>
	);
};
