import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { clx } from 'Utils';
import { Colors, CommunityPartnerMe } from 'Types';
import {
	LoadingSpinner,
	Card,
	LoadingMask,
	AgentJobCardLoading,
	SectionHeadline,
	Button,
	FontAwesome,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	Collapsible
} from 'Elements';
import styles from 'partials/homepage/section/profile-job-section.module.scss';
import { useModal } from 'src/hooks/useModal';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	communityPartnerMe: CommunityPartnerMe;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | number
	) => void;
	jobSearch: {
		enabled: boolean;
		headline: string;
		jobFilter: number;
	};
};

export const CommunityPartnerJobSearchSection: FC<Props> = ({
	isLoadingProfile,
	onChangeValue,
	jobSearch,
	communityPartnerMe
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && jobSearch && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('text.jobSearch')}
									</h5>
									<ReactSwitch
										size="md"
										checked={jobSearch?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'jobSearch',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-jobs-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<div className="pb-4 position-relative">
							{
								communityPartnerMe?.commissionGroupInfo
									?.isPremium && (
									<>
										<FormGroup
											check
											className="d-flex align-items-center gap-2 mb-0">
											<input
												type="radio"
												checked={
													jobSearch?.jobFilter === 0
												}
												onChange={() => {
													onChangeValue(
														'jobSearch',
														'jobFilter',
														0
													);
												}}
												id="showAllJobsRegion"
												value="false"
												className="form-radio-input"
											/>
											<label
												htmlFor="showAllJobsRegion"
												className="form-radio-label">
												{t(
													'forms.show-all-jobs-in-the-region'
												)}
											</label>
										</FormGroup>
										<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
											{t(
												'forms.show-all-jobs-in-the-region-description'
											)}
										</p>
										<FormGroup
											check
											className="d-flex align-items-center gap-2 mb-0">
											<input
												type="radio"
												checked={
													jobSearch?.jobFilter === 2
												}
												onChange={() => {
													onChangeValue(
														'jobSearch',
														'jobFilter',
														2
													);
												}}
												id="businessPartners"
												value="false"
												className="form-radio-input"
											/>
											<label
												htmlFor="businessPartners"
												className="form-radio-label">
												{t(
													'forms.show-only-jobs-from-my-direct-business-partners'
												)}
											</label>
										</FormGroup>
										<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
											{t(
												'forms.show-only-jobs-from-my-direct-business-partners-description'
											)}
										</p>
										<FormGroup
											check
											className="d-flex align-items-center gap-2">
											<input
												type="radio"
												checked={
													jobSearch?.jobFilter === 1
												}
												onChange={() => {
													onChangeValue(
														'jobSearch',
														'jobFilter',
														1
													);
												}}
												id="communityPartners"
												value="false"
												className="form-radio-input"
											/>
											<label
												htmlFor="communityPartners"
												className="form-radio-label">
												{t(
													'forms.show-all-jobs-in-the-regions-but-no-jobs-from-other-community-partners'
												)}
											</label>
										</FormGroup>
									</>
								)
								// : (
								// 	<>
								// 		<FormGroup
								// 			check
								// 			className="d-flex align-items-center gap-2 mb-0">
								// 			<input
								// 				type="radio"
								// 				checked={jobSearch?.jobFilter === 0}
								// 				onChange={() => {
								// 					onChangeValue(
								// 						'jobSearch',
								// 						'jobFilter',
								// 						0
								// 					);
								// 				}}
								// 				id="showAllJobsRegion"
								// 				value="false"
								// 				className="form-radio-input"
								// 			/>
								// 			<label
								// 				htmlFor="showAllJobsRegion"
								// 				className="form-radio-label">
								// 				{t(
								// 					'forms.show-all-jobs-in-the-region'
								// 				)}
								// 			</label>
								// 		</FormGroup>
								// 		<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
								// 			{t(
								// 				'forms.show-all-jobs-in-the-region-description'
								// 			)}
								// 		</p>
								// 		<FormGroup
								// 			check
								// 			className="d-flex align-items-center gap-2">
								// 			<input
								// 				type="radio"
								// 				checked={jobSearch?.jobFilter === 1}
								// 				onChange={() => {
								// 					onChangeValue(
								// 						'jobSearch',
								// 						'jobFilter',
								// 						1
								// 					);
								// 				}}
								// 				id="communityPartners"
								// 				value="false"
								// 				className="form-radio-input"
								// 			/>
								// 			<label
								// 				htmlFor="communityPartners"
								// 				className="form-radio-label">
								// 				{t(
								// 					'forms.show-all-jobs-in-the-regions-but-no-jobs-from-other-community-partners'
								// 				)}
								// 			</label>
								// 		</FormGroup>
								// 	</>
								// )
							}
							<h3 className="h3 text-gray-5 mb-5 pb-5 lh-base d-inline-flex mt-3">
								<SectionHeadline
									title={jobSearch?.headline}
									titlePlaceholder={`[${t(
										'placeholder.internship-list-headline'
									)}]`}
									titleColor="text-gray-5"
									className="mx-auto text-center"
									onUpdateTitle={(val) =>
										onChangeValue(
											'jobSearch',
											'headline',
											val
										)
									}
								/>
							</h3>
							<Row>
								<Col xl={3} className="d-none d-xl-block">
									<Card
										background="gray-1"
										className={clx(
											styles.card,
											'shadow-none'
										)}>
										<div className={styles.sidebar_header}>
											<LoadingMask
												className={clx(
													styles.loading,
													styles.loadingLg
												)}
											/>
										</div>
										<div className={styles.sidebar_body}>
											<div
												className={
													styles.sidebar_section
												}>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingSm,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingMd,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingLg
													)}
												/>
											</div>
											<div
												className={
													styles.sidebar_section
												}>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingSm,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingMd,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingLg
													)}
												/>
											</div>
											<div
												className={
													styles.sidebar_section
												}>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingSm,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingMd,
														'mb-3'
													)}
												/>
												<LoadingMask
													className={clx(
														styles.loading,
														styles.loadingLg,
														'mb-3'
													)}
												/>
											</div>
										</div>
									</Card>
								</Col>
								<Col xl={9}>
									<AgentJobCardLoading count={3} />
								</Col>
							</Row>
						</div>
					</Collapsible>
				)}
				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.jobSearch-title"
					description="info.jobSearch-description"
				/>
				{!jobSearch?.enabled && (
					<div className="overlay overlay_white overlay-box" />
				)}
			</Container>
		</section>
	);
};
