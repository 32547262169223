import { memo, Suspense, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet, useParams } from 'react-router-dom';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { clx } from 'Utils';
import { useResponsive, useEvent } from 'Hooks';
import { AgentEvent, EventFormData, EventStatus, EventType } from 'Types';
import {
	LoadingContent,
	EventDecorationBox,
	CancelEvent,
	SaveAndPublishEvent,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/page/form.module.scss';

export const UpdateEvent = memo(() => {
	const { t } = useTranslation();
	const { event_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const descriptionLimit = 450;
	const validate_number_message = 'validation.number.type';

	const {
		data: fields,
		isFetching,
		updateEvent,
		isError
	} = useEvent(event_id as string);

	const schema = yup.object().shape(
		{
			title: yup.string().required(t('validation.event-title.required')),
			startDate: yup
				.string()
				.nullable()
				.required(t('validation.start-date.required')),
			endDate: yup
				.string()
				.nullable()
				.required(t('validation.end-date.required')),
			meetingLink: yup.string().when('eventType', {
				is: (eventType: number) => eventType === 0 || eventType === 2,
				then: yup
					.string()
					.required(t('validation.meeting-link.required'))
			}),
			address: yup
				.object()
				.nullable()
				.when('eventType', {
					is: (eventType: number) =>
						eventType === 1 || eventType === 2,
					then: yup
						.object()
						.nullable()
						.required(t('validation.address.required'))
				}),
			participationType: yup
				.number()
				.nullable()
				.required(t('validation.participation-type.required')),
			partnerLimit: yup
				.number()
				.typeError(t(validate_number_message))
				.when(['hasPartnerLimit', 'participationType'], {
					is: (hasPartnerLimit: number, participationType: number) =>
						participationType !== 0 && !!hasPartnerLimit,
					then: yup
						.number()
						.required(t('validation.business-partner-limit.required'))
						.integer(t(validate_number_message))
						.min(1, t('validation.business-partner-limit.min'))
						.typeError(t(validate_number_message))
				}),
			participantLimit: yup
				.number()
				.typeError(t(validate_number_message))
				.when(['hasParticipantLimit', 'participationType'], {
					is: (
						hasParticipantLimit: number,
						participationType: number
					) => participationType !== 0 && !!hasParticipantLimit,
					then: yup
						.number()
						.required(t('validation.visitor-limit.required'))
						.integer(t(validate_number_message))
						.min(1, t('validation.visitor-limit.min'))
						.typeError(t(validate_number_message))
				}),
			seatPerPartnerLimit: yup
				.number()
				.typeError(t(validate_number_message))
				.when(['hasPartnerLimit', 'participationType'], {
					is: (hasPartnerLimit: number, participationType: number) =>
						participationType !== 0 && !!hasPartnerLimit,
					then: yup
						.number()
						.integer(t(validate_number_message))
						.required(t('validation.number-of-seat.required'))
						.min(1, t('validation.number-of-seat.min'))
						.typeError(t(validate_number_message))
				})
		},
		[
			['seatPerPartnerLimit', 'hasPartnerLimit'],
			['seatPerPartnerLimit', 'participationType'],
			['partnerLimit', 'participationType'],
			['partnerLimit', 'hasPartnerLimit'],
			['participantLimit', 'participationType'],
			['participantLimit', 'hasParticipantLimit']
		]
	);

	const formMethods = useForm<EventFormData>({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	const { reset, handleSubmit } = formMethods;

	const getEventFormData = (data: EventFormData) => {
		return {
			...fields,
			...data,
			endDate: new Date(data?.endDate),
			startDate: new Date(data?.startDate),
			registrationStartDate: data?.registrationStartDate
				? new Date(data?.registrationStartDate)
				: '',
			registrationEndDate: data?.registrationEndDate
				? new Date(data?.registrationEndDate)
				: '',
			partnerLimit: data.partnerLimit,
			participantLimit: data.participantLimit,
			seatPerPartnerLimit: data.hasPartnerLimit
				? data.seatPerPartnerLimit
				: '-1'
		} as AgentEvent;
	};

	const submitForm: (
		data: EventFormData,
		onFinal?: () => void
	) => Promise<void> = async (data, onFinal) => {
		await updateEvent(getEventFormData(data), onFinal);
	};

	const submitHandler: SubmitHandler<EventFormData> = async (data) => {
		await submitForm(data);
	};

	useEffect(() => {
		reset({
			...fields,
			address:
				fields?.eventType === EventType.Online ? null : fields?.address,
			shortDescription: fields?.shortDescription.substring(
				0,
				descriptionLimit
			),
			description: fields?.description || '',
			hasParticipantLimit: fields?.participantLimit == '-1' ? 0 : 1,
			hasPartnerLimit: fields?.partnerLimit == '-1' ? 0 : 1,
			seatPerPartnerLimit:
				fields?.partnerLimit === '-1'
					? '-1'
					: fields?.seatPerPartnerLimit
		});
	}, [reset, fields]);

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={clx(isDesktopAndBelow && styles.form)}>
				<EventDecorationBox />
				<Suspense fallback={<LoadingContent />}>
					<div className="position-relative">
						<Outlet context={fields} />
						{isFetching && <LoadingContent />}
						{isError && <SystemErrorAlert />}
					</div>
				</Suspense>
				{!isError &&
					fields?.eventStatus !== EventStatus.Canceled &&
					!fields?.isFinished && (
						<SaveAndPublishEvent formData={getEventFormData} />
					)}
				{!isError &&
					fields?.isPublished &&
					fields?.eventStatus !== EventStatus.Canceled &&
					!fields?.isFinished && <CancelEvent />}
			</form>
		</FormProvider>
	);
});

UpdateEvent.displayName = 'UpdateEvent';
