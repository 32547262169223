import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { names } from 'Constants';
import { useCommunityPartnerCreditPayments } from 'Hooks';
import { BadgeStatus, Colors, TransactionType } from 'Types';
import {
	clx,
	currencyFormatter,
	agentPaymentStatus,
	unslug,
	getCurrentLocale
} from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	NoData,
	Pagination,
	ListingPage,
	Button,
	EndOfList,
	Badge,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/transaction.module.scss';


export const AgentListPayment = memo(() => {
	const { payments } = names;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = unslug(searchParams.get('status') ?? '') ?? '';

	const [state, setState] = useState({
		pageSize: 10,
		totalItems: 0,
		currentPage: pageParam,
		keyword: queryParam,
		filter: {
			status: statusParam
		}
	});

	const getStatusByName = (filter_name: string) => {
		return agentPaymentStatus.find(
			(filter) =>
				filter.title?.toLowerCase() === filter_name?.toLowerCase()
		);
	};

	const getStatusById = (status: number) => {
		return agentPaymentStatus.find((filter) => filter.value === status);
	};

	const { data, isFetching } = useCommunityPartnerCreditPayments(
		{
			status: getStatusByName(statusParam)?.value ?? '',
			type: TransactionType.payment,
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword,
			minTotalPaymentAmount: '',
			maxTotalPaymentAmount: '',
			minTotalDonatedAmount: '',
			maxTotalDonatedAmount: '',
			year: '',
			month: ''
		},
		{
			onSuccess: (data) => {
				setState((prev) => ({
					...prev,
					totalItems: data.totalItems
				}));
			}
		}
	);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			filter: {
				...prev.filter,
				status: selected_filter.label?.toString() ?? ''
			}
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = useCallback(
		(id: string) => {
			void navigate(`${payments.path}/${id}`, {
				state: { prevPath: `${payments.path}` }
			});
		},
		[navigate]
	);

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'transaction-number': 'w-sm-20',
			'created-date': 'w-sm-25 fw-500',
			amount: 'w-sm-25',
			status: 'w-sm-15',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	function getMonthName(monthNumber, language) {
		const months = {
			en: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			],
			de: [
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'Juli',
				'August',
				'September',
				'Oktober',
				'November',
				'Dezember'
			]
		};
		return months[language][monthNumber - 1];
	}

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[10, 15, 20, 50]}
			selectedPageSize={state.pageSize}
			filterOptions={agentPaymentStatus}
			selectedFilter={t(
				getStatusByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}
			onPageSizeChange={onChangePageSize}>
			<DataTable>
				{state?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="label.credit-payment-code"
							cellClassName={cellClassName('transaction-number')}
						/>
						<DataTableRowCell
							cellText="card.created-date"
							cellClassName={cellClassName('created-date')}
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName={cellClassName('amount')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						data?.items?.map((payment) => {
							return (
								<DataTableRow key={payment.id}>
									<DataTableRowCell
										cellClassName={cellClassName(
											'transaction-number'
										)}
										cellHead="table.transaction-number"
										cellText={payment.creditPaymentNumber}
									/>
									<DataTableRowCell
										cellClassName={cellClassName(
											'created-date'
										)}
										cellHead="table.created-date">
										<div className="d-flex gap-2 fs-sm text-gray-3">
											<span>
												{getMonthName(
													payment?.month,
													getCurrentLocale()?.code
												)}
											</span>
											<span>{payment?.year}</span>
										</div>
									</DataTableRowCell>
									<DataTableRowCell
										cellClassName={cellClassName('amount')}
										cellHead="table.amount">
										<div className="d-flex flex-column text-end text-sm-start gap-2">
											<div
												className="d-flex gap-2"
												title={currencyFormatter(
													payment.totalPaymentAmount
												)}>
												<span className="fs-sm fw-500 text-gray-3">
													{t('label.total-payment')}:
												</span>
												<span
													className={`fs-sm fw-500 ${
														payment.totalPaymentAmount ===
														0
															? 'text-gray-3'
															: payment.totalPaymentAmount >
															  0
															? 'text-primary'
															: 'text-red'
													}`}>
													{currencyFormatter(
														payment.totalPaymentAmount
													)}
												</span>
											</div>
											<div
												className="d-flex gap-2"
												title={currencyFormatter(
													payment.totalDonatedAmount
												)}>
												<span className="fs-sm fw-500 text-gray-3">
													{t('label.total-donated')}:
												</span>
												<span
													className={`fs-sm fw-500 ${
														payment.totalDonatedAmount ===
														0
															? 'text-gray-3'
															: payment.totalDonatedAmount >
															  0
															? 'text-primary'
															: 'text-red'
													}`}>
													{currencyFormatter(
														payment.totalDonatedAmount
													)}
												</span>
											</div>

											{/* <span className="text-gray-3 lh-base fs-small">
												{transaction.taxIncluded
													? t('label.tax-included')
													: t('label.no-tax')}
											</span> */}
										</div>
									</DataTableRowCell>
									<DataTableRowCell
										cellClassName={cellClassName('status')}
										cellHead="table.status"
										boxClassName="d-flex align-items-center flex-wrap gap-2">
										<Badge
											color={
												getStatusById(payment?.status)
													?.color ?? Colors.muted
											}>
											{t(
												`${
													getStatusById(
														payment?.status
													)?.label
												}`
											)}
										</Badge>
									</DataTableRowCell>
									<DataTableRowCell
										cellHead="table.operation"
										cellClassName={cellClassName(
											'operation'
										)}
										boxClassName="d-flex flex-wrap gap-2">
										<Button
											size="sm"
											color={Colors['white-primary']}
											label={
												t('button.details') as string
											}
											onClick={() =>
												redirectTo(payment.id)
											}
										/>
										{payment?.creditDocumentUrl && (
											<a
												href={payment?.creditDocumentUrl}
												rel="noreferrer"
												target="_blank"
												className={styles.button}
												download={`jobbiplace-invoice_${payment?.id}.pdf`}>
												<FontAwesome
													icon="download"
													size="lg"
												/>
											</a>
										)}
									</DataTableRowCell>
								</DataTableRow>
							);
						})}
					{isFetching && (
						<DataTableLoading
							widths={[20, 25, 25, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-payment" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

AgentListPayment.displayName = 'AgentListPayment';
