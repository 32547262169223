import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { SubscriptionPlan, Tab } from 'Types';
import { Card, DecorationTab, ReactSwitch } from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';
import { currencyFormatter } from 'src/helpers/Utils';
import { useAxios } from 'src/hooks/useAxios';
import { api } from 'src/helpers/API';
import { useCommunityPartnerCommissionLevelMe } from 'src/hooks/useCommunityPartner';

interface Props {
	refetch: () => void;
	subscriptionPlan: SubscriptionPlan;
}

export const SubscriptionPlanDecorationBox: FC<Props> = ({
	refetch,
	subscriptionPlan
}) => {
	const { subscriptionPlan_id } = useParams<'subscriptionPlan_id'>();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const { sendRequest } = useAxios<string>();

	const defaultTab =
		pathname === `/subscription-plans/${subscriptionPlan_id}` ||
		pathname === `/subscription-plans/${subscriptionPlan_id}/general`;

	const { data: commissionLevelMe } = useCommunityPartnerCommissionLevelMe();

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.plan-info',
			path: 'plan-price',
			isActive:
				pathname ===
				`/subscription-plans/${subscriptionPlan_id}/plan-price`
		},
		{
			id: 2,
			title: 'tab.media',
			path: 'media',
			isActive:
				pathname === `/subscription-plans/${subscriptionPlan_id}/media`
		},
		{
			id: 3,
			title: 'tab.plan-premium-features',
			path: 'premium-features',
			isActive:
				pathname ===
				`/subscription-plans/${subscriptionPlan_id}/premium-features`
		},
		{
			id: 4,
			title: 'tab.contact-person',
			path: 'contact-person',
			isActive:
				pathname ===
				`/subscription-plans/${subscriptionPlan_id}/contact-person`
		},
		{
			id: 5,
			title: 'tab.plan-features',
			path: 'features',
			isActive:
				pathname ===
				`/subscription-plans/${subscriptionPlan_id}/features`
		}
	];

	const onChangeActive = async () => {
		await sendRequest(
			api.updateSubscriptionPlan(subscriptionPlan_id as string),
			{
				method: 'put',
				data: { ...subscriptionPlan, active: !subscriptionPlan?.active }
			}
		);
		refetch();
	};

	return (
		<Card tab>
			<div className="d-flex align-items-start justify-content-between">
				<div className="d-flex flex-column ">
					<h4 className="fs-5 fw-500">{subscriptionPlan?.name}</h4>
					<div className={styles.metaItem}>
						<span className="text-gray-5">
							{t('text.your-commission-group')}:
						</span>
						<span className="text-gray-3">
							{commissionLevelMe?.CommissionLevelInfo?.Title}
						</span>
					</div>
					<div className={styles.metaItem}>
						<span className="text-gray-5">
							{t('text.subscription-plan-code')}:
						</span>
						<span className="text-gray-3">
							{subscriptionPlan?.code ?? '...'}
						</span>
					</div>
					<div className={styles.metaItem}>
						<span className="text-gray-5">
							{t('text.commission')}:
						</span>
						<span className="text-gray-3">
							{commissionLevelMe?.CommissionLevelInfo
								?.CommissionPercent
								? `${commissionLevelMe?.CommissionLevelInfo?.CommissionPercent} %`
								: ''}
						</span>
					</div>
					<div className={styles.metaItem}>
						<span className="text-gray-5">
							{t('text.commission-cost')}:
						</span>
						<span className="text-gray-3">
							{currencyFormatter(
								commissionLevelMe?.CommissionLevelInfo
									?.CommissionCost as number ?? 0
							)}
						</span>
					</div>
					<div className={styles.metaItem}>
						<span className="text-gray-5">
							{t('text.subscription-plan-duration')}:
						</span>
						<span className="text-gray-3">
							{`${subscriptionPlan?.durationDays} ${t(
								'label.days'
							)}`}
						</span>
					</div>
				</div>
				{/* <div className="d-flex gap-2">
					<ReactSwitch
						size="md"
						checked={subscriptionPlan?.active}
						onChange={(val: boolean) => {
							onChangeActive();
						}}
					/>
					<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
						{t('forms.is-active')}
					</span>
				</div> */}
			</div>
			<DecorationTab tabs={tabs} />
		</Card>
	);
};
