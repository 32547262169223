import { FC, PropsWithChildren } from 'react';
import { UserCategory } from 'Types';
import { HomepagePreviewHeader, PrivateRoute } from 'Elements';
import styles from 'partials/homepage/layout.module.scss';
import { clx } from 'src/helpers/Utils';

interface Props extends PropsWithChildren {
	type: UserCategory;
	dataProfile: any;
}

export const PreviewProfilePageLayout: FC<Props> = ({
	children,
	type,
	dataProfile
}) => {
	return (
		<PrivateRoute role={type}>
			<main className={styles.wrapper}>
				<div className="position-relative d-flex flex-grow-1">
					<HomepagePreviewHeader  type={type} dataProfile={dataProfile} />
					<div
						className={clx(
							styles.headerEditProfile,
							'w-100 flex-1'
						)}>
						{children}
					</div>
				</div>
			</main>
		</PrivateRoute>
	);
};
