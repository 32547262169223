import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { containerVariants } from 'Constants';
import {
	useAxios,
	useCommunityPartnerPartners,
	useDelayUnmount,
	useModal
} from 'Hooks';
import {
	BadgeStatus,
	Colors,
	CommunityPartnerPartner,
	PartnershipRequestStatus
} from 'Types';
import {
	api,
	clx,
	config,
	formatLocaleDateTime,
	toastify,
	PartnershipRequestStatusList
} from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	FontAwesome,
	ListingPage,
	ModalBoxPartnerApproval,
	NoData,
	Pagination,
	// ShimmerImage,
	SystemErrorAlert
} from 'Elements';
// import styles from 'partials/shared/page.module.scss';

export const ListPartner = () => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios();
	const [openModalApprove, toggleModalApprove] = useModal();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const shouldRenderModalApprove = useDelayUnmount(openModalApprove, 350);
	const [selectedPartner, setSelectedPartner] =
		useState<CommunityPartnerPartner | null>(null);
	const [selectedStatus, setSelectedStatus] = useState<number | null>();

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	// const getFilterByName = (filter_name: string) => {
	// 	return agentPartnerApprovalStatus.find(
	// 		(filter) => filter.title === filter_name
	// 	);
	// };

	const { data, isFetching, refetch, isError } = useCommunityPartnerPartners({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword,
		status: '',
		registrationStatus: '',
		publishStatus: ''
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterChange = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			approval: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSubmitStatus = (
		partner: CommunityPartnerPartner,
		status: PartnershipRequestStatus
	) => {
		setSelectedPartner(partner);
		setSelectedStatus(status);
		toggleModalApprove();
	};

	// const partnerLink = (partner: AgentPartner) => {
	// 	return partner.company.isActive
	// 		? buildUrl(
	// 				config.publicUrl!,
	// 				'businessPartner',
	// 				partner.companyId,
	// 				partner.company.slugUrl
	// 		  )
	// 		: '';
	// };

	const navigateTo = (partnerId, slugUrl) => {
		window.open(
			`${config.publicUrl}/business-partner/${partnerId}/${slugUrl}`,
			'_blank',
			'noopener,noreferrer'
		);
	};

	const onSubmitStatus = async () => {
		if (selectedPartner) {
			if (selectedStatus === PartnershipRequestStatus.Approved) {
				await sendRequest(
					api.updateBusinessPartnerApprovalStatus(
						selectedPartner?.partnershipRequestsInfo?.id
					),
					{
						method: 'POST'
					},
					() => {
						toastify(t(`toastify.business-partner-approved`), {
							type: 'success'
						});

						void refetch();
						toggleModalApprove();
					}
				);
			} else {
				await sendRequest(
					api.updateBusinessPartnerRejectedStatus(
						selectedPartner?.partnershipRequestsInfo?.id
					),
					{
						method: 'POST'
					},
					() => {
						toastify(t(`toastify.business-partner-rejected`), {
							type: 'success'
						});

						void refetch();
						toggleModalApprove();
					}
				);
			}
		}
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			partner: 'w-sm-30',
			'join-date': 'w-sm-15',
			// 'business-partner-status': 'w-sm-20',
			status: 'w-sm-15',
			'modify-date': 'w-sm-15',
			operation: 'w-sm-25'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			// filterOptions={agentPartnerApprovalStatus}
			// selectedFilter={t(
			// 	getFilterByName(approvalParam)?.label ?? 'dropdown.all'
			// )}
			onFilterChange={handleFilterChange}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.partner"
							cellClassName={cellClassName('partner')}
						/>
						<DataTableRowCell
							cellText="table.join-date"
							cellClassName={cellClassName('join-date')}
						/>
						{/* <DataTableRowCell
							cellText="table.business-partner-status"
							cellClassName={cellClassName(
								'business-partner-status'
							)}
						/> */}
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.modify-date"
							cellClassName={cellClassName('modify-date')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((partner) => {
								return (
									<DataTableRow key={partner.id}>
										<DataTableRowCell
											cellHead="table.partner"
											cellClassName={cellClassName(
												'partner'
											)}>
											<div className="d-flex align-items-center gap-3">
												{/* <div
													className={
														styles.avatarBox
													}>
													<ShimmerImage
														width={48}
														height={48}
														defaultImage={
															defaultImage
														}
														src={
															partner?.thumbnailLogo ??
															defaultImage
														}
														alt={
															partner?.businessName ??
															'---'
														}
														className={
															styles.avatar
														}
													/>
												</div> */}
												<div className="d-flex flex-column gap-1 flex-grow-1">
													<h6 className="fs-sm fw-500 mb-0 lh-base">
														{partner?.businessName ??
															'---'}
													</h6>
													{/* {partner?.company
														?.branch && (
														<span className="text-gray-3 lh-sm fs-small">
															{
																partner?.company
																	?.branch
															}
														</span>
													)} */}
													{partner?.mainEmailAddress && (
														<span className="text-gray-3 lh-sm fs-small">
															{
																partner?.mainEmailAddress
															}
														</span>
													)}
												</div>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'join-date'
											)}
											cellHead="table.join-date">
											{partner?.registrationInfo
												?.startedAt ? (
												<time
													dateTime={
														partner
															?.registrationInfo
															?.startedAt
													}
													title={formatLocaleDateTime(
														partner
															?.registrationInfo
															?.startedAt,
														false
													)}>
													{partner?.registrationInfo
														?.startedAt
														? formatLocaleDateTime(
																partner
																	?.registrationInfo
																	?.startedAt,
																false
														  )
														: ''}
												</time>
											) : (
												'----'
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status">
											{partner?.partnershipRequestsInfo ? (
												<Badge
													color={
														PartnershipRequestStatusList[
															partner
																?.partnershipRequestsInfo
																?.status
														]?.color ??
														Colors.primary
													}>
													{t(
														`${
															PartnershipRequestStatusList[
																partner
																	?.partnershipRequestsInfo
																	?.status
															]?.label
														}`
													)}
												</Badge>
											) : (
												<Badge
													color={
														PartnershipRequestStatusList[1]
															?.color ??
														Colors.primary
													}>
													{t(
														`${PartnershipRequestStatusList[1]?.label}`
													)}
												</Badge>
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'modify-date'
											)}
											cellHead="table.modify-date"
											boxClassName="d-flex flex-column gap-1">
											{partner?.partnershipRequestsInfo
												?.lastUpdatedAt ? (
												<time
													dateTime={
														partner
															.partnershipRequestsInfo
															.lastUpdatedAt
													}
													title={formatLocaleDateTime(
														partner
															.partnershipRequestsInfo
															.lastUpdatedAt,
														false
													)}>
													{partner
														.partnershipRequestsInfo
														.lastUpdatedAt
														? formatLocaleDateTime(
																partner
																	.partnershipRequestsInfo
																	.lastUpdatedAt,
																false
														  )
														: ''}
												</time>
											) : (
												'---'
											)}

											{/* {partner.registrationInfo
												.startedAt && (
												<span className="text-gray-3 fs-small">
													{t('label.by')}:&nbsp;
													{
														// partner.registrationInfo
														// 	.startedAt ??
														'---'
													}
												</span>
											)} */}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'operation'
											)}
											cellHead="table.operation">
											<div className="d-flex gap-1 flex-wrap">
												{partner
													?.partnershipRequestsInfo
													?.status ===
													PartnershipRequestStatus.PendingApproval && (
													<>
														<Button
															size="sm"
															className="gap-2"
															color={
																Colors[
																	'white-primary'
																]
															}
															onClick={() =>
																handleSubmitStatus(
																	partner,
																	PartnershipRequestStatus.Approved
																)
															}>
															<FontAwesome icon="check" />
															{t(
																'button.approve'
															)}
														</Button>
														<Button
															size="sm"
															className="gap-2"
															color={
																Colors[
																	'white-red'
																]
															}
															onClick={() =>
																handleSubmitStatus(
																	partner,
																	PartnershipRequestStatus.Rejected
																)
															}>
															<FontAwesome
																swapOpacity
																fillOpacity={
																	0.8
																}
																icon="xmark"
															/>
															{t('button.reject')}
														</Button>
													</>
												)}
												{partner?.publishInfo
													?.version && (
													<Button
														size="sm"
														color={
															Colors[
																'white-primary'
															]
														}
														className="gap-2"
														title={t(
															'title.view-partner-page',
															{
																partnerName:
																	partner.businessName
															}
														)}
														onClick={() =>
															navigateTo(
																partner?.id,
																partner?.slugUrl
															)
														}>
														<FontAwesome
															icon="arrow-up-right-from-square"
															size="sm"
														/>
														{t(
															'button.view-profile'
														)}
													</Button>
												)}
											</div>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[30, 15, 15, 15, 25]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-partner-yet" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
			{shouldRenderModalApprove && (
				<ModalBoxPartnerApproval
					onClose={toggleModalApprove}
					isOpen={openModalApprove}
					partnerName={selectedPartner?.businessName ?? ''}
					status={selectedStatus ?? PartnershipRequestStatus.Approved}
					onSubmit={onSubmitStatus}
				/>
			)}
		</ListingPage>
	);
};
