import { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SubscriptionPlanDetails } from 'Types';
import { currencyFormatter } from 'Utils';
import { AgentAboCalculator, CurrencyController, Card } from 'Elements';
import styled from 'styled-components';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanPriceCalculation: FC = () => {
	const { t } = useTranslation();
	const { control, watch } = useFormContext<SubscriptionPlanDetails>();

	return (
		<CardBox className="h-100">
			<Row className="g-0">
				<Col
					md={6}
					lg={12}
					xl={6}
					className="border-sm-right border-md-right-none border-lg-right border-gray-1 pe-md-4 pe-lg-0 pe-xl-4 pb-4 pb-md-0 pb-lg-4 pb-xl-0">
					<h1 className="fs-5 fw-500">{t("pages.plan-price")}</h1>
					<div className="d-flex align-items-center lh-base my-3">
						{t('label.plan-price.your-subscription-surcharge')}
						{` (€)`}
						<CurrencyController
							control={control}
							name="price"
							className="ms-auto"
							inputClassName="text-end"
							suffix=" €"
						/>
					</div>
					<div className="d-flex align-items-center justify-content-between lh-base my-3">
						<div>
							{t('label.plan-price.tax')}
							{` (%)`}
						</div>
						<div className="text-end">{`${watch('taxPercentage')} %`}</div>
						{/* <CurrencyController
							control={control}
							name="taxPercentage"
							readOnly
							className="ms-auto"
							inputClassName="text-end"
							suffix=" %"
						/> */}
					</div>
					<div className="d-flex align-items-center lh-base border-bottom pb-4">
						<span className="fs-sm fw-400 fs-base text-gray-3">
							{t('label.tax-amount')}
						</span>
						<div className="d-flex flex-column ms-auto gap-1">
							<span className="fs-6 fw-700 text-gray-5 lh-base text-end">
								{currencyFormatter(
									watch('price') -
										(watch('price') -
											watch('price') *
												(watch('taxPercentage') / 100))
								)}
							</span>
						</div>
					</div>
					<div className="d-flex align-items-center lh-base mt-3">
						<span className="fw-500 fs-base">
							{t('label.plan-price.total-plan-price')}
						</span>
						<div className="d-flex flex-column ms-auto gap-1">
							<span className="fs-6 fw-700 text-gray-5 lh-base text-end">
								{currencyFormatter(
									Number(watch('price')) +
										watch('price') *
											(watch('taxPercentage') / 100)
								)}
							</span>
							<span className="text-gray-3 lh-1 fw-400 fs-small text-end">
								{t('label.included')}
							</span>
						</div>
					</div>
				</Col>
				<Col md={6} lg={12} xl={6}>
					<AgentAboCalculator
						additionalPlanPrice={Number(watch('price'))}
						className="ps-md-4 ps-lg-0 ps-xl-4 pt-4 pt-md-0 pt-lg-4 pt-xl-0"
					/>
				</Col>
			</Row>
		</CardBox>
	);
};
