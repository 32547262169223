import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Badge, Button, FontAwesome, ShimmerImage } from 'Elements';
import { Colors, EventParticipant, EventParticipationStatus } from 'Types';
import styles from 'partials/card/event-participant-card.module.scss';

interface Props {
	card: EventParticipant;
	cancelable?: boolean;
	onCancel: () => void;
}

export const EventParticipantCard: FC<Props> = memo(
	({ card, cancelable = true, onCancel }) => {
		const { t } = useTranslation();
		const defaultLogo = '/assets/img/business-partner-default.png';
		const status = card.status;
		const isParticipationCanceled =
			status === EventParticipationStatus.Canceled ||
			status === EventParticipationStatus.CanceledByAgent;

		return (
			<div className={styles.card}>
				<div className="d-flex align-items-center">
					<div className={styles.logoBox}>
						{!card.isExtern && (
							<ShimmerImage
								src={card.businessPartnerLogo || defaultLogo}
								defaultImage={defaultLogo}
								width={50}
								height={50}
								alt={card.fullName}
								className={styles.logo}
							/>
						)}
						{card.isExtern && (
							<div
								className={clx(
									styles.logo,
									'd-flex align-items-center justify-content-center text-center'
								)}>
								<FontAwesome
									icon={card.isPartner ? 'building' : 'user'}
									size="2x"
									className="text-secondary"
								/>
							</div>
						)}
					</div>
					<div className="flex-grow-1 text-truncate">
						<h2
							title={
								card.isPartner
									? card.businessPartnerName
									: card.fullName
							}
							className="fw-500 fs-sm lh-base mb-1 text-truncate d-flex align-items-center gap-2">
							{card.isPartner ? card.businessPartnerName : card.fullName}
							{!card.isPartner && card.businessPartnerName && (
								<span className="text-gray-3 fs-small">
									|&nbsp;&nbsp;{card.businessPartnerName}
								</span>
							)}
							{status === EventParticipationStatus.CheckIn && (
								<span className="d-flex align-items-center text-primary gap-2 ms-auto fs-small fw-400">
									<FontAwesome icon="user-check" size="1x" />
									{t('label.event.checked-in')}
								</span>
							)}
						</h2>
						<span className="text-gray-3">{card.email}</span>
					</div>
				</div>
				<div className={styles.meta}>
					{card.isPartner && (
						<div className="d-flex align-items-center gap-2 fw-300">
							<FontAwesome
								icon="user"
								size="1x"
								fillOpacity={0.9}
								className="text-gray-3"
							/>
							{card.fullName}
						</div>
					)}
					{card.phoneNumber && (
						<div className="d-flex align-items-center gap-2 fw-300">
							<FontAwesome
								icon="phone"
								size="1x"

								swapOpacity
								className="text-gray-3"
							/>
							{card.phoneNumber}
						</div>
					)}
					{card.isPartner && card.numberOfSeats && (
						<div className="d-flex align-items-center gap-2">
							<FontAwesome
								title={t('label.seats')}
								icon="person-seat"
								size="1x"

								className="text-gray-3"
							/>
							<span className="fw-400">{card.numberOfSeats}</span>
						</div>
					)}
					{!card.isPartner && card.numberOfSeats > 0 && (
						<div className="d-flex align-items-center gap-2">
							<FontAwesome
								title={t('label.seats')}
								icon="person-seat"
								size="1x"

								className="text-gray-3"
							/>
							<span className="fw-300">
								{t('label.event.visitor-guest-number', {
									visitorName: card.fullName,
									guestCount: card.numberOfSeats
								})}
							</span>
						</div>
					)}
					<div className="ms-auto d-flex align-items-center gap-2">
						{cancelable && !isParticipationCanceled && (
							<Button
								size="sm"
								color={Colors['white-red']}
								label={t('button.event.cancel') as string}
								onClick={onCancel}
							/>
						)}
						{isParticipationCanceled && (
							<Badge color={Colors.muted}>
								{status === EventParticipationStatus.Canceled &&
									t('badge.event.canceled-by-partner')}
								{status ===
									EventParticipationStatus.CanceledByAgent &&
									t('badge.event.canceled-by-agent')}
							</Badge>
						)}
					</div>
				</div>
			</div>
		);
	}
);

EventParticipantCard.displayName = 'EventParticipantCard';
