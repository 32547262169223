import { startTransition, useMemo, useState } from 'react';
import * as yup from 'yup';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api } from 'Utils';
import { usePrompt } from 'Hooks';
import { Branch, GeoAddress, SelectableCountry } from 'Types';
import {
	AddressAutoSuggest,
	Card,
	FormSuccess,
	SelectController,
	SubmitButtonGroup,
	SwitchController
} from 'Elements';
import { getCountries } from 'src/data';

export const CreateBranch = () => {
	const descriptionLimit = 270;
	const { t } = useTranslation();
	const [formSuccess, setFormSuccess] = useState(false);
	const countryOptions = useMemo(() => getCountries(), []);
	const validation_message = 'validation.required';

	const addressSchema = yup.object().shape({
		country: yup.object().nullable().required('Country is required'),
		state: yup.object().nullable().required('state is required'),
		city: yup.string().required('City is required'),
		postalCode: yup.string().required('Postal Code is required'),
		street: yup.string().required('Street is required'),
		houseNumber: yup.string().required('House Number is required'),
		location: yup.object().shape({
			longitude: yup.number().required('Longitude is required'),
			latitude: yup.number().required('Latitude is required')
		})
	});

	const schema = yup.object({
		name: yup.string().required(t('validation.branch-title.required')),
		address: addressSchema
	});

	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};

	const defaultValues: Branch = {
		id: '',
		businessPartnerId: '',
		name: '',
		shortDescription: '',
		address: {
			name: '',
			country: null,
			state: null,
			countryName: '',
			countryIso2: '',
			countryIso3: '',
			city: '',
			postalCode: '',
			street: '',
			houseNumber: '',
			location: {
				longitude: 0,
				latitude: 0
			}
		},
		active: true
	};

	const {
		register,
		control,
		reset,
		setValue,
		watch,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<Branch>({
		resolver: yupResolver(schema),
		defaultValues
	});
	const watchCountry = watch('address.country') as SelectableCountry;

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
	};

	const handleSelectAddress = (selected_address: GeoAddress) => {
		const { address, position } = selected_address;
		const selected_country = countryOptions.find(
			(country) =>
				country?.value?.toString()?.toLowerCase() ===
				address?.countryCode?.toLowerCase()
		);

		const selected_state = selected_country?.states?.find(
			(state) => state.label === address?.countrySubdivision
		);

		setValue(
			'address.countryIso2',
			address?.countryCode ?? '',
			valueConfig
		);
		setValue('address.street', address?.streetName ?? '', valueConfig);
		setValue(
			'address.houseNumber',
			address?.streetNumber ?? '',
			valueConfig
		);
		setValue(
			'address.countryIso3',
			address?.countryCodeISO3 ?? '',
			valueConfig
		);
		setValue('address.postalCode', address?.postalCode ?? '', valueConfig);
		setValue('address.countryName', address?.country ?? null, valueConfig);
		setValue('address.city', address?.municipality ?? '', valueConfig);
		setValue(
			'address.location',
			{
				longitude: position?.lon ?? 0,
				latitude: position?.lat ?? 0
			},
			valueConfig
		);
		setValue('address.country', selected_country ?? null, valueConfig);
		setValue('address.state', selected_state ?? null, valueConfig);
	};

	const handleCountryChange = () => {
		setValue('address.state', null, valueConfig);
	};

	const submitRequest: SubmitHandler<Branch> = async (data) => {
		const api_data = {
			...data,
			address: {
				...data.address,
				countryName: data?.address?.country?.value,
				state: data?.address?.state?.value
			}
		};

		// delete api_data?.countryName;
		// delete api_data?.state;

		await axios.post(api.createBranch, api_data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					setFormSuccess(true);
					resetForm();
				});
			}
		});
	};

	const submitHandler: SubmitHandler<Branch> = async (data) => {
		await submitRequest(data);
	};

	return (
		<Card>
			{!formSuccess && (
				<Row>
					<form onSubmit={handleSubmit(submitHandler)} noValidate>
						<Row>
							<Col xl={7} xxl={6}>
								<FormGroup>
									<Label htmlFor="name">
										{t('forms.branches.title')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('name')}
										type="text"
										id="name"
										aria-invalid={!!errors.name}
										className="inputbox w-100"
									/>
									{errors.name && (
										<div className="invalid-feedback d-block">
											{errors.name.message?.toString()}
										</div>
									)}
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="shortDescription">
										{t('forms.branches.short-description')}
									</Label>
									<textarea
										{...register('shortDescription', {
											maxLength: descriptionLimit
										})}
										id="description"
										className="inputbox w-100"
										cols={10}
										rows={5}
										aria-invalid={!!errors.shortDescription}
										maxLength={descriptionLimit}
									/>
									<div className="position-absolute end-0">
										<small className="ms-auto mt-1">
											{watch('shortDescription')
												?.length || 0}
											/{descriptionLimit}
										</small>
									</div>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={7} xxl={6}>
								<div
									className="border p-3 my-3"
									style={{ borderRadius: '8px' }}>
									<FormGroup>
										<Label>
											{t('forms.search-address')}
										</Label>
										<AddressAutoSuggest
											onSelectSuggestion={
												handleSelectAddress
											}
										/>
									</FormGroup>
									<div className="d-flex align-items-center justify-content-center mt-4 mb-4">
										{t('forms.or')}
									</div>
									<Row>
										<Col md={6} xxl={6}>
											<FormGroup>
												<Label htmlFor="street">
													{t('forms.street')}
													<small className="ms-1">
														({t(validation_message)}
														)
													</small>
												</Label>
												<input
													{...register(
														'address.street'
													)}
													type="text"
													id="street"
													aria-invalid={
														!!errors?.address
															?.street
													}
													className="inputbox w-100"
												/>
												{errors?.address?.street && (
													<div className="invalid-feedback d-block">
														{errors.address.street.message?.toString()}
													</div>
												)}
											</FormGroup>
										</Col>
										<Col md={6} xxl={6}>
											<FormGroup>
												<Label htmlFor="houseNumber">
													{t('forms.house-number')}
													<small className="ms-1">
														({t(validation_message)}
														)
													</small>
												</Label>
												<input
													{...register(
														'address.houseNumber'
													)}
													type="text"
													id="houseNumber"
													aria-invalid={
														!!errors?.address
															?.houseNumber
													}
													className="inputbox w-100"
												/>
												{errors?.address
													?.houseNumber && (
													<div className="invalid-feedback d-block">
														{errors.address.houseNumber.message?.toString()}
													</div>
												)}
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label htmlFor="postalCode">
											{t('forms.postal-code')}
											<small className="ms-1">
												({t(validation_message)})
											</small>
										</Label>
										<input
											{...register('address.postalCode')}
											type="text"
											id="postalCode"
											aria-invalid={
												!!errors?.address?.postalCode
											}
											className="inputbox w-100"
										/>
										{errors?.address?.postalCode && (
											<div className="invalid-feedback d-block">
												{errors.address.postalCode.message?.toString()}
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<Label htmlFor="city">
											{t('forms.city')}
											<small className="ms-1">
												({t(validation_message)})
											</small>
										</Label>
										<input
											{...register('address.city')}
											type="text"
											id="city"
											aria-invalid={
												!!errors?.address?.city
											}
											className="inputbox w-100"
										/>
										{errors?.address?.city && (
											<div className="invalid-feedback d-block">
												{errors?.address.city.message?.toString()}
											</div>
										)}
									</FormGroup>
									<Row>
										<Col md={6} xxl={6}>
											<FormGroup>
												<Label htmlFor="country">
													{t('forms.country')}
													<small className="ms-1">
														({t(validation_message)}
														)
													</small>
												</Label>
												<SelectController
													name="address.country"
													control={control as any}
													options={countryOptions}
													error={errors?.address?.country?.message?.toString()}
													placeholder={t(
														'forms.select-country'
													)}
													onSelectChange={
														handleCountryChange
													}
												/>
											</FormGroup>
										</Col>
										<Col md={6} xxl={6}>
											<FormGroup>
												<Label htmlFor="state">
													{t('forms.state')}
													<small className="ms-1">
														({t(validation_message)}
														)
													</small>
												</Label>
												<SelectController
													name="address.state"
													control={control as any}
													options={
														watchCountry?.states
													}
													error={errors?.address?.state?.message?.toString()}
													placeholder={t(
														'forms.select-state'
													)}
												/>
											</FormGroup>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						<Row>
							<FormGroup>
								<SwitchController
									name="active"
									control={control}
									label="label.active"
								/>
							</FormGroup>
							<SubmitButtonGroup
								cancelButton="/branches"
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Row>
					</form>
				</Row>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.add-branch"
					listTitle="button.branch-list"
					successTitle="forms.branch-created"
					className="flex-grow-1 align-self-center"
					listUrl="/branches"
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
