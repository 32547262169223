import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { ColorValues } from 'Types';
import { clx, logoutRedirect } from 'Utils';
import { useGetB2bUserProfileQuery } from 'Hooks';
import { FontAwesome, ShimmerImage, UserDropDownLoading } from 'Elements';
import styles from 'partials/shared/dropdown/user-dropdown.module.scss';

export const UserDropDown = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: user, isLoading, refetch } = useGetB2bUserProfileQuery();
	const [displayName, setDisplayName] = useState('');
	const [displayUsername, setDisplayUsername] = useState('');
	// const displayName = `${user?.firstName} ${user?.lastName}`;
	// const displayUsername = user?.emailAddress ?? user?.username;
	const defaultImage = '/assets/img/profiles/img-user-default.png';

	useEffect(() => {
		setDisplayName(`${user?.firstName} ${user?.lastName}`);
		setDisplayUsername(
			user?.emailAddress ? user?.emailAddress : (user?.username as string)
		);
		refetch && refetch();
	}, [props?.userType, user]);

	const handleLogout = async () => {
		await logoutRedirect();
	};

	return (
		<UncontrolledDropdown>
			<DropdownToggle
				tag="div"
				role="button"
				className="d-flex align-items-center gap-3">
				{!isLoading && (
					<h5
						className={clx(
							'd-none d-sm-block fs-sm fw-500 text-gray-4 lh-md mb-0 flex-grow-1'
						)}>
						{user?.firstName
							? `${t('label.welcome')}, ${user?.firstName}`
							: ''}
					</h5>
				)}
				<div className="d-flex align-items-center gap-2">
					<div className={styles.avatarBox}>
						<ShimmerImage
							src={user?.imageUrl || defaultImage}
							defaultImage={defaultImage}
							width={40}
							height={40}
							alt="Profile"
							className={styles.avatar}
						/>
					</div>
					<FontAwesome
						icon="chevron-down"
						swapOpacity
						size="1x"
						color={ColorValues['gray-3']}
						className={styles.arrow}
					/>
				</div>
			</DropdownToggle>
			<DropdownMenu className={styles.menu} end>
				<DropdownItem
					className={styles.infoBox}
					tag="div"
					text
					toggle={false}>
					{isLoading && <UserDropDownLoading />}
					{!isLoading && (
						<>
							<h5 className="fs-sm fw-500 text-gray-4 text-break">
								<Link to={`/users/${user?.id}`}>
									{displayName}
								</Link>
							</h5>
							<span className="d-flex fs-sm fw-300 text-gray-3 text-break">
								{displayUsername}
							</span>
						</>
					)}
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem
					className={clx(
						styles.item,
						'd-flex align-items-center gap-3'
					)}
					onClick={() => navigate(`/users/${user?.id}`)}>
					<FontAwesome icon="user" width={20} size="lg" />
					{t('dropdown.my-profile')}
				</DropdownItem>
				<DropdownItem
					className={clx(
						styles.item,
						'd-flex align-items-center gap-3 text-red'
					)}
					onClick={handleLogout}>
					<FontAwesome
						icon="right-from-bracket"
						width={20}
						size="lg"
					/>
					{t('dropdown.sign-out')}
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};
