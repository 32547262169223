import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { clx } from 'Utils';
import {  IStep } from 'Types';
import {
	StepContainer,
	UpgradeSubscriptionPaymentMethod,
	UpgradeSubscriptionPlan,
	UpgradeSubscriptionReview
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const UpgradeSubscription = () => {
	const { subscription_id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const CurrentSubscriptionPlanId =
		searchParams.get('CurrentSubscriptionPlanId') || '';
	const UpgradeSubscriptionPlanId =
		searchParams.get('UpgradeSubscriptionPlanId') || '';
	const planType = searchParams.get('planType') || '';

	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-subs-2')
		},
		{
			id: 'step2',
			title: t('wizard.step-preview')
		},
		{
			id: 'step3',
			title: t('wizard.payment-method')
		}
	];

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next, previous, push }) => (
								<UpgradeSubscriptionPlan
									onPrev={() => {
										navigate(
											`/subscriptions/${subscription_id}`
										);
										previous();
									}}
									onNext={next}
									goToStep={push}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ previous, push }) => (
								<UpgradeSubscriptionReview
									onPrev={() => {
										navigate(
											`/subscriptions/${subscription_id}/upgrade?step=step1&communityPartnerId=${communityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&planType=${planType}`
										);
										previous();
									}}
									goToStep={push}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ previous, push }) => (
								<UpgradeSubscriptionPaymentMethod
									onPrev={() => {
										navigate(
											`/subscriptions/${subscription_id}/upgrade?step=step2&communityPartnerId=${communityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&UpgradeSubscriptionPlanId=${UpgradeSubscriptionPlanId}&planType=${planType}`
										);
										previous();
									}}
									goToStep={push}
								/>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
};
