import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useCommunityPartnerPayment } from 'Hooks';
import { Colors } from 'Types';
import { containerVariants } from 'Constants';
import {
	agentPaymentStatus,
	clx,
	CommunityPartnerCreditStatus,
	currencyFormatter,
	formatLocaleDateTime,
	getCurrentLocale
} from 'Utils';
import {
	Badge,
	Button,
	Card,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	LoadingContent,
	SystemErrorAlert
} from 'Elements';
import { Col, Row } from 'reactstrap';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transactionId: string;
}

export const AgentViewPayment: FC<Props> = ({ transactionId }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data, isLoading, isError, isFetching } =
		useCommunityPartnerPayment(transactionId);

	const redirectTo = (id: string) => {
		void navigate(`/credits/${id}`, {
			state: { prevPath: '/payments' }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'business-partner': 'w-sm-35',
			'created-at': 'w-sm-20',
			amount: 'w-sm-15',
			status: 'w-sm-15',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	const getStatusById = (status: number) => {
		return agentPaymentStatus.find((filter) => filter.value === status);
	};

	const getStatusData = (status) => {
		return CommunityPartnerCreditStatus.find(
			(elem) => elem.value === status
		);
	};

	function getMonthName(monthNumber, language) {
		const months = {
			en: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			],
			de: [
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'Juli',
				'August',
				'September',
				'Oktober',
				'November',
				'Dezember'
			]
		};
		return months[language][monthNumber - 1];
	}

	return (
		<>
			<Row>
				<Col>
					<Card>
						<div className="d-flex" style={{ gap: '100px' }}>
							<div>
								<div className={styles.row}>
									<span className={styles.rowLabel}>
										{t('label.credit-payment-code')}:
									</span>
									<span className="fs-6 text-primary">
										{data?.creditPaymentNumber ?? '---'}
									</span>
								</div>
								<div className={styles.row}>
									<span className={styles.rowLabel}>
										{t('label.total-payment')}:
									</span>
									<span>
										{currencyFormatter(
											data?.totalPaymentAmount ?? 0
										)}
									</span>
								</div>
								<div className={styles.row}>
									<span className={styles.rowLabel}>
										{t('label.total-donated')}:
									</span>
									<span>
										{currencyFormatter(
											data?.totalDonatedAmount ?? 0
										)}
									</span>
								</div>
								<div className={styles.row}>
									<span className={styles.rowLabel}>
										{t('label.status')}:
									</span>
									<Badge
										color={
											getStatusById(data?.status ?? 0)
												?.color ?? Colors.muted
										}>
										{t(
											`${
												getStatusById(data?.status ?? 0)
													?.label
											}`
										)}
									</Badge>
								</div>
							</div>
							<div>
								<div className={styles.row}>
									<span className={styles.rowLabel}>
										{t('card.created-date')}:
									</span>
									<div className="d-flex gap-2 fs-sm text-gray-3">
										<span>
											{getMonthName(
												data?.month,
												getCurrentLocale()?.code
											)}
										</span>
										<span>{data?.year}</span>
									</div>
								</div>
							</div>
						</div>
						{isLoading && <LoadingContent />}
					</Card>
				</Col>
			</Row>

			<div
				className={clx(
					'd-flex flex-column gap-4 mt-3',
					!isLoading &&
						(!data || data?.credits?.length === 0) &&
						'd-none'
				)}>
				<DataTable>
					{data && data?.credits?.length > 0 && (
						<DataTableHead>
							
							<DataTableRowCell
								cellText="table.business-partner"
								cellClassName={cellClassName(
									'business-partner'
								)}
							/>
							<DataTableRowCell
								cellText="table.created-at"
								cellClassName={cellClassName('created-at')}
							/>
							<DataTableRowCell
								cellText="table.amount"
								cellClassName={cellClassName('amount')}
							/>
							<DataTableRowCell
								cellText="table.status"
								cellClassName={cellClassName('status')}
							/>
							<DataTableRowCell
								cellText="table.operation"
								cellClassName={cellClassName('operation')}
							/>
						</DataTableHead>
					)}
					<DataTableBody>
						{!isFetching && data && (
							<motion.div
								variants={containerVariants}
								initial="hidden"
								animate="show">
								{data?.credits?.map((credit) => {
									return (
										<DataTableRow
											key={
												credit.communityPartnerCreditIdentifier
											}>
										
											<DataTableRowCell
												cellClassName={cellClassName(
													'business-partner'
												)}
												cellHead="table.business-partner">
												<div className="fs-sm fw-500 text-gray-3">
													{
														credit
															?.businessPartnerOrganizationName
													}
												</div>
												<div className="fs-sm fw-400 text-gray-3">
													{
														credit
															?.businessPartnerEmailAddress
													}
												</div>
											</DataTableRowCell>
											<DataTableRowCell
												cellClassName={cellClassName(
													'created-at'
												)}
												cellHead="table.created-at">
												<time
													className="fs-sm fw-400 text-gray-3"
													title={formatLocaleDateTime(
														credit.createdAt
													)}
													dateTime={formatLocaleDateTime(
														credit.createdAt
													)}>
													{formatLocaleDateTime(
														credit.createdAt
													)}
												</time>
											</DataTableRowCell>
											<DataTableRowCell
												cellClassName={cellClassName(
													'amount'
												)}
												cellHead="table.amount">
												<div className="d-flex flex-column text-end text-sm-start">
													<div
														className={`fs-sm fw-500 ${
															credit.totalCreditAmount ===
															0
																? 'text-gray-3'
																: credit.totalCreditAmount >
																  0
																? 'text-primary'
																: 'text-red'
														}`}
														title={currencyFormatter(
															credit.totalCreditAmount
														)}>
														{currencyFormatter(
															credit.totalCreditAmount
														)}
													</div>
													<span className="text-gray-3 lh-base fs-small">
														{credit.taxIncluded
															? t(
																	'label.tax-included'
															  )
															: t('label.no-tax')}
													</span>
												</div>
											</DataTableRowCell>
											<DataTableRowCell
												cellClassName={cellClassName(
													'status'
												)}
												cellHead="table.status"
												boxClassName="d-flex align-items-center flex-wrap gap-2">
												<Badge
													color={
														getStatusData(
															credit?.creditStatus
														)?.color ??
														Colors.primary
													}>
													{t(
														`${
															getStatusData(
																credit?.creditStatus
															)?.label
														}`
													)}
												</Badge>
												{credit?.donated ? (
													<Badge color={Colors.association}>
														{t('badge.donated')}
													</Badge>
												) : (
													<></>
												)}
											</DataTableRowCell>
											<DataTableRowCell
												cellHead="table.operation"
												cellClassName={cellClassName(
													'operation'
												)}
												boxClassName="d-flex flex-wrap gap-2">
												<Button
													size="sm"
													color={
														Colors['white-primary']
													}
													label={
														t(
															'button.details'
														) as string
													}
													onClick={() =>
														redirectTo(
															credit.communityPartnerCreditIdentifier
														)
													}
												/>
											</DataTableRowCell>
										</DataTableRow>
									);
								})}
							</motion.div>
						)}
						{isFetching && (
							<DataTableLoading
								widths={[35, 20, 15, 15, 15]}
								count={5}
							/>
						)}
					</DataTableBody>
					{isError && <SystemErrorAlert />}
				</DataTable>
			</div>
		</>
	);
};
