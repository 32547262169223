import { currencyFormatter } from 'Utils';
import { PaymentMethodStatus, PaymentMethodCard, VoucherDiscountType } from 'Types';

/**
 * Returns the formatted discount value based on the discount type.
 * @param discount - The discount value.
 * @param discountType - The type of discount (default: VoucherDiscountType.Percent).
 * @returns The formatted discount value.
 */
export const getDiscountFormat = (
	discount = 0,
	discountType: VoucherDiscountType = VoucherDiscountType.Percent
) => {
	if (discountType === VoucherDiscountType.Percent) {
		return `${discount}%`;
	} else {
		return currencyFormatter(discount);
	}
};

export const paymentMethods: PaymentMethodCard[] = [
	{
		id: PaymentMethodStatus.online,
		title: 'title.payment.pay-with-credit-card',
		description: 'text.payment.credit-card-method-description',
		icon: 'credit-card'
	},
	{
		id: PaymentMethodStatus.payPal,
		title: 'title.payment.pay-with-paypal',
		description: 'text.payment.paypal-method-description',
		icon: 'file-invoice-dollar'
	},
	{
		id: PaymentMethodStatus.sepa,
		title: 'title.payment.pay-with-sepa',
		description: 'text.payment.sepa-method-description',
		icon: 'file-invoice-dollar'
	},
	{
		id: PaymentMethodStatus.invoice,
		title: 'title.payment.pay-with-invoice',
		description: 'text.payment.invoice-method-description',
		icon: 'file-invoice-dollar'
	}
];
