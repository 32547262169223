import {
	FC,
	FormEvent,
	KeyboardEvent,
	ReactNode,
	memo,
	useEffect,
	useState
} from 'react';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import Autosuggest, {
	AutosuggestPropsBase,
	ContainerProps,
	RenderInputComponentProps
} from 'react-autosuggest';
import { clx, api } from 'Utils';
import { GeoAddress } from 'Types';
import { Scrollbar, AddressSuggestion, FontAwesome } from 'Elements';
import styles from 'partials/auto-suggest/search-autosuggest.module.scss';

interface Props extends Partial<AutosuggestPropsBase<GeoAddress>> {
	defaultQuery?: string;
	containerClassName?: string;
	inputClassName?: string;
	loadingClassName?: string;
	queryType?: 'address' | 'postal';
	placeholder?: string;
	ariaInvalid?: boolean;
	isDisabled?: boolean;
	onBlur?: () => void;
	onChange?: (value: string) => void;
	onSelectSuggestion?: (suggestion: GeoAddress) => void;
}
interface InputProps {
	autoComplete: string;
	type: string;
	'aria-invalid': boolean;
	className: string;
	placeholder: string;
	value: string;
	disabled?: boolean;
	maxLength?: number;
	onBlur: () => void;
	onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
	onChange: (e: FormEvent<HTMLElement>, { newValue }: any) => void;
}

export const AddressAutoSuggest: FC<Props> = memo(
	({
		defaultQuery = '',
		queryType = 'address',
		containerClassName,
		inputClassName,
		loadingClassName,
		placeholder,
		ariaInvalid = false,
		isDisabled = false,
		onBlur,
		onChange,
		onSelectSuggestion
		// eslint-disable-next-line sonarjs/cognitive-complexity
	}) => {
		const { t } = useTranslation();
		const [query, setQuery] = useState('');
		const [loading, setLoading] = useState(false);
		const [suggestions, setSuggestions] = useState<GeoAddress[]>([]);

		const handleChange = (
			e: FormEvent<HTMLElement>,
			{ newValue }: { newValue: string }
		) => {
			setQuery(newValue);
			onChange?.(newValue);
		};

		const handleBlur = () => {
			onBlur?.();
		};

		const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
			if ((e.which < 48 || e.which > 57) && queryType === 'postal') {
				e.preventDefault();
			}
		};

		const setSuggestionData = async (value: string) => {
			const api_url =
				queryType === 'address'
					? api.searchAddress(value)
					: api.searchPostalCity(value);
			await axios.get(api_url).then((res) => {
				const data = res.data;
				setSuggestions(data?.results);
			});
		};

		const getSuggestions = async (value: string) => {
			const inputValue = value.trim();
			const inputLength = inputValue.length;

			if (inputLength === 0) {
				return [];
			}

			try {
				setLoading(true);
				await setSuggestionData(value);
			} finally {
				setLoading(false);
			}
		};

		const getSuggestionValue = (suggestion: GeoAddress) => {
			return suggestion?.address?.freeformAddress ?? '';
		};

		const shouldRenderSuggestions = (value: string) => {
			return (
				queryType === 'address' ||
				(queryType === 'postal' &&
					value.length === 5 &&
					!!value.match(/^[0-9]+$/))
			);
		};

		const renderInput = (inputProps: RenderInputComponentProps) => {
			return (
				<div className="position-relative h-100">
					<input {...inputProps} />
					{loading && (
						<FontAwesome
							spin
							spinPulse
							icon="spinner"
							size="xl"
							
							className={clx(styles.loading, loadingClassName)}
						/>
					)}
				</div>
			);
		};

		const renderSuggestionsContainer = ({
			containerProps,
			children
		}: {
			containerProps: ContainerProps;
			children: ReactNode;
		}) => {
			if (children) {
				return (
					<div
						{...containerProps}
						className={clx(
							containerClassName,
							styles.suggestionsContainer
						)}>
						<Scrollbar
							className={styles.scrollbar}
							options={{ scrollXMarginOffset: 10 }}>
							{children}
						</Scrollbar>
					</div>
				);
			}
		};

		const onSuggestionSelected = (
			_e: FormEvent<HTMLElement>,
			{ suggestion }: { suggestion: GeoAddress }
		) => {
			onSelectSuggestion?.(suggestion);
		};

		const renderSuggestion = (suggestion: GeoAddress) => {
			return (
				<AddressSuggestion
					title={suggestion?.address?.freeformAddress}
				/>
			);
		};

		const onSuggestionsFetch = async ({ value }: { value: string }) => {
			if (value) await getSuggestions(value);
		};

		const onSuggestionsClear = () => {
			setSuggestions([]);
		};

		useEffect(() => {
			setQuery(defaultQuery);
		}, [defaultQuery]);

		const inputProps: InputProps = {
			autoComplete: 'off',
			type: 'search',
			'aria-invalid': ariaInvalid,
			className: clx('inputbox w-100 text-truncate', inputClassName),
			placeholder:
				placeholder ||
				(queryType === 'address'
					? t('placeholder.search-address')
					: t('placeholder.search-postal-code')),
			value: query || '',
			disabled: isDisabled,
			maxLength: queryType === 'postal' ? 5 : 52000,
			onChange: handleChange,
			onBlur: handleBlur,
			onKeyPress: handleKeyPress
		};

		return (
			<Autosuggest
				theme={styles}
				suggestions={suggestions ?? []}
				shouldRenderSuggestions={shouldRenderSuggestions}
				onSuggestionsFetchRequested={onSuggestionsFetch}
				onSuggestionsClearRequested={onSuggestionsClear}
				getSuggestionValue={getSuggestionValue}
				onSuggestionSelected={onSuggestionSelected}
				renderSuggestionsContainer={renderSuggestionsContainer}
				renderInputComponent={renderInput}
				renderSuggestion={renderSuggestion}
				highlightFirstSuggestion={true}
				inputProps={inputProps}
			/>
		);
	}
);

AddressAutoSuggest.displayName = 'AddressAutoSuggest';
