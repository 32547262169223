import { FC, useEffect, useState } from 'react';
import { setSubscriptionPlan } from 'Actions';
import { SubscriptionCommunityPartner, SubscriptionPlan } from 'Types';
import { useAxios, useDispatch, useSubscriptionPlanDetails } from 'Hooks';
import {
	NoData,
	StepNavigation,
	SubscriptionPlanCard,
	SubscriptionCommunityPartnerCard,
	SkeletonWrapper,
	LoadingMask
} from 'Elements';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';
import { SwiperSlide, Swiper } from 'swiper/react';
import '../../../../../../assets/css/plan-sub.css';

// import required modules
import { Navigation } from 'swiper/modules';
import { api } from 'src/helpers/API';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import { Col, Row } from 'reactstrap';

interface Props {
	onPrev: () => void;
	onNext: () => void;
	goToStep: (stepId: string) => void;
}

export const UpgradeSubscriptionPlan: FC<Props> = ({
	onPrev,
	onNext,
	goToStep
}) => {
	const { subscription_id } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		sendRequest: sendRequestCommunityPartner,
		isLoading: isLoadingCommunityPartner
	} = useAxios<any>();
	const { sendRequest, isLoading } = useAxios<any>();
	const {
		sendRequest: sendRequestSubscriptionPlansFeature,
		isLoading: isLoadingPlansFeature
	} = useAxios<any>();

	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const CurrentSubscriptionPlanId =
		searchParams.get('CurrentSubscriptionPlanId') || '';
	const queryStep = searchParams.get('step') || '';
	const handleSelectPlan = (plan: SubscriptionPlan) => {
		dispatch(setSubscriptionPlan(plan));
		onNext();
		navigate(
			`/subscriptions/${subscription_id}/upgrade?step=step2&communityPartnerId=${communityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&UpgradeSubscriptionPlanId=${plan?.id}&planType=${plan?.type}`
		);
	};

	const { data: subscriptionPlanDetails } = useSubscriptionPlanDetails(
		CurrentSubscriptionPlanId as string,
		{
			enabled: !!CurrentSubscriptionPlanId
		}
	);

	const breakpoints = {
		640: {
			slidesPerView: 1,
			spaceBetween: 20
		},
		768: {
			slidesPerView: 1,
			spaceBetween: 40
		},
		900: {
			slidesPerView: 2,
			spaceBetween: 40
		}
	};

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	const checkedPlan = (item) => {
		let check = false;
		subscriptionPlanDetails?.availableUpgradePlanList?.forEach(
			(element) => {
				if (element === item.id) {
					check = true;
				}
			}
		);
		return check;
	};

	const SkeletonPlans = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column justify-content-between',
					styles.cardPlanSkeleton
				)}>
				<div className="d-flex flex-column align-items-start gap-3  pt-0">
					<LoadingMask />
					<div className={styles.labelHeder}></div>
					<div className={styles.labelPrice}></div>
					<div className={styles.labelText}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
				</div>
				<div className={styles.labelButton}></div>
			</div>
		);
	};

	const getPlanData = () => {
		let communityPartnerData: any = {};
		const planData: any = [];
		sendRequestCommunityPartner(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data: any) => {
				communityPartnerData = data;
			}
		);
		sendRequestSubscriptionPlansFeature(
			api.getPlansCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				const urls = data?.map(
					(plan: any) =>
						plan.active &&
						checkedPlan(plan) &&
						sendRequest(
							api.getSubscriptionPlansFeature(
								plan?.subscriptionPlanFeatureId,
								plan?.id
							),
							{ method: 'GET' },
							(featureData) =>
								planData.push({ featureData, ...plan })
						)
				);
				Promise.all(urls).finally(() => {
					setSelectedCP({ ...communityPartnerData, planData });
				});
			}
		);
	};

	useEffect(() => {
		if (subscriptionPlanDetails) {
			getPlanData();
		}
	}, [subscriptionPlanDetails, CurrentSubscriptionPlanId]);

	useEffect(() => {
		if (queryStep) {
			goToStep(queryStep);
		}
	}, [queryStep]);

	return (
		<div className={styles.form}>
			<div className="position-relative">
				{!isLoadingCommunityPartner && (
					<>
						<SubscriptionCommunityPartnerCard
							showDescription={false}
							key={selectedCP?.id}
							communityPartner={
								selectedCP as SubscriptionCommunityPartner
							}
							selected={false}
						/>
					</>
				)}
				{isLoadingCommunityPartner && (
					<Row className="position-relative">
						<SkeletonWrapper
							skeleton={Skeleton}
							count={1}
							wrapperClassName="row"
						/>
					</Row>
				)}
			</div>
			<div className={clx('mb-3', styles.boxCurrentPlan)}>
				<Row>
					<Col md={3}>
						<div className="fw-500 text-gray-3">
							{t('title.your-current-plan')}:
						</div>
					</Col>
					<Col md={7}>
						<div
							className={clx(
								'd-flex justify-content-between justify-content-md-start',
								styles.row
							)}>
							<span className={styles.rowLabel}>
								{t('label.name')}:
							</span>
							<div className="d-flex align-items-center gap-2">
								<span className="text-primary fs-6 fw-500">
									{subscriptionPlanDetails?.name ?? '---'}
								</span>
							</div>
						</div>
						<div
							className={clx(
								'd-flex justify-content-between justify-content-md-start',
								styles.row
							)}>
							<span className={styles.rowLabel}>
								{t('label.subscription-code')}:
							</span>
							<div className="d-flex align-items-center gap-2">
								<span className="fw-500">
									{subscriptionPlanDetails?.code ?? '---'}
								</span>
							</div>
						</div>
						<div
							className={clx(
								'd-flex justify-content-between justify-content-md-start',
								styles.row
							)}>
							<span className={styles.rowLabel}>
								{t('forms.price')}:
							</span>
							<div className="d-flex align-items-center gap-2">
								<span className="fw-500">
									{currencyFormatter(
										subscriptionPlanDetails?.price ?? 0
									)}
								</span>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			{/* <div className="text-center gap-2 mt-4">
				<h3 className="fw-500 fs-base text-gray-4">
					{t('text.available-subscription-for-you')}
				</h3>
				<p className="fs-small fw-400 text-gray-4">
					{t('text.description-available-subscription')}
				</p>
			</div> */}
			{!isLoading && (
				<div className={styles.boxSwiper}>
					{selectedCP?.planData && selectedCP?.planData?.length > 0 && (
						<div>
							<Swiper
								breakpoints={breakpoints}
								navigation={true}
								modules={[Navigation]}
								className={styles.mySwiper}>
								{selectedCP?.planData
									?.sort(
										(a: any, b: any) => a.price - b.price
									)
									?.map((plan) => (
										<SwiperSlide
											key={plan?.id}
											className={clx(
												styles.swiperSlide,
												plan?.isRecommended &&
													styles.selected,
												selectedCP?.planData?.length ===
													1 && styles.width100
											)}>
											<SubscriptionPlanCard
												card={plan}
												subscriptionPlanPrice={
													subscriptionPlanDetails?.price
												}
												selectedCommunityPartnerId={
													selectedCP?.id
												}
												loadingPlansFeature={
													isLoadingPlansFeature
												}
												selected={
													CurrentSubscriptionPlanId ===
													plan.id
												}
												onSelect={handleSelectPlan}
											/>
										</SwiperSlide>
									))}
							</Swiper>
						</div>
					)}
				</div>
			)}
			{!isLoading && selectedCP?.planData?.length === 0 && (
				<NoData message="title.no-subscription-plan" />
			)}
			{(isLoadingCommunityPartner || isLoading) && (
				<Row className="position-relative mt-2">
					<div className="d-flex align-items-center gap-5 w-100 px-4">
						{[1, 2].map((item) => {
							return (
								<SkeletonWrapper
									key={item}
									count={1}
									wrapperClassName="col"
									skeleton={SkeletonPlans}
								/>
							);
						})}
					</div>
				</Row>
			)}
			{/* <h2 className="fw-500 fs-6 mt-4 text-gray-4">
				{t('text.upgrade-rules')}:
			</h2>
			<p className="fw-400 fs-small mb-2 text-gray-4">
				{t('text.can-upgraded-any-time')}
			</p>
			<p className="fw-400 fs-small mb-4 text-gray-4">
				{t('text.description-upgrade-rules')}
			</p>
			<h2 className="fw-500 fs-6 text-gray-4">
				{t('text.upgrade-and-switch-CP')}
			</h2>
			<p className="fw-400 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP')}
			</p>
			<p className="fw-500 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP-2')}
			</p> */}
			<StepNavigation onPrev={onPrev} showNext={false} />
		</div>
	);
};
