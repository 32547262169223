import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useDelayUnmount,
	useModal,
	useSubscriptionCalculateUpgrade,
	useSubscriptionPlanDetails
} from 'Hooks';
import { api, clx, currencyFormatter } from 'Utils';
import {
	AssetConfigInputType,
	Colors,
	SubscriptionCalculate,
	SubscriptionCommunityPartner,
	SubscriptionPaymentStatus,
	SubscriptionPlan,
	SubscriptionPlanType
} from 'Types';
import {
	Button,
	SubmitButton,
	FontAwesome,
	SubscriptionCommunityPartnerCard,
	ModalBoxPaymentResult,
	ModalBoxPlanInf,
	LoadingMask,
	SkeletonWrapper
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { setInputAssetData } from 'src/containers/Actions';
import { useDispatch } from 'react-redux';
import '../../../../../../styles/core/forms.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { UpgradeSubscriptionVoucher } from '../UpgradeSubscriptionVoucher';

interface Props {
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const UpgradeSubscriptionReview: FC<Props> = ({ onPrev, goToStep }) => {
	const { subscription_id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { sendRequest } = useAxios<any>();
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const [openModal, toggleModal] = useModal();
	const [subscriptionId, setSubscriptionId] = useState('');
	const [selectedInfoPlan, setSelectedInfoPlan] =
		useState<SubscriptionPlan>();
	const [openModalPlanInfo, toggleModalPlanInfo] = useModal();
	const [loading, setLoading] = useState(false);
	const [subscriptionPaymentStatus, SetSubscriptionPaymentStatus] =
		useState<SubscriptionPaymentStatus>(1);
	const [newSubscriptionCalculate, setNewSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [isChecked, setIsChecked] = useState(false);
	const {
		sendRequest: sendSubscriptionRequest,
		isLoading: isSubmittingSubscription
	} = useAxios<string>();
	const [voucherCode, setVoucherCode] = useState<string | null>(
		searchParams.get('voucherCode') || ''
	);

	const shouldRenderModal = useDelayUnmount(openModalPlanInfo, 350);
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const CurrentSubscriptionPlanId =
		searchParams.get('CurrentSubscriptionPlanId') || '';
	const UpgradeSubscriptionPlanId =
		searchParams.get('UpgradeSubscriptionPlanId') || '';
	const planType = searchParams.get('planType') || '';
	const queryStep = searchParams.get('step') || '';

	const { data: subscriptionPlanDetails } = useSubscriptionPlanDetails(
		UpgradeSubscriptionPlanId as string,
		{
			enabled: !!UpgradeSubscriptionPlanId
		}
	);

	const { data: subscriptionCalculate, isLoading } =
		useSubscriptionCalculateUpgrade(
			communityPartnerId,
			CurrentSubscriptionPlanId,
			UpgradeSubscriptionPlanId,
			'',
			Number(planType) as number,
			{
				enabled: !!selectedCP?.id
			}
		);

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	const SkeletonCalculate = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
			</div>
		);
	};

	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				getPlanData(data);
			}
		);
	};

	const getPlanData = (communityPartner) => {
		if (communityPartner) {
			sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(planData) => {
					setSelectedCP({ ...communityPartner, planData });
					setLoading(false);
				}
			);
		}
	};

	useEffect(() => {
		setLoading(true);
		getCommunityPartner();
	}, []);

	useEffect(() => {
		setNewSubscriptionCalculate(subscriptionCalculate);
	}, [subscriptionCalculate]);

	const onRegister = async () => {
		setIsChecked(true);
		await sendSubscriptionRequest(
			api.updateSubscription(subscription_id as string),
			{
				method: 'PUT',
				data: {
					subscriptionPlanId: UpgradeSubscriptionPlanId,
					voucherCode: voucherCode
				}
			},
			async (res) => {
				if (res) {
					await sendSubscriptionRequest(
						api.getBusinessPartnerMe,
						{
							method: 'GET'
						},
						(data: any) => {
							const basic_Profile_data = {
								nameElement: data?.businessName ?? '',
								mainEmailAddress: data?.mainEmailAddress ?? '',
								phoneElement: data.contact?.tel ?? '',
								SubscriptionId: res ?? ''
							};

							for (const key in basic_Profile_data) {
								dispatch(
									setInputAssetData({
										name: key as AssetConfigInputType,
										value: basic_Profile_data[key]
									})
								);
							}
							if (newSubscriptionCalculate?.totalAmount === 0) {
								toggleModal();
								setSubscriptionId(subscription_id as string);
								SetSubscriptionPaymentStatus(
									voucherCode ? 7 : 1
								);
							} else {
								goToStep('step3');
								navigate(
									`/subscriptions/${subscription_id}/upgrade?step=step3&SubscriptionId=${res}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&UpgradeSubscriptionPlanId=${UpgradeSubscriptionPlanId}&communityPartnerId=${communityPartnerId}&voucherCode=${
										voucherCode ?? ''
									}&planType=${planType}`
								);
							}
						}
					);
				} else {
					setIsChecked(false);
				}
			}
		);
	};

	let selectedPlan: any = {};
	let currentPlan: any = {};
	selectedCP?.planData?.map((item) => {
		if (item.id === UpgradeSubscriptionPlanId) {
			selectedPlan = item;
		}
		if (item.id === CurrentSubscriptionPlanId) {
			currentPlan = item;
		}
	});

	useEffect(() => {
		if (queryStep) {
			goToStep(queryStep);
		}
	}, [queryStep]);

	return (
		<div className={styles.form}>
			<div className="position-relative">
				{!loading && (
					<>
						<SubscriptionCommunityPartnerCard
							key={selectedCP?.id}
							communityPartner={
								selectedCP as SubscriptionCommunityPartner
							}
							showDescription={false}
							selected={false}
						/>
					</>
				)}
				{loading && (
					<Row className="position-relative">
						<SkeletonWrapper
							skeleton={Skeleton}
							count={1}
							wrapperClassName="row"
						/>
					</Row>
				)}
			</div>
			<div className="mb-5 py-4" style={{ display: 'flex' }}>
				<div
					className={clx(
						'position-relative',
						styles.boxPlanUpgradeStep2
					)}>
					<div className="d-flex flex-column justify-content-between h-100">
						<div>
							<h6 className="fs-sm fw-400 text-gray-3">
								{t('title.your-current-plan')}
							</h6>
							{isLoading ||
								(loading && (
									<div className={'d-flex flex-column gap-3'}>
										<LoadingMask />
										<div className="d-flex gap-3">
											<div className="py-2 d-flex flex-column gap-3">
												<div
													className={
														styles.labelHeder
													}></div>
												<div
													className={
														styles.labelText
													}></div>
												<div
													className={
														styles.labelText
													}></div>
												<div
													className={
														styles.labelText
													}></div>
											</div>
										</div>
									</div>
								))}
							{!isLoading && currentPlan?.name && !loading && (
								<>
									<div>
										<div className="my-3 d-flex align-items-center gap-1">
											<h1 className="fs-5 fw-700">
												{currentPlan?.name}
											</h1>
											<Button
												color={Colors.empty}
												onClick={() => {
													setSelectedInfoPlan(
														currentPlan
													);
													toggleModalPlanInfo();
												}}>
												<FontAwesome
													icon={'info-circle'}
													size="lg"
												/>
											</Button>
										</div>
									</div>
									<div className={styles.cardPlan}>
										<div className="my-3">
											<div
												className="text-gray-3 fs-sm fw-400 mt-1"
												style={{
													whiteSpace: 'pre-wrap'
												}}
												dangerouslySetInnerHTML={{
													__html:
														currentPlan?.shortDescription ??
														''
												}}
											/>
										</div>
									</div>
									{/* <div className={clx(styles.bullets)}>
									<div className="my-3">
										<div
											className="text-gray-3 fs-sm fw-400 mt-1"
											style={{
												whiteSpace: 'pre-wrap'
											}}
											dangerouslySetInnerHTML={{
												__html:
													currentPlan?.description ??
													''
											}}
										/>
									</div>
								</div> */}
								</>
							)}
						</div>
						{!isLoading && currentPlan?.name && !loading && (
							<div className="d-flex justify-content-between my-3">
								<div>{t('label.your-current-plan-price')}:</div>
								<div className="d-flex flex-column text-primary">
									<h5 className="h5 mb-0">
										{currencyFormatter(
											currentPlan?.price ?? 0
										)}
									</h5>

									<span className="fw-300">
										{t('title.excluded-tax')}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div
					className="mx-2"
					style={{
						width: '2%',
						alignContent: 'center'
					}}>
					<div>
						<FontAwesome
							icon="chevron-right"
							size="2x"
							color="text-primary"
						/>
					</div>
				</div>
				<div
					className={clx(
						'position-relative',
						styles.boxPlanUpgradeStep2
					)}>
					<div className="d-flex flex-column justify-content-between h-100">
						<div>
							<div className="position-relative">
								<Button
									color={Colors['white-primary']}
									className={styles.edit}
									onClick={() => {
										goToStep('step1');
										navigate(
											`/subscriptions/${subscription_id}/upgrade?step=step1&communityPartnerId=${communityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&planTyp=${planType}`
										);
									}}>
									<FontAwesome icon="edit" />
									{t('text.change')}
								</Button>
							</div>
							<h6 className="fs-sm fw-400 text-gray-3 mb-3">
								{t('title.upgrade-to')}
							</h6>
							{isLoading ||
								(loading && (
									<div className={'d-flex flex-column gap-3'}>
										<LoadingMask />
										<div className="d-flex gap-3">
											<div className="py-2 d-flex flex-column gap-3">
												<div
													className={
														styles.labelHeder
													}></div>
												<div
													className={
														styles.labelText
													}></div>
												<div
													className={
														styles.labelText
													}></div>
												<div
													className={
														styles.labelText
													}></div>
											</div>
										</div>
									</div>
								))}
							{!isLoading && selectedPlan?.name && !loading && (
								<>
									<div>
										<div className="my-3 d-flex align-items-center gap-1">
											<h1 className="fs-5 fw-700">
												{selectedPlan?.name}
											</h1>
											<Button
												color={Colors.empty}
												onClick={() => {
													setSelectedInfoPlan(
														selectedPlan
													);
													toggleModalPlanInfo();
												}}>
												<FontAwesome
													icon={'info-circle'}
													size="lg"
												/>
											</Button>
										</div>
									</div>
									<div className={styles.cardPlan}>
										<div className="my-3">
											<div
												className="text-gray-3 fs-sm fw-400 mt-1"
												style={{
													whiteSpace: 'pre-wrap'
												}}
												dangerouslySetInnerHTML={{
													__html:
														selectedPlan?.shortDescription ??
														''
												}}
											/>
										</div>
									</div>
									{/* <div className={clx(styles.bullets)}>
								<div className="my-3">
									<div
										className="text-gray-3 fs-sm fw-400 mt-1"
										style={{ whiteSpace: 'pre-wrap' }}
										dangerouslySetInnerHTML={{
											__html:
												selectedPlan?.description ?? ''
										}}
									/>
								</div>
							</div> */}
								</>
							)}
						</div>
						{!isLoading && selectedPlan?.name && !loading && (
							<div className="d-flex justify-content-between my-3">
								<div>{t('label.upgrade-price')}:</div>
								<div className="d-flex flex-column gap-1">
									{currentPlan?.price !== 0 && (
										<h5
											className="h5 mb-0"
											style={{
												textDecoration: 'line-through',
												color: '#AAAAB2'
											}}>
											{currencyFormatter(
												selectedPlan?.price ?? 0
											)}
										</h5>
									)}
									<h5 className="h5 mb-0 text-primary">
										{currencyFormatter(
											selectedPlan?.price -
												currentPlan?.price
										)}
									</h5>
									<span className="fw-300 text-primary">
										{t('title.excluded-tax')}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{loading || isLoading ? (
				<Row className="position-relative">
					<SkeletonWrapper
						skeleton={SkeletonCalculate}
						count={1}
						wrapperClassName="row"
					/>
				</Row>
			) : (
				<UpgradeSubscriptionVoucher
					subscriptionName={selectedCP?.businessName || ''}
					isPremium={
						subscriptionPlanDetails?.type ===
						SubscriptionPlanType.PremiumPartner
					}
					setIsChecked={setIsChecked}
					isChecked={isChecked}
					setVoucherCode={setVoucherCode}
					voucherCode={voucherCode ?? ''}
					selectedPlanId={UpgradeSubscriptionPlanId}
					setNewSubscriptionCalculate={setNewSubscriptionCalculate}
					selectedCommunityPartnerId={communityPartnerId}
					termsCondition={
						subscriptionPlanDetails?.termsCondition as string
					}
					subscriptionCalculate={
						newSubscriptionCalculate || {
							planBasePrice: 0,
							additionalPrice: 0,
							subscriptionPrice: 0,
							subscriptionDuration: 0,
							discountAmount: 0,
							discountPercent: 0,
							subTotal: 0,
							taxPercent: 0,
							taxAmount: 0,
							totalAmount: 0
						}
					}
				/>
			)}
			<div className="d-flex align-items-center justify-content-end pt-4 gap-3">
				{onPrev && (
					<Button
						color={Colors['white-primary']}
						className="gap-2 me-auto"
						onClick={onPrev}>
						<FontAwesome
							icon="chevron-left"
							size="1x"
							swapOpacity
						/>
						{t('wizard.prev')}
					</Button>
				)}
				<SubmitButton
					icon={false}
					isDisable={!isChecked}
					color={Colors.secondary}
					label={
						newSubscriptionCalculate?.totalAmount !== 0
							? t('wizard.proceed-to-payment')
							: t('button.free-checkout')
					}
					savingLabel=""
					isSubmitting={isSubmittingSubscription}
					onClick={onRegister}
				/>
			</div>
			<ModalBoxPaymentResult
				subscriptionId={subscriptionId}
				subscriptionCalculate={
					newSubscriptionCalculate as SubscriptionCalculate
				}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={openModal}
				onClose={toggleModal}
			/>
			{selectedInfoPlan && shouldRenderModal && (
				<ModalBoxPlanInf
					isOpen={openModalPlanInfo}
					onClose={toggleModalPlanInfo}
					subscription_id={
						currentPlan?.id === selectedInfoPlan?.id
							? subscription_id
							: null
					}
					plan={selectedInfoPlan as SubscriptionPlan}
				/>
			)}
		</div>
	);
};
