import { SelectOptionIcon } from 'Types';

export interface SocialNetwork {
	socialNetwork: number;
	socialNetworkUrl: string;
}

export interface SocialFields {
	name: SelectOptionIcon;
	url: string;
	type: string;
}

export enum SocialNetworks {
	None = 0,
	Facebook = 1,
	Twitter = 2,
	Instagram = 3,
	Youtube = 4,
	Linkedin = 5,
	Xing = 6,
	Tiktok = 7,
	Whatsapp = 8
}
