export enum PaymentMethodStatus {
	invoice = 0,
	online = 1,
	pending = 2,
	payPal = 3,
	sepa = 5
}
export enum PaymentStatus {
	Pending = 0,
	Paid = 1,
	Failed = 2,
	InProcess = 3,
	Canceled = 4,
	PendingSepa = 5,
	Rejected = 6
}
export interface Payment {
	communityPartnerId: string;
	createdAt: string;
	creditDocumentUrl:string;
	creditPaymentNumber: string;
	id: string;
	credits: any;
	lastUpdatedAt: string;
	month: number;
	status: number;
	totalDonatedAmount: number;
	totalPaymentAmount: number;
	year: number;
}

export interface PaymentDetails {
	cardHolder: string;
	cardNumber: string;
	cardFingerprint: string;
	cardAudience: string;
	cardLabel: string;
	cardCountryCode: string;
	cardSecurity: string;
	feeRegion: string;
	failureReason: string;
	failureMessage: string;
	wallet: string;
}

export interface PaymentLinks {
	self: PaymentLinksState;
	checkout: PaymentLinksState;
	refunds: PaymentLinksState;
	chargebacks: PaymentLinksState;
	settlement: PaymentLinksState;
	documentation: PaymentLinksState;
	mandate: PaymentLinksState;
	subscription: PaymentLinksState;
	customer: PaymentLinksState;
	changePaymentState: PaymentLinksState;
}

export interface PaymentLinksState {
	href: string;
	type: string;
}

export interface PaymentAmount {
	currency: string;
	value: string;
}
export interface PaymentMethodCard {
	id: number;
	title: string;
	icon: any;
	description: string;
}
