import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useModal,
	useSubscriptionCalculate,
	useSubscriptionPlanDetails
} from 'Hooks';
import { api, clx } from 'Utils';
import {
	AssetConfigInputType,
	Colors,
	SubscriptionCalculate,
	SubscriptionCommunityPartner,
	SubscriptionPaymentStatus,
	SubscriptionPlan,
	SubscriptionPlanType,
	SubscriptionSate
} from 'Types';
import {
	Button,
	CreateSubscriptionVoucher,
	SubmitButton,
	FontAwesome,
	SubscriptionCommunityPartnerCard,
	ModalBoxPaymentResult,
	ModalBoxPlanInf,
	LoadingMask,
	SkeletonWrapper
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { setInputAssetData } from 'src/containers/Actions';
import { useDispatch } from 'react-redux';
import '../../../../../../styles/core/forms.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row } from 'reactstrap';

interface Props {
	fields: SubscriptionSate;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionReview: FC<Props> = ({
	fields,
	onPrev,
	goToStep
}) => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios<any>();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openModal, toggleModal] = useModal();
	const [subscriptionId, setSubscriptionId] = useState('');
	const [openModalPlanInfo, toggleModalPlanInfo] = useModal();
	const [loading, setLoading] = useState(false);
	const [voucherCode, setVoucherCode] = useState<string | null>(null);
	const [subscriptionPaymentStatus, SetSubscriptionPaymentStatus] =
		useState<SubscriptionPaymentStatus>(1);
	const [newSubscriptionCalculate, setNewSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [isChecked, setIsChecked] = useState(false);
	const {
		sendRequest: sendSubscriptionRequest,
		isLoading: isSubmittingSubscription
	} = useAxios<string>();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const planId = searchParams.get('planId') || '';
	const planType = searchParams.get('planType') || '';

	const selectedPlanId = fields.selectedPlan?.id ?? planId;
	const selectedVoucher = fields.selectedVoucher;
	const selectedCommunityPartner = fields.selectedCommunityPartner;
	// const navigationUrl = `${config.publicUrl}/community-partner/${selectedCommunityPartner?.id}/${selectedCommunityPartner?.id}`;

	const { data: subscriptionCalculate, isLoading } = useSubscriptionCalculate(
		selectedCP?.id as string,
		selectedPlanId as string,
		'',
		Number(planType) as number,
		{
			enabled: !!selectedCP?.id && !!selectedPlanId
		}
	);

	const { data: subscriptionPlanDetails } = useSubscriptionPlanDetails(
		selectedPlanId as string,
		{
			enabled: !!selectedPlanId
		}
	);

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	const SkeletonCalculate = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
			</div>
		);
	};

	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				getPlanData(data);
			}
		);
	};

	const getPlanData = (communityPartner) => {
		if (communityPartner) {
			sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(planData) => {
					setSelectedCP({ ...communityPartner, planData });
					setLoading(false);
				}
			);
		}
	};

	useEffect(() => {
		setLoading(true);
		getCommunityPartner();
	}, [selectedCommunityPartner]);

	useEffect(() => {
		setNewSubscriptionCalculate(subscriptionCalculate);
	}, [subscriptionCalculate]);

	const onRegister = async () => {
		setIsChecked(true);
		await sendSubscriptionRequest(
			api.createSubscription,
			{
				method: 'POST',
				data: {
					coummunityPartnerId: selectedCP?.id,
					subscriptionPlanId: selectedPlanId,
					voucherCode: voucherCode
				}
			},
			async (res) => {
				if (res) {
					await sendSubscriptionRequest(
						api.getBusinessPartnerMe,
						{
							method: 'GET'
						},
						(data: any) => {
							const basic_Profile_data = {
								nameElement: data?.businessName ?? '',
								mainEmailAddress: data?.mainEmailAddress ?? '',
								phoneElement: data.contact?.tel ?? '',
								SubscriptionId: res ?? ''
							};

							for (const key in basic_Profile_data) {
								dispatch(
									setInputAssetData({
										name: key as AssetConfigInputType,
										value: basic_Profile_data[key]
									})
								);
							}
							if (
								newSubscriptionCalculate?.totalAmount === 0 ||
								!selectedCP?.isAutoApprove
							) {
								toggleModal();
								setSubscriptionId(res);
								SetSubscriptionPaymentStatus(
									selectedCP?.isAutoApprove
										? voucherCode
											? 7
											: 1
										: 6
								);
							} else {
								goToStep('step4');
								navigate(
									`/subscriptions/add?step=step4&SubscriptionId=${res}&voucherCode=${
										voucherCode ?? ''
									}&planType=${planType}`
								);
							}
						}
					);
				} else {
					setIsChecked(false);
				}
			}
		);
	};

	let selectedPlanName = '';
	let selectedPlanDescription = '';
	let selectedPlanShortDescription = '';
	let selectedPlan = {};

	selectedCP?.planData?.map((item) => {
		if (item.id === selectedPlanId) {
			selectedPlan = item;
			selectedPlanName = item.name;
			selectedPlanDescription = item.description;
			selectedPlanShortDescription = item.shortDescription;
		}
	});

	return (
		<div className={styles.form}>
			<div className="position-relative">
				{!loading && (
					<>
						<div style={{ borderBottom: '1px solid #E7E7EC' }}>
							<SubscriptionCommunityPartnerCard
								key={selectedCP?.id}
								communityPartner={
									selectedCP as SubscriptionCommunityPartner
								}
								selected={false}
							/>
						</div>
						<Button
							color={Colors['white-primary']}
							className={styles.edit}
							onClick={() => {
								goToStep('step1');
								navigate(`/subscriptions/add?step=step1`);
							}}>
							<FontAwesome icon="edit" />
							{t('text.change')}
						</Button>
					</>
				)}
				{loading && (
					<Row className="position-relative">
						<SkeletonWrapper
							skeleton={Skeleton}
							count={1}
							wrapperClassName="row"
						/>
					</Row>
				)}
			</div>
			<div className="mb-5 py-5 d-flex justify-content-between border-bottom">
				<div>
					<h6 className="fs-sm fw-400 text-gray-3 mb-3">
						{t('title.selected-plan')}
					</h6>
					{isLoading ||
						(loading && (
							<div className={'d-flex flex-column gap-3'}>
								<LoadingMask />
								<div className="d-flex gap-3">
									<div className="py-2 d-flex flex-column gap-3">
										<div
											className={styles.labelHeder}></div>
										<div className={styles.labelText}></div>
										<div className={styles.labelText}></div>
										<div className={styles.labelText}></div>
									</div>
								</div>
							</div>
						))}
					{!isLoading && selectedPlanName && (
						<>
							<div>
								<div className="my-3 d-flex align-items-center gap-1">
									<h1 className="fs-5 fw-700">
										{selectedPlanName}
									</h1>
									<Button
										color={Colors.empty}
										onClick={toggleModalPlanInfo}>
										<FontAwesome
											icon={'info-circle'}
											size="lg"
										/>
									</Button>
								</div>
							</div>
							<div className={styles.cardPlan}>
								<div className="my-3">
									<div
										className="text-gray-3 fs-sm fw-400 mt-1"
										style={{ whiteSpace: 'pre-wrap' }}
										dangerouslySetInnerHTML={{
											__html:
												selectedPlanShortDescription ??
												''
										}}
									/>
								</div>
							</div>
							<div className={clx(styles.bullets)}>
								<div className="my-3">
									<div
										className="text-gray-3 fs-sm fw-400 mt-1"
										style={{ whiteSpace: 'pre-wrap' }}
										dangerouslySetInnerHTML={{
											__html:
												selectedPlanDescription ?? ''
										}}
									/>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="border-bottom position-relative">
					<Button
						color={Colors['white-primary']}
						className={styles.edit}
						onClick={() => {
							goToStep('step2');
							navigate(
								`/subscriptions/add?step=step2&communityPartnerId=${selectedCP?.id}`
							);
						}}>
						<FontAwesome icon="edit" />
						{t('text.change')}
					</Button>
				</div>
			</div>
			{loading || isLoading ? (
				<Row className="position-relative">
					<SkeletonWrapper
						skeleton={SkeletonCalculate}
						count={1}
						wrapperClassName="row"
					/>
				</Row>
			) : (
				<CreateSubscriptionVoucher
					setIsChecked={setIsChecked}
					isChecked={isChecked}
					subscriptionName={selectedCP?.businessName || ''}
					isPremium={
						subscriptionPlanDetails?.type ===
						SubscriptionPlanType.PremiumPartner
					}
					termsCondition={
						subscriptionPlanDetails?.termsCondition as string
					}
					setVoucherCode={setVoucherCode}
					selectedPlanId={planId}
					setNewSubscriptionCalculate={setNewSubscriptionCalculate}
					selectedCommunityPartnerId={communityPartnerId}
					subscriptionCalculate={
						newSubscriptionCalculate || {
							planBasePrice: 0,
							additionalPrice: 0,
							subscriptionPrice: 0,
							subscriptionDuration: 0,
							discountAmount: 0,
							discountPercent: 0,
							subTotal: 0,
							taxPercent: 0,
							taxAmount: 0,
							totalAmount: 0
						}
					}
				/>
			)}
			<div className="d-flex align-items-center justify-content-end pt-4 gap-3">
				{onPrev && (
					<Button
						color={Colors['white-primary']}
						className="gap-2 me-auto"
						onClick={onPrev}>
						<FontAwesome
							icon="chevron-left"
							size="1x"
							swapOpacity
						/>
						{t('wizard.prev')}
					</Button>
				)}
				<SubmitButton
					icon={false}
					isDisable={!isChecked}
					color={Colors.secondary}
					label={
						selectedVoucher?.totalAmount !== 0
							? t('wizard.proceed-to-payment')
							: t('button.free-checkout')
					}
					savingLabel=""
					isSubmitting={isSubmittingSubscription}
					onClick={onRegister}
				/>
			</div>
			<ModalBoxPaymentResult
				subscriptionId={subscriptionId}
				subscriptionCalculate={
					newSubscriptionCalculate as SubscriptionCalculate
				}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={openModal}
				onClose={toggleModal}
			/>
			<ModalBoxPlanInf
				isOpen={openModalPlanInfo}
				onClose={toggleModalPlanInfo}
				plan={selectedPlan as SubscriptionPlan}
			/>
		</div>
	);
};
