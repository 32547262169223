import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCommunityPartnerCredits } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { BadgeStatus, Colors } from 'Types';
import {
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	creditStatus,
	unslug,
	CommunityPartnerCreditStatus
} from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	NoData,
	Pagination,
	ListingPage,
	Button,
	EndOfList,
	SystemErrorAlert,
	Badge
} from 'Elements';

export const ListCredit = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { credits } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = unslug(searchParams.get('status') ?? '') ?? '';

	const [state, setState] = useState({
		pageSize: 20,
		totalItems: 0,
		currentPage: pageParam,
		keyword: queryParam,
		filter: {
			status: statusParam
		}
	});

	const getStatusByName = (filter_name: string) => {
		return creditStatus.find(
			(filter) =>
				filter.title?.toLowerCase() === filter_name?.toLowerCase()
		);
	};

	const { data, isFetching, isError } = useCommunityPartnerCredits(
		{
			communityPartnerCreditStatus: getStatusByName(statusParam)?.value,
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		},
		{
			onSuccess: (data) => {
				setState((prev) => ({
					...prev,
					totalItems: data.totalItems
				}));
			}
		}
	);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			filter: {
				...prev.filter,
				status: selected_filter.label?.toString() ?? ''
			}
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = (id: string) => {
		return navigate(`${credits.path}/${id}`, {
			state: { prevPath: `${credits.path}` }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'credit-number': 'w-sm-15',
			'business-partner': 'w-sm-25 fw-500',
			'created-at': 'w-sm-15',
			amount: 'w-sm-15',
			status: 'w-sm-15',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	const getStatusData = (status) => {
		return CommunityPartnerCreditStatus.find(
			(elem) => elem.value === status
		);
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[10, 15, 20, 50]}
			selectedPageSize={state.pageSize}
			filterOptions={creditStatus}
			selectedFilter={t(
				getStatusByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}
			onPageSizeChange={onChangePageSize}>
			<DataTable>
				{state?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="label.credit-number"
							cellClassName={cellClassName('credit-number')}
						/>
						<DataTableRowCell
							cellText="table.business-partner"
							cellClassName={cellClassName('business-partner')}
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName={cellClassName('created-at')}
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName={cellClassName('amount')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				{!isError && (
					<DataTableBody>
						{!isFetching && (
							<motion.div
								variants={containerVariants}
								initial="hidden"
								animate="show">
								{data?.items?.map((credit) => {
									return (
										<DataTableRow key={credit?.id}>
											<DataTableRowCell
												cellClassName={cellClassName(
													'credit-number'
												)}
												cellHead="table.credit-number"
												cellText={credit?.creditNumber}
											/>
											<DataTableRowCell
												cellClassName={clx(
													cellClassName(
														'business-partner'
													),
													'fw-500'
												)}
												cellHead="table.business-partner"
												cellText={
													credit?.businessPartnerInfo
														?.organizationName ??
													'---'
												}
											/>
											<DataTableRowCell
												cellClassName={cellClassName(
													'created-at'
												)}
												cellHead="table.created-at">
												<time
													title={formatLocaleDateTime(
														credit?.createdAt
													)}
													dateTime={formatLocaleDateTime(
														credit?.createdAt
													)}>
													{formatLocaleDateTime(
														credit?.createdAt
													)}
												</time>
											</DataTableRowCell>
											<DataTableRowCell
												cellClassName={cellClassName(
													'amount'
												)}
												cellHead="table.amount">
												<div className="d-flex flex-column text-end text-sm-start">
													<strong
														className="fs-base"
														title={currencyFormatter(
															credit?.totalCreditAmount
														)}>
														{currencyFormatter(
															credit?.totalCreditAmount
														)}
													</strong>
													<span className="text-gray-3 lh-base fs-small">
														{credit.includedTax
															? t(
																	'label.tax-included'
															  )
															: t('label.no-tax')}
													</span>
												</div>
											</DataTableRowCell>
											<DataTableRowCell
												cellClassName={cellClassName(
													'status'
												)}
												cellHead="table.status"
												boxClassName="d-flex align-items-center flex-wrap gap-2">
												<Badge
													color={
														getStatusData(
															credit?.creditStatus
														)?.color ??
														Colors.primary
													}>
													{t(
														`${
															getStatusData(
																credit?.creditStatus
															)?.label
														}`
													)}
												</Badge>
												{credit?.donated ? (
													<Badge
														color={
															Colors.association
														}>
														{t('badge.donated')}
													</Badge>
												) : (
													<></>
												)}
											</DataTableRowCell>
											<DataTableRowCell
												cellHead="table.operation"
												cellClassName={cellClassName(
													'operation'
												)}
												boxClassName="d-flex flex-wrap gap-2">
												<Button
													size="sm"
													color={
														Colors['white-primary']
													}
													label={
														t(
															'button.details'
														) as string
													}
													onClick={() =>
														redirectTo(credit.id)
													}
												/>
												{/* {credit?.downloadUrl && (
													<a
														href={
															credit?.downloadUrl
														}
														rel="noreferrer"
														target="_blank"
														className={
															styles.button
														}
														download={`jobbiplace-invoice_${credit?.transactionCode}.pdf`}>
														<FontAwesome
															icon="download"
															size="lg"
														/>
													</a>
												)} */}
											</DataTableRowCell>
										</DataTableRow>
									);
								})}
							</motion.div>
						)}
						{isFetching && (
							<DataTableLoading
								widths={[15, 25, 15, 15, 15, 15]}
								count={state.pageSize}
							/>
						)}
					</DataTableBody>
				)}
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-credit" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListCredit.displayName = 'ListCredit';
