import { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgentEvent, Colors } from 'Types';
import { Button, Card, ModalBoxQrCode, FontAwesome } from 'Elements';
import { api, clx, config, formatLocaleDateTime } from 'Utils';
import { useDelayUnmount, useEventQrCode, useModal } from 'Hooks';
import styles from 'partials/feature/publish-bar.module.scss';

interface Props {
	className?: string;
	event: AgentEvent;
}

export const EventPublishBar: FC<Props> = memo(({ event, className }) => {
	const { t } = useTranslation();
	const { event_id } = useParams() as { event_id: string };
	const [openQrModal, toggleQrModal] = useModal();
	const shouldRenderQrhModal = useDelayUnmount(openQrModal, 350);
	// const { data, isLoading } = useEventQrCode(event_id);
	const previewUrl = `${config.publicUrl}/event/${event?.id}/${event.slugTitle}`;

	const redirectToPreview = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	return (
		<Card
			background="green_o-2"
			className={clx(
				className,
				'd-flex align-items-md-center flex-column flex-md-row mb-4'
			)}>
			<div className="d-flex align-items-md-center flex-column flex-md-row flex-wrap flex-grow-1 mb-3 mb-md-0 pe-3 gap-3">
				<div className="d-flex align-items-center me-2">
					<FontAwesome
						icon="code-branch"
						size="lg"
						className="me-2 flex-shrink-0"
					/>
					<span className="text-gray-3 fw-400">
						{t('card.published-version')}:
					</span>
					<span className="ms-1 text-gray-5">
						{event.publishedVersion ?? '...'}
					</span>
				</div>
				<div className="d-flex align-items-center me-2">
					<FontAwesome
						icon="calendar-days"
						size="lg"
						className=" me-2 flex-shrink-0"
					/>
					<span className="text-gray-3">
						{t('card.published-date')}:
					</span>
					<span className="ms-1 text-gray-5">
						{event?.publishedAt
							? formatLocaleDateTime(event?.publishedAt)
							: '...'}
					</span>
				</div>
			</div>
			<div className="ms-md-auto d-flex align-items-center gap-2">
				<Button
					color={Colors['white-primary']}
					title={t('title.download-qrcode')}
					className={styles.action}
					onClick={toggleQrModal}>
					<FontAwesome icon="qrcode" size="xl" />
				</Button>
				<Button
					color={Colors['white-primary']}
					className="gap-2"
					onClick={redirectToPreview}>
					<FontAwesome icon="eye" size="lg" />
					{t('button.view')}
				</Button>
			</div>
			{/* {shouldRenderQrhModal && (
				<ModalBoxQrCode
					isOpen={openQrModal}
					onClose={toggleQrModal}
					title={event.title}
					data={data}
					isLoading={isLoading}
					downloadQrcodeApi={api.downloadEventQrCode(event_id)}
					namespace="event"
				/>
			)} */}
		</Card>
	);
});

EventPublishBar.displayName = 'EventPublishBar';
