import { API } from 'Types';

const accountService = '/accountservice/api';
const subscriptionservice = 'subscriptionservice-b2b/api/b2b';
const jobservice = 'jobservice-b2b/api/b2b';
const mediaService = '/mediaservice/api';
const masterdataService = '/masterdataservice/api';
const addressService = '/addressservice/api';
const eventService = 'eventservice-b2b/api/b2b';

export const api: API = {
	// Business Partners
	getBusinessPartnerMe: `${subscriptionservice}/businesspartners/me`,
	getPaymentMethods: `${subscriptionservice}/businesspartners/me/payment-methods`,
	getBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles?id=${businessPartnerProfileId}`,
	updateBusinessPartnerEditProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles?id=${businessPartnerProfileId}`,
	getCompanyAgents: `${accountService}/companies/agent-list-profile`,
	updateBusinessPartnerProfile: `${subscriptionservice}/businesspartners/me/basic-info`,
	publishBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles/${businessPartnerProfileId}/publish`,
	unpublishBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles/${businessPartnerProfileId}/unpublish`,
	addPaymentMethod: `${subscriptionservice}/businesspartners/me/add-payment-method`,
	deletePaymentMethod: (paymentMethodId) =>
		`${subscriptionservice}/businesspartners/me/remove-payment-method/${paymentMethodId}`,
	updateCompanySetting: `${accountService}/companies/profile-setting`,
	updateBusinessPartnersLogo: `${subscriptionservice}/businesspartners/me/logo`,
	updateCompanyContact: `${accountService}/companies/contact-social-profile`,
	updateCompanyBenefit: `${accountService}/companies/benefitprofile`,
	updateCompanyLinkedinConfig: `${accountService}/companies/set-linkedin-configuration`,
	createLinkedinToken: `${accountService}/companies/create-linkedin-token-url`,
	validateLinkedinToken: (accessToken) =>
		`${accountService}/companies/validate-linkedin-auth-token?accessToken=${accessToken}`,
	createCompanyGallery: `${accountService}/companies/gallery-profile`,
	updateCompanyGallery: (galleryId) =>
		`${accountService}/companies/${galleryId}/update-gallery`,
	removeCompanyGallery: `${accountService}/companies/remove-gallery`,

	// Employee
	createEmployee: `${subscriptionservice}/businesspartneremployees`,
	getEmployees: `${subscriptionservice}/businesspartneremployees`,
	getEmployeeDetail: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	updateEmployeeDetail: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	removeEmployee: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	updateEmployeeImage: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/image/${employeeId}`,

	// Branch
	createBranch: `${subscriptionservice}/businesspartnerbranches`,
	getBranches: `${subscriptionservice}/businesspartnerbranches`,
	getBranchDetail: (branchId) =>
		`${subscriptionservice}/businesspartnerbranches/${branchId}`,
	updateBranchDetail: (branchId) =>
		`${subscriptionservice}/businesspartnerbranches/${branchId}`,
	removeBranch: (branchId) =>
		`${subscriptionservice}/businesspartnerbranches/${branchId}`,

	// Community Partners
	getCommunityPartnerMe: `${subscriptionservice}/communitypartners/me`,
	getCommunityPartnerCommissionLevelMe: `${subscriptionservice}/communitypartners/me/commission-level`,
	getCommunityPartnerProfile: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartnerprofiles?id=${communityPartnerProfileId}`,
	getCommunityPartner: (communityPartnerId) =>
		`${subscriptionservice}/communitypartners/${communityPartnerId}`,
	getPlansCommunityPartner: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartners/${communityPartnerProfileId}/get-plans`,
	updateCommunityPartnerEditProfile: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartnerprofiles/${communityPartnerProfileId}`,
	getAgentParentAssociation: (partnerClass) =>
		`${accountService}/agents/get-agents-by-class?partnerClass=${partnerClass}&api-version=2`,
	getCommunityPartnerArea: `${subscriptionservice}/communitypartners/areas`,
	publishCommunityPartnerProfile: `${subscriptionservice}/communitypartnerprofiles/publish`,
	unpublishCommunityPartnerProfile: `${subscriptionservice}/communitypartnerprofiles/unpublish`,
	getCommunityPartnerPartners: `${subscriptionservice}/partners`,
	getCommunityPartnersCompanies: `${subscriptionservice}/communitypartners`,
	getCommunityPartnerTypes: `${subscriptionservice}/communitypartnertypes`,
	getCommunityPartnerAssociation: `${subscriptionservice}/communitypartners/associations`,
	updateCommunityPartnersMe: `${subscriptionservice}/communitypartners/me`,
	updateCommunityPartnerSettings: `${subscriptionservice}/communitypartners/me/partnersettings`,
	updateCommunityPartnersMeAddress: `${subscriptionservice}/communitypartners/me/address`,
	updateCommunityPartnersMeLogo: `${subscriptionservice}/communitypartners/me/logo`,
	updateAgentBankAccount: `${subscriptionservice}/communitypartners/me/bank-account`,
	updateAgentSetting: `${accountService}/agents/profile-setting`,
	updateAgentContact: `${accountService}/agents/contact-social-profile`,
	updateAgentPlanPrice: `${accountService}/agents/profile-plan-info`,
	deleteAgentAccountRequest: `${accountService}/agents/delete-account-request`,
	deleteAgentAccount: `${accountService}/agents/delete-account`,
	updateBusinessPartnerApprovalStatus: (id) =>
		`${subscriptionservice}/partners/${id}/partner-request/approve`,
	updateBusinessPartnerRejectedStatus: (id) =>
		`${subscriptionservice}/partners/${id}/partner-request/reject`,
	validateVoucherCode: `${accountService}/companysubscriptions/validatevoucher?api-version=2`,
	updateVoucherCompany: (id, voucherCode) =>
		`${accountService}/companysubscriptions/${id}/update-voucher?voucherCode=${voucherCode}`,

	// Member
	createMember: `${subscriptionservice}/communitypartnermembers`,
	getMembers: `${subscriptionservice}/communitypartnermembers`,
	getMemberDetail: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,
	updateMemberDetail: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,
	removeMember: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,

	// Department
	createDepartment: `${subscriptionservice}/communitypartnerdepartments`,
	getDepartments: `${subscriptionservice}/communitypartnerdepartments`,
	getDepartmentDetail: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	updateDepartmentDetail: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	removeDepartment: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	updateDepartmentImage: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/image/${departmentId}`,

	// User
	getB2bUserProfile: `${subscriptionservice}/useraccounts/me`,
	updateB2bUserProfile: `${accountService}/users/user-profile`,
	getB2bUsers: `${subscriptionservice}/useraccounts`,
	createB2bAdmin: `${subscriptionservice}/useraccounts`,
	createB2bUser: `${subscriptionservice}/useraccounts`,
	removeB2bUser: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	getUserDetail: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	updateB2bAdmin: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	updateB2bUser: (userId) => `${subscriptionservice}/users/${userId}`,
	updateUserAvatar: (userId) =>
		`${subscriptionservice}/users/${userId}/image`,

	// Groups
	getUserGroups: `${accountService}/usergroups`,
	createUserGroup: `${accountService}/usergroups`,
	getUserGroupDetail: (id) => `${accountService}/usergroups/${id}`,
	updateUserGroup: (id) => `${accountService}/usergroups/${id}`,

	// Roles
	createUserRoles: `${accountService}/roles`,
	getUserRoles: `${accountService}/roles`,
	getUserRoleDetail: (id) => `${accountService}/roles/${id}`,
	updateUserRole: (id) => `${accountService}/roles/${id}`,

	// Permissions
	getPermissions: `${accountService}/permissions`,
	getPermissionsGraph: `${accountService}/permissions/graph`,

	// Image Media
	uploadMedia: `${mediaService}/Media/single`,
	uploadBatchMedia: `${mediaService}/Media/batch`,

	// Regions
	getAlternativeRegions: `${accountService}/regions/getregionsdetails`,
	getRegions: `${subscriptionservice}/regions`,
	updateAlternativeRegions: `${accountService}/agents/alternative-region-profile`,

	// Job
	createJob: `${jobservice}/jobs`,
	getJobs: `${jobservice}/jobs`,
	getJobTagCategories: `${jobservice}/jobtagcategories`,
	getActivityTags: `${jobservice}/jobactivities`,
	getJobTags: `${jobservice}/jobtags`,
	getJobsInternships: `${masterdataService}/jobs/organization-jobs-internships`,
	getJobDetail: (jobId) => `${jobservice}/jobs/${jobId}`,
	updateJob: (jobId) => `${jobservice}/jobs/${jobId}`,
	removeJob: (jobId) => `${jobservice}/jobs/${jobId}`,
	jobFeature: (jobId) => `${masterdataService}/jobs/${jobId}/job-feature`,
	getJobQrCode: (jobId) => `${masterdataService}/jobs/${jobId}/qrcode`,
	downloadJobQrCode: (jobId) =>
		`${masterdataService}/jobs/${jobId}/qrcode-download`,
	updateContactImage: (jobId) =>
		`${masterdataService}/jobs/${jobId}/update-contact-image`,
	getJobUsages: `${jobservice}/jobs/usage`,
	getJobGeneralUsages: `${jobservice}/jobs/general-usage`,
	updateJobPeriod: (jobId) => `${jobservice}/jobs/${jobId}/periods`,
	generateJobDescriptionByAI: `https://jp-dev-ai-service.azurewebsites.net/api/GenerateJobDescription?code=sva8c9EAoxxP_jxWajrJ114WwqpTLKdKmyg3VdE4Nh8CjAzFuMXCyaA==`,

	// Internship
	getInternships: `${masterdataService}/internships`,
	createInternship: `${masterdataService}/Internships`,
	addTermInternship: (jobId) => `${jobservice}/jobs/${jobId}/add-period`,
	removeTermInternship: (jobId, jobPeriodId) =>
		`${jobservice}/jobs/${jobId}/remove-period/${jobPeriodId}`,
	getInternshipDetail: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	updateInternship: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	removeInternship: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	internshipFeature: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/internship-feature`,
	getInternshipQrCode: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/qrcode`,
	downloadInternshipQrCode: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/qrcode-download`,

	// Publish Internship
	publishInternship: (internshipId) =>
		`${masterdataService}/publishInternships?jobId=${internshipId}`,
	unPublishInternship: (internshipId) =>
		`${masterdataService}/publishInternships/unpublish-internship?jobId=${internshipId}`,
	// linkedinPost: `${masterdataService}/publishjobs/linkedin-post`,

	// Publish Job
	publishJob: `${jobservice}/jobs/publish`,
	unPublishJob: `${jobservice}/jobs/unpublish`,
	linkedinPost: `${masterdataService}/publishjobs/linkedin-post`,

	// Activity
	getActivities: `${masterdataService}/activities/active-activities`,

	// Benefit
	getBenefits: `${subscriptionservice}/benefits`,

	// Sector
	getSectors: `${subscriptionservice}/businesssectors`,

	// JobType
	getJobTypes: `${masterdataService}/jobtypes/active-jobtypes`,

	// Address
	searchAddress: (query) =>
		`${addressService}/address/search?query=${query}&countrySet=de&language=de-DE`,
	searchPostalCity: (query) =>
		`${addressService}/address/search-postal-city?query=${query}&countrySet=DE&language=de-DE`,

	// Subscriptions
	createPrimarySubscription: `${subscriptionservice}/subscriptions/primary`,
	getSubscriptionPlans: `${subscriptionservice}/subscriptionplans`,
	getCompanySubscriptions: `${subscriptionservice}/subscriptions`,
	choosePaymentMethodSubscription: (
		subscriptionId,
		paymentMethodId,
		providerCustomerId
	) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}/assign-subscription-payment-method?paymentMethodId=${paymentMethodId}&providerCustomerId=${providerCustomerId}`,
	deletePaymentMethodSubscription: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}/remove-subscription-payment-method`,
	getSubscriptionCalculate: (
		CommunityPartnerId,
		SubscriptionPlanId,
		VoucherCode,
		SubscriptionPlanType
	) =>
		`${subscriptionservice}/subscriptions/subscription-price-calculate?CommunityPartnerId=${CommunityPartnerId}&SubscriptionPlanId=${SubscriptionPlanId}&VoucherCode=${VoucherCode}&SubscriptionPlanType=${SubscriptionPlanType}`,

	getSubscriptionCalculateUpgrade: (
		CommunityPartnerId,
		CurrentSubscriptionPlanId,
		UpgradeSubscriptionPlanId,
		VoucherCode,
		SubscriptionPlanType
	) =>
		`${subscriptionservice}/subscriptions/upgrade-subscription-price-calculate?CommunityPartnerId=${CommunityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&VoucherCode=${VoucherCode}&UpgradeSubscriptionPlanId=${UpgradeSubscriptionPlanId}&SubscriptionPlanType=${SubscriptionPlanType}`,
	getSubscriptionPlansFeature: (
		subscriptionPlanFeatureId,
		SubscriptionPlanId
	) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanFeatureId}/feature?SubscriptionPlanId=${SubscriptionPlanId}`,
	getSubscriptionPlansCommissionGroups: (commissionGroupId) =>
		`${subscriptionservice}/commissiongroups/${commissionGroupId}`,

	createSubscription: `${subscriptionservice}/subscriptions`,
	updateSubscription: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}`,
	cancelUpgradeSubscription: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}/cancel-upgrade-subscription`,
	getSubscriptionDetail: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}`,
	getSubscriptionPaymentsDetail: (currentSubscriptionPaymentId) =>
		`${subscriptionservice}/subscriptionpayments/${currentSubscriptionPaymentId}`,
	getBusinessPartnerInvoiceDetail: (invoiceId) =>
		`${subscriptionservice}/invoices/${invoiceId}`,
	getSubscriptionPayments: (subscriptionPaymentId, pageNumber, pageSize) =>
		`${subscriptionservice}/subscriptions/${subscriptionPaymentId}/subscription-payments?pageNumber=${pageNumber}&pageSize=${pageSize}`,
	subscriptionPay: (subscriptionPaymentId) =>
		`${subscriptionservice}/subscriptionpayments/${subscriptionPaymentId}/pay`,

	enableSubscriptionRenewal: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}/enable-auto-renewal`,
	disableSubscriptionRenewal: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}/disable-auto-renewal`,
	getAgentsToSwitch: `${accountService}/companysubscriptions/agents-to-switch`,
	switchAgent: (subscriptionId) =>
		`${accountService}/companysubscriptions/${subscriptionId}/switch-agent`,

	// Applicant
	getDemands: `${jobservice}/applyjobs/list`,
	getDemandDetail: (demandId, applicantId) =>
		`${jobservice}/applyjobs/${demandId}/${applicantId}`,
	approveDemand: (demandId) => `${jobservice}/applyjobs/${demandId}/approve`,
	rejectDemand: (demandId) => `${jobservice}/applyjobs/${demandId}/reject`,

	// Internship Registrations
	getInternshipRegistrations: `${masterdataService}/applicantdemandinternships/applicant-demand-b2b`,
	getInternshipRegistrationDetail: (internshipParticipantsId) =>
		`${masterdataService}/applicantdemandinternships/${internshipParticipantsId}`,
	updateInternshipRegistration: (internshipParticipantsId) =>
		`${masterdataService}/applicantdemandinternships/${internshipParticipantsId}/applicat-demand-status`,

	// Payments
	getPayments: `${accountService}/payments/payment-profile`,
	getPaymentStatus: (paymentId) =>
		`${subscriptionservice}/payments/${paymentId}`,
	getPaymentDetails: (payment_id) =>
		`${accountService}/payments/${payment_id}`,
	createPayment: `${accountService}/payments/payment-profile`,
	createPaymentByInvoice: (subscriptionId) =>
		`${accountService}/companysubscriptions/set-payment-method-to-invoice/${subscriptionId}`,

	// Credits
	getCommunityPartnerCredits: `${subscriptionservice}/communitypartnercredits`,
	getCommunityPartnerPayments: `${subscriptionservice}/subscriptionpayments`,
	getBusinessPartnerInvoice: `${subscriptionservice}/invoices`,
	getCommunityPartnerCreditPayments: `${subscriptionservice}/creditpayments`,
	getCompanyTransactions: `${accountService}/transactions/business-partner-profile`,
	getTransactionDetails: (transaction_id) =>
		`${accountService}/transactions/${transaction_id}`,
	getCommunityPartnerCreditDetails: (creditId) =>
		`${subscriptionservice}/communitypartnercredits/${creditId}`,
	getCommunityPartnerPaymentDetails: (creditpaymentId) =>
		`${subscriptionservice}/creditpayments/${creditpaymentId}`,

	// Voucher
	applyVoucher: (subscriptionPlanId, voucherCode) =>
		`${accountService}/voucher/apply?subscriptionPlanId=${subscriptionPlanId}&voucherCode=${voucherCode}`,
	validateVoucher: `${accountService}/voucher/validate`,
	getAgentVouchers: `${subscriptionservice}/vouchers`,
	getAgentVoucherDetails: (voucherId) =>
		`${subscriptionservice}/vouchers/${voucherId}`,

	// Invitation
	getInvitations: `${subscriptionservice}/communitypartnerinvitations`,
	getAvailableVouchers: `${subscriptionservice}/communitypartnerinvitations/available-vouchers`,
	createInvitation: `${subscriptionservice}/communitypartnerinvitations`,
	getInvitationDetails: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,
	updateInvitation: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,
	deleteInvitation: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,

	// Subscription plans
	createSubscriptionPlan: `${subscriptionservice}/subscriptionplans`,
	updateSubscriptionPlan: (subscriptionPlanId) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanId}`,
	getSubscriptionPlanDetails: (subscriptionPlanId) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanId}`,
	patchSubscriptionPlanMedia: (subscriptionPlanId) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanId}`,
	deleteSubscriptionPlanMedia: (id, subscriptionPlanGalleryId) =>
		`${subscriptionservice}/subscriptionplans/${id}?subscriptionPlanGalleryId=${subscriptionPlanGalleryId}`,

	updateSubscriptionPlanFeatures: (
		subscriptionPlanId,
		planFeatureid,
		publishedJobs,
		publishedInternships
	) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanId}/features?planFeatureid=${planFeatureid}&publishedJobs=${publishedJobs}&publishedInternships=${publishedInternships}`,
	// Extern Partners
	getExternPartners: `${accountService}/agentexternpartners`,
	createExternPartner: `${accountService}/agentexternpartners`,
	getExternPartnerDetails: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,
	updateExternPartner: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,
	removeExternPartner: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,

	// Event

	/** Agent events */
	createEvent: `${eventService}/events`,
	getEvents: `${eventService}/events`,
	// getPublicEvents: `${accountService}/events/agent-events-preview`,
	getEvent: (eventId) => `${eventService}/events/${eventId}`,
	updateEvent: (eventId) => `${eventService}/events/${eventId}`,
	publishEvent: (eventId) => `${eventService}/events/${eventId}/publish`,
	unPublishEvent: (eventId) => `${eventService}/events/${eventId}/unpublish`,
	cancelEvent: (eventId) => `${eventService}/events/${eventId}/cancel`,
	// cloneEvent: (eventId) => `${accountService}/events/${eventId}/clone`,
	inviteCompany: (eventId) =>
		`${eventService}/eventinvitations/${eventId}/invite-businesspartner`,
	inviteGuest: (eventId) =>
		`${eventService}/eventinvitations/${eventId}/invite-guest`,
	inviteGuestByContactGroups: (eventId) =>
		`${eventService}/eventinvitations/${eventId}/invite-guest-by-contact-groups`,
	getCompaniesToInvite: `${subscriptionservice}/businesspartners/businesspartners-to-invite`,
	// getEventInvitations: (eventId) =>
	// 	`${accountService}/events/${eventId}/invitations`,
	getEventAllInvitations: (eventId, isPartner, status, keyword) =>
		`${eventService}/eventinvitations/${eventId}?isPartner=${isPartner}&status=${status}&keyword=${keyword}`,
	cancelInvitation: (eventId, participantId) =>
		`${eventService}/events/${eventId}/participants/${participantId}/cancel`,
	getParticipants: (eventId) =>
		`${eventService}/events/${eventId}/participants`,
	getEventQrCode: (eventId) => `${accountService}/events/${eventId}/qrcode`,
	downloadEventQrCode: (eventId) =>
		`${accountService}/events/${eventId}/qrcode-download`,
	exportParticipants: (eventId) =>
		`${accountService}/events/${eventId}/participants/export-participants-to-csv`,
	checkInParticipants: `${eventService}/events/participants/checkin-participant`,

	/** Company events */
	getCompanyEventInvitations: `${eventService}/businesspartnerevents/invitations`,
	getCompanyParticipatedEvent: `${eventService}/businesspartnerevents/participated-events`,
	searchEvent: `${accountService}/companyevents/events/search`,
	getEventDetails: (eventId) =>
		`${eventService}/businesspartnerevents/events/${eventId}`,
	getEventLimit: (eventId) =>
		`${eventService}/businesspartnerevents/events/${eventId}/check-limit`,
	participateEvent: (eventId) =>
		`${eventService}/businesspartnerevents/events/${eventId}/participate`,
	cancelCompanyParticipation: (eventId) =>
		`${eventService}/businesspartnerevents/events/${eventId}/participate/cancel`,

	// Marketing Asset
	getMarketingAssets: `${accountService}/marketingassets`,
	getMarketingTemplates: `${accountService}/marketingassets/get-all-by-tooltype`,
	getMarketingAssetConfig: (assetId) =>
		`${accountService}/marketingassets/${assetId}`,
	exportMarketingAssetAsPdf: `${accountService}/exportmarketingassets/export-poster-as-pdf`,
	exportMarketingAssetAsJpg: `${accountService}/exportmarketingassets/export-poster-as-jpg`,

	// Job Statistics
	getJobStatistic: `${masterdataService}/jobstatistics`,
	getTotalJobStatistic: `${masterdataService}/jobstatistics/total`,

	// Contact Groups
	getContactGroups: `${eventService}/contactgroups`,
	updateContactGroupDetails: (contactGroupId) =>
		`${eventService}/contactgroups/${contactGroupId}`,
	removeContactGroup: (contactGroupId) =>
		`${eventService}/contactgroups/${contactGroupId}`,
	createContactGroup: `${eventService}/contactgroups`,

	//Contact People
	getContactPeople: `${eventService}/contactpersons`,
	removeContactPeople: (contactPeopleId) =>
		`${eventService}/contactpersons/${contactPeopleId}`,
	createContactPeople: `${eventService}/contactpersons`,
	getContactPeopleDetails: (contactPeopleId) =>
		`${eventService}/contactpersons/${contactPeopleId}`,
	exportContactPeople: (contactGroupId) =>
		`${eventService}/contactpersons/export-contact-people-to-csv?contactGroupId=${contactGroupId}`,
	importContactPeople: (contactGroupId, deleteAllExistingItems) =>
		`${eventService}/contactpersons/import-contact-people-from-csv?contactGroupId=${contactGroupId}&deleteAllExistingItems=${deleteAllExistingItems}`,
	getContactGroupDetails: (contactGroupId) =>
		`${eventService}/contactgroups/${contactGroupId}`,

	// Agent Plan
	// createAgentPlan: `${accountService}/communitypartnerplans`,
	getAgentPlans: `${accountService}/communitypartnerplans`,
	getAgentProfilePlan: `${accountService}/communitypartnerplans`,
	getAgentPlanDetails: (planId) =>
		`${accountService}/communitypartnerplans/${planId}`,
	updateAgentProfilePlan: `${accountService}/communitypartnerplans`
};
