
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDemand } from 'Hooks';
import { ApplicantFile } from 'Types';
import { RequestDecoration, RequestFiles, RequestInformation } from 'Elements';

export const ViewRequest = () => {
	const { request_id, applicant_id } = useParams();
	const { data: request, isLoading } = useDemand(
		request_id as string,
		applicant_id as string
	);

	return (
		<Row>
			<Col xs={12} className="mb-4">
				<RequestDecoration request={request} isLoading={isLoading} />
			</Col>
			<Col sm={6} className="mb-4 mb-sm-0">
				<RequestInformation request={request} isLoading={isLoading} />
			</Col>
			<Col sm={6}>
				<RequestFiles
					files={
						(request?.documents as ApplicantFile[] | undefined) ??
						[]
					}
					isLoading={isLoading}
				/>
			</Col>
		</Row>
	);
};
