import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useVoucher } from 'Hooks';
import { Colors, MyVoucher } from 'Types';
import {
	Card,
	LoadingContent,
	VoucherDetails,
	VoucherCompanies,
	Button
} from 'Elements';
import { useTranslation } from 'react-i18next';

const VoucherCard = styled(Card)`
	height: 100%;
	min-height: 250px;
`;

export const ViewVoucher = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { voucher_id } = useParams();
	const { data: voucher, isLoading } = useVoucher(voucher_id as string);

	return (
		<div>
			<Row>
				<Col md={6}>
					<VoucherCard>
						{!isLoading && (
							<VoucherDetails voucher={voucher as MyVoucher} />
						)}
						{isLoading && <LoadingContent />}
					</VoucherCard>
				</Col>
				<Col md={6} className="mt-4 mt-md-0">
					<VoucherCard>
						{!isLoading && (
							<VoucherCompanies
								companies={voucher?.voucherUsageInfos || null}
							/>
						)}
						{isLoading && <LoadingContent />}
					</VoucherCard>
				</Col>
			</Row>
			<div className="text-end mt-3">
				<Button
					type="button"
					color={Colors['white-primary']}
					className="me-auto"
					onClick={() => navigate('/vouchers')}>
					{t('button.back')}
				</Button>
			</div>
		</div>
	);
};
