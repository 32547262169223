import {
	Button,
	CheckboxController,
	FontAwesome,
	Badge,
	NoData
} from 'Elements';
import { FC, useEffect, useState } from 'react';

import { Colors } from 'src/types/Color';
import styles from './subscription-plan-features.module.scss';
import { useTranslation } from 'react-i18next';

import Drawer from 'react-modern-drawer';

import 'react-modern-drawer/dist/index.css';
import { clx, currencyFormatter, formatLocalAddress } from 'src/helpers/Utils';
import { useAxios } from 'src/hooks/useAxios';
import { api } from 'src/helpers/API';

interface Props {
	isOpen?: boolean;
	isLoading?: boolean;
	list: any;
	availableUpgradePlanIds: any;
	onAdd?: (arr: any) => void;
	toggleDrawer?: () => void;
}

export const PlanListDrawer: FC<Props> = ({
	isOpen,
	list,
	isLoading,
	toggleDrawer,
	availableUpgradePlanIds,
	onAdd
}) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState<string>('');
	const [plans, setPlans] = useState<any>([]);
	const [newPlans, setNewPlans] = useState<any>([]);

	const onSearch = (q: string) => {
		setKeyword(q);
		const res = findByNamePlan(q);
		setPlans(res);
	};

	function findByNamePlan(q) {
		return list.filter((plan) => plan?.name.includes(q));
	}

	const onSelectedPlan = (item) => {
		if (checkedPlan(item)) {
			const newList: any = [];
			newPlans?.forEach((element) => {
				if (element !== item.id) {
					newList.push(element);
				}
			});
			setNewPlans(newList);
		} else {
			setNewPlans((prev: any) => [...prev, item.id]);
		}
	};

	const checkedPlan = (item) => {
		let check = false;
		newPlans?.forEach((element) => {
			if (element === item.id) {
				check = true;
			}
		});
		return check;
	};

	useEffect(() => {
		setNewPlans(availableUpgradePlanIds);
		setPlans(list);
	}, [isOpen]);

	return (
		<Drawer
			open={isOpen as never}
			onClose={() => {
				toggleDrawer && toggleDrawer();
			}}
			lockBackgroundScroll
			zIndex={100}
			direction="right"
			className="d-flex flex-column gap-3"
			size="34rem">
			<div className="d-flex justify-content-between align-items-center px-4 py-4 border-bottom border-gray-1">
				<h2 className="fs-5 fw-600 ">{t('text.add-subscriptions')}</h2>
				<Button
					size="sm"
					color={Colors['white-gray']}
					className="gap-2"
					onClick={() => {
						toggleDrawer && toggleDrawer();
					}}>
					<FontAwesome icon="close" />
					{t('button.close')}
				</Button>
			</div>
			<div className="px-4">
				<p className="fs-sm fw-400">
					{t('text.description-add-subscriptions')}
				</p>
				<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-1">
					<div className="p-0 inputbox">
						<input
							type="search"
							value={keyword}
							className={clx(styles.inputSearch, 'w-100')}
							placeholder={t('menu.search')}
							onChange={(e) => {
								onSearch(e.target.value);
							}}
						/>
						<Button
							type="button"
							color={Colors.empty}
							onClick={() => onSearch('')}
							className={clx('p-0', styles.submitSearch)}>
							<FontAwesome
								size="sm"
								icon={
									keyword === ''
										? 'magnifying-glass'
										: 'close'
								}
							/>
						</Button>
					</div>
				</div>
			</div>
			<div
				className="d-flex flex-column border-top border-gray-1 gap-3 px-4 py-3"
				style={{ overflowY: 'auto', height: '700px' }}>
				{!isLoading &&
					plans?.map((item) => {
						return (
							<div
								key={item.id}
								className={`${styles.CardPlan} ${
									checkedPlan(item) && styles.CardPlanSelected
								}`}>
								<div className="d-flex align-items-start gap-1">
									{item.active && (
										<div
											onClick={() => {
												onSelectedPlan(item);
											}}>
											<CheckboxController
												isChecked={checkedPlan(item)}
												name="showDetails"
												id="showDetails"
												label=""
											/>
										</div>
									)}
									<div className="d-flex flex-column gap-3">
										<span className="fs-base fw-500">
											{item.name}
										</span>
										<div>
											{currencyFormatter(item.price ?? 0)}
										</div>
										<div
											className={clx(
												'text-gray-3 fs-sm fw-400 mt-1',
												styles.description
											)}
											style={{
												whiteSpace: 'pre-wrap'
											}}
											dangerouslySetInnerHTML={{
												__html:
													item.shortDescription ?? ''
											}}
										/>
									</div>
								</div>
							</div>
						);
					})}
				{isLoading &&
					[1, 2, 3].map((item) => {
						return (
							<div
								key={item}
								className={styles.cardPlanSkeleton}></div>
						);
					})}

				{!isLoading && plans?.length === 0 && (
					<NoData message="title.no-plan" />
				)}
			</div>
			<div className="d-flex justify-content-between align-items-center px-4 py-4 border-bottom border-gray-1">
				<Button
					size="sm"
					color={Colors['white-red']}
					className="gap-2"
					onClick={() => {
						toggleDrawer && toggleDrawer();
					}}>
					{t('button.close')}
				</Button>
				<Button
					size="sm"
					color={Colors.primary}
					className="gap-2"
					onClick={() => {
						onAdd && onAdd(newPlans);
					}}>
					<FontAwesome icon="save" />
					{t('button.add')}
				</Button>
			</div>
		</Drawer>
	);
};
