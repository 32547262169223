import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios, useInvitations } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { api, formatLocaleDateTime, getDiscountFormat } from 'Utils';
import {
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	FontAwesome,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';
import { Colors } from 'src/types/Color';

export const ListInvitation = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { invitations } = names;
	const { sendRequest, isLoading } = useAxios<string>();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isFetching, isError, refetch } = useInvitations({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSelectRow = (row_id: string) => {
		return navigate(`${invitations.path}/${row_id}`, {
			state: { prevPath: invitations.path }
		});
	};

	const handleDeleteRow = async (row_id: string) => {
		await sendRequest(
			api.deleteInvitation(row_id),
			{
				method: 'delete'
			},
			() => {
				refetch();
			}
		);
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.business-partner"
							cellClassName="w-100 w-sm-35"
						/>
						<DataTableRowCell
							cellText="table.receiver"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.voucher"
							cellClassName="w-100 w-sm-20"
						/>
						<DataTableRowCell
							cellText="table.sent-date"
							cellClassName="w-100 w-sm-15"
						/>
						{/* <DataTableRowCell
							cellText="table.signup-date"
							cellClassName="w-100 w-sm-15"
						/> */}
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-15"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((invitation) => {
								return (
									<DataTableRow key={invitation.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-35"
											cellHead="table.business-partner">
											<div className="d-flex flex-column text-end text-sm-start">
												<h4
													className="fs-sm mb-1"
													title={
														invitation.businessPartnerName
													}>
													{invitation.businessPartnerName}
												</h4>
												<span className="text-gray-3 lh-base">
													{invitation.email}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.receiver"
											cellText={
												invitation.receiverName || '---'
											}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											cellHead="table.voucher">
											{invitation?.voucherInfo?.voucherCode && (
												<div className="d-flex flex-column text-end text-sm-start">
													<h4
														className="fs-sm mb-1"
														title={
															invitation.voucherInfo.voucherCode
														}>
														{invitation.voucherInfo.voucherCode}
													</h4>
													<span className="text-gray-3 lh-base">
														{getDiscountFormat(
															invitation.voucherInfo.voucherDiscount,
															invitation.voucherInfo.voucherDiscountType
														)}
													</span>
												</div>
											)}
											{!invitation?.voucherInfo?.voucherCode && '---'}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.sent-date">
											<time
												title={formatLocaleDateTime(
													invitation.sentDate
												)}
												dateTime={formatLocaleDateTime(
													invitation.sentDate
												)}>
												{formatLocaleDateTime(
													invitation.sentDate
												)}
											</time>
										</DataTableRowCell>
										{/* <DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.signup-date">
											{invitation.lastSignUpDate && (
												<time
													title={formatLocaleDateTime(
														invitation.lastSignUpDate
													)}
													dateTime={formatLocaleDateTime(
														invitation.lastSignUpDate
													)}>
													{formatLocaleDateTime(
														invitation.lastSignUpDate
													)}
												</time>
											)}
											{invitation.usageCount === 0 &&
												t('label.not-signup-yet')}
										</DataTableRowCell> */}
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.operation">
											<DataTableOperation
												onEditRow={
													invitation?.registrants
														?.length === 0
														? () =>
																handleSelectRow(
																	invitation.id
																)
														: false
												}
												onViewRow={
													invitation.registrants &&
													invitation?.registrants
														?.length > 0
														? () =>
																handleSelectRow(
																	invitation.id
																)
														: false
												}>
												<Button
													disabled={isLoading}
													size="sm"
													color={Colors['white-red']}
													onClick={() => {
														if (
															invitation
																?.registrants
																?.length === 0
														) {
															handleDeleteRow(
																invitation.id
															);
														}
													}}>
													<FontAwesome
														icon="trash-can"
														size="lg"
													/>
												</Button>
											</DataTableOperation>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[35, 15, 20, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-invitation-sent" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
};
