import { memo, FC, Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'Hooks';
import { Colors, EventForm } from 'Types';
import { Button, StepNavigation, FontAwesome } from 'Elements';
import { clx, formatLocalAddress, formatTimeToCET } from 'Utils';
import styles from 'partials/step/step-container.module.scss';
import coverStyles from 'partials/control/cover-controller.module.scss';

interface Props {
	fields: EventForm;
	onNext: () => void;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
	setEventId: Dispatch<SetStateAction<string>>;
}
export const CreateEventPreviewSubmit: FC<Props> = memo(
	({ fields, setEventId, onNext, onPrev, goToStep }) => {
		const { t } = useTranslation();
		const { submitEvent } = useEvent();

		const getAddressFormat = () => {
			if (fields.step1.address) {
				const {
					street,
					houseNumber,
					houseNo,
					postalCode,
					city,
					state
				} = fields.step1.address;
				return formatLocalAddress(
					street ?? '',
					(houseNumber ?? houseNo) || '',
					postalCode ?? '',
					city ?? '',
					state ?? ''
				);
			}
			return t('label.no-address-selected');
		};

		const {
			handleSubmit,
			formState: { isSubmitting }
		} = useForm();

		const formData = {
			...fields.step1,
			...fields.step2,
			startDate: new Date(fields.step1.startDate as string),
			endDate: new Date(fields.step1.endDate as string),
			registrationStartDate: new Date(
				fields.step2.registrationStartDate as string
			),
			registrationEndDate: new Date(
				fields.step2.registrationEndDate as string
			),
			partnerRegistrationStartDate: new Date(
				fields.step2.partnerRegistrationStartDate as string
			),
			partnerRegistrationEndDate: new Date(
				fields.step2.partnerRegistrationEndDate as string
			),
			seatPerPartnerLimit: fields.step2.hasPartnerLimit
				? fields.step2.seatPerPartnerLimit
				: '-1'
		};

		const onSubmit = async () => {
			const eventId = await submitEvent(formData, onNext);
			setEventId(eventId);
		};

		return (
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<Row>
					<Col sm={5} md={12} xl={5} xxl={4} className="mb-4">
						<img
							src={
								fields.step1.featuredPhoto ||
								'/assets/img/default-image.png'
							}
							alt={fields.step1.title}
							className={clx(coverStyles.cover, 'h-auto')}
						/>
					</Col>
					<Col sm={7} md={12} xl={7} xxl={8} className="mb-4">
						<h4 className="fw-500 fs-base mb-0 lh-base">
							{fields.step1.title}
						</h4>
						<p className="text-gray-3 mb-0 lh-base">
							{fields.step1.shortDescription || '---'}
						</p>
					</Col>
					<Col xs={12} className="mb-4">
						<h4 className="fs-sm">
							{t('forms.event.description')}
						</h4>
						<p
							className="text-gray-3 mb-0"
							dangerouslySetInnerHTML={{
								__html: fields.step1.description || '---'
							}}
						/>
					</Col>
					<Col sm={6} md={12} lg={6} className="mb-4">
						<h4 className="fs-sm fw-500">
							{t('forms.start-date')}
						</h4>
						<time className="text-gray-3">
							{formatTimeToCET(fields.step1.startDate)}
						</time>
					</Col>
					<Col sm={6} md={12} lg={6} className="mb-4">
						<h4 className="fs-sm fw-500">{t('forms.end-date')}</h4>
						<time className="text-gray-3">
							{formatTimeToCET(fields.step1.endDate)}
						</time>
					</Col>
					<Col sm={6} md={12} lg={6} className="mb-4">
						<h4 className="fs-sm fw-500">
							{t('forms.event.event-type')}
						</h4>
						<p className="text-gray-3 mb-0">
							{fields.step1.eventType === 0 &&
								t('forms.event.online')}
							{fields.step1.eventType === 1 &&
								t('forms.event.in-person')}
							{fields.step1.eventType === 2 &&
								t('forms.event.hybrid')}
						</p>
					</Col>
					{fields.step1.eventType !== 0 && (
						<Col sm={6} md={12} lg={6} className="mb-4">
							<h4 className="fs-sm fw-500">
								{t('forms.event.address')}
							</h4>
							<p className="text-gray-3 mb-0">
								{getAddressFormat()}
							</p>
						</Col>
					)}
				</Row>
				<div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step1')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						<FontAwesome icon="users" size="lg" className="me-2" />
						{t('title.event.visitors')}
					</h4>
					<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
						<Row>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.registration-type')}
								</h4>
								<p className="text-gray-3 mb-0">
									{fields.step2.participationType === 0 &&
										t('forms.event.participation-public')}
									{fields.step2.participationType === 1 &&
										t(
											'forms.event.participation-with-registration'
										)}
									{fields.step2.participationType === 2 &&
										t('forms.event.participation-vip')}
								</p>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.total-visitor')}
								</h4>
								<p className="text-gray-3 mb-0">
									{fields.step2.participantLimit.toString() ===
									'-1'
										? t('forms.event.unlimited')
										: fields.step2.participantLimit}
								</p>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.start-registration')}
								</h4>
								<time className="text-gray-3">
									{fields.step2.registrationStartDate
										? formatTimeToCET(
												fields.step2
													.registrationStartDate
										  )
										: '---'}
								</time>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.end-registration')}
								</h4>
								<time className="text-gray-3">
									{fields.step2.registrationEndDate
										? formatTimeToCET(
												fields.step2.registrationEndDate
										  )
										: '---'}
								</time>
							</Col>
						</Row>
					</div>
				</section>
				<section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						<FontAwesome
							icon="buildings"
							size="lg"
							className="me-2"
						/>
						{t('title.event.event-partners')}
					</h4>
					<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
						<Row>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t(
										'forms.event.has-event-partner-registration'
									)}
								</h4>
								<p className="text-gray-3 mb-0">
									{fields.step2.hasPartner
										? t('label.yes')
										: t('label.no')}
								</p>
							</Col>
							{fields.step2.hasPartner && (
								<Col sm={6} md={12} lg={6} className="mb-4">
									<h4 className="fs-sm fw-500">
										{t('forms.event.only-with-invitation')}
									</h4>
									<p className="text-gray-3 mb-0">
										{fields.step2.partnerNeedInvitation
											? t('label.yes')
											: t('label.no')}
									</p>
								</Col>
							)}
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.total-event-partners')}
								</h4>
								<p className="text-gray-3 mb-0">
									{fields.step2.partnerLimit.toString() ===
									'-1'
										? t('forms.event.unlimited')
										: fields.step2.partnerLimit}
								</p>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.participant-max-seat')}
								</h4>
								<p className="text-gray-3 mb-0">
									{fields.step2.hasPartnerLimit
										? fields.step2.seatPerPartnerLimit
										: t('forms.event.unlimited')}
								</p>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.start-registration')}
								</h4>
								<time className="text-gray-3">
									{fields.step2.partnerRegistrationStartDate
										? formatTimeToCET(
												fields.step2
													.partnerRegistrationStartDate
										  )
										: '---'}
								</time>
							</Col>
							<Col sm={6} md={12} lg={6} className="mb-4">
								<h4 className="fs-sm fw-500">
									{t('forms.event.end-registration')}
								</h4>
								<time className="text-gray-3">
									{fields.step2.partnerRegistrationEndDate
										? formatTimeToCET(
												fields.step2
													.partnerRegistrationEndDate
										  )
										: '---'}
								</time>
							</Col>
							{fields.step2.hasPartner && (
								<Col sm={6} md={12} lg={6} className="mb-4">
									<h4 className="fs-sm fw-500">
										{t(
											'forms.event.show-participant-details'
										)}
									</h4>
									<p className="text-gray-3 mb-0">
										{fields.step2.showPartnersDetail
											? t('label.yes')
											: t('label.no')}
									</p>
								</Col>
							)}
						</Row>
					</div>
				</section>
				<div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step2')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<StepNavigation
					type="submit"
					savingLabel=""
					label="button.event.create-event"
					isSubmitting={isSubmitting}
					onPrev={onPrev}
				/>
			</form>
		);
	}
);

CreateEventPreviewSubmit.displayName = 'CreateEventPreviewSubmit';
