import {
	Address,
	SocialNetwork,
	Status,
	Contact,
	MainRegion,
	CustomerDto,
	SelectOption,
	SelectOptionIcon,
	Logo
} from 'Types';

export const enum AgentApproveStatus {
	pending = 0,
	approved = 1,
	rejected = 2,
	canceled = 3,
	inactive = 4
}

export const enum AgentPartnerApprovalStatus {
	autoApproved = 1,
	approved = 2,
	pending = 3,
	rejected = 4,
	autoRejected = 5
}

export enum CommunityPartnerProfileStatus {
	Registration = 0,
	PendingApproval = 1,
	Active = 2,
	Rejected = 3
}

export interface AgentProfile {
	id: string;
	tenantId: string;
	businessName: string;
	agentName: string;
	webSiteAddress: string;
	taxNumber: string;
	description: string;
	logo: Logo;
	businessPartnerProfileId?: string;
	isActive: boolean;
	isGlobal: boolean;
	firstName: string;
	lastName: string;
	email: string;
	agentEmail: string;
	slugUrl: string;
	publishInfo: {
		lastUpdatedAt: string;
		status: number;
	};
	commissionGroupId: string;
	commissionGroup: AgentCommissionGroup;
	address: Address;
	status: Status;
	smallCompany: boolean;
	approveStatus: AgentApproveStatus;
	agentBankAccount: CommunityPartnerBankAccount;
	agentSetting: AgentSetting;
	contact: Contact;
	socialNetwork: SocialNetwork[];
	mainRegion: MainRegion;
	alternativeRegionList: MainRegion[];
	isApprove: boolean;
	agentCommissionLevel: AgentCommissionLevel;
	companyApprovalStatus: boolean;
	communityPartnerClass: number;
	partnerClass: number;
	companySubscription: {
		agentAdditionalCharge: number;
		id: string;
		subTotal: number;
		taxAmount: number;
		taxPercent: number;
		total: number;
	};
}

export interface AgentCommissionLevel {
	id: string;
	commissionPercent: number;
	maxActiveAbo: number;
	minActiveAbo: number;
	title: string;
}

export interface AgentCommissionGroup {
	id: string;
	title: string;
	isDefault: boolean;
	label: string;
	IsPremium: boolean;
	commissionLevels: AgentCommissionLevel[];
}

export interface AgentProfileData extends CommunityPartnerMe {
	agentTypeId?: SelectOption | undefined | null;
}

export interface CommunityPartnerBankAccount {
	payout: boolean;
	ownerName: string;
	iban: string;
	bic: string;
	climateChange: boolean;
}

export interface CommunityPartnerHeader {
	headerImage: Logo;
	logo: Logo;
	headerTitle: string;
	slogan: string;
	description: string;
	themeColor: number;
}

export interface CommunityPartnerProfile {
	id: string;
	communityPartnerId: string;
	header: CommunityPartnerHeader;
	version: number;
	benefitList?: string[];
	socialNetworks: SocialNetwork[];
	auditInfo?: {
		createdBy: string;
		createdAt: string;
		updatedBy: string;
		updatedAt: string;
	};
	contactPerson: {
		address: string;
		description: string;
		email: string;
		fullName: string;
		image: Logo;
		phoneNumber: string;
		position: string;
	};
	statistics: {
		memberCount: string;
		enabled: boolean;
		establishedDate: string;
		departments: string;
	};
	employee: {
		enabled: boolean;
		headline: string;
	};
	jobs: {
		enabled: boolean;
		headline: string;
	};
	jobAds: {
		enabled: boolean;
		headline: string;
		description: number;
	};
	internships: {
		enabled: boolean;
		headline: string;
	};
	benefits: {
		enabled: boolean;
		headline: string;
		description: string | number;
	};
	departments: {
		enabled: boolean;
		headline: string;
	};
	members: {
		enabled: boolean;
		headline: string;
	};
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
	events: {
		enabled: boolean;
		headline: string;
	};
	partners: {
		enabled: boolean;
		headline: string;
		description: string;
	};
	areas: {
		enabled: boolean;
		headline: string;
		description: string;
	};
	jobSearch: {
		enabled: boolean;
		headline: string;
		jobFilter: number;
	};
}

export interface CommunityPartnerCommissionLevelMe {
	ActiveSubscriptionCount: number;
	CommissionLevelInfo: {
		CommissionLevelId: {
			value: string;
		};
		Title: string;
		MinActiveAbo: number;
		MaxActiveAbo: number;
		CommissionPercent: number;
		CommissionCost: number;
	};
}

export interface CommunityPartnerMe {
	id: string;
	communityPartnerProfileId: string;
	associateCommunityPartnerId: any;
	commissionGroupInfo: {
		isPremium: boolean;
		premiumLabel: string;
	};
	smallBusiness: boolean;
	isAutoApprove: boolean;
	commissionGroupId: string;
	hrEmailAddress: string;
	tenantId: string;
	logo: Logo;
	businessName: string;
	firstName: string;
	lastName: string;
	mainEmailAddress: string;
	emailVerificationCode: string;
	emailIsVerified: boolean;
	emailVerifiedAt: string;
	regionCode: string;
	address: Address;
	partnerClass: number;
	parentCommunityPartnerId: string;
	status: number;
	agentSetting?: AgentSetting;
	communityPartnerTypeCode: any;
	taxNumber: string;
	webSite: string;
	additionalPlanPrice: number;
	description: string;
	slogan: string;
	slugUrl: string;
	publishInfo: {
		version: number;
		lastUpdatedAt: string;
		status: number;
	};
	bankAccount: {
		payout: true;
		ownerName: string;
		iban: string;
		bic: string;
		climateChange: boolean;
	};
}

export interface CommunityPartnerProfileInitialData extends CommunityPartnerMe {
	sectorCode: string;
}
export interface CommunityPartnerProfileData extends CommunityPartnerMe {
	sectorCode: SelectOption | undefined | null;
}

export interface AgentSetting {
	logo: string;
	thumbnailLogo: string;
	headerTitle: string;
	headlineTitle: string;
	slogan: string;
	description: string;
	jobHeadline: string;
	internshipHeadline: string;
	jobDescription: string;
	internshipDescription: string;
	jobHeadlineStatus: boolean;
	internshipHeadlineStatus: boolean;
	companyHeadline: string;
	companyOverviewDescription: string;
	companyOverviewSectionStatus: boolean;
	eventSectionTitle: string;
	eventSectionStatus: boolean;
	partnerHeadline: string;
	partnerHeadlineStatus: boolean;
	sloganBgColor: string;
	headerBgColor: string;
	headerImage: string;
	headerThumbnailImage: string;
	memberTitle: string;
	partnerDescription: string;
	companyOverviewImage: string;
	companyOverviewThumbnailImage: string;
	memberSectionStatus: boolean;
	departmentSectionStatus: boolean;
	departmentSectionTitle: string;
	departments: string;
	memberNumber: string;
	establishedDate: string;
	statisticsSectionStatus: boolean;
	jobListTitle: string;
	internshipListTitle: string;
	internshipOverviewHeadLine: string;
	jobListStatus: boolean;
	internshipListStatus: boolean;
	communityPartnerClass: number;
	jobFilterSetting: number;
	areaCommonHeadlineStatus: boolean;
	areaCommonHeadline: string;
	areaListStatus: boolean;
	areaOverviewHeadLine: string;
	areaText: string;
}

export interface CommunityPartnerType {
	id: string;
	title: string;
	associateCommunityPartnerId: string;
	description: string;
	code: string;
	translateTitles: [
		{
			language: string;
			value: string;
		}
	];
	active: boolean;
}

export interface CommunityPartnerAssociation {
	businessName: string;
	id: string;
}

export interface CommunityPartnerSettings {
	communityPartnerTypeCode: any;
	associateCommunityPartnerId: any;
}

export interface AgentParentAssociationList {
	id: string;
	agentName: string;
	slugUrl: string;
	webSiteAddress: string;
	taxId: string;
	description: string;
	firstName: string;
	lastName: string;
	email: string;
	agentEmail: string;
	isGlobal: boolean;
	isActive: boolean;
	address: Address;
	agentSetting: AgentSetting;
	contact: Contact;
	socialNetwork: SocialNetwork[];
	communityPartnerClass: number;
	mainRegion: MainRegion;
	companyApprovalStatus: boolean;
	agentPlanInfo: string;
	commissionGroup: string;
	alternativeRegionList: MainRegion[];
}

export interface CommunityPartnerArea {
	businessName: string;
	id: string;
	logo: Logo;
}

export interface AgentPartner {
	id: string;
	companyId: string;
	purchaseDate: string;
	agentApprovalStatus: AgentApprovalStatus;
	agentApprovedStatus: AgentPartnerApprovalStatus;
	company: CompanyDto;
	status: Status;
}

export interface CommunityPartnerPartner {
	id: string;
	thumbnailLogo: string;
	businessName: string;
	mainEmailAddress: string;
	status: number;
	slugUrl: string;
	partnershipRequestsInfo: any;
	registrationInfo: {
		startedAt: string;
		lastUpdatedAt: string;
		status: number;
		referralCommunityPartnerId: {
			value: string;
		};
	};
	publishInfo: {
		lastUpdatedAt: string;
		status: number;
		version: number;
	};
	logo: Logo;
	slogan: string;
	description: string;
}

export interface AgentApprovalStatus {
	agentApprovedStatus: string;
	updatedAt: string;
	description: string;
	createdBy: null;
	createdByIdentify: string;
}

export interface CompanyDto extends CustomerDto {
	branch: string;
	slugUrl: string;
	isActive: boolean;
}

export interface Member {
	id: string;
	phoneNumber: string;
	description: string;
	isActive: boolean;
	fullName: string;
	email: string;
	showInHomePage: boolean;
	image: { thumbnail: string; url: string } | null;
	jobPosition: string;
}

export interface Department {
	id: string;
	title: string;
	description: string;
	isActive: boolean;
	image: Logo;
	sortOrder: number;
}

export interface AgentPlan {
	coverPhotoUrl: string;
	videoUrl: string;
	createdAt: string;
	updatedAt: string;
	price: number;
	description: string;
	contactPerson: SubscriptionPlanContactPersonDetails;
	termsAndConditionLink: string;
	features: AgentPlanFeature[];
}

export interface AgentPlanForm extends Omit<AgentPlan, 'features'> {
	features: {
		title: string;
		description: string;
		icon: any;
		sortOrder: number;
	}[];
}

export interface SubscriptionPlanContactPersonDetails {
	fullName: string;
	description: string;
	avatarUrl: string;
	published: boolean;
}

export interface AgentPlanFeature {
	title: string;
	description: string;
	icon?: SelectOptionIcon;
	sortOrder: number;
}

export interface AgentPlanInfo {
	additionalCharge: number;
	description: string;
	updatedAt: string;
	updatedBy: string;
}
