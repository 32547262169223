import { memo, FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import {
	CropperRef,
	Cropper,
	CropperPreview,
	CropperState,
	CropperImage,
	CropperTransitions,
	ImageRestriction
} from 'react-advanced-cropper';
import { clx } from 'Utils';
import { AspectRatio, Colors, ModalProps } from 'Types';
import { Button, FontAwesome, ModalBox, SubmitButton } from 'Elements';
import 'react-advanced-cropper/dist/style.css';
import styles from 'partials/modal/image-cropper.module.scss';

interface Props extends ModalProps {
	image: string;
	aspectRatio?: AspectRatio;
	onCrop: (file: FormData) => void;
}

export const ModalBoxCropImage: FC<Props> = memo(
	({ isOpen, image, aspectRatio = '0/0', onCrop, onClose }) => {
		const { t } = useTranslation();
		const cropperRef = useRef<CropperRef | null>(null);
		const buttonColor = Colors['white-primary'];
		const [state, setState] = useState<{
			cropperState: CropperState | null;
			cropperImage: CropperImage | null;
			cropperTransition: CropperTransitions | null;
			saving: boolean;
			aspectRatio: number;
		}>({
			cropperState: null,
			cropperImage: null,
			cropperTransition: null,
			saving: false,
			aspectRatio: eval(aspectRatio)
		});

		const updateState = (name: string, value: unknown) => {
			setState((prev) => ({
				...prev,
				[name]: value
			}));
		};

		const flip = (horizontal: boolean, vertical: boolean) => {
			if (cropperRef.current) {
				cropperRef.current.flipImage(horizontal, vertical);
			}
		};

		const rotate = (angle: number) => {
			if (cropperRef.current) {
				cropperRef?.current.rotateImage(angle);
			}
		};

		const setAspectRatio = (ratio?: number) => {
			cropperRef?.current?.reset();
			if (!ratio) {
				updateState('aspectRatio', undefined);
			} else {
				updateState('aspectRatio', ratio);
			}
		};

		const onChange = (cropper: CropperRef) => {
			setState((prev) => ({
				...prev,
				cropperState: cropper.getState(),
				cropperImage: cropper.getImage()
			}));
		};

		const onTransitionsChange = (cropper: CropperRef) => {
			updateState('cropperTransition', cropper.getTransitions());
		};

		const handleCrop = () => {
			const canvas = cropperRef?.current?.getCanvas();
			if (canvas) {
				updateState('saving', true);
				const form = new FormData();
				canvas.toBlob((blob) => {
					if (blob) {
						form.append('formFile', blob, 'image.png');
						updateState('saving', false);
						onClose && onClose();
						onCrop?.(form);
					}
				}, 'image/png');
			}
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				title="title.edit-image">
				<Row>
					<Col lg={8}>
						<Cropper
							ref={cropperRef}
							src={image}
							stencilProps={{
								grid: true,
								aspectRatio: state.aspectRatio,
								overlayClassName: styles.cropperBack,
								handlerClassNames: {
									default: 'bg-secondary',
									hover: 'bg-secondary-dark'
								},
								lineClassNames: {
									default: styles.cropperLine,
									hover: styles.cropperLineHover
								}
							}}
							className={styles.cropper}
							imageRestriction={ImageRestriction.fitArea}
							onChange={onChange}
							onTransitionsStart={onTransitionsChange}
							onTransitionsEnd={onTransitionsChange}
						/>
					</Col>
					<Col lg={4} className="mt-3 mt-lg-0">
						<div className={styles.config}>
							<div
								className={clx(
									styles.configBox,
									'flex-grow-1'
								)}>
								<CropperPreview
									className={styles.preview}
									image={state.cropperImage}
									state={state.cropperState}
									transitions={state.cropperTransition}
								/>
							</div>
							<div className={styles.configBox}>
								<div className={styles.setting}>
									<FormGroup row>
										<Col sm={6}>
											<Label htmlFor="width">
												{t('forms.width')}
											</Label>
											<input
												readOnly
												type="text"
												className="inputbox w-100"
												value={`${state?.cropperState?.coordinates?.width?.toFixed(
													0
												)} px`}
												placeholder={t(
													'placeholder.width'
												)}
											/>
										</Col>
										<Col sm={6}>
											<Label htmlFor="width">
												{t('forms.height')}
											</Label>
											<input
												readOnly
												type="text"
												className="inputbox w-100"
												value={`${state.cropperState?.coordinates?.height?.toFixed(
													0
												)} px`}
												placeholder={t(
													'placeholder.height'
												)}
											/>
										</Col>
									</FormGroup>
									<div className="d-flex flex-column gap-2">
										<div className={styles.settingGrid}>
											<Button
												color={buttonColor}
												className={styles.settingItem}
												title={t('title.rotate-90')}
												onClick={() => rotate(90)}>
												<FontAwesome
													icon="rotate-right"
													size="lg"
												/>
											</Button>
											<Button
												color={buttonColor}
												className={styles.settingItem}
												title={t(
													'title.rotate-counter-clock-90'
												)}
												onClick={() => rotate(-90)}>
												<FontAwesome
													icon="rotate-left"
													size="lg"
												/>
											</Button>
											<Button
												color={buttonColor}
												className={styles.settingItem}
												title={t(
													'title.flip-horizontal'
												)}
												onClick={() =>
													flip(true, false)
												}>
												<FontAwesome
													icon="arrows-repeat"
													size="lg"
												/>
											</Button>
											<Button
												color={buttonColor}
												className={styles.settingItem}
												title={t('title.flip-vertical')}
												onClick={() =>
													flip(false, true)
												}>
												<FontAwesome
													icon="arrows-repeat"
													size="lg"
													rotation={90}
												/>
											</Button>
										</div>
										{aspectRatio === '0' && (
											<div className={styles.settingGrid}>
												<Button
													color={buttonColor}
													className={
														styles.settingItem
													}
													title={t(
														'title.aspect-ratio-16-9'
													)}
													onClick={() =>
														setAspectRatio(16 / 9)
													}>
													16:9
												</Button>
												<Button
													color={buttonColor}
													className={
														styles.settingItem
													}
													title={t(
														'title.aspect-ratio-3-4'
													)}
													onClick={() =>
														setAspectRatio(3 / 4)
													}>
													3:4
												</Button>
												<Button
													color={buttonColor}
													className={
														styles.settingItem
													}
													title={t(
														'title.aspect-ratio-1-1'
													)}
													onClick={() =>
														setAspectRatio(1)
													}>
													1:1
												</Button>
												<Button
													color={buttonColor}
													className={
														styles.settingItem
													}
													title={t(
														'title.aspect-ratio-free'
													)}
													onClick={() =>
														setAspectRatio()
													}>
													{t('title.free')}
												</Button>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between pt-3">
								<Button
									size="sm"
									color={buttonColor}
									onClick={onClose}>
									{t('button.cancel')}
								</Button>
								<SubmitButton
									size="sm"
									isSubmitting={state.saving}
									onClick={handleCrop}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</ModalBox>
		);
	}
);

ModalBoxCropImage.displayName = 'ModalBoxCropImage';
