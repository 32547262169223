import { FC } from 'react';
import { clx } from 'Utils';
import { SocialNetwork } from 'Types';
import { FontAwesome } from 'Elements';
import styles from 'partials/homepage/social-item.module.scss';

interface Props {
	social: SocialNetwork;
	className?: string;
	color?: string;
}

type SocialItems = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const SocialItem: FC<Props> = ({ social, className, color }) => {
	const getImageUrl = () => {
		const imageType: Record<SocialItems, any> = {
			1: 'facebook',
			2: 'twitter',
			3: 'instagram',
			4: 'youtube',
			5: 'linkedin',
			6: 'xing',
			7: 'tiktok',
			8: 'whatsapp'
		};
		return imageType[social?.socialNetwork];
	};

	return (
		<div className={clx(styles.item, className)}>
			{/* {onRemove && (
					<Button
						color={Colors.empty}
						className={styles.remove}
						onClick={onRemove}>
						<FontAwesome icon="trash-xmark" size="lg" />
					</Button>
				)} */}
			<FontAwesome
				set="fab"
				icon={getImageUrl()}
				size="lg"
				color={color}
			/>
		</div>
	);
};

SocialItem.displayName = 'SocialItem';
