import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAxios } from 'Hooks';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	AgentSetting,
	DataList,
	DataQueryParams,
	CommunityPartnerType,
	AgentPlan,
	AgentPlanForm,
	AgentParentAssociationList,
	CommunityPartnerProfile,
	CommunityPartnerPartner,
	CommunityPartnerCommissionLevelMe,
	CommunityPartnerAssociation,
	CommunityPartnerSettings,
	CommunityPartnerArea,
	CommunityPartnerProfileInitialData
} from 'Types';
import axios from 'Adapter';

const config = (options = {}) => {
	return {
		...options,
		staleTime: Infinity
	};
};

export const useCommunityPartnerMe = <T = CommunityPartnerProfileInitialData>(
	options?: UseQueryOptions<T, any, CommunityPartnerProfileInitialData>
): UseQueryResult<CommunityPartnerProfileInitialData, unknown> => {
	return useQuery({
		queryKey: [queryKey.communityPartnerMe],
		queryFn: () => fetch.communityPartnerMe(),
		...config(options)
	});
};

export const useCommunityPartnerCommissionLevelMe = <
	T = CommunityPartnerCommissionLevelMe
>(
	options?: UseQueryOptions<T, any, CommunityPartnerCommissionLevelMe>
): UseQueryResult<CommunityPartnerCommissionLevelMe, unknown> => {
	return useQuery({
		queryKey: [queryKey.commissionLevelMe],
		queryFn: () => fetch.communityPartnerCommissionLevelMe(),
		...config(options)
	});
};

export const useCommunityPartnerProfile = <T = CommunityPartnerProfile>(
	communityPartnerProfileId: string,
	options?: UseQueryOptions<T, any, CommunityPartnerProfile>
): UseQueryResult<CommunityPartnerProfile, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.communityPartnerProfile,
			{ id: communityPartnerProfileId }
		],
		queryFn: () => fetch.communityPartnerProfile(communityPartnerProfileId),
		...config(options)
	});
};

export const updateCommunityPartnerEditProfile: (
	communityPartnerProfileId: string,
	fields: CommunityPartnerProfile,
	onFinal?: () => void,
	onCatch?: () => void
) => Promise<void> = async (
	communityPartnerProfileId,
	fields,
	onFinal,
	onCatch
) => {
	const data = fields;
	await axios
		.put(
			api.updateCommunityPartnerEditProfile(communityPartnerProfileId),
			data
		)
		.then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.community-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		})
		.catch(() => onCatch?.());
};

export const useCommunityPartnerArea = <T = DataList<CommunityPartnerArea>>(
	{ pageSize = 500, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CommunityPartnerArea>>
): UseQueryResult<DataList<CommunityPartnerArea>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.agentPartnersArea,
			{
				pageSize,
				pageNumber,
				keyword
			}
		],
		queryFn: () =>
			fetch.communityPartnerAreaList(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useCommunityPartnerPartners = <
	T = DataList<CommunityPartnerPartner>
>(
	{
		pageSize = 12,
		pageNumber = 1,
		keyword = '',
		status = '',
		registrationStatus = '',
		publishStatus = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CommunityPartnerPartner>>
): UseQueryResult<DataList<CommunityPartnerPartner>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.communityPartnerPartners,
			{
				pageSize,
				pageNumber,
				keyword,
				status,
				registrationStatus,
				publishStatus
			}
		],
		queryFn: () =>
			fetch.communityPartnerPartners(
				pageSize,
				pageNumber,
				keyword,
				status,
				registrationStatus,
				publishStatus
			),
		...config(options)
	});
};

export const useUpdateCommunityPartner = (
	options?: UseQueryOptions<CommunityPartnerProfileInitialData>
) => {
	const query = useCommunityPartnerMe(options);
	const queryProfile = useCommunityPartnerProfile(
		query?.data?.communityPartnerProfileId as string,
		{
			enabled: !!query?.data?.communityPartnerProfileId
		}
	);
	const agent = query.data;
	const address = agent?.address;
	const { sendRequest, error } = useAxios();

	const updateCommunityPartner: (
		fields: Partial<CommunityPartnerProfileInitialData>,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		const data = {
			...agent,
			...fields
		};
		await sendRequest(
			api.updateCommunityPartnersMe,
			{ data, method: 'PUT' },
			() => {
				query.refetch();
				queryProfile.refetch();
				startTransition(() => {
					toastify('toastify.community-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
		if (error) onCatch?.();
	};

	const updateCommunityPartnerSettings: (
		fields: Partial<CommunityPartnerSettings>,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		const data = {
			...fields
		};
		await sendRequest(
			api.updateCommunityPartnerSettings,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.community-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
		if (error) onCatch?.();
	};

	const updateAgentSetting: (
		field: Partial<AgentSetting>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = {
			agentSetting: {
				...field
			}
		};
		await sendRequest(
			api.updateAgentSetting,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.community-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const deleteAgentRequest: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await sendRequest(
			api.deleteAgentAccountRequest,
			{ method: 'DELETE' },
			onFinal
		);
	};

	const deleteAgentAccount: (
		fields: string,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = { confirmationCode: fields };
		await sendRequest(
			api.deleteAgentAccount,
			{ data, method: 'DELETE' },
			onFinal
		);
	};

	return {
		...query,
		agent,
		address,
		updateCommunityPartner,
		updateCommunityPartnerSettings,
		updateAgentSetting,
		deleteAgentRequest,
		deleteAgentAccount
	};
};

export const useCommunityPartnerTypes = <T = DataList<CommunityPartnerType>>(
	{ pageSize, pageNumber, keyword, active }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CommunityPartnerType>>
): UseQueryResult<DataList<CommunityPartnerType>> => {
	return useQuery({
		queryKey: [
			queryKey.communityPartnerTypes,
			{ pageSize, pageNumber, keyword, active }
		],
		queryFn: () =>
			fetch.communityPartnerTypes(pageSize, pageNumber, keyword, active),
		...config(options)
	});
};

export const useCommunityPartnerAssociation = <
	T = DataList<CommunityPartnerAssociation>
>(
	{ pageSize, pageNumber, keyword, active }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CommunityPartnerAssociation>>
): UseQueryResult<DataList<CommunityPartnerAssociation>> => {
	return useQuery({
		queryKey: [
			queryKey.communityPartnerAssociation,
			{ pageSize, pageNumber, keyword, active }
		],
		queryFn: () =>
			fetch.communityPartnerAssociation(
				pageSize,
				pageNumber,
				keyword,
				active
			),
		...config(options)
	});
};

export const useAgentParentAssociation = <T = AgentParentAssociationList[]>(
	partnerClass: number,
	options?: UseQueryOptions<T, any, AgentParentAssociationList[]>
): UseQueryResult<AgentParentAssociationList[]> => {
	return useQuery({
		queryKey: [queryKey.communityPartnerMe, { partnerClass }],
		queryFn: () => fetch.agentParentAssociationList(partnerClass),
		...config(options)
	});
};

export const useAgentPlans = <T = DataList<AgentPlan>>(
	{ pageSize, pageNumber, keyword }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentPlan>>
): UseQueryResult<DataList<AgentPlan>> => {
	return useQuery({
		queryKey: [queryKey.agentPlans, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.agentPlanList(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useAgentPlanDetails = <T = AgentPlan>(
	agentPlanId: string,
	options?: UseQueryOptions<T, any, AgentPlan>
): UseQueryResult<AgentPlan> => {
	return useQuery({
		queryKey: [queryKey.agentPlan, { agentPlanId }],
		queryFn: () => fetch.agentPlanDetails(agentPlanId),
		...config(options)
	});
};

export const useAgentProfilePlan = <T = AgentPlanForm>(
	options?: UseQueryOptions<T, any, AgentPlanForm>
): UseQueryResult<AgentPlanForm> => {
	return useQuery({
		queryKey: [queryKey.agentProfilePlan],
		queryFn: () => fetch.agentProfilePlan(),
		...config(options)
	});
};

export const useCRUDAgentPlan = (options?: UseQueryOptions<AgentPlanForm>) => {
	const { sendRequest } = useAxios();
	const query = useAgentProfilePlan(options);

	const updateAgentPlan: (
		fields: AgentPlanForm,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await sendRequest(
			api.updateAgentProfilePlan,
			{ data: fields, method: 'PATCH' },
			() => {
				void query.refetch();
				startTransition(() => {
					toastify('toastify.plan-price.updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	return { ...query, updateAgentPlan };
};
