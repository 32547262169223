import { memo, startTransition, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import { names } from 'Constants';
import { format } from 'date-fns';
import { ExternPartnerFormData, Media, ColorValues } from 'Types';
import { useExternPartner, usePrompt } from 'Hooks';
import {
	Card,
	FormSuccess,
	SubmitButtonGroup,
	CoverController,
	SwitchController,
	FormInlineTip,
	DatePickerController,
	FontAwesome
} from 'Elements';

const CardBox = styled(Card)`
        border: 1px dashed #D5D5DB;
        padding: 26px;
        background: transparent;
        box-shadow: unset;
`;

export const CreateExternPartner = memo(() => {
	const { t } = useTranslation();
	const { externPartners } = names;
	const { createExternPartner } = useExternPartner();
	const descriptionLimit = 450;
	const [hasExpireDate, setExpireDate] = useState(false);
	const [formSuccess, setFormSuccess] = useState(false);

	const schema = yup.object().shape({
		name: yup.string().required(t('validation.name.required')),
		logoUrl: yup.string().required(t('validation.logo.required')),
		expireDateTime: yup.string().when(['hasExpireDate'], {
			is: true,
			then: yup
				.string()
				.nullable()
				.required(t('validation.expire-date.required'))
		})
	});

	const defaultValues: Partial<ExternPartnerFormData> = {
		name: '',
		logoUrl: '',
		shortDescription: '',
		link: ''
	};

	const {
		reset,
		control,
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<ExternPartnerFormData>({
		resolver: yupResolver(schema)
	});

	const watchLogo = { url: watch('logoUrl') ?? '' };
	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
	};

	const handleImageChange = (image_data: Partial<Media>) => {
		setValue('logoUrl', image_data.absoluteUri ?? '', { shouldDirty: true });
	};

	const onSwitchExpireDate = (val: boolean) => {
		setExpireDate(val);
		if (!val) {
			setValue('expireDateTime', '');
		}
	};

	const onSubmit: SubmitHandler<ExternPartnerFormData> = async (data) => {
		const api_data = {
			...data,
			logoUrl: watchLogo.url ?? '',
			expireDateTime: data.expireDateTime
				? format(new Date(data.expireDateTime as string), 'yyyy-MM-dd')
				: ''
		};
		await createExternPartner(api_data, () => {
			startTransition(() => {
				setFormSuccess(true);
				resetForm();
			});
		});
	};

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={7}>
							<FormGroup className="w-100 w-lg-70">
								<Label htmlFor="logo">
									{t('forms.logo')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<CoverController
									className="w-50"
									name="logoUrl"
									image={watchLogo}
									onRemove={handleImageChange}
									onUpload={handleImageChange}
								/>
								{errors.logoUrl && (
									<div className="invalid-feedback d-block">
										{errors.logoUrl.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="name">
									{t('forms.name')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('name')}
									type="text"
									id="name"
									className="inputbox w-100"
								/>
								{errors.name && (
									<div className="invalid-feedback d-block">
										{errors.name.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="link">{t('forms.link')}</Label>
								<input
									{...register('link')}
									type="text"
									id="link"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="shortDescription">
									{t('forms.short-desc')}
								</Label>
								<textarea
									{...register('shortDescription', {
										maxLength: descriptionLimit
									})}
									maxLength={descriptionLimit}
									id="shortDescription"
									className="inputbox w-100"
									cols={10}
									rows={5}
									aria-invalid={!!errors.shortDescription}
								/>
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="hasExpireDate"
									control={control}
									label="forms.has-expire-date"
									boxClassName="py-2"
									onSwitchChange={onSwitchExpireDate}
								/>
								<FormInlineTip tip="forms.expire-date-extern-partner" />
							</FormGroup>
							{hasExpireDate && (
								<FormGroup>
									<Label htmlFor="expireDateTime">
										{t('forms.expire-date')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<DatePickerController
										name="expireDateTime"
										minDate={new Date()}
										control={control}
										error={errors.expireDateTime}
										placeholder={t('forms.select-date')}
									/>
								</FormGroup>
							)}
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Col>
						<Col md={4} xxl={5}>
							<CardBox className='d-flex flex-grow-1 gap-2 '>
								<h6
									className='fs-5 fw-400'>
									<FontAwesome icon="info-circle" size="lg" color={ColorValues.green} />
								</h6>
								<div>
									<h6
										className='fs-5 fw-400'>
										{t('forms.title-helper-extern-partner')}
									</h6>
									<p className='text-gray-3 fw-400'>{t("forms.description-helper-extern-partner")}</p>
								</div>
							</CardBox>
						</Col>
					</Row>
				</form>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.extern-partner.create-new"
					listTitle="button.extern-partner.list"
					successTitle="title.extern-partner-create"
					className="flex-grow-1 align-self-center"
					listUrl={externPartners.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
});

CreateExternPartner.displayName = 'CreateExternPartner';
