import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	Button,
	CommunityPartnerAreaCard,
	LoadingSpinner,
	NoData
} from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';
import { Text, Title } from 'Styles';
import { clx } from 'src/helpers/Utils';
import { useCommunityPartnerArea } from 'src/hooks/useCommunityPartner';
import { Colors } from 'src/types/Color';
import { useState } from 'react';

type Props = {
	isLoadingProfile: boolean;
	areas: {
		enabled: boolean;
		headline: string;
		description: string;
	};
};

export const CommunityPartnerPreviewPartnerAreasSection = ({
	isLoadingProfile,
	areas
}: Props) => {
	const { t } = useTranslation();
	const perRow = 8;
	const [next, setNext] = useState(perRow);

	const { data } = useCommunityPartnerArea({
		pageSize: 500,
		pageNumber: 1,
		keyword: ''
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	return data?.items?.length !== 0 ? (
		<section
			className="position-relative mb-5 pt-4"
			style={{ background: '#fffcf4' }}>
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<div
						className={clx(
							styles.title,
							'd-flex flex-column justify-content-center mx-auto mb-3'
						)}>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{areas?.headline}
						</Title>
						{areas?.description && (
							<Text
								as="div"
								textColor="text-gray-5"
								className="mt-3 lh-md text-center">
								{areas?.description}
							</Text>
						)}
					</div>
				)}
				{data && data?.items?.length > 0 && (
					<Row className="justify-content-center">
						{data?.items?.slice(0, next)?.map((partner) => {
							return (
								<Col
									md={6}
									lg={3}
									key={partner.id}
									className="mb-4">
									<CommunityPartnerAreaCard
										key={partner.id}
										partner={partner}
									/>
								</Col>
							);
						})}
					</Row>
				)}
				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{data?.items && data?.items?.length > next && (
						<Button
							size="lg"
							color={Colors['border-gray-4']}
							label={t('button.more-partners') as string}
							className="rounded-pill"
							onClick={handleMore}
						/>
					)}
				</div>
				{data && data?.items?.length === 0 && (
					<NoData message={t('title.no-partner-yet')} />
				)}
			</Container>
		</section>
	) : (
		<></>
	);
};
