import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { findDuplicates, toastify } from 'Utils';
import { Colors, ModalProps, SocialFields, SocialNetwork } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	SocialController
} from 'Elements';
import { array, object, string } from 'yup';

interface Props extends ModalProps {
	onSubmit: (data: SocialFields[]) => void;
	list: SocialNetwork[];
	onRemoveSocial: (social: SocialNetwork) => void;
}

export const ModalBoxAddSocial: FC<Props> = memo(
	({ isOpen, onClose, onSubmit, list, onRemoveSocial }) => {
		const { t } = useTranslation();
		const schema = object().shape({
			email: string().email(t('validation.email.type')),
			socialNetwork: array().of(
				object().shape({
					name: object({
						label: string(),
						value: string()
					})
						.nullable()
						.required(t('validation.select-social.required')),
					url: string()
						.matches(
							/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
							t('validation.url.type')
						)
						.required(t('validation.url.required'))
				})
			)
		});

		const checkSelectedSocial = (socialFields: SocialFields[]) => {
			const duplicates = findDuplicates(
				socialFields,
				(social) => social.name.value
			);
			return Object.keys(duplicates).length;
		};

		const methods = useForm<{ socialNetwork: SocialFields[] }>({
			resolver: yupResolver(schema)
		});

		const submitSocial: SubmitHandler<{
			socialNetwork: SocialFields[];
		}> = async (data) => {
			const social_data = data?.socialNetwork;
			if (checkSelectedSocial(social_data) === 0) {
				onSubmit(social_data);
			} else {
				toastify(t('toastify.some-social-duplicate'));
			}
		};
		const isValid = methods?.watch('socialNetwork')?.every((item) => {
			return item.name.value && item.url;
		});

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.add-social">
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(submitSocial)}>
						<SocialController
							onRemoveSocial={onRemoveSocial}
							errors={methods.formState.errors}
							list={list}
						/>
						<ModalBoxFooter className="justify-content-end mt-5">
							<Button
								color={Colors['white-primary']}
								onClick={onClose}>
								{t('button.cancel')}
							</Button>
							<SubmitButton
								className="ms-4"
								isDisable={
									!methods.formState.isDirty || !isValid
								}
								isSubmitting={methods.formState.isSubmitting}
							/>
						</ModalBoxFooter>
					</form>
				</FormProvider>
			</ModalBox>
		);
	}
);

ModalBoxAddSocial.displayName = 'ModalBoxAddSocial';
