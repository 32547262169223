import { startTransition, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { names } from 'Constants';
import { SubscriptionPlansForm } from 'Types';
import { usePrompt, useSubscriptionPlan } from 'Hooks';
import {
	Card,
	FormSuccess,
	RichTextController,
	SubmitButtonGroup,
} from 'Elements';

export const CreateSubscriptionPlans = () => {
	const { t } = useTranslation();
	const descriptionLimit = 450;
	const { subscriptionPlans } = names;
	const { createSubscriptionPlan } = useSubscriptionPlan();
	const [formSuccess, setFormSuccess] = useState(false);

	const schema = yup.object().shape({
		name: yup.string().required(t('validation.title.required')),
		publishedJobs: yup
			.number()
			.required(t('validation.max-job-capacity.required')),
		publishedInternships: yup
			.number()
			.required(t('validation.max-internship-capacity.required'))
	});

	const defaultValues: Partial<SubscriptionPlansForm> = {
		name: '',
		shortDescription: '',
		description: '',
		publishedJobs: null,
		publishedInternships: null
	};

	const {
		reset,
		control,
		watch,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<SubscriptionPlansForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
	};

	const onSubmit: SubmitHandler<SubscriptionPlansForm> = async (data) => {
		const api_data = {
			...data,
			active: false
		};
		await createSubscriptionPlan(api_data, () => {
			startTransition(() => {
				setFormSuccess(true);
				resetForm();
			});
		});
	};

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="title">
									{t('forms.title')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('name')}
									type="text"
									id="title"
									aria-invalid={!!errors.name}
									className="inputbox w-100"
								/>
								{errors.name && (
									<div className="invalid-feedback d-block">
										{errors.name.message}
									</div>
								)}
							</FormGroup>
							<FormGroup className="position-relative">
								<Label htmlFor="shortDescription">
									{t('forms.event.short-desc')}
								</Label>
								<textarea
									{...register('shortDescription', {
										maxLength: descriptionLimit
									})}
									maxLength={descriptionLimit}
									id="shortDescription"
									className="inputbox w-100"
									cols={10}
									rows={5}
								/>
								<div className="position-absolute end-0">
									<small className="ms-auto mt-1">
										{watch('shortDescription')?.length || 0}
										/{descriptionLimit}
									</small>
								</div>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description">
									{t('forms.event.description')}
								</Label>
								<RichTextController
									control={control}
									name="description"
									hasImage={false}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="maxJobCapacity">
									{t('forms.max-job-capacity')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('publishedJobs')}
									type="text"
									id="max-job-capacity"
									aria-invalid={!!errors.publishedJobs}
									className="inputbox w-100"
								/>
								{errors.publishedJobs && (
									<div className="invalid-feedback d-block">
										{errors.publishedJobs.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="maxInternshipCapacity">
									{t('forms.max-internship-capacity')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('publishedInternships')}
									type="text"
									id="publishedInternships"
									aria-invalid={!!errors.publishedInternships}
									className="inputbox w-100"
								/>
								{errors.publishedInternships && (
									<div className="invalid-feedback d-block">
										{errors.publishedInternships.message}
									</div>
								)}
							</FormGroup>
							{/* <FormGroup>
								<SwitchController
									name="active"
									control={control}
									label="forms.is-active"
								/>
							</FormGroup> */}
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
								label={t('button.save')}
								icon="save"
								savingLabel={t('button.saving')}
							/>
						</Col>
					</Row>
				</form>
			)}

			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.send-new-subscription-plan"
					listTitle="button.subscription-plan-list"
					className="flex-grow-1 align-self-center"
					successTitle="title.subscription-plan-success"
					listUrl={subscriptionPlans.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
