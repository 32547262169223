import { MenuItem } from 'Types';
import { names } from 'Constants';

const {
	events,
	credits,
	payments,
	users,
	partners,
	departments,
	subscriptionPlans,
	contactGroups,
	jobs,
	invitations,
	externPartners,
	aboCalculator
} = names;

export const communityPartnerMenu: MenuItem[] = [
	// Profile menu
	{
		id: 'community-partner',
		icon: 'apartment',
		label: 'menu.my-community-partner',
		subs: [
			{
				label: 'menu.profile',
				to: `/my-community-partner`
			},
			{
				label: 'menu.members',
				to: `/members`
			},
			{
				label: 'menu.my-vouchers',
				to: `/vouchers`
			},
			{
				label: 'menu.departments',
				to: departments.path
			},
			{
				label: 'menu.subscription-plans',
				to: subscriptionPlans.path,
				isPrime: true
			}
		]
	},

	// Partners menu
	{
		id: 'partners',
		icon: 'building',
		label: 'menu.partners',
		subs: [
			{
				label: 'menu.list-partners',
				to: partners.path
			},
			// {
			// 	label: 'menu.extern-partners-list',
			// 	to: externPartners.path
			// },
			{
				label: 'menu.invitations',
				to: invitations.path,
				separator: true
			},
			// {
			// 	label: 'menu.abo-calculator',
			// 	to: aboCalculator.path,
			// 	isPrime: false
			// }
		]
	},

	// Jobs menu
	{
		id: 'jobs',
		icon: 'briefcase',
		label: 'menu.job',
		subs: [
			{
				label: 'menu.list-jobs',
				to: jobs.path
			},
			{
				label: 'menu.applicants-requests',
				to: jobs.requests.path
			},
			// {
			// 	label: 'menu.internships',
			// 	to: '/internships'
			// },
			// {
			// 	label: 'menu.internship-management',
			// 	to: '/internship-management'
			// },
			// {
			// 	label: 'menu.internship-participants',
			// 	to: '/internship-participants'
			// }
		]
	},

	// Events menu
	{
		id: 'community_partner_events',
		icon: 'calendar-day',
		label: 'menu.event.key',
		subs: [
			{
				label: 'menu.list-events',
				to: events.path
			},
			{
				label: 'menu.add-event',
				to: events?.add?.path ?? '',
				separator: true
			},
			{
				label: 'menu.contact-groups',
				to: contactGroups.path
			}
		]
	},

	// Marketing toolbox menu
	{
		id: 'marketing',
		label: 'menu.marketing',
		icon: 'megaphone',
		subs: [
			// {
			// 	label: 'menu.toolbox',
			// 	to: '/marketing/toolbox'
			// },
			{
				label: 'menu.integration',
				to: '/marketing/integration'
			}
		]
	},

	// Transaction menu
	{
		id: 'settlements',
		label: 'menu.settlements',
		icon: 'arrow-up-arrow-down',
		subs: [
			// {
			// 	label: 'menu.all-transactions',
			// 	to: transactions.path
			// },
			{
				label: 'menu.credits',
				to: credits.path
			},
			{
				label: 'menu.payments',
				to: payments.path
			}
		]
	},

	// User menu
	{
		id: 'user',
		icon: 'users-gear',
		label: 'menu.users',
		to: users.path
	}
];
