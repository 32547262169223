import { Col, Container, Row } from 'reactstrap';
import { Button, LoadingSpinner, NoData, ShimmerImage } from 'Elements';
import { Title } from 'Styles';
import { useProfileJobs } from 'src/hooks/useJob';
import { useState } from 'react';
import { truncateToWord } from 'src/helpers/Utils';
import { Colors } from 'src/types/Color';
import { useTranslation } from 'react-i18next';
import styles from 'partials/homepage/card/profile-job-card.module.scss';
import { Logo } from 'src/types/BusinessPartner';
import styled from 'styled-components';
import { rgba } from 'polished';

type Props = {
	isLoadingProfile: boolean;
	header: {
		description: string;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: string;
		themeColor: number;
	};
	jobs: {
		enabled: boolean;
		headline: string;
	};
};

const Card = styled.article<any>`
	${({ $bgColor }) =>
		$bgColor &&
		`
		&:hover {
			box-shadow: 0 5px 10px ${rgba($bgColor, 0.1)};
		}`}};
`;

export const CommunityPartnerPreviewJobSection = ({
	isLoadingProfile,
	header,
	jobs
}: Props) => {
	const { t } = useTranslation();
	const perRow = 3;
	const defaultImage = '/assets/img/default-image.png';
	const [next, setNext] = useState(perRow);
	const { data, isLoading } = useProfileJobs({
		status: 1,
		pageSize: 20,
		pageNumber: 1,
		keyword: '',
		kind: 0
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	return data?.items?.length !== 0 ? (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && jobs && (
					<div className="position-relative">
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{jobs?.headline}
						</Title>
					</div>
				)}
				{!isLoading && (
					<Row className="justify-content-center mt-3">
						{data &&
							data?.items?.slice(0, next)?.map((job) => {
								return (
									<Col
										md={6}
										lg={4}
										key={job.id}
										className="mb-4">
										<Card
											className={styles.card}
											$bgColor={header?.themeColor}>
											<div
												className={styles.imageWrapper}>
												<ShimmerImage
													src={
														job?.featuredPhoto
															?.url ||
														defaultImage
													}
													defaultImage={defaultImage}
													alt={job?.title}
													width={500}
													height={500}
													className={styles.image}
												/>
												{/* {video && (
													<IconPlayer
														className={
															styles.player
														}
													/>
												)}
												{!video && (
													<FontAwesome
														icon="link"
														size="2x"
														className={styles.link}
													/>
												)} */}
											</div>
											<div className="flex-grow-1 d-flex flex-column gap-2">
												{/* {getTranslate(
													job.sector?.sectorTranslates
												) && (
													<div className="d-flex align-items-center gap-2 lh-base">
														<FontAwesome
															icon="industry"
															size="lg"
															fixedWidth
														/>
														<span className="text-gray-3 lh-1">
															{getTranslate(
																job.sector
																	?.sectorTranslates
															)}
														</span>
													</div>
												)} */}
												<h4 className="text-break fs-5 mb-0 lh-sm">
													{job.title}
												</h4>
												{job.shortDescription && (
													<div
														className="flex-grow-1 lh-md text-break text-gray-3"
														dangerouslySetInnerHTML={{
															__html: truncateToWord(
																job.shortDescription,
																25
															)
														}}
													/>
												)}
											</div>
										</Card>
									</Col>
								);
							})}
					</Row>
				)}
				{!isLoading && data && data?.items?.length === 0 && (
					<NoData
						message={t('title.no-jobs-found')}
						iconClassName="w-40 w-md-30 w-xl-20"
					/>
				)}
				{!isLoading && data && data?.items?.length > next && (
					<div className="d-flex align-items-center justify-content-center mt-3">
						<Button
							size="lg"
							color={Colors['border-gray-4']}
							className="fs-base"
							onClick={handleMore}>
							{t('button.more-jobs')}
						</Button>
					</div>
				)}
				{isLoading && <LoadingSpinner />}
			</Container>
		</section>
	) : (
		<></>
	);
};
