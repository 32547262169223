import { Container, Row } from 'reactstrap';

import { Button, LoadingSpinner, NoData, ProfileEventCard } from 'Elements';
import { Title } from 'Styles';
import { useEvents } from 'src/hooks/useEvent';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AgentEvent } from 'src/types/Event';
import { Colors } from 'src/types/Color';

type Props = {
	isLoadingProfile: boolean;
	events: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerPreviewEventSection = ({
	isLoadingProfile,
	events
}: Props) => {
	const { t } = useTranslation();
	const perRow = 8;
	const [next, setNext] = useState(perRow);

	const { data } = useEvents({
		status: 1,
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	return data?.totalItems !== 0 ? (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && events && (
					<div className="position-relative">
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{events?.headline}
						</Title>
					</div>
				)}
				{data && data?.totalItems > 0 && (
					<Row className="justify-content-center mt-3">
						{data?.items.map((event) => (
							<ProfileEventCard
								key={event.id}
								card={event as AgentEvent}
							/>
						))}
					</Row>
				)}
				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{data?.items && data?.items?.length > next && (
						<Button
							size="lg"
							color={Colors['border-gray-4']}
							label={t('button.more-partners') as string}
							className="rounded-pill"
							onClick={handleMore}
						/>
					)}
				</div>
				{data && data?.items?.length === 0 && (
					<NoData message={t('title.no-partner-yet')} />
				)}
			</Container>
		</section>
	) : (
		<></>
	);
};
