import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { containerVariants, names } from 'Constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Colors, Job, BadgeStatus } from 'Types';
import { motion } from 'framer-motion';
import { jobStatus, formatLocalAddress } from 'Utils';
import {
	Badge,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';
import { useBranches } from 'src/hooks/useBranch';

export const ListBranch = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { branches } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const [list, setList] = useState<any>([]);
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		filterBy: string;
		isRemoving: boolean;
		selectedJob: Job | null;
	}>({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam,
		isRemoving: false,
		selectedJob: null
	});

	const getFilterByName = (filter_name: string) => {
		return [
			{
				value: '',
				label: 'dropdown.all',
				title: 'all',
				color: Colors.primary
			},
			{
				value: true,
				label: 'dropdown.active',
				title: 'active',
				color: Colors.primary
			},
			{
				value: false,
				label: 'dropdown.inactive',
				title: 'inActive',
				color: Colors.primary
			}
		].find((filter) => filter.title === filter_name);
	};

	const { data, isError, isFetching } = useBranches({
		status: getFilterByName(statusParam)?.value,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	useEffect(() => {
		setList(data?.items);
	}, [data, state]);

	const handleSelectRow = (row_id: string) => {
		void navigate(`${branches.path}/${row_id}`, {
			state: { prevPath: branches.path }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			// filterOptions={}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.title"
							cellClassName="w-100 w-sm-35"
						/>
						<DataTableRowCell
							cellText="table.short-description"
							cellClassName="w-100 w-sm-30"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-20"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-15"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{list?.map((branch) => {
								return (
									<DataTableRow key={branch.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-35"
											cellHead="table.title">
											<div className="fw-500 py-1 ps-2 ps-sm-0 text-end text-sm-start">
												<Link
													to={`${branches.path}/${branch.id}`}
													state={{
														prevPath: `/${branches.path}`
													}}
													title={branch.name}
													className="d-flex">
													{branch.name}
												</Link>
												<p className="text-gray-3 fs-small fw-light mb-0">
													{formatLocalAddress(
														branch?.address?.street,
														branch?.address
															?.houseNumber,
														branch?.address
															?.postalCode,
														branch?.address?.city,
														branch?.address?.state
													)}
												</p>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-30"
											boxClassName="d-flex align-items-center"
											cellHead="table.short-description">
											<span className="fs-small fw-400 text-gray-3">
												{branch.shortDescription}
											</span>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											<Badge
												color={
													branch.active
														? Colors.primary
														: Colors.muted
												}>
												{branch.active
													? t('label.active')
													: t('label.inactive')}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											boxClassName="d-flex align-items-center"
											cellHead="table.operation">
											<DataTableOperation
												onEditRow={() =>
													handleSelectRow(branch.id)
												}></DataTableOperation>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[35, 30, 20, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-branch" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
};
