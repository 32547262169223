import { FC } from 'react';
import { Col } from 'reactstrap';
import { CommunityPartnerArea } from 'Types';
import { ShimmerImage } from 'Elements';
import styles from 'partials/homepage/card/partner-area-card.module.scss';

interface Props {
	partner: CommunityPartnerArea;
}

export const CommunityPartnerAreaCard: FC<Props> = ({ partner }) => {
	const defaultLogo = '/assets/img/agent-default.jpg';

	return (
		<Col className="mb-4">
			<article className={styles.card}>
				<div className={styles.logoWrapper}>
					<ShimmerImage
						width={154}
						height={163}
						src={partner?.logo?.thumbnail || defaultLogo}
						defaultImage={defaultLogo}
						alt={partner?.businessName}
						className={styles.logo}
					/>
				</div>
				<div className="position-relative d-flex flex-column align-items-center text-center px-2 pb-4 gap-2 w-100 mt-auto">
					<h4 className="fs-6 fw-bold lh-base mb-0">{partner?.businessName}</h4>
					{/* {partner.address?.state && (
						<span className="fw-light">
							{partner.address?.state}
						</span>
					)} */}
				</div>
			</article>
		</Col>
	);
};
