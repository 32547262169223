import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import styles from 'partials/homepage/card/add-card.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
	navigationUrl: string;
	title: string;
	className?: string;
	size?: 'lg' | 'md' | 'sm';
}

export const ProfileAddNewCard: FC<Props> = ({
	navigationUrl,
	title,
	className,
	size = 'lg'
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate(navigationUrl, { state: { prevPath: '/homepage' } });
	};

	const cardClass = clx(
		styles.card,
		className,
		size === 'lg' && styles.cardLg,
		size === 'md' && styles.cardMd,
		size === 'sm' && styles.cardSm
	);

	return (
		<article className={cardClass} role="button" onClick={handleNavigate}>
			<span className={styles.edit}>
				<FontAwesome icon="plus" size="lg" swapOpacity />
			</span>
			<span className="mt-3 fw-500 text-primary">{t(title)}</span>
		</article>
	);
};
