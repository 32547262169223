/* eslint-disable import/no-duplicates */
import { formatDistanceToNowStrict, intlFormat } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { getCurrentLocale } from 'Utils';

/**
 * Returns the date format based on the current locale.
 * @returns The date format string.
 */
export const dateFormat = (): string => {
	const currentLocaleCode = getCurrentLocale().code;
	return currentLocaleCode === 'de'
		? 'dd.MM.yyyy HH:mm z'
		: 'yyyy-MM-dd HH:mm z';
};

/**
 * Returns the current time in the format hh:mm.
 *
 * @returns {string} The current time.
 */
export const getCurrentTime = () => {
	const now = new Date();
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`;
};

/**
 * Converts a date to a specific time zone.
 * @param date - The date to convert, either a string or a Date object.
 * @param timeZone - The time zone to convert to, defaults to 'Europe/Berlin'.
 * @returns The converted date as a string representation in the specified time zone.
 */
export const convertToTimeZone = (
	date: string | Date,
	timeZone = 'Europe/Berlin'
): string => {
	return date instanceof Date
		? date.toLocaleString('en-US', { timeZone })
		: new Date(date).toLocaleString('en-US', { timeZone });
};

/**
 * Returns the locale format based on the current locale.
 *
 * @returns The locale format as a string.
 */
export const localeFormat = () => {
	const currentLocale = getCurrentLocale().code;
	return currentLocale === 'de' ? 'dd.LLL yyyy' : 'yyyy-LLL-dd';
};

/**
 * Returns the date format based on the current locale.
 * @param withHour - Whether to include the hour in the format.
 * @returns The formatted date string.
 */
export const localeDateFormat = (withHour = true): string => {
	const currentLocale = getCurrentLocale().code;
	const hourFormat = withHour ? 'HH:mm z' : '';
	return currentLocale === 'de'
		? `dd.LLL yyyy${hourFormat}`
		: `yyyy-LLL-dd${hourFormat}`;
};

/**
 * Format a date using the international date format for a given locale.
 *
 * @param {string | Date} date - The date to format.
 * @param {Intl.DateTimeFormatOptions} formatOption - The formatting options.
 * @returns {string} - The formatted date.
 */ export const intlDateFormat = (
	date: string | Date,
	formatOption: Record<string, any>
) => {
	const currentLocale = getCurrentLocale().lang;
	return intlFormat(new Date(date), formatOption, {
		locale: currentLocale
	});
};

/**
 * Format the given time to Central European Time (CET) and return it as a string.
 *
 * @param time - The time to be formatted. Can be a string or a Date object.
 * @param localeOption - An optional object specifying the formatting options.
 * @returns The formatted time as a string.
 */
export const formatTimeToCET = (
	time: string | Date,
	localeOption?: Intl.DateTimeFormatOptions
) => {
	return intlDateFormat(time, {
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
		timeZone: 'Europe/Berlin',
		localeMatcher: 'lookup',
		timeZoneName: 'short',
		...localeOption
	});
};

/**
 * Formats a given time to the current time in a human-readable format.
 *
 * @param time - The time to be formatted.
 * @returns The formatted time.
 */
export const formatTimeToNow = (time: string) => {
	const currentLocale = getCurrentLocale().code;
	const locale = currentLocale === 'de' ? de : enUS;
	return formatDistanceToNowStrict(new Date(time), { locale });
};

/**
 * Formats a given date and time in the locale-specific format.
 *
 * @param dateTime - The date and time to format.
 * @param withHour - Whether to include the hour and minute in the formatted output.
 * @returns The formatted date and time.
 */
export const formatLocaleDateTime = (
	dateTime: string | Date,
	withHour = true
) => {
	const options = {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		...(withHour && {
			hour: 'numeric',
			minute: 'numeric',
			hour12: false
		})
	};

	return intlDateFormat(dateTime, options);
};

/**
 * Adds the specified number of days to the given date.
 *
 * @param {string | Date} date - The date to which the days should be added.
 * @param {number} days - The number of days to add.
 * @returns {Date} - The new date after adding the days.
 */
export const addDays = (date: string | Date, days: number): Date => {
	const millisecondsPerDay = 24 * 60 * 60 * 1000;
	return new Date(new Date(date).getTime() + days * millisecondsPerDay);
};

/**
 * Subtract a specified number of days from a date.
 *
 * @param date - The date to subtract days from.
 * @param days - The number of days to subtract.
 * @returns The new date after subtracting the specified number of days.
 */
export const subtractDays = (date: Date | string, days: number): Date => {
	const millisecondsInADay = 24 * 60 * 60 * 1000;
	const timeToSubtract = days * millisecondsInADay;
	const dateValue = typeof date === 'string' ? new Date(date) : date;
	return new Date(dateValue.getTime() - timeToSubtract);
};

const formatDate = (date: Date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return new Date(`${year}-${month}-${day}`);
};

export const getRemainingDay = (current: Date, endDate: Date) => {
	if (endDate) {
		const timeDifference =
			Number(formatDate(endDate)) - Number(formatDate(current));
		return timeDifference / (1000 * 60 * 60 * 24);
	}
};
