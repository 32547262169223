import { Names } from 'Types';

export const names: Names = {
	// Dashboard
	dashboard: {
		path: '/',
		title: 'pages.dashboard',
		description: 'seo.dashboard'
	},

	// Transaction
	transactions: {
		title: 'pages.transaction-list',
		path: '/transactions',
		description: 'seo.transaction-list',
		details: {
			title: 'pages.transaction-details',
			description: 'seo.transaction-details'
		}
	},

	// Credits
	credits: {
		title: 'pages.credit-list',
		path: '/credits',
		description: 'seo.credit-list',
		details: {
			title: 'pages.credit-details',
			description: 'seo.credit-details'
		}
	},

	// Invoices
	invoices: {
		title: 'pages.invoice-list',
		path: '/invoices',
		description: 'seo.invoice-list',
		details: {
			title: 'pages.invoice-details',
			description: 'seo.invoice-details'
		}
	},

	// Payments
	payments: {
		title: 'pages.payment-list',
		path: '/payments',
		description: 'seo.payment-list',
		details: {
			title: 'pages.payment-details',
			description: 'seo.payment-details'
		}
	},

	// Subscription
	subscriptions: {
		title: 'pages.subscription-list',
		path: '/subscriptions',
		description: 'seo.subscription-list',
		details: {
			title: 'pages.subscription-details',
			description: 'seo.subscription-details'
		}
	},

	// User
	users: {
		title: 'pages.user-list',
		path: '/users',
		description: 'seo.user-list',
		details: { title: 'pages.user-update', description: 'seo.user-update' },
		add: {
			path: '/users/add?prevPath=/users',
			title: 'pages.user-add',
			description: 'seo.user-add'
		}
	},

	// Branch
	branches: {
		title: 'pages.branch-list',
		path: '/branches',
		description: 'seo.branch-list',
		details: { title: 'pages.branch-update', description: 'seo.branch-update' },
		add: {
			path: '/branches/add?prevPath=/branches',
			title: 'pages.branch-add',
			description: 'seo.branch-add'
		}
	},

	// Group
	groups: {
		title: 'pages.user-groups',
		path: '/groups',
		description: 'seo.group-list',
		details: {
			title: 'pages.user-group-update',
			description: 'seo.group-update'
		},
		add: {
			path: '/groups/add?prevPath=/groups',
			title: 'pages.user-group-add',
			description: 'seo.group-add'
		}
	},

	// Role
	roles: {
		title: 'pages.user-roles',
		path: '/roles',
		description: 'seo.role-list',
		details: {
			title: 'pages.user-role-update',
			description: 'seo.role-update'
		},
		add: {
			path: '/roles/create',
			title: 'pages.user-role-add',
			description: 'seo.role-add'
		}
	},

	// Partners
	partners: {
		title: 'pages.partner-list',
		path: '/partners',
		description: 'seo.partner-list'
	},

	// Events
	events: {
		title: 'pages.event-list',
		path: '/events',
		description: 'seo.event-list',
		add: {
			path: '/events/create',
			title: 'pages.event-create',
			description: ''
		},
		details: { title: 'pages.event-details', description: '' }
	},

	// Jobs
	jobs: {
		title: 'pages.job-list',
		path: '/jobs',
		description: 'seo.job-list',
		add: {
			path: '/jobs/create',
			title: 'pages.job-add',
			description: 'seo.job-create'
		},
		details: { title: 'pages.job-details', description: 'seo.job-details' },
		requests: {
			path: '/requests',
			title: 'pages.applicants-requests',
			description: 'seo.request-details',
			details: {
				title: 'pages.request-details',
				description: 'seo.request-details'
			}
		},
		internships: {
			path: '/internships',
			title: 'pages.internships',
			description: 'seo.internship-details',
			add: {
				path: '/internships/create',
				title: 'pages.internship-add',
				description: 'seo.internship-create'
			},
			details: {
				title: 'pages.internship-details',
				description: 'seo.internship-details'
			}
		},
		internshipParticipants: {
			path: '/internship-participants',
			title: 'pages.internship-participants',
			description: 'seo.internship-participants-details',
			details: {
				title: 'pages.internship-participants-details',
				description: 'seo.internship-participants-details'
			}
		},
		internshipManagement: {
			path: '/internship-management',
			title: 'pages.internship-management',
			description: 'seo.internship-details'
		}
	},

	// Departments
	departments: {
		title: 'pages.department-list',
		path: '/departments',
		description: 'seo.department-list',
		add: {
			path: '/departments/create',
			title: 'pages.department-add',
			description: 'seo.department-create'
		},
		details: {
			title: 'pages.department-details',
			description: 'seo.department-details'
		}
	},

	// Invitations
	invitations: {
		title: 'pages.invitation-list',
		path: '/invitations',
		description: 'seo.invitation-list',
		add: {
			path: '/invitations/create',
			title: 'pages.invitation-send',
			description: 'seo.invitation-create'
		},
		details: {
			title: 'pages.invitation-details',
			description: 'seo.invitation-details'
		}
	},

	// subscription Plans
	subscriptionPlans: {
		title: 'pages.subscription-plans-list',
		path: '/subscription-plans',
		description: 'seo.subscription-plans-list',
		add: {
			path: '/subscription-plans/create',
			title: 'pages.subscription-plans',
			description: 'seo.subscription-plans-create'
		},
		details: {
			title: 'pages.subscription-plans',
			description: 'seo.subscription-plans-details'
		}
	},

	// Contact Groups
	contactGroups: {
		title: 'pages.contact-groups-list',
		path: '/contact-groups',
		description: 'seo.contact-groups-list',
		add: {
			path: '/contact-groups/create',
			title: 'pages.contact-group-add',
			description: 'seo.contact-group-create'
		},
		details: {
			title: 'pages.contact-group-details',
			description: 'seo.contact-group-details'
		}
	},

	// externPartners
	externPartners: {
		title: 'pages.extern-partners-list',
		path: '/extern-partners',
		description: 'seo.extern-partner-list',
		add: {
			path: '/extern-partners/create',
			title: 'pages.extern-partners-add',
			description: 'seo.extern-partners-create'
		},
		details: {
			title: 'pages.extern-partners-details',
			description: 'seo.extern-partners-details'
		}
	},

	// Abo Calculator
	aboCalculator: {
		title: 'pages.abo-calculator',
		path: '/abo-calculator',
		description: 'seo.abo-calculator'
	}
};
