import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { names } from 'Constants';
import { Tab, Colors, Media, AspectRatio } from 'Types';
import { clx, intlDateFormat } from 'Utils';
import { useModal, useSelector, useUpdateJob } from 'Hooks';
import {
	Badge,
	Card,
	DecorationTab,
	FeaturePhoto,
	FontAwesome,
	LoadingContent,
	ModalBoxChangeImage
} from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';
import { useFormContext } from 'react-hook-form';

export const JobDecorationBox: FC = () => {
	const { t } = useTranslation();
	const { jobs } = names;
	const [image, setImage] = useState<{ url: string; thumbnail: string }>();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { job_id } = useParams();
	const { pathname } = useLocation();
	const [openModal, toggleModal] = useModal();
	const { data: fields, isFetching } = useUpdateJob(job_id as string);

	const { setValue, getValues } = useFormContext();

	const defaultImage = '/assets/img/default-image.png';

	const defaultTab =
		pathname === `${jobs.path}/${job_id}` ||
		pathname === `${jobs.path}/${job_id}/general`;

	const tabs = useMemo(() => {
		return fields?.kind === 0
			? [
					{
						id: 0,
						title: 'tab.general',
						path: 'general',
						isActive: defaultTab
					},
					{
						id: 1,
						title: 'tab.job-info',
						path: 'info',
						isActive: pathname === `${jobs.path}/${job_id}/info`
					},
					{
						id: 2,
						title: 'tab.job-description',
						path: 'description',
						isActive:
							pathname === `${jobs.path}/${job_id}/description`
					},
					{
						id: 3,
						title: 'tab.contact-person',
						path: 'contact-person',
						isActive:
							pathname === `${jobs.path}/${job_id}/contact-person`
					},
					Number(localStorage.getItem('partnerType')) === 2 && {
						id: 4,
						title: 'tab.publish',
						path: 'visibility-settings',
						isActive:
							pathname ===
							`${jobs.path}/${job_id}/visibility-settings`
					}
			  ]
			: [
					{
						id: 0,
						title: 'tab.general',
						path: 'general',
						isActive: defaultTab
					},
					{
						id: 1,
						title: 'tab.job-info',
						path: 'info',
						isActive: pathname === `${jobs.path}/${job_id}/info`
					},
					{
						id: 2,
						title: 'tab.job-description',
						path: 'description',
						isActive:
							pathname === `${jobs.path}/${job_id}/description`
					},
					{
						id: 3,
						title: 'tab.job-time',
						path: 'internship',
						isActive:
							pathname === `${jobs.path}/${job_id}/internship`
					},
					{
						id: 4,
						title: 'tab.contact-person',
						path: 'contact-person',
						isActive:
							pathname === `${jobs.path}/${job_id}/contact-person`
					},
					Number(localStorage.getItem('partnerType')) === 2 && {
						id: 5,
						title: 'tab.publish',
						path: 'visibility-settings',
						isActive:
							pathname ===
							`${jobs.path}/${job_id}/visibility-settings`
					}
			  ];
	}, [fields?.kind, assetConfig?.partnerType, jobs.path, job_id, pathname]);

	const getBadgeData = () => {
		let badgeColor = Colors.warning;
		let badgeLabel = 'badge.draft';
		if (fields) {
			if (fields.jobStatus === 0) {
				badgeLabel = 'badge.draft';
			} else if (fields.jobStatus === 1) {
				badgeLabel = 'badge.published';
				badgeColor = Colors.green;
			} else if (fields.jobStatus === 2) {
				badgeLabel = 'badge.unPublished';
				badgeColor = Colors.muted;
			}
			// if (fields.isExpired) {
			// 	badgeLabel = 'badge.expired';
			// 	badgeColor = Colors.muted;
			// }
		}
		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			setImage({
				url: image_data.absoluteUri,
				thumbnail: image_data.thumbnailUrl
			});
			setValue(
				'featuredPhoto',
				{
					url: image_data.absoluteUri,
					thumbnail: image_data.thumbnailUrl
				},
				{ shouldDirty: true }
			);
		}
	};

	const handleRemoveImage = async () => {
		setImage({
			url: '',
			thumbnail: ''
		});
		setValue(
			'featuredPhoto',
			{
				url: '',
				thumbnail: ''
			},
			{ shouldDirty: true }
		);
	};

	useEffect(() => {
		setImage(getValues().featuredPhoto ?? (fields?.featuredPhoto as any));
	}, [getValues, fields?.featuredPhoto]);

	return (
		<Card tab className="mb-4">
			{isFetching && <LoadingContent />}
			<div className={clx(styles.body, 'flex-column flex-md-row gap-3')}>
				<FeaturePhoto image={image} onClick={toggleModal} />
				<div className="d-flex flex-column flex-grow-1 flex-wrap">
					<h3 className="h5 mb-0 lh-sm fw-400">
						{fields?.title ?? '...'}
					</h3>
					<div className="text-gray-3 fw-300 d-flex align-items-center flex-wrap mb-4">
						<div className={styles.metaItem}>
							<FontAwesome icon="code-branch" size="lg" />
							<span className="text-gray-3">
								{t('card.current-version')}:
							</span>
							<span className="text-gray-5">
								{fields?.version ?? '...'}
							</span>
						</div>
						{fields?.auditInfo?.updatedAt && (
							<div className={styles.metaItem}>
								<FontAwesome icon="calendar-days" size="lg" />
								<span className="text-gray-3">
									{fields?.jobStatus === 0
										? t('card.created-date')
										: t('card.last-update')}
									:
								</span>
								<span className="text-gray-5">
									{intlDateFormat(
										fields?.auditInfo?.updatedAt,
										{
											day: 'numeric',
											month: 'short',
											year: 'numeric',
											hour: 'numeric',
											minute: 'numeric'
										}
									) || '...'}
								</span>
							</div>
						)}
						<div className={styles.metaItem}>
							<FontAwesome icon="user" size="lg" />
							<span className="text-gray-3">{t('card.by')}:</span>
							<span className="text-gray-5">
								{fields?.auditInfo?.updatedBy ??
									fields?.auditInfo?.createdBy}
							</span>
						</div>
					</div>
					<div className="d-flex gap-2">
						<Badge
							color={
								fields?.kind === 0
									? Colors.job
									: Colors.association
							}>
							{fields?.kind === 0
								? t('menu.job')
								: t('badge.internship')}
						</Badge>
						<Badge color={getBadgeData().color}>
							{getBadgeData().label}
						</Badge>
					</div>
				</div>
			</div>
			<DecorationTab tabs={tabs as Tab[]} />
			<ModalBoxChangeImage
				name="featuredPhoto"
				isOpen={openModal}
				title="title.change-image"
				image={image?.url as string}
				aspectRatio={AspectRatio['16/9']}
				defaultImage={defaultImage}
				onClose={toggleModal}
				onRemove={handleRemoveImage}
				onImageChange={handleUploadImage}
			/>
		</Card>
	);
};
