import { memo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { Button, FontAwesome } from 'Elements';
import styles from 'partials/modal/modalbox.module.scss';

interface Props {
	title?: string;
	icon?: ReactNode;
	onClose?: () => void;
}

export const ModalBoxHeader: FC<Props> = memo(({ title, onClose, icon }) => {
	const { t } = useTranslation();
	return (
		<header className={styles.header}>
			<div className='d-flex align-items-center gap-2'>
				{icon}
				{title && (
					<h4 className="fs-6 fw-400 mb-0 me-2 flex-grow-1">
						{t(title)}
					</h4>
				)}
			</div>
			{onClose && (
				<Button
					color={Colors.empty}
					className={styles.close}
					onClick={onClose}>
					<FontAwesome
						icon="xmark"
						swapOpacity
						strokeWidth={1}
						size="xl"
					/>
				</Button>
			)}
		</header>
	);
});

ModalBoxHeader.displayName = 'ModalBoxHeader';
