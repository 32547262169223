import { Button, CheckboxController, FontAwesome, Badge } from 'Elements';
import { FC, useEffect, useState } from 'react';

import { Colors } from 'src/types/Color';
import styles from './locations.module.scss';
import { useTranslation } from 'react-i18next';

import Drawer from 'react-modern-drawer';
import { useBranches } from 'src/hooks/useBranch';

import 'react-modern-drawer/dist/index.css';
import { clx, formatLocalAddress } from 'src/helpers/Utils';

interface Props {
	isOpen?: boolean;
	locations?: [
		{
			postalCode: string;
			address: string;
			primary: boolean;
			regionCode: number;
			title: string;
			locationPoint: {
				longitude: number;
				latitude: number;
			};
		}
	];
	onAdd?: (arr: any) => void;
	toggleDrawer?: () => void;
}

export const LocationListDrawer: FC<Props> = ({
	isOpen,
	toggleDrawer,
	locations,
	onAdd
}) => {
	const { t } = useTranslation();
	const [newLocations, setNewLocations] = useState<any>([]);
	const [list, setList] = useState<any>([]);
	const [state, setState] = useState<{
		pageSize: number;
		keyword: string;
		currentPage: string | number;
	}>({
		pageSize: 1000,
		currentPage: 1,
		keyword: ''
	});
	const { data, isLoading } = useBranches({
		status: '',
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	useEffect(() => {
		const newData: any = [];
		data?.items?.forEach((item) => {
			item['title'] = item.name;
			newData.push(item);
		});
		setList(newData);
		setNewLocations([]);
		const newList: any = [];
		locations?.forEach((item) => {
			newList.push(item);
		});
		setNewLocations(newList);
	}, [data, locations, isOpen]);

	const onSearch = (q: string) => {
		setState((prev) => ({
			...prev,
			keyword: q
		}));
	};

	const onSelectedLocation = (item) => {
		if (checkedLocation(item)) {
			const newList: any = [];
			newLocations?.forEach((element) => {
				if (element.name !== item.name) {
					newList.push(element);
				}
			});
			setNewLocations(newList);
		} else {
			setNewLocations((prev: any) => [...prev, item]);
		}
	};

	const checkedLocation = (item) => {
		let check = false;
		newLocations?.forEach((element) => {
			if (element.name === item.name) {
				check = true;
			}
		});
		return check;
	};

	return (
		<Drawer
			open={isOpen as never}
			onClose={() => {
				toggleDrawer && toggleDrawer();
			}}
			lockBackgroundScroll
			zIndex={100}
			direction="right"
			className="d-flex flex-column gap-3"
			size="34rem">
			<div className="d-flex justify-content-between align-items-center px-4 py-4 border-bottom border-gray-1">
				<h2 className="fs-5 fw-600 ">
					{t('text.available-locations')}
				</h2>
				<Button
					size="sm"
					color={Colors['white-gray']}
					className="gap-2"
					onClick={() => {
						toggleDrawer && toggleDrawer();
					}}>
					<FontAwesome icon="close" />
					{t('button.close')}
				</Button>
			</div>
			<div className="px-4">
				{/* <p className="fs-sm fw-400">
					{t('text.description-available-locations')}
				</p> */}
				<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-1">
					<div className="p-0 inputbox">
						<input
							type="search"
							value={state.keyword}
							className={clx(styles.inputSearch, 'w-100')}
							placeholder={t('menu.search')}
							onChange={(e) => {
								onSearch(e.target.value);
							}}
						/>
						<Button
							type="button"
							color={Colors.empty}
							onClick={() => onSearch('')}
							className={clx('p-0', styles.submitSearch)}>
							<FontAwesome
								size="sm"
								icon={
									state.keyword === ''
										? 'magnifying-glass'
										: 'close'
								}
							/>
						</Button>
					</div>
				</div>
			</div>
			<div
				className="d-flex flex-column border-top border-gray-1 gap-3 px-4 py-3"
				style={{ overflowY: 'auto', height: '700px' }}>
				{!isLoading &&
					list?.map((item) => {
						return (
							<div
								key={item.id}
								className={`${styles.CardBranch} ${
									checkedLocation(item) &&
									styles.CardBranchSelected
								}`}>
								<div className="d-flex align-items-start gap-1">
									{item.active && (
										<div
											onClick={() => {
												onSelectedLocation(item);
											}}>
											<CheckboxController
												isChecked={checkedLocation(
													item
												)}
												name="showDetails"
												id="showDetails"
												label=""
											/>
										</div>
									)}
									<div className="d-flex flex-column gap-3">
										<span className="fs-base fw-500">
											{item.name}
										</span>
										<div>
											<Badge
												color={
													item.active
														? Colors.primary
														: Colors.muted
												}>
												{item.active
													? t('label.active')
													: t('label.inactive')}
											</Badge>
										</div>
										<div className="d-flex gap-2">
											<FontAwesome
												icon="location-dot"
												size="sm"
												color="text-primary"
											/>
											{formatLocalAddress(
												item?.address?.street,
												item?.address?.houseNumber,
												item?.address?.postalCode,
												item?.address?.city,
												item?.address?.state as any
											)}
										</div>
										<div
											className={clx(
												'text-gray-3 fs-sm fw-400 mt-1',
												styles.description
											)}
											style={{
												whiteSpace: 'pre-wrap'
											}}
											dangerouslySetInnerHTML={{
												__html:
													item.shortDescription ?? ''
											}}
										/>
									</div>
								</div>
							</div>
						);
					})}
				{isLoading &&
					[1, 2, 3].map((item) => {
						return (
							<div
								key={item}
								className={styles.cardBranchSkeleton}></div>
						);
					})}
			</div>
			<div className="d-flex justify-content-between align-items-center px-4 py-4 border-bottom border-gray-1">
				<Button
					size="sm"
					color={Colors['white-red']}
					className="gap-2"
					onClick={() => {
						toggleDrawer && toggleDrawer();
					}}>
					{t('button.close')}
				</Button>
				<Button
					size="sm"
					color={Colors.primary}
					className="gap-2"
					onClick={() => {
						onAdd && onAdd(newLocations);
					}}>
					<FontAwesome icon="save" />
					{t('button.add')}
				</Button>
			</div>
		</Drawer>
	);
};
