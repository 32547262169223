import { FC, Fragment, memo } from 'react';
import { clx } from 'Utils';
import { Job } from 'Types';
import { IconPlayer } from 'Elements';
import styles from 'partials/job/preview/job-image-section.module.scss';

interface Props {
	job: Job;
	jobImage?: any;
}

export const JobImageSection: FC<Props> = ({ job, jobImage }) => {
	const defaultImage = '/assets/img/default-image.png';

	const image = job?.featuredPhoto?.url || jobImage?.url || defaultImage;
	const video = job?.featuredVideo?.url;

	return image || video ? (
		<Fragment>
			<div
				className={clx(
					styles.imageWrapper,
					video && styles.video,
					'position-relative p-3 p-lg-0'
				)}>
				<img src={image} alt={job?.title} className={styles.image} />
				{video && (
					<div className={styles.play_icon}>
						<IconPlayer />
					</div>
				)}
			</div>
		</Fragment>
	) : (
		<></>
	);
};
