import { usePaymentMethodList } from 'src/hooks/useBusinessPartner';
import styles from 'partials/step/step-container.module.scss';
import { Button, FontAwesome, ModalBoxDelete } from 'src/containers/Elements';
import { useEffect, useState } from 'react';
import { api } from 'src/helpers/API';
import { useAxios } from 'src/hooks/useAxios';
import { config } from 'src/helpers/Config';
import { Colors } from 'src/types/Color';
import { useTranslation } from 'react-i18next';
import { clx } from 'src/helpers/Utils';
import { useModal } from 'src/hooks/useModal';
import { useDelayUnmount } from 'src/hooks/useDelayUnmount';
import { toastify } from 'src/helpers/Toastify';
import { PaymentMethod } from 'src/types/BusinessPartner';

export const ListPaymentMethod = () => {
	const { t } = useTranslation();
	const [openModalDelete, toggleModalDelete] = useModal();
	const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);
	const { data, isLoading, refetch } = usePaymentMethodList();
	const [disabled, setDisabled] = useState(false);
	const { sendRequest, isLoading: loadingDelete } = useAxios();
	const [selectedPaymentMethod, setSelectedPaymentMethod] =
		useState<PaymentMethod>();
	const onClickAddPayment = async () => {
		const returnUrl: any = `${config.redirectUrl}payment-methods?`;
		setDisabled(true);
		await sendRequest(
			api.addPaymentMethod,
			{ data: { returnUrl: returnUrl } },
			async (data: any) => {
				window.location = data;
			}
		);
	};

	const onClickDeletePayment = async (id) => {
		setDisabled(true);
		await sendRequest(
			api.deletePaymentMethod(id),
			{ method: 'delete' },
			async () => {
				toastify('toastify.payment-method-removed', {
					type: 'success'
				});
				toggleModalDelete();
				refetch();
			}
		);
	};

	useEffect(() => {
		setDisabled(false);
	}, [data]);

	return (
		<>
			<div
				className={`d-flex flex-column gap-4 p-5 ${
					data?.length !== 0 ? 'bg-white' : ''
				}`}
				style={{
					borderRadius: '16px'
				}}>
				{!isLoading &&
					data?.map((item) => {
						return (
							<div
								key={item.paymentMethodId}
								className={clx(
									'w-60',
									disabled
										? styles.disabledCardPayment
										: styles.cardPayment
								)}
								style={{ cursor: 'default' }}>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										<div className="d-flex align-items-center gap-2">
											<FontAwesome
												icon={'credit-card'}
												className={
													disabled
														? 'text-gray-2'
														: 'text-primary'
												}
												size="1x"
												swapOpacity
											/>
											<span
												className={`fs-5 fw-500 ${
													disabled
														? 'text-gray-2'
														: 'text-primary'
												}`}>
												{`${item.title}  ${
													item.last4 ?? ''
												}`}
											</span>
										</div>
										<div className="mt-2">
											{item.expYear &&
												item.expYear !== '0' && (
													<span
														className={`fw-400 fs-sm 	${
															disabled
																? 'text-gray-2'
																: 'text-gray-3'
														} mx-3`}>
														{`Expired ${item.expMonth}/${item.expYear}`}
													</span>
												)}
											{item.expYear === '0' && (
												<span className="fw-400 fs-sm text-gray-3 mx-3">
													{item.email}
												</span>
											)}
										</div>
									</div>
									<div>
										<Button
											onClick={() => {
												if (!disabled) {
													setSelectedPaymentMethod(
														item
													);
													toggleModalDelete();
												}
											}}
											className="gap-2"
											color={
												disabled
													? Colors['gray-2']
													: Colors['white-red']
											}>
											<FontAwesome
												icon="trash"
												size="1x"
											/>
											{t('button.remove-this-card')}
										</Button>
									</div>
								</div>
							</div>
						);
					})}
				{isLoading &&
					[1, 2, 3].map((item) => {
						return (
							<div
								key={item}
								className={clx(
									'w-60',
									styles.cardPaymentSkeleton
								)}></div>
						);
					})}
				{!isLoading && data?.length !== 0 && (
					<div
						className={clx(
							'w-60',
							disabled
								? styles.disabledCardPayment
								: styles.addCardPayment
						)}
						onClick={onClickAddPayment}>
						<div className="d-flex align-items-center gap-2">
							<FontAwesome
								icon="plus"
								className={
									disabled ? 'text-gray-2' : 'text-primary'
								}
								size="1x"
								swapOpacity
							/>
							<span
								className={`fs-5 fw-500  ${
									disabled ? 'text-gray-2' : 'text-primary'
								}`}>
								{t('button.add-payment-method')}
							</span>
						</div>
						<div className="mt-2">
							<span
								className={`fw-400 fs-sm mx-3 ${
									disabled ? 'text-gray-2' : 'text-gray-3'
								}`}>
								{t('button.add-new-payment-method')}
							</span>
						</div>
					</div>
				)}
				{!isLoading && data?.length === 0 && (
					<div className="mx-auto flex flex-column justify-content-center align-items-center">
						<div className="text-center mb-3">
							<FontAwesome
								icon="credit-card"
								size="3x"
								className="text-primary"
							/>
						</div>
						<div className="text-center mb-3">
							<h2 className="fs-6 fw-500">
								{t('button.no-payment-method')}
							</h2>
						</div>
						<div className="text-center">
							<p className="fs-small fw-400">
								{t('text.you-have-no-payment-method-yet')}:
							</p>
						</div>
						<div className="text-center">
							<Button
								onClick={() => {
									if (!disabled) {
										onClickAddPayment();
									}
								}}
								className="gap-2"
								color={
									disabled ? Colors['gray-2'] : Colors.primary
								}>
								<FontAwesome icon="add" size="1x" />
								{t('button.add-payment-method')}
							</Button>
						</div>
					</div>
				)}
			</div>
			{shouldRenderModalDelete && (
				<ModalBoxDelete
					isOpen={openModalDelete}
					onClose={toggleModalDelete}
					onRemove={() =>
						onClickDeletePayment(
							selectedPaymentMethod?.paymentMethodId
						)
					}
					isRemoving={loadingDelete}
					title={selectedPaymentMethod?.title ?? ''}
				/>
			)}
		</>
	);
};
