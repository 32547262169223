import drawerStyles from 'partials/profile/premium-drawer.module.scss';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import { useSubscriptionPlanDetails } from 'src/hooks/useSubscriptionPlans';
import { Box } from 'Styles';
import {
	Button,
	FontAwesome,
	ModalBox,
	ShimmerImage
} from 'src/containers/Elements';
import { useTheme } from 'Hooks';
import { Colors } from 'src/types/Color';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';

const CommunityPartnerPreviewCompanyOverviewPremiumDrawer = (props) => {
	const PartnerButton = styled((props: any) => (
		<Button color={Colors.empty} {...props} />
	))`
		${({ theme }) =>
			theme &&
			`
				background-color: #fff !important;
				color: #000 !important;
				&:hover {
					color: #000;
					background-color: ${darken(0.07, '#fff')} !important;
				}
			`}
	`;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const [image, setImage] = useState<any>();
	const targetRef = useRef<HTMLDivElement | null>(null);
	const { data: subscriptionPlan, isLoading } = useSubscriptionPlanDetails(
		props?.selected?.id as string
	);

	useEffect(() => {
		if (targetRef.current) {
			const elementPosition =
				targetRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;
			window.scrollTo({
				top: elementPosition - offset,
				behavior: 'smooth'
			});
		}
	}, []);

	return (
		<>
			<div ref={targetRef} className={drawerStyles['drawer']}>
				<div className={drawerStyles['buttonClose']}>
					<Button
						color={Colors['warning']}
						className="gap-2"
						onClick={() => {
							props?.setIsOpen(false);
						}}>
						<FontAwesome icon="close" size="lg" />
						{t('button.close')}
					</Button>
				</div>

				<div className={drawerStyles['drawer-content']}>
					<Container fluid={false}>
						{!isLoading && (
							<div className="d-flex align-items-start flex-wrap gap-5 mb-4">
								<div
									className="d-flex flex-column w-100"
									style={{ gap: '30px' }}>
									<div
										key={props?.selected?.id}
										className={clx(
											styles.boxPlanDrawer,
											'h-auto'
										)}>
										<div
											key={props?.selected?.id}
											className="d-flex flex-column gap-1">
											<div className="fs-4 fw-700">
												{props?.selected?.name}
											</div>
											<div className="fs-5 fw-700">
												{currencyFormatter(
													props?.selected?.price ?? 0
												)}
											</div>
											<div className="fs-sm fw-400">
												{
													subscriptionPlan?.shortDescription
												}
											</div>
										</div>
										<div className="d-flex align-items-center mt-4 pb-4">
											<PartnerButton
												className="rounded-pill px-md-4 fs-base"
												label={t(
													'button.become-partner'
												)}
											/>
										</div>
									</div>
								</div>
								<div className={drawerStyles['features']}>
									{subscriptionPlan?.features?.map(
										(item: any, index: any) => {
											return (
												<div
													className="d-flex gap-3 align-items-start"
													key={index}>
													<FontAwesome
														size="3x"
														icon={item.icon}
														className="mt-1 text-white"
													/>
													<div className="d-flex flex-column gap-2 text-white">
														<span className="fs-5 fw-400">
															{item.title}
														</span>
														<span className="fs-small fw-400 text-gary-2">
															{item.description}
														</span>
													</div>
												</div>
											);
										}
									)}
								</div>
								<div className="d-flex flex-column flex-md-row align-items-center gap-4 w-100">
									{!isLoading &&
										subscriptionPlan?.contactPerson
											.published &&
										subscriptionPlan?.contactPerson
											?.avatarUrl && (
											<div
												className="d-flex flex-column align-items-center justify-content-center gap-4"
												style={{
													margin: '0 auto'
												}}>
												<Box
													$hasShadow={true}
													$bgColor={
														theme[
															'communityPartner'
														].primaryColor
													}
													className={
														styles.imageWrapper
													}>
													<ShimmerImage
														src={
															subscriptionPlan
																?.contactPerson
																?.avatarUrl
														}
														defaultImage={''}
														alt={
															subscriptionPlan
																?.contactPerson
																?.fullName
														}
														width={400}
														height={400}
														className={styles.image}
													/>
												</Box>
											</div>
										)}

									<div className="d-flex justify-content-center align-items-start w-100 flex-column">
										<div>
											<h6 className="h3 text-gray-1 text-start lh-sm">
												{
													subscriptionPlan
														?.contactPerson
														?.fullName
												}
											</h6>
										</div>
										<span className="text-white fs-base fw-400">
											{
												subscriptionPlan?.contactPerson
													?.description
											}
										</span>
									</div>
								</div>
								{subscriptionPlan?.subscriptionPlanGalleries && (
									<div>
										{subscriptionPlan
											?.subscriptionPlanGalleries
											?.length > 0 && (
											<div
												className={
													drawerStyles['gallery']
												}>
												{subscriptionPlan?.subscriptionPlanGalleries?.map(
													(item: any) => {
														return (
															<>
																{item.mediaType ===
																0 ? (
																	<img
																		key={
																			item.id
																		}
																		style={{
																			borderRadius:
																				'8px',
																			objectFit:
																				'cover',
																			height: '320px',
																			width: '420px'
																		}}
																		// className="w-25 h-25"
																		src={
																			item.url
																		}
																		onClick={() => {
																			setImage(
																				item
																			);
																			setOpen(
																				true
																			);
																		}}
																	/>
																) : (
																	<div
																		style={{
																			borderRadius:
																				'8px',
																			background:
																				'#f5f5f7',
																			position:
																				'relative',
																			width: '100%',
																			height: '100%'
																		}}
																		onClick={() => {
																			setImage(
																				item
																			);
																			setOpen(
																				true
																			);
																		}}>
																		<video
																			autoPlay
																			controls
																			className="w-100 h-100"
																			src={
																				item.url
																			}
																		/>
																		<div
																			style={{
																				position:
																					'absolute',
																				top: 0,
																				left: 0,
																				width: '100%',
																				height: '100%',
																				textAlign:
																					'center',
																				display:
																					'flex',
																				alignItems:
																					'center',
																				justifyContent:
																					'center'
																			}}>
																			<FontAwesome
																				icon="play"
																				size="3x"
																				className="text-white"
																			/>
																		</div>
																	</div>
																)}
															</>
														);
													}
												)}
											</div>
										)}
									</div>
								)}
							</div>
						)}
					</Container>
				</div>
				<ModalBox
					isOpen={open}
					onClose={() => {
						setOpen(false);
					}}
					size="xl"
					className="fixed h-auto p-8"
					scrollBehavior="inside">
					{image?.mediaType === 0 ? (
						<img
							alt={image?.id}
							style={{
								borderRadius: '8px',
								objectFit: 'cover'
							}}
							src={image?.url}
						/>
					) : (
						<video autoPlay controls className={styles.videoModal}>
							<source src={image?.url} type="video/mp4" />
						</video>
					)}
				</ModalBox>
			</div>
		</>
	);
};

export default CommunityPartnerPreviewCompanyOverviewPremiumDrawer;
