import { Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	Button,
	CommunityPartnerPartnerCard,
	LoadingSpinner,
	NoData
} from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';
import { Text, Title } from 'Styles';
import { clx } from 'src/helpers/Utils';
import { useCommunityPartnerPartners } from 'src/hooks/useCommunityPartner';
import { useState } from 'react';
import { Colors } from 'src/types/Color';

type Props = {
	isLoadingProfile: boolean;
	partners: {
		enabled: boolean;
		headline: string;
		description: string;
	};
};

export const CommunityPartnerPreviewPartnerSection = ({
	isLoadingProfile,
	partners
}: Props) => {
	const { t } = useTranslation();
	const perRow = 8;
	const [next, setNext] = useState(perRow);

	const { data } = useCommunityPartnerPartners({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		status: '',
		registrationStatus: '',
		publishStatus: 1
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	return data?.totalItems !== 0 ? (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<div
						className={clx(
							styles.title,
							'd-flex flex-column justify-content-center mx-auto mb-3'
						)}>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{partners?.headline}
						</Title>
						{partners?.description && (
							<Text
								as="div"
								textColor="text-gray-5"
								className="mt-3 lh-md text-center">
								{partners?.description}
							</Text>
						)}
					</div>
				)}
				{data && data?.totalItems > 0 && (
					<Row className="justify-content-center">
						{data?.items.map((partner) => (
							<CommunityPartnerPartnerCard
								key={partner.id}
								partner={partner}
							/>
						))}
					</Row>
				)}
				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{data?.items && data?.items?.length > next && (
						<Button
							size="lg"
							color={Colors['border-gray-4']}
							label={t('button.more-partners') as string}
							className="rounded-pill"
							onClick={handleMore}
						/>
					)}
				</div>
				{data && data?.items?.length === 0 && (
					<NoData message={t('title.no-partner-yet')} />
				)}
			</Container>
		</section>
	) : (
		<></>
	);
};
