import { createBrowserRouter, Outlet } from 'react-router-dom';
import { names } from 'Constants';
import {
	PublicThemeProvider,
	PageLayout,
	RemoveTrailingSlash,
	UpdateAgentAssociation,
	UpdateCommunityPartnerAddress,
	UpdateCommunityPartnerBankAccount,
	UpdateCommunityPartnerGeneral,
	UpdateCompanyGeneral,
	UpdateCompanyAddress,
	UpdateJobDescription,
	UpdateJobContactPerson,
	UpdateJobGeneral,
	UpdateJobInfo,
	UpdateUserGeneral,
	UpdateSubscriptionCurrentPriceInfo,
	UpdateUserAddress,
	UpdateEventGeneral,
	EventPartners,
	EventVisitors,
	UpdateEventRegistration,
	PartnerScript,
	BecomePartnerScript,
	JobsScript,
	JobbiplacePartnerScript,
	UpdateSubscriptionHistory,
	UpdateSubscriptionAssignedPaymentMethod,
	UpdateJobInternship,
	SubscriptionPlanGeneral,
	SubscriptionPlanPriceCalculation,
	SubscriptionPlanPremiumFeatures,
	SubscriptionPlanContactPerson,
	SubscriptionPlanFeatures,
	SubscriptionPlanMedia,
	UpdateSubscriptionFeatures,
	UpdateBranchGeneral,
	UpdateBranchAddress
} from 'Elements';

import {
	Dashboard,
	MyBusinessPartner,
	MyCommunityPartner,
	MyCommunityPartnerProfilePage,
	MyCommunityPartnerPreviewProfilePage,
	EmployeeUpdate,
	EmployeeCreate,
	EmployeeList,
	JobList,
	JobCreate,
	JobUpdate,
	VoucherList,
	MemberCreate,
	MemberUpdate,
	ErrorPage,
	SubscriptionList,
	SubscriptionCreate,
	SubscriptionCheckout,
	SubscriptionPaymentResult,
	TransactionList,
	TransactionDetails,
	SubscriptionDetails,
	PartnerList,
	EventCreate,
	EventList,
	ListContactGroups,
	ContactGroupCreate,
	ContactGroupUpdate,
	RequestList,
	RequestDetails,
	UserList,
	UserCreate,
	UserUpdate,
	UserGroupList,
	UserGroupCreate,
	UserGroupUpdate,
	UserRoleList,
	UserRoleCreate,
	UserRoleUpdate,
	MemberList,
	VoucherDetails,
	InvitationList,
	InvitationCreate,
	InvitationUpdate,
	ListExternPartners,
	ExternPartnerCreate,
	ExternPartnerUpdate,
	MyEvents,
	EventDetails,
	MyEventDetails,
	ToolboxWelcome,
	Integration,
	CreditList,
	CreditDetails,
	InvoiceList,
	InvoiceDetails,
	PaymentList,
	PaymentDetails,
	DepartmentList,
	DepartmentCreate,
	DepartmentUpdate,
	SubscriptionPaymentInvoice,
	AboCalculator,
	MyBusinessPartnerProfilePage,
	PaymentMethodList,
	SubscriptionPlansList,
	SubscriptionPlansCreate,
	SubscriptionPlansUpdate,
	BranchList,
	BranchCreate,
	BranchUpdate,
	SubscriptionUpgrade
} from 'Pages';

import MyBusinessPartnerPreviewProfilePage from 'src/components/pages/business-partner/MyBusinessPartnerPreviewProfilePage';
import VisibilitySetting from 'src/components/elements/job/update/tab/visibility-setting';
import JobPreviewPage from 'src/components/pages/jobs/job/JobPreviewPage';

const { jobs } = names;

export const routeList = [
	{
		element: (
			<>
				<RemoveTrailingSlash />
				<PageLayout />
			</>
		),
		children: [
			{
				index: true,
				element: <Dashboard />
			},
			{
				path: '/',
				element: <Dashboard />
			},
			// My company profile pages
			{
				path: 'my-business-partner',
				element: <MyBusinessPartner />,
				children: [
					{
						index: true,
						element: <UpdateCompanyGeneral />
					},
					{
						path: 'general',
						element: <UpdateCompanyGeneral />
					},
					{
						path: 'address',
						element: <UpdateCompanyAddress />
					}
				]
			},
			// My agent profile pages
			{
				path: 'my-community-partner',
				element: <MyCommunityPartner />,
				children: [
					{
						index: true,
						element: <UpdateCommunityPartnerGeneral />
					},
					{
						path: 'general',
						element: <UpdateCommunityPartnerGeneral />
					},
					{
						path: 'address',
						element: <UpdateCommunityPartnerAddress />
					},
					{
						path: 'bank',
						element: <UpdateCommunityPartnerBankAccount />
					},
					{
						path: 'association',
						element: <UpdateAgentAssociation />
					}
				]
			},
			// Partner pages
			{
				path: 'partners',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <PartnerList />
					}
				]
			},
			// Events pages
			{
				path: 'events',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <EventList />
					},
					{
						path: 'create',
						element: <EventCreate />
					},
					{
						path: 'my-events',
						element: <MyEvents />
					},
					{
						path: ':event_id',
						element: <EventDetails />,
						children: [
							{
								index: true,
								element: <UpdateEventGeneral />
							},
							{
								path: 'general',
								element: <UpdateEventGeneral />
							},
							{
								path: 'registration',
								element: <UpdateEventRegistration />
							},
							{
								path: 'event-partners',
								element: <EventPartners />
							},
							{
								path: 'visitors',
								element: <EventVisitors />
							},
							{
								path: ':event_slug',
								element: <MyEventDetails />
							}
						]
					}
				]
			},
			// Contact Groups pages
			{
				path: 'contact-groups',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <ListContactGroups />
					},
					{
						path: 'create',
						element: <ContactGroupCreate />
					},
					{
						path: ':contactGroup_id',
						element: <ContactGroupUpdate />
					}
				]
			},
			// Invitation pages
			{
				path: 'invitations',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InvitationList />
					},
					{
						path: 'create',
						element: <InvitationCreate />
					},
					{
						path: ':invitation_id',
						element: <InvitationUpdate />
					}
				]
			},
			// Subscription plans pages
			{
				path: 'subscription-plans',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <SubscriptionPlansList />
					},
					{
						path: 'create',
						element: <SubscriptionPlansCreate />
					},
					{
						path: ':subscriptionPlan_id',
						element: <Outlet />,
						children: [
							{
								element: <SubscriptionPlansUpdate />,
								children: [
									{
										index: true,
										element: <SubscriptionPlanGeneral />
									},
									{
										path: 'general',
										element: <SubscriptionPlanGeneral />
									},
									{
										path: 'plan-price',
										element: (
											<SubscriptionPlanPriceCalculation />
										)
									},
									{
										path: 'media',
										element: <SubscriptionPlanMedia />
									},
									{
										path: 'premium-features',
										element: (
											<SubscriptionPlanPremiumFeatures />
										)
									},
									{
										path: 'contact-person',
										element: (
											<SubscriptionPlanContactPerson />
										)
									},
									{
										path: 'features',
										element: <SubscriptionPlanFeatures />
									}
								]
							}
						]
					}
				]
			},
			// Abo Calculator
			{
				path: 'abo-calculator',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <AboCalculator />
					}
				]
			},
			// Extern Partner pages
			{
				path: 'extern-partners',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <ListExternPartners />
					},
					{
						path: 'create',
						element: <ExternPartnerCreate />
					},
					{
						path: ':externPartner_id',
						element: <ExternPartnerUpdate />
					}
				]
			},
			// Employee Pages
			{
				path: 'employees',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <EmployeeList />
					},
					{
						path: 'create',
						element: <EmployeeCreate />
					},
					{
						path: ':employee_id',
						element: <EmployeeUpdate />
					}
				]
			},
			// Member Pages
			{
				path: 'members',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <MemberList />
					},
					{
						path: 'create',
						element: <MemberCreate />
					},
					{
						path: ':member_id',
						element: <MemberUpdate />
					}
				]
			},
			// Department Pages
			{
				path: 'departments',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <DepartmentList />
					},
					{
						path: 'create',
						element: <DepartmentCreate />
					},
					{
						path: ':department_id',
						element: <DepartmentUpdate />
					}
				]
			},
			// Branch Pages
			{
				path: 'branches',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <BranchList />
					},
					{
						path: 'create',
						element: <BranchCreate />
					},
					{
						path: ':branch_id',
						element: <Outlet />,
						children: [
							{
								element: <BranchUpdate />,
								children: [
									{
										index: true,
										element: <UpdateBranchGeneral />
									},
									{
										path: 'general',
										element: <UpdateBranchGeneral />
									},
									{
										path: 'address',
										element: <UpdateBranchAddress />
									}
								]
							}
						]
					}
				]
			},
			// Voucher Pages
			{
				path: 'vouchers',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <VoucherList />
					},
					{
						path: ':voucher_id',
						element: <VoucherDetails />
					}
				]
			},
			// Jobs Pages
			{
				path: jobs.path,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <JobList />
					},
					{
						path: jobs.add?.path,
						element: <JobCreate />
					},
					{
						path: ':job_id',
						element: <Outlet />,
						children: [
							{
								element: <JobUpdate />,
								children: [
									{
										index: true,
										element: <UpdateJobGeneral />
									},
									{
										path: 'general',
										element: <UpdateJobGeneral />
									},
									{
										path: 'info',
										element: <UpdateJobInfo />
									},
									{
										path: 'description',
										element: <UpdateJobDescription />
									},
									{
										path: 'internship',
										element: <UpdateJobInternship />
									},
									{
										path: 'contact-person',
										element: <UpdateJobContactPerson />
									},
									{
										path: 'visibility-settings',
										element: <VisibilitySetting />
									}
									// {
									// 	path: `preview`,
									// 	element: (
									// 		<PublicThemeProvider>
									// 			<JobPreviewPage />
									// 		</PublicThemeProvider>
									// 	)
									// }
								]
							}
						]
					}
				]
			},
			// Requests Pages
			{
				path: 'requests',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <RequestList />
					},
					{
						path: ':request_id/:applicant_id',
						element: <RequestDetails />
					}
				]
			},
			// Subscription Pages
			{
				path: 'subscriptions',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <SubscriptionList />
					},
					{
						path: 'add',
						element: <SubscriptionCreate />
					},
					{
						path: ':subscription_id/upgrade',
						element: <SubscriptionUpgrade />
					},
					{
						path: ':subscription_id',
						element: <SubscriptionDetails />,
						children: [
							{
								index: true,
								element: <UpdateSubscriptionCurrentPriceInfo />
							},
							{
								path: 'current-price-info',
								element: <UpdateSubscriptionCurrentPriceInfo />
							},
							{
								path: 'history',
								element: <UpdateSubscriptionHistory />
							},
							{
								path: 'assigned-payment-method',
								element: (
									<UpdateSubscriptionAssignedPaymentMethod />
								)
							},
							{
								path: 'features',
								element: <UpdateSubscriptionFeatures />
							}
						]
					}
				]
			},
			// Subscription Pages
			{
				path: 'payment-methods',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <PaymentMethodList />
					}
				]
			},
			// Toolbox Pages
			{
				path: 'marketing',
				element: <Outlet />,
				children: [
					{
						path: 'toolbox',
						element: <Outlet />,
						children: [
							{
								index: true,
								element: <ToolboxWelcome />
							}
						]
					},
					{
						path: 'integration',
						element: <Integration />,
						children: [
							{
								index: true,
								element: <PartnerScript />
							},
							{
								path: 'partners',
								element: <PartnerScript />
							},
							{
								path: 'become-partner',
								element: <BecomePartnerScript />
							},
							{
								path: 'jobs',
								element: <JobsScript />
							},
							{
								path: 'jobbiplace-partner',
								element: <JobbiplacePartnerScript />
							}
						]
					}
				]
			},

			// // Transaction Pages
			// {
			// 	path: 'transactions',
			// 	element: <Outlet />,
			// 	children: [
			// 		{
			// 			index: true,
			// 			element: <TransactionList />
			// 		},
			// 		{
			// 			path: ':transaction_id',
			// 			element: <TransactionDetails />
			// 		}
			// 	]
			// },
			// Credit Pages
			{
				path: 'credits',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <CreditList />
					},
					{
						path: ':credit_id',
						element: <CreditDetails />
					}
				]
			},
			// Invoice Pages
			{
				path: 'invoices',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InvoiceList />
					},
					{
						path: ':invoice_id',
						element: <InvoiceDetails />
					}
				]
			},
			// Payment Pages
			{
				path: 'payments',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <PaymentList />
					},
					{
						path: ':payment_id',
						element: <PaymentDetails />
					}
				]
			},
			// Checkout pages
			{
				path: 'checkout',
				element: <SubscriptionCheckout />
			},
			// Payment Result Pages
			{
				path: 'payment',
				element: <SubscriptionPaymentResult />
			},
			{
				path: 'payment-invoice',
				element: <SubscriptionPaymentInvoice />
			},
			// User Pages
			{
				path: 'users',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserList />
					},
					{
						path: 'create',
						element: <UserCreate />
					},
					{
						path: ':user_id',
						element: <UserUpdate />,
						children: [
							{
								index: true,
								element: <UpdateUserGeneral />
							},
							{
								path: 'general',
								element: <UpdateUserGeneral />
							},
							{
								path: 'address',
								element: <UpdateUserAddress />
							}
						]
					}
				]
			},
			// User Group Pages
			{
				path: 'groups',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserGroupList />
					},
					{
						path: 'create',
						element: <UserGroupCreate />
					},
					{
						path: ':group_id',
						element: <UserGroupUpdate />
					}
				]
			},
			// User Role Pages
			{
				path: 'roles',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserRoleList />
					},
					{
						path: 'create',
						element: <UserRoleCreate />
					},
					{
						path: ':role_id',
						element: <UserRoleUpdate />
					}
				]
			}
		],
		errorElement: <ErrorPage />
	},
	{
		element: <PublicThemeProvider />,
		children: [
			{
				path: '/my-business-partner/homepage',
				element: <MyBusinessPartnerProfilePage />
			},
			{
				path: '/my-community-partner/homepage',
				element: <MyCommunityPartnerProfilePage />
			},
			{
				path: '/my-business-partner/homepage-preview',
				element: <MyBusinessPartnerPreviewProfilePage />
			},
			{
				path: '/my-community-partner/homepage-preview',
				element: <MyCommunityPartnerPreviewProfilePage />
			},
			{
				path: `${jobs.path}/:job_id/preview`,
				element: <JobPreviewPage />
			}
		]
	}
];

export const routes = createBrowserRouter(routeList);
