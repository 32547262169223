import styled from 'styled-components';
import { t } from 'i18next';
import { darken } from 'polished';
import { Col, Row } from 'reactstrap';
import { Colors, Media, Logo } from 'Types';
import { useDelayUnmount, useModal } from 'Hooks';
import {
	Button,
	ButtonProps,
	EditButton,
	LoadingSpinner,
	ModalBoxChangeImage
} from 'Elements';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';

type Props = {
	isLoadingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo
	) => void;
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
};

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} size="lg" {...props} />
))`
	${({ theme }) =>
		theme &&
		`
		background-color: #fff !important;
		color: #000 !important;
		&:hover {
			color: #000;
			background-color: ${darken(0.07, '#fff')} !important;
		}
	`}
`;

export const CommunityPartnerCompanyOverviewStandard = ({
	onChangeValue,
	becomePartner,
	isLoadingProfile
}: Props) => {
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const handleResetImage = async () => {
		const api_data = {
			url: '',
			thumbnail: ''
		};

		onChangeValue('becomePartner', 'image', api_data);
	};

	const handleUploadImage = async (image_data: Media) => {
		const api_data = {
			url: image_data?.absoluteUri || '',
			thumbnail: image_data?.thumbnailUrl || ''
		};

		onChangeValue('becomePartner', 'image', api_data);
	};

	return (
		<Row className="g-0">
			<Col md={7} xl={6} className="order-1 order-md-0">
				<div className="px-3 py-5 ps-lg-0 d-flex flex-column gap-3 justify-content-center h-100">
					<h3 className="text-white h3 mb-0">
						<input
							type="text"
							onChange={(e) =>
								onChangeValue(
									'becomePartner',
									'headline',
									e.target.value
								)
							}
							value={becomePartner?.headline ?? ''}
							id="becomePartnerHeadline"
							placeholder={`[${t(
								'placeholder.business-partner-name'
							)}]`}
							className={`${stylesProfile.inputEdit} w-90`}
						/>
					</h3>
					<p className="text-white mb-0 fs-base lh-md fw-300">
						<textarea
							rows={4}
							onChange={(e) =>
								onChangeValue(
									'becomePartner',
									'description',
									e.target.value
								)
							}
							value={becomePartner?.description ?? ''}
							id="becomePartnerDescription"
							placeholder={`[${t(
								'placeholder.business-partner-overview-description'
							)}]`}
							className={`${stylesProfile.inputEdit} w-90`}
						/>
					</p>
					<div className="d-flex align-items-center">
						<PartnerButton
							className="rounded-pill px-md-5"
							label={t('button.become-partner') as string}
						/>
					</div>
				</div>
			</Col>
			<Col md={5} xl={6}>
				<div
					role="button"
					className={styles.editable}
					onClick={toggleModal}>
					{isLoadingProfile && (
						<div className="d-flex align-items-center justify-content-center h-100">
							<LoadingSpinner />
						</div>
					)}
					{!isLoadingProfile && (
						<>
							<img
								src={
									becomePartner?.image?.thumbnail ||
									'/assets/img/default-image.png'
								}
								alt={becomePartner?.headline}
							/>
							<div className="edit-overlay">
								<EditButton className="edit-overlay-icon" />
							</div>
						</>
					)}
				</div>
			</Col>
			{shouldRenderModal && (
				<ModalBoxChangeImage
					name="companyOverviewImage"
					isOpen={openModal}
					title="title.change-image"
					image={becomePartner?.image?.thumbnail || ''}
					onClose={toggleModal}
					onRemove={handleResetImage}
					onImageChange={handleUploadImage}
				/>
			)}
		</Row>
	);
};
