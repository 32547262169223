import { memo, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCompaniesToInvite, useEventAllInvitations } from 'Hooks';
import {
	Card,
	InviteCompanyCard,
	InviteCompanyCardLoading,
	InviteGuestBar,
	PageHeadingSearch,
	Pagination,
	NoData
} from 'Elements';
import styles from 'partials/page/event.module.scss';

export const EventPartnerCompanyList: FC = memo(() => {
	const { t } = useTranslation();
	const { event_id } = useParams();

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: 1,
		keyword: '',
		inviting: false
	});

	const { data: invitedList, refetch: refetchInvited } =
		useEventAllInvitations({ eventId: event_id as string });

	const { data: list, isLoading } = useCompaniesToInvite(
		{
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		},
		{
			refetchOnMount: false
		}
	);

	const handlePageChange = (page: number) => {
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSearch = (query: string) => {
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const checkInvited = (companyId: string) => {
		return (
			invitedList?.some((company) => company.businessPartnerId === companyId) ??
			false
		);
	};

	const onInvite = () => {
		refetchInvited();
	};

	return (
		<Card className={styles.card}>
			<h3 className="text-gray-3 fs-6 fw-400 mb-3">
				{t('title.guest-list')}
			</h3>
			<InviteGuestBar />
			<PageHeadingSearch className="mb-2" onSubmit={handleSearch} />
			<div>
				{!isLoading &&
					list?.items?.map((company) => {
						return (
							<InviteCompanyCard
								key={company.id}
								card={company}
								isInvited={checkInvited(company.id)}
								onInvite={onInvite}
							/>
						);
					})}
			</div>
			{isLoading && <InviteCompanyCardLoading count={10} />}
			{!isLoading && list?.items?.length === 0 && (
				<NoData message="title.no-company" />
			)}
			{!isLoading && list && list?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={list.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</Card>
	);
});

EventPartnerCompanyList.displayName = 'EventPartnerCompanyList';
