import {
	AgentPartnerApprovalStatus,
	AgentSetting,
	CustomerDto,
	PaymentMethod,
	Voucher,
	VoucherDiscountType,
	Address,
	Logo,
	SubscriptionPlanContactPersonDetails
} from 'Types';

export const enum SubscriptionStatus {
	pendingApproval = 0,
	active = 1,
	rejected = 2,
	pendingPayment = 3,
	expired = 4
}
export const enum SubscriptionPaymentStatus {
	Pending = 0,
	Paid = 1,
	Failed = 2,
	InProcess = 3,
	Canceled = 4,
	PendingSepa = 5,
	PendingApproval = 6,
	Voucher100 = 7,
	test = -1
}

export enum SubscriptionPlanType {
	BuiltIn = 0,
	PremiumPartner = 1
}

export interface SubscriptionPlan {
	id: string;
	communityPartnerId?: string;
	code: string;
	name: string;
	shortDescription: string;
	description: string;
	durationDays: number;
	remainDuration: number;
	price: number;
	planBasePrice: number;
	total: number;
	taxAmount: number;
	isActive: boolean;
	agentPercentPay: number;
	publishedJobs: number | null;
	publishedInternships: number | null;
	taxPercent: number;
	subscriptionType: number;
	type: number;
	featureData?: SubscriptionPlansFeature;
}

export interface SubscriptionPlanDetails {
	id: string;
	tenantId: string;
	type: number;
	upgradeable: boolean;
	availableUpgradePlanIds: any;
	availableUpgradePlanList: any;
	subscriptionPlanFeatureId: string;
	publishedJobs: number | null;
	publishedInternships: number | null;
	contactPerson: SubscriptionPlanContactPersonDetails;
	features: [
		{
			title: string;
			description: string;
			icon: string;
			sortOrder: number;
		}
	];
	subscriptionPlanGalleries: any;
	code: string;
	name: string;
	shortDescription: string;
	description: string;
	price: number;
	durationDays: number;
	taxPercentage: number;
	active: boolean;
	isFree: boolean;
	termsCondition: string;
	isRecommended: boolean;
}

export interface SubscriptionPrimaryPlan {
	id: string;
	code: string;
	name: string;
	shortDescription: string;
	description: string;
	durationDays: number;
	remainDuration: number;
	price: number;
	planBasePrice: number;
	total: number;
	taxAmount: number;
	isActive: boolean;
	agentPercentPay: number;
	taxPercent: number;
	subscriptionType: number;
	type: number;
}

export interface SubscriptionSate {
	selectedAgent: SubscriptionAgentSetting | null;
	selectedCommunityPartner: SubscriptionCommunityPartner | null;
	selectedPlan: SubscriptionPlan | null;
	selectedPrimaryPlan: SubscriptionPrimaryPlan | null;
	selectedVoucher: Voucher | null;
}

export interface SubscriptionForm {
	step1: SubscriptionFormAgent;
	step2: SubscriptionPlan | null;
}

export interface SubscriptionFormAgent {
	agent: SubscriptionAgentSetting | null;
}

export interface SubscriptionAgent {
	id: string;
	agentMainList: SubscriptionAgentSetting[];
	agentAlternativeList: SubscriptionAgentSetting[];
	agentId: string;
	regionId: string;
}

export interface SubscriptionPlan {
	active: boolean;
	code: string;
	termsCondition: string;
	creditPercentage: number;
	description: string;
	durationDays: number;
	id: string;
	isFree: boolean;
	name: string;
	price: number;
	isRecommended: boolean;
	shortDescription: string;
	subscriptionPlanFeatureId: string;
	taxPercentage: number;
	type: number;
}

export interface SubscriptionCommunityPartner {
	additionalPlanPrice: number;
	address: Address;
	businessName: string;
	communityPartnerTypeCode: string;
	firstName: string;
	slugUrl: string;
	slogan: string;
	description: string;
	id: string;
	isAutoApprove: boolean;
	lastName: string;
	commissionGroupInfo: any;
	logo: Logo;
	mainEmailAddress: string;
	partnerClass: number;
	regionCode: string;
	smallBusiness: boolean;
	tenantId: string;
	planData?: SubscriptionPlan[];
}

export interface SubscriptionAgentSetting {
	id: string;
	name: string;
	slugUrl: string;
	agentSetting: AgentSetting;
	companyApprovalStatus?: boolean;
}

export interface Subscription {
	id: string;
	paymentData?: any;
	agentApprovedStatus: AgentPartnerApprovalStatus;
	agentApprovalStatus: {
		agentApprovedStatus: string;
		updatedAt: string;
		description: string;
		createdBy: string;
		createdByIdentify: string;
	};
	subscriptionPlanInfo: any;
	companyId: string;
	name: string;
	currentSubscriptionPaymentId: string;
	upgradeSubscriptionPaymentId: string;
	agentId: string;
	purchaseDate: string;
	renewalDate: string;
	totalAmount: number;
	totalPrice: number;
	endDate: string;
	company: CustomerDto;
	code: string;
	title: string;
	remainDuration: number;
	remainDays: string;
	autoRenewal: boolean;
	agent: CustomerDto;
	communityPartnerInfo: CustomerDto;
	status: number;
	payment: SubscriptionPayment;
	paymentIds: { paymentId: string }[];
	duration: number;
	shortDescription: string;
	description: string;
	paymentStatus: string;
	transaction: SubscriptionTransaction;
	plan: SubscriptionPlan;
	price: number;
	paymentMethod: PaymentMethod;
	subscriptionType: string;
	parentId: string;
	payUrl: string;
	startDate: string;
	priceForXDays: number;
	isSubCompanySubscription: boolean;
	subCompanySubscription: Subscription[];
	subTotal: number;
	discountAmount: number;
	taxAmount: number;
	taxPercent: number;
	discount: number;
	priceAfterDiscount: number;
	discountType: VoucherDiscountType;
	applyVoucher: Voucher;
	subscriptionPlanFeatureInfo: {
		jobSetting: {
			jobEnabled: boolean;
			publishJobEnabled: boolean;
			maxPublishJobs: number;
			limitJobVisibility: boolean;
			jobsGoogleIndex: boolean;
		};
		internshipSetting: {
			internshipEnabled: boolean;
			publishInternshipEnabled: boolean;
			maxPublishInternships: number;
			internshipManagerEnabled: boolean;
		};
		eventSetting: {
			eventsEnabled: boolean;
			publishEventsEnabled: boolean;
			maxPublishEvents: number | number;
		};
		userAccountSetting: {
			maxUserAccounts: number;
		};
	};
}
export interface SubscriptionDetails
	extends Omit<Subscription, 'subCompanySubscription'> {
	subCompanySubscription: Subscription;
	communityPartnerInfo: any;
	subscriptionPlanInfo: any;
	paymentMethodInfo: any;
}

export interface SubscriptionCalculate {
	planBasePrice: number;
	additionalPrice: number;
	subscriptionPrice: number;
	subscriptionDuration: number;
	discountAmount: number;
	paidSubscriptionPrice?: number;
	discountPercent: number;
	subTotal: number;
	taxPercent: number;
	taxAmount: number;
	totalAmount: number;
}

export interface SubscriptionPlans {
	name: string;
	shortDescription: string;
	description: string;
	price: number;
	publishedJobs: number | null;
	publishedInternships: number | null;
	active: boolean;
	isRecommended: boolean;
}

export interface SubscriptionPlansForm {
	name: string;
	shortDescription: string;
	description: string;
	price: number;
	publishedJobs: number | null;
	publishedInternships: number | null;
	active: boolean;
	isRecommended: boolean;
}

export interface SubscriptionPlansMedia {
	mediaType: number;
	url: string;
	thumbnail: string;
}

export interface SubscriptionPlansFeature {
	id: string;
	subscriptionPlanId: string;
	jobSetting: {
		jobEnabled: boolean;
		publishJobEnabled: boolean;
		maxPublishJobs: number;
		limitJobVisibility: boolean;
		jobsGoogleIndex: boolean;
	};
	internshipSetting: {
		internshipEnabled: boolean;
		publishInternshipEnabled: boolean;
		maxPublishInternships: number;
		internshipManagerEnabled: boolean;
	};
	eventSetting: {
		eventsEnabled: boolean;
		publishEventsEnabled: boolean;
		maxPublishEvents: number;
	};
	userAccountSetting: {
		maxUserAccounts: number;
	};
}

export interface SubscriptionCommissionGroups {
	id: string;
	title: string;
	label: string;
	description: string;
	isDefault: boolean;
	isActive: boolean;
	auditInfo: {
		createdBy: string;
		createdAt: string;
		updatedBy: string;
		updatedAt: string;
	};
	isPremium: boolean;
	commissionLevels: [
		{
			id: string;
			title: string;
			minActiveAbo: number;
			maxActiveAbo: number;
			commissionPercent: number;
			commissionCost: number;
			auditInfo: number;
		}
	];
	defaultSubscriptionFeature: {
		id: string;
		jobSetting: {
			jobEnabled: boolean;
			publishJobEnabled: boolean;
			maxPublishJobs: number;
			limitJobVisibility: boolean;
			jobsGoogleIndex: boolean;
		};
		internshipSetting: {
			internshipEnabled: boolean;
			publishInternshipEnabled: boolean;
			maxPublishInternships: number;
			internshipManagerEnabled: boolean;
		};
		eventSetting: {
			eventsEnabled: boolean;
			publishEventsEnabled: boolean;
			maxPublishEvents: number;
		};
		userAccountSetting: {
			maxUserAccounts: number;
		};
		durationDays: number;
		taxPercentage: number;
	};
	subscriptionPlans: [
		{
			planId: string;
		},
		{
			planId: string;
		}
	];
}

export interface SubscriptionPayment {
	id: string;
	businessPartnerId: string;
	subscriptionId: string;
	createdAt: string;
	lastUpdatedAt: string;
	status: number;
	subscriptionPlanInfo: {
		durationDays: 365;
		name: 'plan 2';
		price: 2000;
		subscriptionPlanCode: '10002';
		subscriptionPlanId: 'ac699dea-396d-4047-b5b4-38e5144ad4f7';
		taxPercentage: 19;
		type: 1;
	};
	provider: {
		errorMessage: string;
		paymentReference: string;
		paymentProvider: string;
		providerStatus: string;
		description: string;
		paymentMethodInfo:
			| {
					paymentMethodIdentifier: string;
					type: string;
					brand: string;
					last4: string;
					expMonth: string;
					expYear: string;
					email: string;
			  }
			| any;
	};
	tenantId: string;
	price: {
		subscriptionPrice: number;
		totalAmount: number;
		taxAmount: number;
		taxPercent: number;
		discountAmount: number;
		discountPercent: number;
		subscriptionDuration: number;
		subTotal: number;
		voucherCode: string;
	};
	communityPartnerCreditId: string;
	invoiceInfo: {
		firstName: string;
		lastName: string;
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: string;
		address: {
			name: string;
			countryName: string;
			countryIso2: string;
			countryIso3: string;
			state: string;
			city: string;
			postalCode: string;
			street: string;
			houseNumber: string;
			location: {
				longitude: number;
				latitude: number;
			};
		};
		invoiceNumber: string;
		invoiceDocumentUrl: string;
	};
}

export interface SubscriptionTransaction {
	id: string;
	amount: number;
	taxAmount: number;
	totalAmount: number;
	taxPercent: number;
	priceAfterDiscount: number;
	applyVoucher: Voucher;
	discountAmount: number;
	subTotal: number;
	discount: number;
	discountType: VoucherDiscountType;
}
