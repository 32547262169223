import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { api, clx, config, currencyFormatter, formatLocalAddress } from 'Utils';
import {
	Button,
	Card,
	Badge,
	FontAwesome,
	ModalBoxPlanInf,
	LoadingMask,
	SkeletonWrapper
} from 'Elements';
import { Colors, SubscriptionCommunityPartner, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-agent-card.module.scss';
import { useModal } from 'src/hooks/useModal';
import { useSubscriptions } from 'src/hooks/useSubscription';
import { useAxios } from 'src/hooks/useAxios';

interface Props {
	communityPartner: SubscriptionCommunityPartner;
	selected: boolean;
	showDescription?: boolean;
	notShadow?: boolean;
	onSelect?: (communityPartner: SubscriptionCommunityPartner) => void;
}

export const SubscriptionCommunityPartnerCard: FC<Props> = ({
	communityPartner,
	selected,
	showDescription = true,
	notShadow = false,
	onSelect
}) => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios<any>();
	const [planData, setPlanData] = useState<SubscriptionPlan[]>([]);
	const [planDataLoading, setPlanDataLoading] = useState(true);
	const [planSelected, setPlanSelected] = useState<any>();
	const [openModal, toggleModal] = useModal();
	const navigationUrl = `${config.publicUrl}/community-partner/${communityPartner?.id}/${communityPartner?.slugUrl}`;

	const { data: subscriptions } = useSubscriptions(
		{
			pageSize: 20,
			pageNumber: 1,
			keyword: '',
			subscriptionPlanType: ''
		},
		{ enabled: !!onSelect }
	);

	const checkButtonCommunityPartner = (id) => {
		let show = true;
		subscriptions?.items?.forEach((item) => {
			if (id === item.communityPartnerInfo.communityPartnerId) {
				show = false;
			}
		});
		return show;
	};

	const getPlanData = async () => {
		if (communityPartner) {
			await sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(planData) => {
					setPlanData(planData);
					setPlanDataLoading(false);
				}
			);
		}
	};

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	useEffect(() => {
		getPlanData();
	}, [communityPartner]);

	const checkActivePlan = () => {
		let flag = true;
		if (planData.length !== 0) {
			planData?.map((plan) => {
				if (plan.active) {
					flag = false;
				}
			});
		}
		return flag;
	};

	return (
		<Card
			className={`${clx(selected && styles.selected)} ${
				!onSelect && styles.notShadow
			}  ${notShadow && styles.notShadow} `}>
			{communityPartner && (
				<>
					<Row>
						<>
							<div className="d-flex flex-wrap align-items-center gap-3">
								<div className={styles.boxLogo}>
									<img
										src={
											communityPartner?.logo?.url ||
											'/assets/img/default-image.png'
										}
										alt={communityPartner?.businessName}
										className={styles.logoWrapper}
									/>
								</div>
								<div className="w-60">
									<div className="d-flex flex-wrap align-items-center gap-3 mb-3 mt-0">
										<div className="d-flex flex-column">
											<h4 className="fs-base lh-base mb-0 pb-0">
												<a
													target="_blank"
													rel="noopener noreferrer"
													className="d-inline-flex"
													href={navigationUrl}>
													{
														communityPartner?.businessName
													}
												</a>
											</h4>
											{communityPartner?.slogan && (
												<span className="text-gray-3 fs-small lh-md my-0 py-0">
													{communityPartner?.slogan}
												</span>
											)}
										</div>
									</div>
									{communityPartner?.commissionGroupInfo && (
										<div className="mb-2 d-flex">
											{communityPartner
												?.commissionGroupInfo
												?.isPremium ? (
												<Badge color={Colors.premium}>
													<FontAwesome
														icon={'medal'}
														size="lg"
														className="me-2"
													/>
													{
														communityPartner
															?.commissionGroupInfo
															?.premiumLabel
													}
												</Badge>
											) : (
												<Badge
													color={Colors.association}>
													{t(
														'badge.standard-partner'
													)}
												</Badge>
											)}
										</div>
									)}
									{communityPartner?.regionCode && (
										<div className="d-flex align-items-center mb-2">
											<FontAwesome
												icon="location"
												size="1x"
												className="text-primary me-2"
											/>
											<span className="text-gray-3 fw-400 fs-sm lh-base">
												{`${t('card.region')} ${
													communityPartner?.regionCode
												}XXX`}
											</span>
										</div>
									)}
									<div>
										{communityPartner?.address && (
											<div className="text-gray-3 fw-400 fs-sm d-flex flex-wrap lh-base mb-0">
												<FontAwesome
													icon="location-dot"
													size="1x"
													className="text-primary me-2"
												/>
												{formatLocalAddress(
													communityPartner?.address
														?.street,
													communityPartner?.address
														?.houseNumber,
													communityPartner?.address
														?.postalCode,
													communityPartner?.address
														?.city,
													communityPartner?.address
														?.state
												)}
											</div>
										)}
									</div>
								</div>
							</div>
							{showDescription && (
								<div className="mb-3 mt-3">
									{communityPartner?.description && (
										<div
											className="lh-md"
											style={{ whiteSpace: 'pre-wrap' }}
											dangerouslySetInnerHTML={{
												__html: communityPartner?.description
											}}
										/>
									)}
								</div>
							)}
						</>
						{!planDataLoading &&
							onSelect &&
							planData &&
							planData?.length > 0 && (
								<div className="d-flex flex-wrap align-items-center gap-3 mb-3">
									<span>{t('text.available-plans')}:</span>
									{planData?.map((plan) => {
										if (plan.active) {
											return (
												<div
													key={plan.id}
													onClick={() => {
														setPlanSelected({
															...plan,
															communityPartnerId:
																communityPartner?.id
														});
														toggleModal();
													}}
													className={`d-flex align-items-end justify-content-between gap-1 cursor-pointer ${
														plan.type === 0
															? styles.boxPlans
															: styles.boxPlansPremium
													}`}>
													<div className="d-flex flex-column gap-1">
														<span className="text-wrap">
															{plan?.name}
														</span>
														<span>
															{currencyFormatter(
																plan?.price ?? 0
															)}
														</span>
													</div>
													<div>
														<FontAwesome
															icon={'info-circle'}
															color="#4C5C84"
															size="1x"
														/>
													</div>
												</div>
											);
										}
									})}
								</div>
							)}
						{planDataLoading && onSelect && (
							<div className="d-flex align-items-center gap-3 mb-3">
								<span>{t('text.available-plans')}:</span>
								{[1, 2, 3].map((_item, index) => {
									return (
										<div
											key={index}
											className={`d-flex align-items-end justify-content-between gap-1 ${styles.boxPlansSkeleton}`}></div>
									);
								})}
							</div>
						)}
					</Row>
					{!communityPartner?.isAutoApprove && (
						<div className='d-flex justify-content-start'>
							<Badge color={Colors['warning']}>
								<FontAwesome icon={'info-circle'} size="1x" />
								<span className="ms-1">
									{t('badge.no-approval')}
								</span>
							</Badge>
						</div>
					)}
					{onSelect && (
						<div className="mt-3 d-flex justify-content-between">
							<div>
								<a
									target="_blank"
									rel="noreferrer noopener"
									href={navigationUrl}
									className={clx(
										styles.more,
										'w-100 h-100 w-lg-auto'
									)}>
									{t('button.more-about-agent')}
								</a>
							</div>
							{checkButtonCommunityPartner(
								communityPartner.id
							) ? (
								<div>
									<Button
										disabled={
											planDataLoading || checkActivePlan()
										}
										color={Colors.secondary}
										className="w-100 h-100"
										onClick={() =>
											onSelect(communityPartner)
										}>
										{t('button.choose-community-partner')}
									</Button>
								</div>
							) : (
								<Button
									as="a"
									to="/subscriptions"
									color={Colors['white-primary']}>
									{t('button.go-to-my-subscription-list')}
								</Button>
							)}
						</div>
					)}
					{!checkButtonCommunityPartner(communityPartner.id) &&
						onSelect && (
							<Row>
								<Col className="d-flex align-items-center  justify-content-between gap-2 mt-3">
									<div
										className="text-warning d-flex gap-2 align-items-center w-60"
										style={{
											padding: '9px 8px',
											borderRadius: '8px',
											background:
												'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
										}}>
										<FontAwesome icon="circle-info" />
										<span>
											{t(
												'text.this-community-partner-is-already-on-your-subscription-list'
											)}
										</span>
									</div>
								</Col>
							</Row>
						)}
				</>
			)}
			{!communityPartner?.businessName && (
				<Row className="position-relative">
					<SkeletonWrapper
						skeleton={Skeleton}
						count={1}
						wrapperClassName="row"
					/>
				</Row>
			)}
			<ModalBoxPlanInf
				nextUrl={
					!checkButtonCommunityPartner(communityPartner?.id) &&
					onSelect
						? ''
						: `/subscriptions/add?step=step3&communityPartnerId=${
								communityPartner?.id
						  }&planId=${planSelected?.id as any}&planType=${
								planSelected?.type
						  }`
				}
				isOpen={openModal}
				onClose={toggleModal}
				plan={planSelected as SubscriptionPlan}
			/>
		</Card>
	);
};
