import { startTransition, useState } from 'react';
import * as yup from 'yup';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api } from 'Utils';
import { names } from 'Constants';
import { usePrompt } from 'Hooks';
import { Media, Department, AspectRatio } from 'Types';
import {
	Card,
	FormSuccess,
	SubmitButtonGroup,
	SwitchController,
	AvatarController
} from 'Elements';

export const CreateDepartment = () => {
	const defaultImage = {
		url: '',
		thumbnailUrl: ''
	};
	const { departments } = names;
	const { t } = useTranslation();
	const [image, setImage] = useState<Partial<Media>>(defaultImage);
	const [formSuccess, setFormSuccess] = useState(false);

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.title.required'))
	});

	const defaultValues: Partial<Department> = {
		image: {
			url: '',
			thumbnail: ''
		},
		title: '',
		isActive: false,
		description: ''
	};

	const {
		reset,
		register,
		control,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<Partial<Department>>({
		resolver: yupResolver(schema),
		defaultValues
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
		setImage(defaultImage);
	};

	const handleImage = (image_data: Partial<Media>) => {
		setImage(image_data);
	};

	const submitRequest: SubmitHandler<Partial<Department>> = async (data) => {
		const api_data = {
			...data,
			isActive: data?.isActive ? data?.isActive : false,
			image: image.absoluteUri
				? {
						url: image.absoluteUri,
						thumbnail: image.thumbnailUrl
				  }
				: null
		};
		await axios.post(api.createDepartment, api_data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					setFormSuccess(true);
					resetForm();
				});
			}
		});
	};

	const submitHandler: SubmitHandler<Partial<Department>> = async (data) => {
		await submitRequest(data);
	};

	return (
		<Card>
			{!formSuccess && (
				<Row>
					<Col md={6} xxl={5}>
						<FormGroup>
							<AvatarController
								name="image"
								aspectRatio={AspectRatio['16/9']}
								control={control}
								image={image.absoluteUri}
								onRemove={handleImage}
								onUpload={handleImage}
								defaultImage="/assets/img/business-partner-default.png"
							/>
						</FormGroup>
					</Col>
					<Col md={6} xxl={5}>
						<form onSubmit={handleSubmit(submitHandler)} noValidate>
							<Row>
								<Col>
									<FormGroup>
										<Label htmlFor="title">
											{t('forms.title')}
											<small className="ms-1">
												({t('validation.required')})
											</small>
										</Label>
										<input
											{...register('title')}
											type="text"
											id="title"
											aria-invalid={!!errors.title}
											className="inputbox w-100"
										/>
										{errors.title && (
											<div className="invalid-feedback d-block">
												{errors.title.message}
											</div>
										)}
									</FormGroup>
									<FormGroup className="position-relative">
										<Label htmlFor="description">
											{t('forms.agent.department-desc')}
										</Label>
										<textarea
											{...register('description')}
											id="description"
											className="inputbox w-100"
											cols={10}
											rows={5}
										/>
									</FormGroup>
									<FormGroup className="position-relative">
										<Label htmlFor="sortOrder">
											{t('forms.sort-order')}
										</Label>
										<input
											{...register('sortOrder')}
											type="text"
											id="sortOrder"
											defaultValue={1}
											placeholder={t(
												'placeholder.sort-order'
											)}
											className="inputbox w-100"
										/>
									</FormGroup>
									<FormGroup>
										<SwitchController
											name="isActive"
											control={control}
											label="forms.show-in-home"
										/>
									</FormGroup>
									<SubmitButtonGroup
										label="button.create-department"
										savingLabel=""
										cancelButton="/departments"
										isDisable={!isDirty}
										isSubmitting={isSubmitting}
									/>
								</Col>
							</Row>
						</form>
					</Col>
				</Row>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.add-departments"
					listTitle="button.department-list"
					successTitle="forms.department.created"
					className="flex-grow-1 align-self-center"
					listUrl={departments.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
