import { FC, memo } from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { clx, getCurrentLocale } from 'Utils';
import { Button, FontAwesome } from 'Elements';
import { Benefit, ColorValues, Colors } from 'Types';
import styles from 'partials/homepage/card/benefit-card.module.scss';
import { useUpdateBenefits } from 'src/hooks/useBenefit';

interface Props {
	benefit: Benefit;
	isRemoving: boolean;
	onClick: () => void;
}

const Icon = styled.div`
	${({ theme }) =>
		theme.businessPartner.primaryColor &&
		` border-color: ${theme.businessPartner.primaryColor}
	`};
`;

export const ProfileBenefitCard: FC<Props> = memo(
	({ benefit, isRemoving, onClick }) => {
		const { benefits } = useUpdateBenefits();

		const getTitle = (id: any) => {
			let title = '';
			benefits?.items?.forEach((element) => {
				if (id === element.id) {
					title = element.title;
					element.translateTitles.find((item) => {
						if (
							item?.language.toLowerCase() ===
							getCurrentLocale().code.toLowerCase()
						) {
							title = item.value
								? item.value
								: element.translateTitles[0].value ??
								  element.title;
						}
					});
					if (title === '') {
						title = element.title;
					}
				}
			});
			return title;
		};

		return (
			<Col sm={6} lg={4} xxl={3} className={styles.borderBox}>
				<div
					role="button"
					className={clx(
						!isRemoving ? styles.box : styles.boxDisabled,
						'd-flex flex-column flex-md-row gap-2 align-items-center justify-content-center'
					)}
					onClick={onClick}>
					<Icon className={styles.icon}>
						<FontAwesome icon="check" size="2x" />
					</Icon>
					<h6 className="fs-sm fw-400 mb-0 me-3 text-center text-gray-5 lh-base flex-grow-1 text-break">
						{getTitle(benefit.id)}
					</h6>
					{!isRemoving && (
						<div className="edit-overlay">
							<Button
								color={Colors.empty}
								disabled={isRemoving}
								className="edit-overlay-icon">
								<FontAwesome
									icon="trash-xmark"
									size="xl"
									color={ColorValues.red}
								/>
							</Button>
						</div>
					)}
				</div>
			</Col>
		);
	}
);
ProfileBenefitCard.displayName = 'ProfileBenefitCard';
