import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api, fetch, toastify } from 'Utils';
import { queryKey } from 'Constants';
import { Branch, DataList, DataQueryParams } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: true },
		...options
	};
};

export const useBranches = <T = DataList<Branch>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Branch>>
): UseQueryResult<DataList<Branch>, unknown> => {
	return useQuery({
		queryKey: [queryKey.branches, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.branchList(pageSize, pageNumber, keyword),
		...config(options)
	});
};



export const useBranch = <T = Branch>(
	branchId: string,
	options?: UseQueryOptions<T, any, Branch>
): UseQueryResult<Branch, unknown> => {
	return useQuery({
		queryKey: [queryKey.branch, { id: branchId }],
		queryFn: () => fetch.branchDetails(branchId),
		...config(options)
	});
};

export const useUpdateBranch = (branchId: string) => {
	const { refetch: refetchList } = useBranches({});
	const query = useBranch(branchId);
	const branch = query.data;

	const updateBranch: (
		fields: Partial<Branch>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...branch,
			...fields,
			isActive: true
		};
		await axios
			.put(api.updateBranchDetail(branchId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.branch-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const removeBranch: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios.delete(api.removeBranch(branchId)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.branch-removed', {
						type: 'success'
					});
					refetchList();
				});
			}
		});
	};

	return {
		...query,
		updateBranch,
		removeBranch,
		branch
	};
};
