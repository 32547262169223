import { startTransition, useState } from 'react';
import * as yup from 'yup';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api } from 'Utils';
import { usePrompt } from 'Hooks';
import { AspectRatio, Media, Member } from 'Types';
import {
	Card,
	FormSuccess,
	SubmitButtonGroup,
	SwitchController,
	AvatarController
} from 'Elements';

export const CreateMember = () => {
	const defaultImage = {
		absoluteUri: '',
		thumbnailUrl: ''
	};
	const descriptionLimit = 270;
	const { t } = useTranslation();
	const [image, setImage] = useState<Partial<Media>>(defaultImage);
	const [formSuccess, setFormSuccess] = useState(false);

	const schema = yup.object().shape({
		fullName: yup.string().required(t('validation.fullName.required'))
	});

	const defaultValues: Partial<Member> = {
		image: null,
		fullName: '',
		email: '',
		jobPosition: '',
		phoneNumber: '',
		isActive: false,
		description: ''
	};

	const {
		reset,
		watch,
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<Partial<Member>>({
		resolver: yupResolver(schema),
		defaultValues
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		setImage(defaultImage);
		reset(defaultValues);
	};

	const handleImage = (image_data: Partial<Media>) => {
		setImage(image_data);
		setValue(
			'image',
			{
				url: image_data.absoluteUri ?? '',
				thumbnail: image_data.thumbnailUrl ?? ''
			},

			{ shouldDirty: true }
		);
	};

	const submitRequest: SubmitHandler<Partial<Member>> = async (data) => {
		const api_data = {
			...data,
			isActive: true,
			showInHomePage: data?.showInHomePage ? data?.showInHomePage : false,
			image:
				image.absoluteUri === ''
					? null
					: {
							url: image?.absoluteUri,
							thumbnail: image?.thumbnailUrl
					  }
		};
		await axios.post(api.createMember, api_data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					setFormSuccess(true);
					resetForm();
				});
			}
		});
	};

	const submitHandler: SubmitHandler<Partial<Member>> = async (data) => {
		await submitRequest(data);
	};

	return (
		<Card>
			{!formSuccess && (
				<Row>
					<Col sm={5} xl={4}>
						<FormGroup>
							<AvatarController
								name="image"
								aspectRatio={AspectRatio['3/4']}
								control={control}
								image={image.absoluteUri}
								onRemove={handleImage}
								onUpload={handleImage}
							/>
						</FormGroup>
					</Col>
					<Col sm={7} xl={8}>
						<form onSubmit={handleSubmit(submitHandler)} noValidate>
							<Row>
								<Col xl={9} xxl={7}>
									<FormGroup>
										<Label htmlFor="fullName">
											{t('forms.fullName')}
											<small className="ms-1">
												({t('validation.required')})
											</small>
										</Label>
										<input
											{...register('fullName')}
											type="text"
											id="fullName"
											aria-invalid={!!errors.fullName}
											className="inputbox w-100"
										/>
										{errors.fullName && (
											<div className="invalid-feedback d-block">
												{errors.fullName.message}
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<Label htmlFor="email">
											{t('forms.email')}
										</Label>
										<input
											{...register('email')}
											type="email"
											id="email"
											className="inputbox w-100"
										/>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="jobPosition">
											{t('forms.position')}
										</Label>
										<input
											{...register('jobPosition')}
											type="text"
											id="jobPosition"
											className="inputbox w-100"
										/>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="phoneNumber">
											{t('forms.phone-number')}
										</Label>
										<input
											{...register('phoneNumber')}
											type="tel"
											inputMode="tel"
											id="phoneNumber"
											className="inputbox w-100"
										/>
									</FormGroup>
									<FormGroup className="position-relative">
										<Label htmlFor="description">
											{t('forms.agent.member-desc')}
										</Label>
										<textarea
											{...register('description', {
												maxLength: descriptionLimit
											})}
											id="description"
											className="inputbox w-100"
											cols={10}
											rows={5}
											aria-invalid={!!errors.description}
											maxLength={descriptionLimit}
										/>
										<div className="position-absolute end-0">
											<small className="ms-auto mt-1">
												{watch('description')?.length ||
													0}
												/{descriptionLimit}
											</small>
										</div>
									</FormGroup>
									<FormGroup>
										<SwitchController
											name="showInHomePage"
											control={control}
											label="forms.show-in-home"
										/>
									</FormGroup>
									<SubmitButtonGroup
										cancelButton="/members"
										isDisable={!isDirty}
										isSubmitting={isSubmitting}
									/>
								</Col>
							</Row>
						</form>
					</Col>
				</Row>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.add-member"
					listTitle="button.member-list"
					successTitle="forms.member-created"
					className="flex-grow-1 align-self-center"
					listUrl="/members"
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
