import { t } from 'i18next';
import { AxiosError } from 'axios';
import { Error } from 'Types';
import { toastify } from 'Utils';

const errorCode = [
	'1000',
	'1001',
	'1002',
	'1100',
	'1101',
	'1102',
	'1103',
	'1104',
	'1105',
	'1106',
	'1107',
	'1108',
	'1109',
	'1110',
	'1111',
	'1112',
	'1200',
	'1201',
	'1202',
	'1203',
	'1204',
	'1205',
	'1206',
	'1207',
	'1208',
	'1300',
	'1400',
	'1401',
	'1402',
	'1403',
	'1404',
	'1405',
	'1500',
	'1501',
	'1502',
	'1503',
	'1504',
	'1505',
	'1506',
	'1507',
	'1508',
	'1509',
	'1600',
	'1601',
	'1602',
	'1603',
	'1604',
	'1605',
	'1606',
	'1607',
	'1608',
	'1609',
	'1610',
	'1611',
	'1612',
	'1700',
	'1701',
	'1800',
	'1801',
	'1802',
	'1803',
	'1804',
	'1805',
	'1806',
	'1807',
	'1808',
	'1809',
	'1810',
	'1811',
	'1812',
	'1813',
	'1814',
	'1815',
	'1816',
	'1817',
	'1818',
	'1819',
	'1820',
	'1821',
	'1822',
	'1823',
	'1824',
	'1825',
	'1826',
	'1827',
	'1828',
	'1829',
	'1830',
	'1831',
	'1832',
	'1833',
	'1834',
	'1900',
	'1901',
	'2000',
	'2001',
	'2002',
	'2100',
	'2101',
	'2102',
	'2103',
	'2104',
	'2105',
	'2106',
	'2200',
	'2201',
	'2202',
	'2203',
	'2204',
	'2205',
	'2300',
	'2301',
	'2302',
	'2303',
	'2304',
	'2400',
	'2401',
	'2500',
	'2600',
	'2700',
	'2800',
	'2801',
	'2900',
	'2901',
	'3000',
	'3100',
	'3101',
	'3200',
	'3300',
	'3301',
	'J1000',
	'J1001',
	'J1002',
	'J1003',
	'J1004',
	'J1005',
	'J1006',
	'J1007',
	'J2000',
	'J2001',
	'J3000',
	'J3001',
	'J4000',
	'J5000',
	'J5001',
	'J6000',
	'J7000'
];

/**
 * Displays an error notification based on the given Axios error.
 * @param error - The Axios error object.
 */
export const ErrorNotification = (error: AxiosError<Error>) => {
	let message = 'global.an-error-occurred';
	const errorData = error?.response?.data;
	const errorDetail = errorData?.detail as string;

	if (errorCode.includes(errorDetail)) {
		message = `error.${errorDetail}`;
	} else {
		switch (errorData?.status || error.status) {
			case 403:
				message = 'error.403.title';
				break;
			case 409:
			case 404:
				message = errorData?.detail
					? `error.${errorData?.detail}`
					: errorData?.title
					? `${errorData?.title}`
					: 'global.an-error-occurred';
				break;
			case 400:
			case 500:
				message =
					errorData?.title ||
					errorData?.detail ||
					'global.an-error-occurred';
				break;
			default:
				break;
		}
	}
	toastify(t(message));
};
