import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';

import {
	Button,
	Collapsible,
	FontAwesome,
	LoadingSpinner,
	ModalBoxSectionEditProfile,
	ProfileAddNewCard,
	ProfileEventCard,
	ReactSwitch,
	SectionHeadline
} from 'Elements';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';
import { names } from 'Constants';
import { useModal } from 'src/hooks/useModal';
import { AgentEvent, Colors } from 'Types';
import { useEvents } from 'src/hooks/useEvent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	events: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerEventSection = ({
	isLoadingProfile,
	onChangeValue,
	events
}: Props) => {
	const { t } = useTranslation();
	const { events: eventUrl } = names;
	const navigate = useNavigate();
	const [openModal, toggleModal] = useModal();
	const addInternshipUrl = eventUrl.add?.path as string;
	const perRow = 8;
	const [next, setNext] = useState(perRow);

	const { data, isLoading } = useEvents({
		status: 1,
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	const handleNavigate = () => {
		navigate(addInternshipUrl, { state: { prevPath: '/homepage' } });
	};

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && events && !isLoading && (
					<div className="position-relative">
						<Collapsible
							header={
								<div
									className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
									<div className="d-flex flex-wrap align-items-center gap-3 ">
										<h5 className="fs-5 fw-700 d-flex align-items-center">
											{' '}
											{t('menu.events')}
										</h5>
										<ReactSwitch
											size="md"
											checked={events?.enabled}
											onChange={(val: boolean) => {
												onChangeValue(
													'events',
													'enabled',
													val
												);
											}}
										/>
										<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
											{t('forms.show-events-in-home')}
										</span>
									</div>
									<div>
										<Button
											size="sm"
											color={Colors['white-gray']}
											onClick={() => toggleModal()}>
											<FontAwesome
												icon="circle-info"
												size="1x"
												className="text-gray me-1"
											/>
											{t('button.more')}
										</Button>
									</div>
								</div>
							}>
							<SectionHeadline
								title={events?.headline}
								titlePlaceholder={`[${t(
									'placeholder.our-events'
								)}]`}
								titleColor="text-gray-5"
								className="mx-auto text-center"
								onUpdateTitle={(val) =>
									onChangeValue('events', 'headline', val)
								}
							/>
							{data && data?.totalItems > 0 && (
								<Row className="justify-content-center mt-3">
									{data?.items.map((event) => (
										<ProfileEventCard
											preview={false}
											key={event.id}
											card={event as AgentEvent}
										/>
									))}
								</Row>
							)}
							<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
								{data?.items && data?.items?.length > next && (
									<Button
										size="lg"
										color={Colors['border-gray-4']}
										label={
											t('button.more-partners') as string
										}
										className="rounded-pill"
										onClick={handleMore}
									/>
								)}
								{data && data?.items?.length > 0 && (
									<Button
										className="gap-3 rounded-pill"
										onClick={handleNavigate}>
										<FontAwesome
											icon="plus"
											size="lg"
											swapOpacity
										/>
										{t('title.add-new-event')}
									</Button>
								)}
							</div>
							{events && data && data?.items?.length === 0 && (
								<div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
									<Button
										size="lg"
										className="gap-3 rounded-pill"
										onClick={handleNavigate}>
										<FontAwesome
											icon="plus"
											size="xl"
											swapOpacity
										/>
										{t('title.add-new-event')}
									</Button>
								</div>
							)}
						</Collapsible>
					</div>
				)}
				{!events?.enabled && (
					<div className="overlay overlay_white overlay-box" />
				)}

				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.events-title"
					description="info.events-description"
				/>
			</Container>
		</section>
	);
};
