import { memo, FC, useMemo, useState, useEffect, useCallback } from 'react';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useOutletContext } from 'react-router-dom';
import { getCountries } from 'Data';
import { formatLocalAddress, getCurrentLocale, formatTimeToCET } from 'Utils';
import {
	AgentEvent,
	EventFormGeneral,
	EventType,
	GeoAddress,
	RadioOption
} from 'Types';
import {
	RadioController,
	RichTextController,
	AddressAutoSuggest,
	Card,
	EventDatePicker,
	FormInlineTip,
	SwitchController
} from 'Elements';

export const UpdateEventGeneral: FC = memo(() => {
	const { t } = useTranslation();
	const required_message = 'validation.required';

	const descriptionLimit = 450;
	const [startDateCET, setStartCET] = useState('');
	const [endDateCET, setEndCET] = useState('');
	const fields = useOutletContext<AgentEvent>();
	const currentLocaleLang = getCurrentLocale().lang;

	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};

	const {
		watch,
		control,
		getValues,
		setValue,
		register,
		clearErrors,
		formState: { errors }
	} = useFormContext<EventFormGeneral>();
	const countries = useMemo(() => getCountries(), []);

	const getEventType: () => RadioOption[] = () => {
		return [
			{
				label: t('forms.event.online'),
				value: EventType.Online
			},
			{
				label: t('forms.event.in-person'),
				value: EventType.InPerson
			},
			{
				label: t('forms.event.hybrid'),
				value: EventType.Hybrid
			}
		];
	};

	const getAddressFormat = () => {
		const {
			street = '',
			houseNo = '',
			postalCode = '',
			city = '',
			state = ''
		} = fields?.address || {};

		return fields?.address
			? formatLocalAddress(street, houseNo, postalCode, city, state)
			: '';
	};

	const filterPassedTime = (time: Date, type: 'start' | 'end') => {
		const currentDate =
			type === 'end'
				? getValues('startDate')
					? new Date(getValues('startDate'))
					: new Date()
				: new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const onStartDateChange = (val: Date) => {
		if (val) {
			const startDate = val;
			setStartCET(formatTimeToCET(startDate));
			const endDate = new Date(getValues('endDate'));
			if (endDate && isAfter(startDate, endDate)) {
				setValue('endDate', '');
			}
		} else {
			setStartCET('');
		}
	};

	const onEndDateChange = (val: Date) => {
		setEndCET(val ? formatTimeToCET(val) : '');
	};

	const onSelectAddress = (selected_address: GeoAddress) => {
		const { address, position } = selected_address;

		const selected_country = countries.find(
			(country) =>
				country.value.toString().toLowerCase() ===
				address?.countryCode.toLowerCase()
		);
		const selected_state = selected_country?.states?.find(
			(state) => state.label === address?.countrySubdivision
		);
		setValue(
			'address.street',
			address?.street ?? address?.streetName ?? '',
			valueConfig
		);
		setValue('address.houseNo', address?.streetNumber ?? '', valueConfig);
		setValue('address.postalCode', address?.postalCode ?? '', valueConfig);
		setValue('address.city', address?.municipality ?? '', valueConfig);
		setValue(
			'address.country',
			(selected_country?.value as string) ?? '',
			valueConfig
		);
		setValue(
			'address.state',
			(selected_state?.value as string) ?? '',
			valueConfig
		);
		setValue('address.location.latitude', position.lat ?? 0, valueConfig);
		setValue('address.location.longitude', position.lon ?? 0, valueConfig);
		setValue('address.countryName', address?.country ?? '', valueConfig);
		setValue('address.countryIso2', address?.countryCode ?? '', valueConfig);
		setValue('address.countryIso3', address?.countryCodeISO3 ?? '', valueConfig);

		clearErrors('address');
	};

	const handleStartDateChange = useCallback(() => {
		const startDate = watch('startDate') || fields?.startDate;
		const startCET = formatTimeToCET(startDate);
		setStartCET(startCET);
	}, [fields?.startDate, watch]);

	const handleEndDateChange = useCallback(() => {
		const endDate = watch('endDate') || fields?.endDate;
		const endCET = formatTimeToCET(endDate);
		setEndCET(endCET);
	}, [fields?.endDate, watch]);

	useEffect(() => {
		if (fields) {
			handleStartDateChange();
			handleEndDateChange();
		}
	}, [fields, currentLocaleLang, handleStartDateChange, handleEndDateChange]);

	return (
		<Card className="mb-4">
			<Row>
				<Col md={8} xxl={6}>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.event.title')}
							<small className="ms-1">
								({t(required_message)})
							</small>
						</Label>
						<input
							{...register('title')}
							type="text"
							id="title"
							aria-invalid={!!errors.title}
							className="inputbox w-100"
						/>
						{errors.title && (
							<div className="invalid-feedback d-block">
								{errors.title.message}
							</div>
						)}
					</FormGroup>
					<FormGroup className="position-relative">
						<Label htmlFor="shortDescription">
							{t('forms.event.short-desc')}
						</Label>
						<textarea
							{...register('shortDescription', {
								maxLength: descriptionLimit
							})}
							maxLength={descriptionLimit}
							id="shortDescription"
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
						<div className="position-absolute end-0">
							<small className="ms-auto mt-1">
								{watch('shortDescription')?.length || 0}/
								{descriptionLimit}
							</small>
						</div>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="description">
							{t('forms.event.description')}
						</Label>
						<RichTextController
							control={control}
							name="description"
							hasImage={false}
							defaultValue={fields?.description}
						/>
					</FormGroup>
					<Row>
						<Col sm={6} lg={12} xl={6}>
							<FormGroup>
								<Label htmlFor="startDate">
									{t('forms.start-date')}
									<small className="ms-1">
										({t(required_message)})
									</small>
								</Label>
								<EventDatePicker
									name="startDate"
									control={control}
									error={errors.startDate}
									defaultSelected={fields?.startDate}
									filterTime={(time: Date) =>
										filterPassedTime(time, 'start')
									}
									onDateChange={onStartDateChange}
								/>
								{startDateCET && (
									<div className="text-gray-3 fs-small mt-2">
										{startDateCET}
									</div>
								)}
							</FormGroup>
						</Col>
						<Col sm={6} lg={12} xl={6}>
							<FormGroup>
								<Label htmlFor="endDate">
									{t('forms.end-date')}
									<small className="ms-1">
										({t(required_message)})
									</small>
								</Label>
								<EventDatePicker
									name="endDate"
									control={control}
									error={errors.endDate}
									defaultSelected={fields?.endDate}
									minDate={
										watch('startDate')
											? new Date(watch('startDate'))
											: new Date()
									}
									filterTime={(time: Date) =>
										filterPassedTime(time, 'end')
									}
									onDateChange={onEndDateChange}
								/>
								{endDateCET && (
									<div className="text-gray-3 fs-small mt-2">
										{endDateCET}
									</div>
								)}
							</FormGroup>
						</Col>
						<FormInlineTip tip="forms.event.date-info" />
					</Row>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.event.event-type')}
							<small className="ms-1">
								({t(required_message)})
							</small>
						</Label>
						<RadioController
							control={control}
							name="eventType"
							option={getEventType()}
							boxClassName="pt-1"
							radioClassName="d-flex gap-3 pb-1"
							error={errors.eventType}
						/>
					</FormGroup>
					{watch('eventType') !== EventType.Online && (
						<FormGroup>
							<Label htmlFor="address">
								{t('forms.event.address')}
							</Label>
							<AddressAutoSuggest
								defaultQuery={getAddressFormat()}
								onSelectSuggestion={onSelectAddress}
							/>
							{errors.address && (
								<div className="invalid-feedback d-block">
									{errors.address.message}
								</div>
							)}
						</FormGroup>
					)}
					{watch('eventType') !== EventType.InPerson && (
						<FormGroup>
							<Label htmlFor="meetingLink">
								{t('forms.event.url')}
							</Label>
							<input
								{...register('meetingLink')}
								type="text"
								inputMode="url"
								id="meetingLink"
								className="inputbox w-100"
							/>
							{errors.meetingLink && (
								<div className="invalid-feedback d-block">
									{errors.meetingLink.message}
								</div>
							)}
						</FormGroup>
					)}
					<FormGroup>
						<SwitchController
							control={control}
							name="isInternal"
							boxClassName="py-2 mb-2"
							label="forms.event.internal-event"
						/>
						<FormInlineTip tip="forms.event.internal-event-desc" />
					</FormGroup>
				</Col>
			</Row>
		</Card>
	);
});

UpdateEventGeneral.displayName = 'UpdateEventGeneral';
