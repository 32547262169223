import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, LoadingContent, Badge } from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';
import { clx, companyPaymentStatus } from 'Utils';
import { Colors, SubscriptionDetails, SubscriptionPayment } from 'Types';

import { formatLocaleDateTime } from 'src/helpers/DateTime';
import { useSearchParams } from 'react-router-dom';

interface Props {
	subscription?: SubscriptionDetails;
	subscriptionPayments?: SubscriptionPayment;
	isLoading: boolean;
	refetch: () => void;
}

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPaymentInfo: FC<Props> = ({
	subscriptionPayments,
	isLoading,
	refetch
}) => {
	const [searchParams] = useSearchParams();
	const showModal = searchParams.get('showModal') === 'true' ? true : false;
	const { t } = useTranslation();
	const row_class = 'justify-content-between';

	const paymentMethod = subscriptionPayments?.provider?.paymentMethodInfo
		? `${subscriptionPayments.provider.paymentMethodInfo.brand ?? ''} ${
				subscriptionPayments.provider.paymentMethodInfo.type
		  }
		  ${
				subscriptionPayments.provider.paymentMethodInfo.last4
					? `******* ${subscriptionPayments.provider.paymentMethodInfo.last4}`
					: ''
			}
		
		  ${
				subscriptionPayments.provider.paymentMethodInfo.expYear !== '0'
					? ` Exp: ${subscriptionPayments.provider.paymentMethodInfo.expYear}`
					: ''
			}	
		 
		  ${
				subscriptionPayments.provider.paymentMethodInfo.expMonth !== '0'
					? `/ ${subscriptionPayments.provider.paymentMethodInfo.expMonth}`
					: ''
			} `
		: '---';

	const getStatusData = () => {
		return companyPaymentStatus.find(
			(elem) => elem.value === subscriptionPayments?.status
		);
	};

	useEffect(() => {
		if (!showModal) {
			refetch && refetch();
		}
	}, [showModal]);

	return (
		<div>
			<CardBox className="h-100">
				<div className="d-flex mb-3 align-items-center justify-content-between">
					<h6 className="text-gray-3 fs-6 fw-400">
						{t('title.payment-info')}
					</h6>
					<div className="d-flex gap-2 align-items-center">
						<Badge
							color={getStatusData()?.color ?? Colors.muted}
							className="ms-auto">
							{t(`${getStatusData()?.label}`)}
						</Badge>
					</div>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.description-status')}:
					</span>
					<span style={{ textAlign: 'justify' }}>
						{subscriptionPayments?.provider?.errorMessage ??
							subscriptionPayments?.provider?.providerStatus}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.payment-reference')}:
					</span>
					<span>
						{subscriptionPayments?.provider?.paymentReference ||
							'---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.payment-method')}:
					</span>
					<span>
						{
							// paymentMethod
							subscriptionPayments?.provider?.paymentMethodInfo
						}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>{t('label.email')}:</span>
					<span>
						{subscriptionPayments?.invoiceInfo?.emailAddress ||
							'---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('table.created-at')}:
					</span>
					<span>
						{subscriptionPayments?.createdAt
							? formatLocaleDateTime(
									subscriptionPayments?.createdAt || ''
							  )
							: '---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.updated-at')}:
					</span>
					<span>
						{subscriptionPayments?.lastUpdatedAt
							? formatLocaleDateTime(
									subscriptionPayments?.lastUpdatedAt || ''
							  )
							: '---'}
					</span>
				</div>
				{isLoading && <LoadingContent />}
			</CardBox>
		</div>
	);
};
