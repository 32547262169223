import { Suspense, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { names } from 'Constants';
import { clx } from 'Utils';
import { getCountries, getStates } from 'Data';
import { Branch, Colors } from 'Types';
import {
	useDelayUnmount,
	useModal,
	useResponsive,
	useUpdateBranch
} from 'Hooks';
import {
	Button,
	Card,
	LoadingContent,
	ModalBoxDelete,
	SubmitButton,
	BranchDecoration,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/form.module.scss';

export const UpdateBranch = () => {
	const { branches } = names;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { branch_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const {
		data: branch,
		isFetching,
		updateBranch,
		removeBranch
	} = useUpdateBranch(branch_id as string);

	const addressSchema = yup.object().shape({
		country: yup.object().nullable().required('Country is required'),
		state: yup.object().nullable().required('state is required'),
		city: yup.string().required('City is required'),
		postalCode: yup.string().required('Postal Code is required'),
		street: yup.string().required('Street is required'),
		houseNumber: yup.string().required('House Number is required'),
		location: yup.object().shape({
			longitude: yup.number().required('Longitude is required'),
			latitude: yup.number().required('Latitude is required')
		})
	});

	const schema = yup.object({
		name: yup.string().required(t('validation.branch-title.required')),
		address: addressSchema
	});

	const formMethods = useForm<Branch>({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	const {
		reset,
		handleSubmit,
		setValue,
		formState: { isDirty, isSubmitting }
	} = formMethods;

	const defaultCountry = (selected_country?: string) => {
		if (selected_country) {
			return getCountries().find(
				(country) => country.value === selected_country
			);
		}
		return null;
	};

	const defaultState = (selected?: any) => {
		if (selected) {
			return getStates().find((state) => {
				return (
					state.value === selected?.address?.state &&
					state.country === selected?.address?.countryName
				);
			});
		}
		return null;
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeBranch(() => {
				setIsRemoving(false);
				navigate(`${branches.path}`);
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const submitHandler: SubmitHandler<Branch> = async (data) => {
		const api_data = {
			...data,
			address: {
				...data.address,
				countryName: data?.address?.country?.value,
				state: data?.address?.state?.value
			}
		};

		delete api_data?.countryName;
		delete api_data?.state;
		await updateBranch(api_data as any);
	};

	useEffect(() => {
		reset({
			...branch,
			address: {
				...branch?.address,
				country: defaultCountry(branch?.address?.countryName),
				state: defaultState(branch)
			}
		});
	}, [reset, branch]);

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={clx(isDesktopAndBelow && styles.form)}>
				<BranchDecoration
					isFetching={isFetching}
					branch={branch}
					setValue={setValue}
				/>
				<Suspense fallback={<LoadingContent />}>
					<Card className={clx(isDesktopAndBelow && 'mb-4')}>
						<Row>
							<Col md={8} xxl={6}>
								<Outlet context={branch} />
								{isFetching && <LoadingContent />}
							</Col>
						</Row>
					</Card>
					<Card
						className={clx(
							isDesktopAndBelow && styles.sticky_action
						)}>
						<div className="d-flex align-items-center justify-content-end gap-2">
							<Button
								color={Colors['white-red']}
								className="me-auto"
								onClick={toggleModal}>
								<FontAwesome
									icon="trash-xmark"
									size="lg"
									className="me-2"
								/>
								{t('button.remove')}
							</Button>

							<SubmitButton
								cancelButton="/branches"
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</div>
					</Card>
				</Suspense>
			</form>
			{shouldRenderModal && (
				<ModalBoxDelete
					isOpen={openModal}
					onClose={toggleModal}
					onRemove={handleRemoveUser}
					isRemoving={isRemoving}
					title={name ?? '---'}
				/>
			)}
		</FormProvider>
	);
};
