import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Link, useParams } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { CheckboxController, FontAwesome } from 'src/containers/Elements';
import { clx } from 'src/helpers/Utils';
import { Colors } from 'src/types/Color';
import { JobKind, JobUsage } from 'src/types/Job';
import styles from './job-partner.module.scss';
import { Subscription, SubscriptionStatus } from 'src/types/Subscription';
import { useTranslation } from 'react-i18next';
import { useUpdateJob } from 'src/hooks/useJob';

interface Props {
	subscription: Subscription;
	jobUsage: JobUsage[] | undefined;
}

const PartnerCard: FC<Props> = ({ subscription, jobUsage }) => {
	const { t } = useTranslation();
	const {
		watch,
		getValues,
		setValue,
		formState: { isDirty }
	} = useFormContext();

	const defaultImage = '/assets/img/default-image.png';
	const isChecked = watch('jobVisibilities')?.some(
		(v) => v.subscriptionId === subscription.id
	);
	const notActive = subscription.status !== SubscriptionStatus.active;

	const isExpired = subscription.status === SubscriptionStatus.expired;
	const { job_id } = useParams();
	const { data: jobData } = useUpdateJob(job_id as string);

	const handelSelectPartner = (subscription: Subscription) => {
		const jobVisibilities = getValues('jobVisibilities') || [];

		const setJobVisibilities = jobVisibilities.some(
			(v) => v.subscriptionId === subscription.id
		)
			? jobVisibilities.filter(
					(v) => v.subscriptionId !== subscription.id
			  )
			: [
					...jobVisibilities,
					{
						communityPartnerId: subscription?.communityPartnerInfo
							.communityPartnerId as string,
						subscriptionId: subscription?.id
					}
			  ];
		setValue('jobVisibilities', setJobVisibilities, {
			shouldDirty: true
		});
	};

	const getJobCapacity = useMemo(() => {
		return (kind: JobKind) => {
			if (jobUsage) {
				return jobUsage.find(
					(job) =>
						job.subscriptionId === subscription.id &&
						job.kind === kind
				);
			}
		};
	}, [jobUsage, subscription.id]);

	const jobCapacity = getJobCapacity(JobKind.Job);
	const internshipCapacity = getJobCapacity(JobKind.Internship);

	const hasReachedCapacity = (isChecked: boolean) => {
		const jobKind = getValues('kind');
		if (
			jobKind === JobKind.Job &&
			jobCapacity?.count ===
				Number(
					subscription.subscriptionPlanFeatureInfo?.jobSetting
						?.maxPublishJobs
				) &&
			!isChecked &&
			!jobData?.jobVisibilities?.some(
				(v) => v.subscriptionId === subscription.id
			)
		) {
			return true;
		}
		if (
			jobKind === JobKind.Internship &&
			internshipCapacity?.count ===
				Number(
					subscription.subscriptionPlanFeatureInfo?.internshipSetting
						?.maxPublishInternships
				) &&
			!isChecked &&
			!jobData?.jobVisibilities?.some(
				(v) => v.subscriptionId === subscription.id
			)
		) {
			return true;
		}
	};

	return (
		<div
			onClick={() =>
				!notActive &&
				!hasReachedCapacity(isChecked) &&
				handelSelectPartner(subscription)
			}
			key={subscription.id}
			className={clx(
				styles['premium-card'],
				isChecked && styles['premium-card--checked'],
				(notActive || hasReachedCapacity(isChecked)) &&
					styles['premium-card--disabled']
			)}>
			<div className="d-flex justify-content-center align-items-center gap-2 ">
				<div className="flex-10.5 d-flex justify-content-center align-items-center">
					{!notActive && !hasReachedCapacity(isChecked) && (
						<div>
							<CheckboxController
								isChecked={isChecked}
								name="showDetails"
								id="showDetails"
								label="  "
							/>
						</div>
					)}
					<img
						src={defaultImage}
						alt="Logo"
						className={styles['premium-logo']}
					/>
				</div>

				<div className="flex-1 d-flex  gap-1 flex-column">
					<span className="fw-semibold">
						{subscription.communityPartnerInfo.organizationName}
					</span>
					<span className="fw-light">{subscription.description}</span>
				</div>
			</div>
			<div className="d-flex gap-2">
				<span>
					<FontAwesome
						icon="check"
						className="text-primary"
						size="1x"
					/>
				</span>
				<span>{t('label.your-job-usage')}:</span>
				<span>{`${jobCapacity?.count || 0}/${
					subscription.subscriptionPlanFeatureInfo?.jobSetting
						?.maxPublishJobs == null
						? t('label.unlimited')
						: subscription.subscriptionPlanFeatureInfo?.jobSetting
								?.maxPublishJobs
				}`}</span>
			</div>
			<div className="d-flex gap-2">
				<span>
					<FontAwesome
						icon="check"
						className="text-primary"
						size="1x"
					/>
				</span>
				<span>{t('label.your-internships-usage')}:</span>
				<span>{`${internshipCapacity?.count || 0}/${
					subscription.subscriptionPlanFeatureInfo?.internshipSetting
						?.maxPublishInternships === null
						? t('label.unlimited')
						: subscription.subscriptionPlanFeatureInfo
								?.internshipSetting.maxPublishInternships
				}`}</span>
			</div>
			{isExpired && (
				<Badge
					color={Colors.warning}
					className="w-100 d-flex justify-content-between gap-2">
					<div className="w-100 d-flex justify-content-start gap-2">
						<FontAwesome icon="exclamation-circle" />
						<div>Your subscription has been expired</div>
					</div>
					<div className={styles['premium-card--link']}>
						<Link to={''}>Renew Now</Link>
					</div>
				</Badge>
			)}
		</div>
	);
};

export default PartnerCard;
