import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	SubscriptionPlan,
	SubscriptionPlanDetails,
	SubscriptionPlansForm,
	SubscriptionPlansMedia
} from 'Types';
import { AxiosError } from 'axios';
import { useAxios } from './useAxios';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useSubscriptionPlans = <T = DataList<SubscriptionPlan>>(
	{ pageSize, pageNumber, keyword = '', planType }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<SubscriptionPlan>>
): UseQueryResult<DataList<SubscriptionPlan>, AxiosError<Error>> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPlans,
			{ pageSize, pageNumber, keyword, planType }
		],
		queryFn: () =>
			fetch.subscriptionPlanList(pageSize, pageNumber, keyword, planType),
		...config(options)
	});
};

export const useSubscriptionPlanDetails = <T = SubscriptionPlanDetails>(
	subscriptionPlanId: string,
	options?: UseQueryOptions<T, any, SubscriptionPlanDetails>
): UseQueryResult<SubscriptionPlanDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.subscriptionPlans, { id: subscriptionPlanId }],
		queryFn: () => fetch.subscriptionPlanDetails(subscriptionPlanId),
		...config(options)
	});
};

export const useSubscriptionPlan = (subscriptionPlanId?: string) => {
	const query = useSubscriptionPlanDetails(subscriptionPlanId as string, {
		enabled: !!subscriptionPlanId
	});
	const subscriptionPlan = query.data;
	const { sendRequest } = useAxios();

	const createSubscriptionPlan: (
		fields: SubscriptionPlansForm,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.post(api.createSubscriptionPlan, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	const updateSubscriptionPlan: (
		fields: SubscriptionPlansForm,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...subscriptionPlan,
			...fields
		};
		await axios
			.put(api.updateSubscriptionPlan(subscriptionPlanId as string), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.subscription-plans-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const updateSubscriptionPlanMedia: (
		field: Partial<SubscriptionPlansMedia>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = field;
		await sendRequest(
			api.patchSubscriptionPlanMedia(subscriptionPlanId as string),
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.subscription-plans-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const deleteSubscriptionPlanMedia: (
		subscriptionPlanGalleryId: string,
		onFinal?: () => void
	) => Promise<void> = async (subscriptionPlanGalleryId, onFinal) => {
		await sendRequest(
			api.deleteSubscriptionPlanMedia(
				subscriptionPlanId as string,
				subscriptionPlanGalleryId as string
			),
			{ method: 'delete' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.subscription-plans-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	return {
		...query,
		createSubscriptionPlan,
		updateSubscriptionPlan,
		updateSubscriptionPlanMedia,
		deleteSubscriptionPlanMedia
	};
};
