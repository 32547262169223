import { FC, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Box } from 'Styles';
import { useModal, useTheme } from 'Hooks';
import { Contact, Media, SocialNetwork, Logo, Colors } from 'Types';
import {
	Button,
	Collapsible,
	EditButton,
	FontAwesome,
	IconGrid,
	LoadingSpinner,
	ModalBoxChangeImage,
	ModalBoxSectionEditProfile,
	ShimmerImage,
	SocialList
} from 'Elements';
import styles from 'partials/homepage/section/profile-contact-section.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';

interface Props {
	isLoading: boolean;
	contact?: Contact;
	social: SocialNetwork[];
	address?: string;
	validForm: any;
	type: 'communityPartner' | 'businessPartner';
	onUpdate: (
		objKey: string,
		key: string,
		value: string | boolean | Logo | SocialNetwork[]
	) => void;
}

export const ProfileContactSection: FC<Props> = ({
	contact,
	social,
	address,
	isLoading,
	type,
	validForm,
	onUpdate
}) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [openModalImage, toggleModalImage] = useModal();
	const [openModal, toggleModal] = useModal();
	const defaultImage = '/assets/img/profiles/img-user-default.png';
	const initialState = useMemo(() => {
		return {
			fullName: `[${t('placeholder.contact-full-name')}]`,
			tel: `[${t('placeholder.contact-tel')}]`,
			email: `[${t('placeholder.contact-email')}]`,
			position: `[${t('placeholder.contact-position')}]`,
			address: `[${t('placeholder.contact-address')}]`,
			contactDescription: `[${t('placeholder.contact-description')}]`,
			contactImage: '',
			contactThumbnailImage: ''
		};
	}, [t]);

	const handleRemoveImage = async () => {
		onUpdate('contactPerson', 'image', {
			url: '',
			thumbnail: ''
		} as Logo);
	};

	const handleUploadImage = async (image_data: Media) => {
		onUpdate('contactPerson', 'image', {
			url: image_data.absoluteUri,
			thumbnail: image_data.thumbnailUrl
		} as Logo);
	};

	const onAddSocial = (selectedSocial) => {
		const updatedSocial = selectedSocial?.map((item) => ({
			socialNetwork: parseInt(item.name.value),
			socialNetworkUrl: item.url
		}));
		onUpdate('socialNetworks', '', updatedSocial);
	};

	const onRemoveSocial = (selected) => {
		const list: SocialNetwork[] = [];

		social?.forEach((item) => {
			if (selected.socialNetwork !== item.socialNetwork) {
				list.push({
					socialNetwork: item.socialNetwork,
					socialNetworkUrl: item.socialNetworkUrl
				});
			}
		});

		onUpdate('socialNetworks', '', list);
	};

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && contact && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('tab.contact-person')}
									</h5>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<Row className="justify-content-center">
							<Col lg={8} xl={6}>
								<div className="d-flex flex-column gap-4">
									<div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
										<Box
											$hasShadow={false}
											$bgColor={theme[type].primaryColor}
											className={styles.imageWrapper}
											onClick={toggleModalImage}>
											<ShimmerImage
												src={
													contact.contactImage ||
													defaultImage
												}
												defaultImage={defaultImage}
												alt={contact.fullName}
												width={160}
												height={160}
												className={styles.image}
											/>
											<div className="edit-overlay">
												<EditButton className="edit-overlay-icon" />
											</div>
										</Box>
										<div className="d-flex flex-column gap-2">
											<div className="d-flex flex-column gap-1">
												<h4 className="h4 lh-base mb-0">
													<input
														type="text"
														onChange={(e) =>
															onUpdate(
																'contactPerson',
																'fullName',
																e.target.value
															)
														}
														placeholder={
															initialState.fullName
														}
														value={
															contact?.fullName ??
															''
														}
														id="fullName"
														className={`${
															!validForm[
																'contactPerson-fullName'
															]
																? stylesProfile.inputError
																: stylesProfile.inputEdit
														} w-90`}
													/>
												</h4>
												{!validForm[
													'contactPerson-fullName'
												] && (
													<span
														style={{
															color: 'red'
														}}>
														{t(
															'validation.fullName.required'
														)}
													</span>
												)}
												<div className="fs-base lh-base text-break">
													<input
														type="text"
														onChange={(e) =>
															onUpdate(
																'contactPerson',
																'position',
																e.target.value
															)
														}
														placeholder={
															initialState.position
														}
														value={
															contact?.position ??
															''
														}
														id="position"
														className={`${stylesProfile.inputEdit} w-90`}
													/>
												</div>
											</div>
											<hr className="bg-gray-3 my-0" />
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="envelope"
													size="lg"
												/>
												<input
													type="text"
													onChange={(e) =>
														onUpdate(
															'contactPerson',
															'email',
															e.target.value
														)
													}
													placeholder={
														initialState.email
													}
													value={contact?.email ?? ''}
													id="email"
													className={`${stylesProfile.inputEdit} w-90`}
												/>
											</div>
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="phone"
													size="lg"
												/>
												<input
													type="text"
													onChange={(e) =>
														onUpdate(
															'contactPerson',
															'phoneNumber',
															e.target.value
														)
													}
													placeholder={
														initialState.tel
													}
													value={contact?.tel ?? ''}
													id="phoneNumber"
													className={`${stylesProfile.inputEdit} w-90`}
												/>
											</div>
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="location-dot"
													size="lg"
													swapOpacity
												/>
												<input
													type="text"
													onChange={(e) =>
														onUpdate(
															'contactPerson',
															'address',
															e.target.value
														)
													}
													placeholder={
														initialState.address
													}
													value={address ?? ''}
													id="address"
													className={`${stylesProfile.inputEdit} w-90`}
												/>
											</div>
										</div>
									</div>
									<div className="d-flex align-items-center gap-3">
										<h4 className="h3 mb-0 flex-shrink-0">
											{t('title.contact')}
										</h4>
										<IconGrid
											className="flex-grow-1"
											fill={theme[type].primaryColor}
										/>
										<div className="d-inline-flex align-items-center ms-auto flex-shrink-0">
											<SocialList
												className={styles.social}
												list={social}
												onAddSocial={onAddSocial}
												onRemoveSocial={onRemoveSocial}
											/>
										</div>
									</div>
									<textarea
										rows={4}
										onChange={(e) =>
											onUpdate(
												'contactPerson',
												'description',
												e.target.value
											)
										}
										value={
											contact?.contactDescription ?? ''
										}
										id="contactDescription"
										className={`${stylesProfile.inputEdit} w-90`}
									/>
								</div>
							</Col>
						</Row>
					</Collapsible>
				)}
			</Container>
			<ModalBoxChangeImage
				name="contactImage"
				isOpen={openModalImage}
				title="title.change-logo"
				image={contact?.contactImage || defaultImage}
				onClose={toggleModalImage}
				onRemove={handleRemoveImage}
				onImageChange={handleUploadImage}
			/>
			<ModalBoxSectionEditProfile
				isOpen={openModal}
				onClose={toggleModal}
				title="info.contact-person-title"
				description="info.contact-person-description"
			/>
		</section>
	);
};
