import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDemands } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { Applicant, ApplicantApproveStatus, BadgeStatus, Colors } from 'Types';
import {
	clx,
	formatLocaleDateTime,
	getFilterByName,
	getFilterByValue,
	requestStatus
} from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	ListingPage,
	NoData,
	Pagination,
	ShimmerImage,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/shared/page.module.scss';

export const ListRequest = memo(() => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const {
		jobs: { requests: requestPage }
	} = names;
	// const defaultImage = '/assets/img/profiles/img-user-default.png';
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 10,
		filterBy: statusParam,
		currentPage: pageParam,
		keyword: queryParam
	});

	const {
		data: requests,
		isFetching,
		isError
	} = useDemands({
		status: getFilterByName(requestStatus, statusParam)?.value,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const getRequestStatus = (req_status: ApplicantApproveStatus) => {
		return (
			<Badge
				color={
					getFilterByValue(requestStatus, req_status)?.color ??
					Colors.primary
				}>
				{getFilterByValue(requestStatus, req_status)?.label
					? t(
							getFilterByValue(requestStatus, req_status)
								?.label as string
					  )
					: '...'}
			</Badge>
		);
	};

	const handleSelectRow = (row_id: string, applicantId: string) => {
		void navigate(`${requestPage.path}/${row_id}/${applicantId}`, {
			state: { prevPath: requestPage.path }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			applicant: 'w-sm-25',
			'job-title': 'w-sm-30',
			'request-time': 'w-sm-20',
			status: 'w-sm-15',
			operation: 'w-sm-10'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<ListingPage
			totalItems={requests?.totalItems}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			filterOptions={requestStatus}
			selectedFilter={t(
				getFilterByName(requestStatus, statusParam)?.label ??
					'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<DataTable>
				{requests && requests?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.applicant"
							cellClassName={cellClassName('applicant')}
						/>
						<DataTableRowCell
							cellText="table.job-title"
							cellClassName={cellClassName('job-title')}
						/>
						<DataTableRowCell
							cellText="table.request-time"
							cellClassName={cellClassName('request-time')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{requests?.items?.map((request) => {
								return (
									<DataTableRow key={request?.id}>
										<DataTableRowCell
											cellClassName={cellClassName(
												'applicant'
											)}
											cellHead="table.applicant">
											<div className="d-flex align-items-center gap-3">
												<div className="d-flex flex-column">
													<h6 className="fs-sm fw-400 mb-0 lh-base">
														{request?.applicantInfo
															?.firstName
															? `${request?.applicantInfo?.firstName} ${request?.applicantInfo?.lastName} `
															: ''}
													</h6>
													<span className="text-gray-3 fs-small">
														{
															request
																?.applicantInfo
																?.email
														}
													</span>
												</div>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'job-title'
											)}
											cellHead="table.job-title">
											<h6 className="fs-sm fw-500 mb-0 lh-base">
												{
													request?.publishedJobInfo
														?.title
												}
											</h6>
											{request?.code && (
												<span className="text-gray-3 fw-300">
													{`${t('forms.code')}: ${
														request?.code
													}`}
												</span>
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'request-time'
											)}
											cellHead="table.request-time">
											<time
												dateTime={request.requestDate}>
												{formatLocaleDateTime(
													request.createdAt
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status">
											{getRequestStatus(request?.status)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'operation'
											)}
											cellHead="table.operation">
											<DataTableOperation>
												<Button
													size="sm"
													color={
														Colors['white-primary']
													}
													label={
														t(
															'button.details'
														) as string
													}
													onClick={() =>
														handleSelectRow(
															request?.id,
															request?.applicantId
														)
													}
												/>
											</DataTableOperation>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[25, 30, 20, 15, 10]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && requests?.totalItems === 0 && (
					<NoData message="title.no-request" />
				)}
				{isError && <SystemErrorAlert />}
			</DataTable>
			{!isFetching && requests && requests?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={requests?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListRequest.displayName = 'ListRequest';
