import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	LoadingSpinner,
	PreviewProfileStaffCard,
	PreviewProfileStaffSection
} from 'Elements';
import { Title } from 'Styles';
import { useMembers } from 'src/hooks/useMember';
import { SwiperSlide } from 'swiper/react';
import styles from 'partials/homepage/section/staff-section.module.scss';

type Props = {
	isLoadingProfile: boolean;
	members: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerPreviewMemberSection = ({
	isLoadingProfile,
	members
}: Props) => {
	const { t } = useTranslation();

	const { data } = useMembers({
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	return data?.totalItems !== 0 ? (
		<section className="position-relative mb-5">
			{isLoadingProfile && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && (
				<Container>
					<Title
						textColor="text-gray-5"
						className="h3 fw-700 mb-4 text-center">
						{members?.headline ?? ''}
					</Title>
					{data && data?.items?.length > 0 && (
						<PreviewProfileStaffSection
							list={data}
							profileType="communityPartner">
							{data &&
								data?.items?.reverse()?.map((member) => {
									return (
										<SwiperSlide
											key={member.id}
											className={styles.card}>
											<PreviewProfileStaffCard
												id={member.id}
												name={member.fullName}
												staffType="members"
												image={member?.image?.url ?? ''}
												position={member.jobPosition}
												email={member?.email}
												phoneNumber={member?.phoneNumber}
											/>
										</SwiperSlide>
									);
								})}
						</PreviewProfileStaffSection>
					)}
				</Container>
			)}
		</section>
	) : (
		<></>
	);
};
