import { memo, FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { config } from 'Utils';
import { Colors, EventRegisterForm, ModalProps } from 'Types';
import { useCompanyEventLimit, useGetB2bUserProfileQuery } from 'Hooks';
import {
	Button,
	CheckboxController,
	FormInlineCheckbox,
	LoadingContent,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

interface Props extends ModalProps {
	eventId?: string;
	onParticipate: (data: EventRegisterForm) => Promise<void>;
}

export const ModalBoxEventParticipation: FC<Props> = memo(
	({ isOpen, onClose, eventId, onParticipate }) => {
		const { t } = useTranslation();
		const [isDisabled, setIsDisabled] = useState(true);
		const { data, isLoading } = useGetB2bUserProfileQuery(undefined, {
			refetchOnMountOrArgChange: false
		});
		const { data: eventLimit, isLoading: isLoadingLimit } =
			useCompanyEventLimit(eventId as string, { enabled: !!eventId });

		const checkLimit =
			!isLoadingLimit && eventLimit && eventLimit.partnerCanRegister;

		const schema = yup.object().shape({
			phoneNumber: yup
				.string()
				.required(t('validation.phone-number.required')),
			numberOfSeats: yup
				.number()
				.required(t('validation.number-of-seat.required'))
				.typeError(t('validation.number.type'))
				.integer(t('validation.number.type'))
				.min(1, t('validation.number-of-seat.min'))
				.max(
					eventLimit?.seatPerPartnerLimit === -1
						? 50
						: eventLimit?.seatPerPartnerLimit || 50,
					t('validation.number-of-seat.max', {
						count: eventLimit?.seatPerPartnerLimit
					})
				)
		});

		const {
			reset,
			watch,
			control,
			register,
			handleSubmit,
			formState: { isSubmitting, errors, isDirty }
		} = useForm<EventRegisterForm>({
			resolver: yupResolver(schema)
		});

		const handleCheckbox = (checked: boolean) => {
			setIsDisabled(!checked);
		};

		const onSubmit: SubmitHandler<EventRegisterForm> = async (data) => {
			await onParticipate(data);
		};

		useEffect(() => {
			reset({
				businessPartnerName: data?.companyName ?? '',
				email: data?.emailAddress ?? '',
				phoneNumber: '',
				numberOfSeats: 1,
				showDetails: true,
				invitationId: ''
			});
		}, [data, reset]);

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.event.register-as-event-partners')}>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<FormGroup>
						<Label htmlFor="companyName">
							{t('forms.event.business-partner-name')}
						</Label>
						<input
							{...register('businessPartnerName')}
							type="text"
							id="companyName"
							disabled={isLoading}
							className="inputbox w-100"
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="email">{t('forms.email')}</Label>
						<input
							{...register('email')}
							type="email"
							id="email"
							disabled={isLoading}
							className="inputbox w-100"
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="phoneNumber">
							{t('forms.phone-number')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('phoneNumber')}
							type="text"
							id="phoneNumber"
							disabled={!checkLimit}
							className="inputbox w-100"
						/>
						{errors.phoneNumber && (
							<div className="invalid-feedback d-block">
								{errors.phoneNumber.message?.toString()}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<Label htmlFor="numberOfSeats">
							{t('forms.event.number-of-seat')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('numberOfSeats')}
							type="text"
							inputMode="numeric"
							id="numberOfSeats"
							disabled={!checkLimit}
							className="inputbox w-100"
						/>
						{errors.numberOfSeats && (
							<div className="invalid-feedback d-block">
								{errors.numberOfSeats.message?.toString()}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<CheckboxController
							control={control}
							name="showDetails"
							id="showDetails"
							isChecked={watch('showDetails') ?? true}
							label={
								t(
									'forms.event.show-participant-details'
								) as string
							}
						/>
					</FormGroup>
					<FormInlineCheckbox
						id="terms-condition"
						className="d-flex"
						onChange={handleCheckbox}>
						{t('forms.terms-and-conditions')}
						<a
							href={`${config.publicUrl}/agb-agent`}
							target="_blank"
							rel="noreferrer"
							className="text-decoration-underline ms-1 text-green">
							{t('global.terms-and-services')}
						</a>
					</FormInlineCheckbox>
					<ModalBoxFooter className="justify-content-end pt-3">
						<SubmitButton
							icon={false}
							label={t('button.event.participate')}
							savingLabel={t('button.event.participating')}
							isDisable={!isDirty || !checkLimit || isDisabled}
							isSubmitting={isSubmitting}
						/>
					</ModalBoxFooter>
				</form>
				{!checkLimit && (
					<div className="position-absolute d-flex align-items-center justify-content-center flex-column bg-white w-100 h-100 start-0 end-0 text-center round-15 p-3">
						<FontAwesome
							icon='warning'
							className="mb-3 text-yellow"
							size="6x"
						/>
						<span className="fw-400 fs-base lh-base	">
							{t('label.event.participation-forbidden')}
						</span>
						<Button
							color={Colors['gray-4']}
							label={t('button.ok') as string}
							className="w-40 mt-4"
							onClick={onClose}
						/>
					</div>
				)}
				{isLoadingLimit && (
					<LoadingContent title="title.event.check-participation" />
				)}
			</ModalBox>
		);
	}
);

ModalBoxEventParticipation.displayName = 'ModalBoxEventParticipation';
