import {
	SocialNetwork,
	Address,
	AgentSetting,
	Contact,
	Translate,
	SelectOption,
	SelectableAddress
} from 'Types';

export enum CompanyApproveStatus {
	Pending = 0,
	Approved = 1,
	Rejected = 2,
	Expired = 3,
	PendingAgentApproval = 4
}
export enum BusinessPartnerProfileStatus {
	Registration = 0,
	NoSubscription = 1,
	Active = 2,
	Expired = 3
}

export enum PartnershipRequestStatus {
	PendingApproval = 0,
	Approved = 1,
	Rejected = 2,
	AutoRejected = 3
}

export interface BusinessPartnerMe {
	id: string;
	companyName: string;
	businessName: string;
	providerCustomerId: string;
	webSiteAddress: string;
	taxNumber: string;
	hrEmailAddress: string;
	slogan: string;
	logo: Logo;
	description: string;
	businessPartnerProfileId: string;
	approveStatus: CompanyApproveStatus;
	registrationInfo: {
		lastUpdatedAt: string;
		referralCommunityPartnerId: string;
		startedAt: string;
		status: number;
	};
	isActive: boolean;
	firstName: string;
	lastName: string;
	publishInfo: {
		version: number;
		lastUpdatedAt: string;
		status: number;
	};
	email: string;
	companyEmail: string;
	mainEmailAddress: string;
	agentId: string;
	slugUrl: string;
	address: Address;
	status: number;
	companySetting: CompanySetting;
	agentSetting: AgentSetting;
	contact: Contact;
	benefitList: Benefit[];
	companyBenefitDto: Benefit[];
	agentName: string;
	socialNetwork: SocialNetwork[];
	gallery: Gallery[];
	regionId: string;
	regionCode: string;
	region: string;
	sector: SelectOption;
	linkedinConfig: LinkedinConfig;
	sectorName: string;
}

export interface BusinessPartnerProfileInitialData extends BusinessPartnerMe {
	sectorCode: string;
}

export interface BusinessPartnerProfileData extends BusinessPartnerMe {
	sectorCode: SelectOption | undefined | null;
}

export interface BusinessPartnerProfileFormData extends BusinessPartnerMe {
	sectorCode: string;
}

export interface BusinessPartnerHeader {
	headerImage: Logo;
	logo: Logo;
	headerTitle: string;
	slogan: string;
	description: string;
	themeColor: number;
}

export interface BusinessPartnerProfile {
	id: string;
	businessPartnerId: string;
	header: BusinessPartnerHeader;
	version: number;
	benefitList: string[];
	benefitItems: string[];
	socialNetworks: SocialNetwork[];
	auditInfo?: {
		createdBy: string;
		createdAt: string;
		updatedBy: string;
		updatedAt: string;
	};
	contactPerson: {
		address: string;
		description: string;
		email: string;
		fullName: string;
		image: Logo;
		phoneNumber: string;
		position: string;
	};
	statistics: {
		enabled: boolean;
		establishedDate: string;
		employees: string;
		location: string;
		branch: string;
	};
	employee: {
		enabled: boolean;
		headline: string;
	};
	jobs: {
		enabled: boolean;
		headline: string;
	};
	jobAds: {
		enabled: boolean;
		headline: string;
		description: number;
	};
	internships: {
		enabled: boolean;
		headline: string;
	};
	benefits: {
		enabled: boolean;
		headline: string;
		description: string | number;
	};
	gallery: {
		title: string;
		showInHomePage: boolean;
	};
	galleryItems: [
		{
			url: string;
			thumbnail: string;
		}
	];
}

export interface PaymentMethod {
	paymentMethodId: string;
	title: string;
	type: string;
	brand: string;
	last4: string;
	expMonth: string;
	expYear: string;
	email: string;
}
export interface BusinessPartnerMe {
	companyId: string;
	name: string;
	companyEmail: string;
	mainEmailAddress: string;
	regionId: string;
	regionName: string;
	paymentMethods: any;
	primarySubscriptionId: number;
	businessPartnerProfileId: string;
	approveStatus: CompanyApproveStatus;
	isActive: boolean;
	webSite?: string;
	status: number;
	partnerType: number;
	publishInfo: {
		version: number;
		lastUpdatedAt: string;
		status: number;
	};
	logo: Logo;
	registrationInfo: {
		lastUpdatedAt: string;
		referralCommunityPartnerId: string;
		startedAt: string;
		status: number;
	};
	companySetting: CompanySetting;
}

export interface Logo {
	url: string;
	thumbnail: string;
}

export interface businessPartnerLogo {
	logo: Logo;
}

export interface CompanySetting {
	logo: string;
	thumbnailLogo: string;
	companyHeaderLogo: string;
	companyHeaderThumbnailLogo: string;
	slogan: string;
	employeeHeadline: string;
	employeeHeadlineStatus: boolean;
	employeeSectionStatus: boolean;
	description: string;
	jobCommonHeadline: string;
	internshipCommonHeadline: string;
	jobText: string;
	internshipText: string;
	jobOverviewHeadLine: string;
	internshipOverviewHeadLine: string;
	jobCommonHeadlineStatus: boolean;
	internshipCommonHeadlineStatus: boolean;
	benefitSectionStatus: boolean;
	statisticsSectionStatus: boolean;
	gallerySectionStatus: boolean;
	jobListStatus: boolean;
	internshipListStatus: boolean;
	jobStatus: boolean;
	businessPartnerText: string;
	companyOverviewDescription: string;
	branch: string;
	employeeNumber: string;
	establishedDate: string;
	location: string;
	benefitTitle: string;
	benefitDescription: string;
	secondaryColor: string;
}

export interface CompanyIntroduction {
	logo: string;
	thumbnailLogo: string;
	companyHeaderLogo: string;
	companyHeaderThumbnailLogo: string;
	slogan: string;
	description: string;
	businessPartnerText: string;
	companyOverviewDescription: string;
}

export interface Statistic {
	branch: string;
	establishedDate: string;
	location: string;
	employeeNumber: string;
}

export interface CompanyJobCommon {
	jobCommonHeadline: string;
	jobText: string;
	jobCommonHeadlineStatus: boolean;
}

export interface CompanyJobList {
	jobOverviewHeadLine: string;
	jobListStatus: boolean;
}

export interface CompanyBenefit {
	benefitTitle: string;
	benefitDescription: string;
}

export interface CompanyBenefitDto {
	id: string;
	code: string;
	isActive: boolean;
	benefitTranslates: Translate[];
}

export interface CompanyEmployee {
	employeeHeadline: string;
	employeeHeadlineStatus: boolean;
}

export interface Benefit {
	id: string;
	code: string;
	title: string;
	translateTitles: Translate[];
	isActive: boolean;
}

export interface Gallery {
	url: string;
	thumbnail: string;
}

export interface GalleryFormData {
	title: string;
	sortOrder: number;
	image: string;
	imageThumbnail: string;
	showInHomePage: boolean;
	size: string;
	originalName: string;
}

export interface Employee {
	id: string;
	fullName: string;
	description: string;
	isActive: boolean;
	jobPosition: string;
	showInHomePage: boolean;
	companyId?: string;
	image: { thumbnail: string; url: string } | null;
}

export interface EmployeeForm {
	image: { thumbnail: string; url: string } | null;
	fullName: string;
	description: string;
	jobPosition: string;
	isActive: boolean;
	showInHomePage: boolean;
}

export interface Branch {
	id: string;
	businessPartnerId: string;
	name: string;
	state?: SelectOption | null;
	countryName?: SelectOption | null;
	shortDescription: string;
	address: SelectableAddress;
	active: boolean;
}

export interface Activity {
	id: string;
	code: string;
	isActive: boolean;
	activityTranslates: Translate[];
}

export interface ActivityId {
	activityId: string;
}

export interface JobType {
	id: string;
	code: string;
	isActive: boolean;
	jobKind: number;
	jobTypeTranslates: Translate[];
}

export interface InternshipType {
	id: string;
	code: string;
	isActive: boolean;
	jobTypeTranslates: Translate[];
}

export interface Sector {
	id: string;
	code: string;
	title: string;
	isActive: boolean;
	translates: Translate[];
}

export interface LinkedinConfig {
	clientIdentify?: string;
	clientSecret?: string;
	token?: string;
}
