import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, FieldErrors } from 'react-hook-form';
import { clx, getKeyFromValue } from 'Utils';
import { socialList } from 'Data';
import { FontAwesome, SelectController } from 'Elements';
import { SocialNetwork, SocialNetworks } from 'src/types/Social';

interface Props {
	className?: string;
	errors: FieldErrors;
	list: SocialNetwork[];
	onRemoveSocial: (social: SocialNetwork) => void;
}

export const SocialController: FC<Props> = ({
	className,
	errors,
	list,
	onRemoveSocial
}) => {
	const { t } = useTranslation();
	const { control, register, setValue } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'socialNetwork'
	});

	const appendSocial = () => {
		append({
			name: '',
			url: ''
		});
	};

	const formatOptionLabel = ({
		label,
		icon
	}: {
		label: string;
		icon: any;
	}) => {
		return (
			<div className="d-flex align-items-center gap-2">
				<FontAwesome icon={icon} set="fab" size="lg" />
				<div className="text-truncate text-capitalize">{label}</div>
			</div>
		);
	};

	useEffect(() => {
		if (list?.length === 0) return;
		const socialList = list?.map((item) => {
			return {
				name: {
					value: item.socialNetwork,
					label: getKeyFromValue(SocialNetworks, item.socialNetwork),
					icon: getKeyFromValue(
						SocialNetworks,
						item.socialNetwork
					)?.toLocaleLowerCase()
				},
				url: item.socialNetworkUrl
			};
		});
		setValue('socialNetwork', socialList, {
			shouldDirty: true,
			shouldValidate: true
		});
	}, [list]);

	return (
		<Fragment>
			{fields?.map((field: any, index) => {
				return (
					<div
						className={clx(
							'd-flex flex-column w-100 flex-sm-row mb-3',
							className
						)}
						key={field.id}>
						<SelectController
							control={control}
							name={`socialNetwork.${index}.name`}
							options={socialList}
							error={errors?.socialNetwork?.[index]?.name}
							className="flex-shrink-0 w-100 w-xs-35 me-sm-2 mb-2 mb-sm-0"
							formatOptionLabel={formatOptionLabel}
						/>
						<div className="d-flex flex-grow-1 flex-column w-100 w-xs-65">
							<div className="d-flex align-items-center flex-grow-1 gap-2">
								<input
									{...register(`socialNetwork.${index}.url`)}
									type="text"
									inputMode="url"
									aria-invalid={
										!!errors?.socialNetwork?.[index]?.url
									}
									placeholder={t('placeholder.url')}
									className="inputbox"
								/>
								<span
									className="d-flex align-items-center justify-content-center flex-shrink-0 p-1 text-red"
									role="button"
									tabIndex={0}
									onClick={() => {
										const socialItem = list?.find(
											(item) =>
												item.socialNetworkUrl ===
												field.url
										);

										socialItem &&
											onRemoveSocial(socialItem);
										remove(index);
									}}>
									<FontAwesome icon="trash-xmark" size="lg" />
								</span>
							</div>
							{errors?.socialNetwork?.[index]?.url && (
								<div className="invalid-feedback d-block">
									{
										errors?.socialNetwork?.[index]?.url
											.message
									}
								</div>
							)}
						</div>
					</div>
				);
			})}
			<span
				role="button"
				tabIndex={0}
				className="text-primary d-flex align-items-center gap-2 lh-base"
				onClick={appendSocial}>
				<FontAwesome icon="plus-circle" size="lg" />
				{t('forms.add-social-link')}
			</span>
		</Fragment>
	);
};

SocialController.displayName = 'SocialController';
