import { FC, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Card, ModalBoxChangeImage, FontAwesome } from 'Elements';
import {
	AspectRatio,
	SubscriptionPlanDetails,
	SubscriptionPlansMedia
} from 'Types';
import styled from 'styled-components';
import { useModal } from 'src/hooks/useModal';
import { useSubscriptionPlan } from 'src/hooks/useSubscriptionPlans';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanMedia: FC = () => {
	const [openModal, toggleModal] = useModal();
	const [image, setImage] = useState<any>();
	const { subscriptionPlan_id } = useParams();
	const fields = useOutletContext<SubscriptionPlanDetails>();
	const { updateSubscriptionPlanMedia, deleteSubscriptionPlanMedia } =
		useSubscriptionPlan(subscriptionPlan_id);

	const handleUploadMedia = async (image_data: Partial<any>) => {
		if (image_data) {
			const data = {
				url: image_data.absoluteUri,
				thumbnail:
					image_data.mimeType.split('/')[0] === 'image'
						? image_data.thumbnailUrl
						: image_data.absoluteUri,
				mediaType: image_data.mimeType.split('/')[0] === 'image' ? 0 : 1
			};
			await updateSubscriptionPlanMedia(
				data as Partial<SubscriptionPlansMedia>
			);
		}
	};

	const handleMediaRemove = async () => {
		await deleteSubscriptionPlanMedia(image?.id as string);
		toggleModal();
	};

	return (
		<CardBox className="h-100">
			<div className="d-flex gap-3 flex-wrap">
				<div
					className="d-flex flex-column align-items-center justify-content-center"
					onClick={() => {
						setImage(null as any);
						toggleModal();
					}}
					style={{
						cursor: 'pointer',
						borderRadius: '8px',
						padding: '10px',
						width: '200px',
						height: '200px',
						background: '#F5F5F7'
					}}>
					<FontAwesome
						size="3x"
						icon="cloud-arrow-up"
						className="text-primary"
					/>
					<span>Upload media</span>
				</div>
				{fields?.subscriptionPlanGalleries.map((item) => {
					return (
						<div
							key={item.id}
							onClick={() => {
								setImage(item);
								toggleModal();
							}}
							style={{
								borderRadius: '8px',
								cursor: 'pointer',
								width: '280px',
								height: '200px'
							}}>
							{item.mediaType === 0 ? (
								<img
									alt={item.id}
									style={{
										borderRadius: '8px',
										objectFit:"cover"
									}}
									className="w-100 h-100"
									src={item.url}
								/>
							) : (
								<div
									style={{
										borderRadius: '8px',
										background: '#f5f5f7',
										position: 'relative',
										width: '100%',
										height: '100%'
									}}>
									<video
										className="w-100 h-100"
										src={item.url}
									/>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											textAlign: 'center',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
										<FontAwesome
											icon="play"
											size="3x"
											className="text-white"
										/>
									</div>
								</div>
							)}
						</div>
					);
				})}
				<ModalBoxChangeImage
					name="image"
					title="title.change-media"
					isOpen={openModal}
					image={image?.url || ''}
					mediaType={image?.mediaType}
					withVideo
					showButtonUpload={image?.url ? false : true}
					aspectRatio={AspectRatio['16/9']}
					onClose={toggleModal}
					onImageChange={handleUploadMedia}
					onRemove={handleMediaRemove}
				/>
			</div>
		</CardBox>
	);
};
