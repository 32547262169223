import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Container, Row } from 'reactstrap';

import {
	LoadingSpinner,
	SectionHeadline,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	Button,
	FontAwesome,
	Collapsible,
	ModalBoxAddBenefit,
	ProfileBenefitCard
} from 'Elements';
import styles from 'partials/homepage/section/benefit-section.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';
import { useModal } from 'src/hooks/useModal';
import { Colors } from 'Types';
import {  useUpdateBenefits } from 'src/hooks/useBenefit';
import { useDelayUnmount } from 'src/hooks/useDelayUnmount';
import { useState } from 'react';

const GridBox = styled('div')`
	background-color: ${({ theme }) =>
		theme && rgba(theme.businessPartner.primaryColor, 0.3)};
	border-color: ${({ theme }) => theme && theme.businessPartner.primaryColor};
`;

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	benefitList: string[];
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	benefits: {
		enabled: boolean;
		headline: string;
		description: string | number;
	};
};

export const BusinessPartnerBenefitSection = ({
	isLoadingProfile,
	onChangeValue,
	benefits,
	benefitList
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const [openModalInfo, toggleModalInfo] = useModal();
	const perRow = 8;
	const [next, setNext] = useState(perRow);
	const { benefits: list } = useUpdateBenefits();

	const checkBenefit = (id) => {
		let check = false;
		benefitList?.forEach((item) => {
			if (item === id) {
				check = true;
			}
		});
		return check;
	};

	const handleMoreBenefit = () => {
		setNext(next + perRow);
	};

	const handleRemoveBenefit = (id) => {
		const newList: any = [];
		benefitList?.forEach((item) => {
			if (item !== id) {
				newList.push(item);
			}
		});
		onChangeValue('benefitList', '', newList);
	};

	return (
		<section className="position-relative mb-5 ">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && benefits && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('forms.benefits')}
									</h5>
									<ReactSwitch
										size="md"
										checked={benefits?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'benefits',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-benefits-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModalInfo()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<GridBox className={styles.grid}>
							<SectionHeadline
								title={benefits?.headline}
								titlePlaceholder={`[${t(
									'placeholder.our-benefits'
								)}]`}
								description={benefits?.description}
								descriptionPlaceholder={`[${t(
									'placeholder.benefit-description'
								)}]`}
								className="mx-auto text-center"
								onUpdateTitle={(val) =>
									onChangeValue('benefits', 'headline', val)
								}
								onUpdateDescription={(val) =>
									onChangeValue(
										'benefits',
										'description',
										val
									)
								}
							/>
							<Row className="g-0">
								{benefitList &&
									list?.items
										?.slice(0, next)
										?.map((benefit) => {
											if (checkBenefit(benefit.id)) {
												return (
													<ProfileBenefitCard
														key={benefit.id}
														benefit={benefit}
														isRemoving={false}
														onClick={() => {
															handleRemoveBenefit(
																benefit.id
															);
														}}
													/>
												);
											}
										})}
							</Row>
							<div className="d-flex align-items-center justify-content-center mt-4 gap-3">
								{benefitList?.length > next && (
									<Button
										size="lg"
										color={Colors['gray-5']}
										className="fs-base rounded-pill"
										label={
											t('button.more-benefits') as string
										}
										onClick={handleMoreBenefit}
									/>
								)}
								<Button
									size="lg"
									className="gap-3 rounded-pill"
									onClick={toggleModal}>
									<FontAwesome
										icon="plus"
										size="xl"
										swapOpacity
									/>
									{t('title.add-new-benefit')}
								</Button>
							</div>
						</GridBox>
					</Collapsible>
				)}
			</Container>
			{!isLoadingProfile && !benefits?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
			{shouldRenderModal && (
				<ModalBoxAddBenefit
					isOpen={openModal}
					onClose={toggleModal}
					onChangeValue={onChangeValue}
					title={t('title.choose-benefit')}
					selectedBenefits={benefitList as string[]}
				/>
			)}
			<ModalBoxSectionEditProfile
				isOpen={openModalInfo}
				onClose={toggleModalInfo}
				title="info.benefits-title"
				description="info.benefits-description"
			/>
		</section>
	);
};
