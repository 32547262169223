import { FC } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import {
	Colors,
	JobGenerateDescriptionByAIFormData,
	ModalProps,
	JobGenerateDescriptionByAIResult,
} from 'Types';
import {
	Button,
	FontAwesome,
	ModalBox,
	ModalBoxFooter,
	SelectController,
} from 'Elements';
import { useGenerateJobDescriptionByAI } from 'src/hooks/useJob';
import { localeOptions } from 'Constants';
import { useSelector } from 'Hooks';

interface Props extends ModalProps {
	jobTitle: string;
	jobTags: string;
	fillDescriptionByAI: (JobFormDescription: JobGenerateDescriptionByAIResult) => void;
}
export const ModalBoxGenerateJobDescriptionByAI: FC<Props> = ({
	jobTitle,
	jobTags,
	fillDescriptionByAI,
	isOpen,
	onClose,
}) => {
	const { t } = useTranslation();
	const { submitRequest } = useGenerateJobDescriptionByAI();
	const { locale } = useSelector((state) => state.setting);
	const required_validation = 'validation.required';
	const schema = yup.object().shape({
		jobTitle: yup
			.string()
			.required(t('validation.title.required')),
		requirement: yup
			.string()
			.required(t('validation.requirement.required')),
		maxWord: yup
			.number()
			.max(1000, t('validation.maxWord.limit'))
			.required(t('validation.maxWord.required')),
	});

	const defaultLanguage = () => {
		const selected = localeOptions?.filter(option => (option.value as string).toLowerCase() === locale.code.toLowerCase())[0];
		if (selected) {
			return selected
		}
	};
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<JobGenerateDescriptionByAIFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			jobTitle: jobTitle,
			requirement: jobTags,
			maxWord: 400,
			languageCode: defaultLanguage()
		}
	});
	function stopPropagate(callback: () => void) {
		return (e: { stopPropagation: () => void, preventDefault: () => void }) => {
			e.stopPropagation();
			e.preventDefault();
			callback()
		}
	}
	const onSubmit: SubmitHandler<JobGenerateDescriptionByAIFormData> = async (formData) => {
		const data = {
			...formData,
			languageCode: formData.languageCode.value,
		};
		await submitRequest(data, (res) => {
			fillDescriptionByAI(res);
			onClose?.();
		});
	};

	return (
		<ModalBox size="lg" isOpen={isOpen}>
			<div className='my-3 d-flex gap-3'>
				<FontAwesome
					icon="wand-magic-sparkles"
					size="3x"
					color="text-primary"
				/>
				<div>
					<h5 className='fs-base'>{t('title.job.get-help-with-AI')}</h5>
					<p className="text-gray-3 lh-base">
						{t('text.generate-job-description')}
					</p>
				</div>
			</div>
			<form onSubmit={stopPropagate(handleSubmit(onSubmit))}>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="jobTitle" className='fw-semibold text-gray-4'>
								{t('forms.job.job-title')}
								<small className="ms-1 text-gray-3 fw-normal">
									({t(required_validation)})
								</small>
							</Label>
							<input
								{...register('jobTitle')}
								type="text"
								id="jobTitle"
								className="inputbox w-100"
							/>
							<small className="ms-1 text-gray-3">
								<FontAwesome icon="info-circle" size="lg" className='me-1' />
								{t('info.job-title-with-AI')}
							</small>
							{errors.jobTitle && (
								<div className="invalid-feedback d-block">
									{errors.jobTitle.message}
								</div>
							)}
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="requirementsText" className="mb-0 fw-semibold text-gray-4">
									{t('forms.job.requirements')}
									<small className="ms-1 text-gray-3 fw-normal">
										({t(required_validation)})
									</small>
								</Label>
							</div>
							<textarea
								{...register('requirement')}
								id="requirement"
								className="inputbox w-100"
								cols={10}
								rows={5}
							/>
							<small className="ms-1 text-gray-3">
								<FontAwesome icon="info-circle" size="lg" className='me-1' />
								{t('info.job-reqirement-with-AI')}
							</small>
							{errors.requirement && (
								<div className="invalid-feedback d-block">
									{errors.requirement.message}
								</div>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="languageCode" className='fw-semibold text-gray-4'>
								{t('forms.job.language')}
								<small className="ms-1 text-gray-3 fw-normal">
									({t(required_validation)})
								</small>
							</Label>
							<SelectController
								name="languageCode"
								control={control}
								options={localeOptions}
								defaultValue={defaultLanguage()}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label htmlFor="maxWord" className='fw-semibold text-gray-4'>
								{t('forms.job.max-word')}
								<small className="ms-1 text-gray-3 fw-normal">
									({t(required_validation)})
								</small>
							</Label>
							<input
								{...register('maxWord')}
								type="text"
								id="maxWord"
								className="inputbox w-100"
								style={{ height: '44px' }}
							/>
							{errors.maxWord && (
								<div className="invalid-feedback d-block">
									{errors.maxWord.message}
								</div>
							)}
						</FormGroup>
					</Col>
				</Row>
				<ModalBoxFooter className="pt-3 d-flex justify-content-end border-top mt-4 gap-3">
					<Button
						color={Colors['white-gray']}
						label={t('button.cancel') as string}
						onClick={onClose}
					/>
					<Button
						type='submit'
						color={Colors.green}
						disabled={isSubmitting}
					>
						<>
							{isSubmitting ?
								<FontAwesome
									spin
									spinPulse
									icon="spinner"
									size="lg"
									className='me-2'
								/>
								:
								<FontAwesome
									icon="wand-magic-sparkles"
									size="lg"
									className='me-2'
								/>
							}
							<span>{t('button.job.generate-and-fill')}</span>
						</>
					</Button>
				</ModalBoxFooter>
			</form>
		</ModalBox>
	);
};
