import { Badge, FontAwesome } from 'src/containers/Elements';
import styles from './partner.module.scss';
import { Colors } from 'src/types/Color';
import { SubscriptionCommunityPartner } from 'src/types/Subscription';
import { FC } from 'react';
import { clx, formatLocalAddress } from 'src/helpers/Utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { JobVisibilitySetting } from 'src/types/Job';
import { useFormContext } from 'react-hook-form';

interface Partner {
	partner: SubscriptionCommunityPartner;
	isPremium: boolean;
	toggleDrawer: () => void;
}
const PartnerCard: FC<Partner> = ({ partner, isPremium, toggleDrawer }) => {
	const defaultImage = '/assets/img/default-image.png';
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { watch } = useFormContext<JobVisibilitySetting>();
	const isSelected = watch('jobVisibilities')?.some(
		(v) => v.communityPartnerId === partner.id
	);
	const handelPartnerNavigation = () => {
		if (isPremium && !isSelected) {
			void toggleDrawer();
			// set time out after closing the drawer
			setTimeout(() => {
				navigate(
					`/subscriptions/add?step=step2&communityPartnerId=${partner.id}`
				);
			}, 100);
		}
	};

	return (
		<div
			className={clx(styles.card, isSelected && styles['card--selected'])}
			onClick={handelPartnerNavigation}>
			<div className={styles.header}>
				<div className="flex-10.5">
					<img
						src={partner?.logo?.thumbnail || defaultImage}
						alt="Logo"
						className={styles.logo}
					/>
				</div>
				<div className="flex-1">
					<h3 className={styles.title}>{partner.businessName}</h3>
					<p className={styles.subtitle}>{partner.description}</p>
				</div>
			</div>

			<div>
				{isPremium ? (
					<Badge color={Colors.premium}>Premium Partner</Badge>
				) : (
					<Badge color={Colors.tertiary}>Standard Partner</Badge>
				)}
			</div>
			{isSelected && (
				<div>
					<Badge color={Colors.yellow}>
						<div className="d-flex gap-2">
							<FontAwesome
								icon="info-circle"
								className="text-yellow"
							/>
							<span>{t('text.already-has-been-selected')}</span>
						</div>
					</Badge>
				</div>
			)}
			{partner?.isAutoApprove && (
				<div className="mt-2">
					<Badge color={Colors['warning']}>
						<FontAwesome icon={'info-circle'} size="1x" />
						<span className="ms-1">{t('badge.no-approval')}</span>
					</Badge>
				</div>
			)}

			<div className="d-flex align-items-center ">
				<FontAwesome
					icon="location"
					size="1x"
					className="text-primary me-2"
				/>
				<span className="text-gray-3 fw-400 fs-sm lh-base">
					{`${t('card.region')} ${partner.regionCode}XXX`}
				</span>
			</div>
			<div className="text-gray-3 fw-400 fs-sm d-flex flex-wrap lh-base mb-0">
				<FontAwesome
					icon="location-dot"
					size="1x"
					className="text-primary me-2"
				/>
				{formatLocalAddress(
					partner?.address?.street,
					partner?.address?.houseNumber,
					partner?.address?.postalCode,
					partner?.address?.city,
					partner?.address?.state
				)}
			</div>
		</div>
	);
};

export default PartnerCard;
