import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FontAwesome } from 'Elements';
import { Colors } from 'src/types/Color';
import { useNavigate } from 'react-router-dom';

interface Props {
	type?: 'submit' | 'button';
	label?: string;
	savingLabel?: string;
	icon?: any | false;
	isDisable?: boolean;
	cancelButton?: string;
	isSubmitting: boolean;
	[x: string]: any;
}

export const SubmitButton: FC<Props> = memo(
	({
		type = 'submit',
		label = 'button.save',
		savingLabel = 'button.saving',
		icon = 'floppy-disk',
		isDisable,
		cancelButton,
		isSubmitting,
		...props
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		return (
			<div className="d-flex gap-2">
				{cancelButton && cancelButton !== "" && <Button
					type="button"
					color={Colors['white-primary']}
					className="me-auto"
					onClick={()=>navigate(cancelButton)}>
					{t('button.cancel')}
				</Button>}
				<Button
					type={type}
					disabled={isDisable || isSubmitting}
					{...props}>
					{isSubmitting && (
						<span className="d-flex align-items-center gap-2">
							<FontAwesome
								spin
								spinPulse
								icon="spinner"
								size="lg"
							/>
							{savingLabel ? t(savingLabel) : t(label)}
						</span>
					)}
					{!isSubmitting && (
						<span className="d-flex align-items-center gap-2">
							{icon && <FontAwesome icon={icon} size="lg" />}
							{t(label)}
						</span>
					)}
				</Button>
			</div>
		);
	}
);

SubmitButton.displayName = 'SubmitButton';
