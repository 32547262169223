import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Colors,
	EventRegisterForm,
	EventStatus,
	RegistrationStatus
} from 'Types';
import {
	useCompanyEventDetails,
	useCompanyEventLimit,
	useDelayUnmount,
	useGoBack,
	useModal,
	useMyEventInvitation
} from 'Hooks';
import {
	EventDetailsSection,
	EventInfoSection,
	EventOrganizerSection,
	EventOrganizerBox,
	LoadingSpinner,
	EventLocationBox,
	EventParticipationBox,
	EventParticipationStatusBox,
	ModalBoxEventParticipation,
	EventStatusBar,
	Button,
	FontAwesome
} from 'Elements';

export const MyCompanyEventDetails: FC = memo(() => {
	const { t } = useTranslation();
	const { event_id } = useParams();
	const { goBack, location } = useGoBack();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const {
		data: eventDetails,
		isLoading,
		refetch
	} = useCompanyEventDetails(event_id as string);
	const { participateEvent, cancelParticipateEvent } = useMyEventInvitation(
		event_id as string
	);

	const {
		data: eventLimit,
		isLoading: isLoadingLimit,
		refetch: refetchLimit
	} = useCompanyEventLimit(event_id as string);

	const canEventPartnerParticipate =
		!isLoading &&
		!isLoadingLimit &&
		eventDetails &&
		eventLimit &&
		eventLimit?.partnerCanRegister &&
		eventDetails?.hasPartner &&
		eventDetails?.registrationStatus === RegistrationStatus.Open &&
		!eventDetails?.businessPartnerInvitation &&
		(!eventDetails?.participationInfo ||
			eventDetails?.participationInfo?.status === 1 || eventDetails?.participationInfo?.status === 2) &&
		eventDetails?.partnerRegistrationStatus !== RegistrationStatus.Finished;

	const onFinalParticipate = () => {
		toggleModal();
		refetch();
		refetchLimit();
	};

	const onAccept = () => {
		toggleModal();
	};

	const onCancel = async () => {
		await cancelParticipateEvent(() => {
			refetch();
			refetchLimit();
		});
	};

	const onRegister = () => {
		toggleModal();
	};

	const onParticipate = async (data: EventRegisterForm) => {
		const api_data = {
			...data,
			invitationId: eventDetails?.businessPartnerInvitation?.id ?? ''
		};
		await participateEvent(api_data, onFinalParticipate);
	};




	return (
		<div>
			{!isLoading && eventDetails && (
				<Row>
					<Col xl={7} className="pe-xl-4">
						{location.state?.prevPath && (
							<div className="d-flex align-items-center lh-lg mb-1">
								<Button
									color={Colors.empty}
									className="px-0 text-gray-5 back-button"
									onClick={goBack}>
									<FontAwesome
										icon='arrow-left'
										size="lg"
										className="me-2 back-icon"
									/>
									{t('button.back-to-result')}
								</Button>
							</div>
						)}
						{(eventDetails.isFinished ||
							eventDetails.eventStatus ===
							EventStatus.Canceled) && (
								<EventStatusBar
									isCanceled={
										eventDetails.eventStatus ===
										EventStatus.Canceled
									}
									isFinished={eventDetails.isFinished}
								/>
							)}
						<EventInfoSection event={eventDetails} />
						<div className="d-xl-none mb-4 mb-xl-0">
							<EventOrganizerBox event={eventDetails} />
							<EventLocationBox event={eventDetails} />
							{!canEventPartnerParticipate && (eventDetails.businessPartnerInvitation ||
								eventDetails.participationInfo) &&
								!eventDetails.isFinished &&
								eventDetails.eventStatus !==
								EventStatus.Canceled && (
									<EventParticipationStatusBox
										event={eventDetails}
										onAccept={onAccept}
										onCancel={onCancel}
									/>
								)}
							{canEventPartnerParticipate && (
								<EventParticipationBox
									onRegister={onRegister}
								/>
							)}
							{!isLoadingLimit &&
								eventLimit &&
								!eventLimit.alreadyParticipated &&
								!eventLimit.partnerCanRegister &&
								eventDetails.hasPartner && (
									<div className="d-flex align-items-center my-3 py-4 bg-gray-5_o-9 round-15 px-3">
										<FontAwesome
											icon='warning'
											size="lg"
											className="me-2"
											fillOpacity={0.7}
										/>
										{t('label.event.event-reach-capacity')}
									</div>
								)}
						</div>
						<EventDetailsSection event={eventDetails} />
						<EventOrganizerSection event={eventDetails} />
					</Col>
					<Col xl={5} className="ps-xl-4">
						<div className="d-none d-xl-block">
							<EventOrganizerBox event={eventDetails} />
							<EventLocationBox event={eventDetails} />
							{!canEventPartnerParticipate && (eventDetails.businessPartnerInvitation ||
								eventDetails.participationInfo) &&
								!eventDetails.isFinished &&
								eventDetails.eventStatus !==
								EventStatus.Canceled && (
									<EventParticipationStatusBox
										event={eventDetails}
										onAccept={onAccept}
										onCancel={onCancel}
									/>
								)}
							{canEventPartnerParticipate && (
								<EventParticipationBox
									onRegister={onRegister}
								/>
							)}
							{!isLoadingLimit &&
								eventLimit &&
								!eventLimit.alreadyParticipated &&
								!eventLimit.partnerCanRegister &&
								eventDetails.hasPartner && (
									<div className="d-flex align-items-center my-3 py-4 bg-gray-5_o-9 round-15 px-3">
										<FontAwesome
											icon='warning'
											size="lg"
											className="me-2"
											fillOpacity={0.7}
										/>
										{t('label.event.event-reach-capacity')}
									</div>
								)}
						</div>
					</Col>
				</Row>
			)}
			{shouldRenderModal && (
				<ModalBoxEventParticipation
					isOpen={openModal}
					onClose={toggleModal}
					eventId={eventDetails?.id}
					onParticipate={onParticipate}
				/>
			)}
			{isLoading && <LoadingSpinner />}
		</div>
	);
});

MyCompanyEventDetails.displayName = 'MyCompanyEventDetails';
