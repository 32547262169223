import { FC, Suspense } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Box } from 'Styles';
import { clx, getCurrentLocale } from 'Utils';
import { ColorValues, UserCategory } from 'Types';
import {
	useCommunityPartnerMe,
	useBusinessPartnerMe,
	useGetB2bUserProfileQuery,
	useJob,
	useTheme,
	useBusinessPartnerProfile,
	useCommunityPartnerProfile,
	useActivityTags
} from 'Hooks';
import {
	JobPreviewHeader,
	JobCompanyAddress,
	JobContactPersonSection,
	JobImageSection,
	JobInfoSection,
	JobOrganizationSection,
	JobOrganizationSectionLoading,
	JobPageLayout,
	JobSocialMedia,
	JobStatisticSection,
	JobStatisticsSectionLoading,
	JobTextBoxSection,
	LoadingContent
} from 'Elements';
import styles from 'partials/page/job-preview-page.module.scss';
import { useTranslation } from 'react-i18next';

const StyledBox = styled.div<{ $userType: UserCategory }>`
	border-radius: 30px;
	border-bottom: 2px solid;
	${({ theme, $userType }) =>
		theme[$userType].primaryColor &&
		`border-color: ${theme[$userType].primaryColor}`};
`;

const JobPreviewPage: FC = () => {
	const { t } = useTranslation();
	const { job_id } = useParams();
	const { data: user } = useGetB2bUserProfileQuery();
	const userType =
		user?.partnerType === 2
			? 'businessPartner'
			: ('communityPartner' as UserCategory);
	const { data: businessPartnerMe } = useBusinessPartnerMe({
		enabled: user?.partnerType === 2 ? true : false
	});
	const { data: dataActivityTags } = useActivityTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		isActive: ''
	});
	const {
		data: businessPartnerProfile,
		isLoading: isLoadingBusinessPartnerProfile
	} = useBusinessPartnerProfile(
		businessPartnerMe?.businessPartnerProfileId as string,
		{
			enabled: businessPartnerMe?.businessPartnerProfileId ? true : false
		}
	);
	const { data: communityPartnerMe } = useCommunityPartnerMe({
		enabled: user?.partnerType === 1 ? true : false
	});

	const {
		data: communityPartnerProfile,
		isLoading: isLoadingCommunityPartnerProfile
	} = useCommunityPartnerProfile(
		communityPartnerMe?.communityPartnerProfileId as string,
		{
			enabled: communityPartnerMe?.communityPartnerProfileId
				? true
				: false
		}
	);

	const { data: job, isLoading: isLoadingJob } = useJob(job_id as string);
	const partnerStatus =
		businessPartnerMe?.status || communityPartnerMe?.status;

	const primaryColor =
		(businessPartnerProfile?.header?.themeColor ||
			communityPartnerProfile?.header?.themeColor) ??
		ColorValues.primary;

	const isLoadingOrganization =
		isLoadingBusinessPartnerProfile || isLoadingCommunityPartnerProfile;
	const businessPartnerStatistics = businessPartnerProfile?.statistics;
	const communityPartnerStatistics = communityPartnerProfile?.statistics;
	const companyAddress = businessPartnerMe?.address;
	const agentAddress = communityPartnerMe?.address;
	const companyContact = businessPartnerProfile?.contactPerson;
	const agentContact = communityPartnerProfile?.contactPerson;
	const organizationName =
		businessPartnerProfile?.header?.headerTitle ||
		communityPartnerProfile?.header?.headerTitle;
	const organizationLogo =
		businessPartnerProfile?.header?.logo?.thumbnail ||
		communityPartnerProfile?.header?.logo?.thumbnail;
	const organizationImage =
		businessPartnerProfile?.header?.headerImage ||
		communityPartnerProfile?.header?.headerImage;
	const organizationSlogan =
		businessPartnerProfile?.header?.slogan ||
		communityPartnerProfile?.header?.slogan;
	const organizationDescription =
		businessPartnerProfile?.header?.description ||
		communityPartnerProfile?.header?.description;
	const organizationAddress = companyAddress || agentAddress;
	const organizationSocial =
		businessPartnerProfile?.socialNetworks ||
		communityPartnerProfile?.socialNetworks;

	const jobContactName =
		job?.contactPerson?.fullName ||
		companyContact?.fullName ||
		agentContact?.fullName ||
		'---';
	const jobContactEmail =
		job?.contactPerson?.emailAddress ||
		companyContact?.email ||
		agentContact?.email ||
		'---';
	const jobContactTel =
		job?.contactPerson?.phoneNumber ||
		companyContact?.phoneNumber ||
		agentContact?.phoneNumber ||
		'---';
	const jobContactImage =
		job?.contactPerson?.avatar?.thumbnail ||
		companyContact?.image?.thumbnail ||
		agentContact?.image?.thumbnail;

	const StyledTitle = styled.h6<{ $userType: UserCategory }>`
		&:before {
			background-color: ${primaryColor};
		}
	`;

	const Hr = styled.hr<{ $userType: UserCategory }>`
		background-color: ${primaryColor};
	`;

	// useEffect(() => {
	// 	if (primaryColor && userType) {
	// 		setTheme({
	// 			...theme,
	// 			[userType]: {
	// 				...theme[userType],
	// 				primaryColor
	// 			}
	// 		});
	// 	}
	// 	// eslint-disable-next-line
	// }, [primaryColor, setTheme]);

	const getTitle = (id: any, array: any) => {
		let title = '';
		array?.items?.forEach((element) => {
			if (id === element.id) {
				title = element.title;
				element.translateTitles.find((item) => {
					if (
						item?.language.toLowerCase() ===
						getCurrentLocale().code.toLowerCase()
					) {
						title = item.value
							? item.value
							: element.translateTitles[0].value ?? element.title;
					}
				});
				if (title === '') {
					title = element.title;
				}
			}
		});
		return title;
	};

	return (
		<JobPageLayout>
			<JobPreviewHeader
				partnerType={user?.partnerType}
				partnerStatus={partnerStatus}
				job={job as any}
			/>
			{!isLoadingJob && job && (
				<section className="mb-5 mb-lg-6">
					<Box
						$hasShadow={false}
						$bgColor={primaryColor || (ColorValues.primary as any)}
						className={styles.decoration}>
						<Container
							className={clx(
								styles.decoration_content,
								'd-none d-lg-block h-100'
							)}></Container>
					</Box>
					<Container>
						<Row>
							<Col
								lg={4}
								className={clx(
									styles.left_content,
									'd-none d-lg-block'
								)}>
								<StyledBox
									$userType={userType}
									className="p-3 bg-gray-1 d-flex flex-column gap-4">
									<JobImageSection
										job={job as any}
										jobImage={organizationImage as any}
									/>

									{!isLoadingOrganization && (
										<JobOrganizationSection
											logo={organizationLogo as any}
											title={organizationName}
											// slogan={organizationSlogan as any}
											// description={
											// 	organizationDescription as any
											// }
										/>
									)}
									{/* {isLoadingOrganization && (
										<div className="w-100">
											<JobOrganizationSectionLoading />
										</div>
									)} */}

									{!isLoadingOrganization &&
										businessPartnerStatistics?.enabled && (
											<JobStatisticSection
												color={primaryColor}
												userType="businessPartner"
												establishedDate={
													businessPartnerStatistics?.establishedDate as string
												}
												employees={
													businessPartnerStatistics?.employees as string
												}
												branch={
													businessPartnerStatistics?.branch as string
												}
												location={
													businessPartnerStatistics?.location as string
												}
											/>
										)}
									{!isLoadingOrganization &&
										communityPartnerStatistics?.enabled && (
											<JobStatisticSection
												color={primaryColor}
												userType="communityPartner"
												establishedDate={
													communityPartnerStatistics?.establishedDate as string
												}
												memberNumber={
													communityPartnerStatistics?.memberCount as string
												}
												departments={
													communityPartnerStatistics?.departments as string
												}
											/>
										)}
									{isLoadingOrganization && (
										<JobStatisticsSectionLoading
											userType={userType}
										/>
									)}
									{organizationSocial &&
										organizationSocial?.length > 0 && (
											<JobSocialMedia
												socialList={organizationSocial}
											/>
										)}
									<JobCompanyAddress
										address={organizationAddress}
									/>
								</StyledBox>
								<JobContactPersonSection
									color={primaryColor}
									userType={userType}
									image={jobContactImage}
									name={jobContactName}
									email={jobContactEmail}
									tel={jobContactTel}
								/>
							</Col>
							<Col lg={8}>
								<div
									className={clx(
										styles.left_content,
										'd-lg-none'
									)}>
									<JobImageSection
										job={job as any}
										jobImage={organizationImage as any}
									/>
								</div>
								<JobInfoSection
									partnerMe={
										user?.partnerType === 2
											? businessPartnerMe
											: communityPartnerMe
									}
									job={job as any}
									userType={userType}
								/>
								<div className="d-flex flex-column gap-5 mt-3">
									{job?.description &&
										job?.showDescription && (
											<JobTextBoxSection
												color={primaryColor}
												userType={userType}
												title="title.job.desc"
												content={job.description}
											/>
										)}
									{job?.requirementsText &&
										job?.showRequirementsText && (
											<JobTextBoxSection
												color={primaryColor}
												userType={userType}
												title="title.job.requirements"
												content={job.requirementsText}
											/>
										)}
									{job?.responsibilitiesText &&
										job?.showResponsibilitiesText && (
											<JobTextBoxSection
												color={primaryColor}
												userType={userType}
												title="title.job.responsibilities"
												content={
													job.responsibilitiesText
												}
											/>
										)}
									{job?.benefitsText &&
										job?.showBenefitsText && (
											<JobTextBoxSection
												color={primaryColor}
												userType={userType}
												title="title.job.benefits"
												content={job.benefitsText}
											/>
										)}
									{job.jobActivities.length !== 0 && (
										<div>
											<StyledTitle
												$userType={userType}
												className={clx(
													styles.title,
													'fs-6 mb-3 d-flex gap-2 align-items-center'
												)}>
												<span className="flex-shrink-0">
													{t('forms.activity')}
												</span>
												<Hr
													$userType={userType}
													className="flex-grow-1 opacity-100 m-0"
												/>
											</StyledTitle>
											<div className="d-flex flex-wrap align-items-center gap-2">
												{job.jobActivities?.map(
													(activity) => {
														return (
															<div
																className="d-flex align-items-center"
																style={{
																	borderRadius:
																		'8px',
																	padding:
																		'8px 16px',
																	whiteSpace:
																		'nowrap',
																	background:
																		'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
																}}
																key={activity}>
																<span className="text-gray-3 text-base fw-500">
																	{getTitle(
																		activity,
																		dataActivityTags
																	)}
																</span>
															</div>
														);
													}
												)}
											</div>
										</div>
									)}
								</div>

								<div className="d-lg-none mt-5">
									<StyledBox
										$userType={userType}
										className="p-3 bg-gray-1 d-flex flex-column gap-4">
										{!isLoadingOrganization && (
											<JobOrganizationSection
												logo={organizationLogo as any}
												title={organizationName}
												slogan={
													organizationSlogan as any
												}
												description={
													organizationDescription as any
												}
											/>
										)}
										{isLoadingOrganization && (
											<JobOrganizationSectionLoading />
										)}
										{!isLoadingOrganization &&
											businessPartnerStatistics?.enabled && (
												<JobStatisticSection
													userType="businessPartner"
													establishedDate={
														businessPartnerStatistics?.establishedDate as string
													}
													employees={
														businessPartnerStatistics?.employees as string
													}
													branch={
														businessPartnerStatistics?.branch as string
													}
													location={
														businessPartnerStatistics?.location as string
													}
												/>
											)}
										{!isLoadingOrganization &&
											communityPartnerStatistics?.enabled && (
												<JobStatisticSection
													userType="communityPartner"
													establishedDate={
														communityPartnerStatistics?.establishedDate as string
													}
													memberNumber={
														communityPartnerStatistics?.memberCount as string
													}
													departments={
														communityPartnerStatistics?.departments as string
													}
												/>
											)}
										{isLoadingOrganization && (
											<JobStatisticsSectionLoading
												userType={userType}
											/>
										)}

										{!isLoadingOrganization &&
											organizationSocial &&
											organizationSocial?.length > 0 && (
												<JobSocialMedia
													socialList={
														organizationSocial
													}
												/>
											)}
										<JobCompanyAddress
											address={organizationAddress}
										/>
									</StyledBox>
									<JobContactPersonSection
										userType={userType}
										name={jobContactName}
										image={jobContactImage}
										tel={jobContactTel}
										email={jobContactEmail}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{isLoadingJob && <LoadingContent />}
		</JobPageLayout>
	);
};

export default JobPreviewPage;
