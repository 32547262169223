import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlansFeature } from 'Types';
import { FontAwesome } from 'Elements';
import { Col, Row } from 'reactstrap';

interface Props {
	subscriptionPlansFeature: SubscriptionPlansFeature;
	maxJob?: number | null;
	maxInternship?: number | null;
}

export const SubscriptionPlansFeatureForm: FC<Props> = ({
	subscriptionPlansFeature,
	maxJob,
	maxInternship
}) => {
	const { t } = useTranslation();

	const maxPublishJobs = maxJob
		? maxJob
		: subscriptionPlansFeature?.jobSetting?.maxPublishJobs;
	const maxPublishInternships = maxInternship
		? maxInternship
		: subscriptionPlansFeature?.internshipSetting?.maxPublishInternships;

	return (
		<Row>
			<Col sm={12} xl={4}>
				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon="briefcase"
						size="lg"
						color="text-primary"
					/>
					<span className="fw-500 fs-base">{t('menu.job')}</span>
				</div>
				{/* {subscriptionPlansFeature?.jobSetting?.jobEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.job-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.jobSetting
										?.maxPublishJobs !== null
										? subscriptionPlansFeature?.jobSetting
												?.maxPublishJobs
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)} */}

				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon={
							subscriptionPlansFeature?.jobSetting
								?.publishJobEnabled &&
							subscriptionPlansFeature?.jobSetting
								?.maxPublishJobs !== 0
								? 'check'
								: 'close'
						}
						size="1x"
						className={`flex-shrink-0  ${
							subscriptionPlansFeature?.jobSetting
								?.publishJobEnabled &&
							subscriptionPlansFeature?.jobSetting
								?.maxPublishJobs !== 0
								? 'text-green'
								: 'text-red'
						}`}
					/>

					<div className="fw-400 fs-sm">
						{t('text.feature.publish-job-enabled')}
						{subscriptionPlansFeature?.jobSetting
							?.maxPublishJobs !== 0 &&
							` (${t('label.max')}: ${
								maxPublishJobs !== null
									? maxPublishJobs
									: t('label.unlimited')
							})`}
					</div>
				</div>
				{/* {subscriptionPlansFeature?.jobSetting
						?.limitJobVisibility && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.limit-job-visibility')}
							</div>
						</div>
					)} */}
			</Col>
			<Col sm={12} xl={4}>
				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon="chalkboard-user"
						size="lg"
						color="text-primary"
					/>
					<span className="fw-500 fs-base">
						{t('text.internship')}
					</span>
				</div>
				{/* {subscriptionPlansFeature?.internshipSetting
						?.internshipEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.internship-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.internshipSetting
										?.maxPublishInternships !== null
										? subscriptionPlansFeature
												?.internshipSetting
												?.maxPublishInternships
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)} */}

				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon={
							subscriptionPlansFeature?.internshipSetting
								?.publishInternshipEnabled &&
							subscriptionPlansFeature?.internshipSetting
								?.maxPublishInternships !== 0
								? 'check'
								: 'close'
						}
						size="1x"
						className={`flex-shrink-0  ${
							subscriptionPlansFeature?.internshipSetting
								?.publishInternshipEnabled &&
							subscriptionPlansFeature?.internshipSetting
								?.maxPublishInternships !== 0
								? 'text-green'
								: 'text-red'
						}`}
					/>
					<div className="fw-400 fs-sm">
						{t('text.feature.publish-internship-enabled')}
						{subscriptionPlansFeature?.internshipSetting
							?.maxPublishInternships !== 0 &&
							` (${t('label.max')}: ${
								maxPublishInternships !== null
									? maxPublishInternships
									: t('label.unlimited')
							})`}
					</div>
				</div>

				{/* {subscriptionPlansFeature?.internshipSetting
						?.internshipManagerEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.internship-manager-enabled')}
							</div>
						</div>
					)} */}
			</Col>
			<Col sm={12} xl={4}>
				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon="memo-circle-info"
						size="lg"
						color="text-primary"
					/>
					<span className="fw-500 fs-base">{t('title.other')}</span>
				</div>
				{/* {subscriptionPlansFeature?.eventSetting?.eventsEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.events-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.eventSetting
										?.maxPublishEvents !== null
										? subscriptionPlansFeature?.eventSetting
												?.maxPublishEvents
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)} */}
				{/* {subscriptionPlansFeature?.eventSetting
						?.publishEventsEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-events-enabled')}
							</div>
						</div>
					)} */}

				<div className="d-flex align-items-center gap-2 mb-3">
					<FontAwesome
						icon={
							subscriptionPlansFeature?.userAccountSetting
								?.maxUserAccounts &&
							subscriptionPlansFeature?.userAccountSetting
								?.maxUserAccounts !== 0
								? 'check'
								: 'close'
						}
						size="1x"
						className={`flex-shrink-0  ${
							subscriptionPlansFeature?.userAccountSetting
								?.maxUserAccounts &&
							subscriptionPlansFeature?.userAccountSetting
								?.maxUserAccounts !== 0
								? 'text-green'
								: 'text-red'
						}`}
					/>
					<div className="fw-400 fs-sm">
						{t('text.feature.user-accounts')}
						{subscriptionPlansFeature?.userAccountSetting
							?.maxUserAccounts !== 0 &&
							` (${t('label.max')}: ${
								subscriptionPlansFeature?.userAccountSetting
									?.maxUserAccounts !== null
									? subscriptionPlansFeature
											?.userAccountSetting
											?.maxUserAccounts
									: t('label.unlimited')
							})`}
					</div>
				</div>
				{subscriptionPlansFeature?.jobSetting?.jobsGoogleIndex && (
					<div className="d-flex align-items-center gap-2 mb-3">
						<FontAwesome
							icon={
								subscriptionPlansFeature?.jobSetting
									?.jobsGoogleIndex
									? 'check'
									: 'close'
							}
							size="1x"
							className={`flex-shrink-0  ${
								subscriptionPlansFeature?.jobSetting
									?.jobsGoogleIndex
									? 'text-green'
									: 'text-red'
							}`}
						/>
						<div className="fw-400 fs-sm">
							{t('text.feature.jobs-google-index')}
						</div>
					</div>
				)}
			</Col>
		</Row>
	);
};
