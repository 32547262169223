import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Badge,
	Button,
	FontAwesome,
	LoadingSpinner,
	ModalBoxPlanInf
} from 'Elements';
import { currencyFormatter } from 'Utils';
import { Colors, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-plan-card.module.scss';
import { useSubscriptionCalculate } from 'src/hooks/useSubscription';
import { useModal } from 'src/hooks/useModal';
import { useDelayUnmount } from 'src/hooks/useDelayUnmount';

interface Props {
	card: SubscriptionPlan;
	selectedCommunityPartnerId?: string;
	selected: boolean;
	subscriptionPlanPrice?: number;
	loadingPlansFeature: boolean;
	onSelect: (plan: SubscriptionPlan) => void;
}

export const SubscriptionPlanCard: FC<Props> = ({
	card,
	loadingPlansFeature,
	selectedCommunityPartnerId,
	subscriptionPlanPrice,
	onSelect
}) => {
	const { t } = useTranslation();
	const [openModalPlanInfo, toggleModalPlanInfo] = useModal();
	const shouldRenderModal = useDelayUnmount(openModalPlanInfo, 350);
	const handleSelectPlan = () => {
		onSelect(card);
	};

	const { data: subscriptionCalculate, isLoading } = useSubscriptionCalculate(
		selectedCommunityPartnerId as string,
		card.id as string,
		'',
		card.type as number,
		{
			enabled: !!selectedCommunityPartnerId
		}
	);

	return (
		<>
			<div className={styles.cardPlan}>
				<div className="d-flex align-items-center justify-content-between">
					<h1 className="h4 fs-5 text-gray-4">{card.name}</h1>
					{card?.isRecommended && (
						<Badge color={Colors.primary}>
							{t('forms.recommended')}
						</Badge>
					)}
				</div>
				<h4
					className={`fw-500 fs-5 ${subscriptionPlanPrice && 'mb-0'}`}
					style={{
						textDecoration: subscriptionPlanPrice
							? 'line-through'
							: 'none',
						color: subscriptionPlanPrice ? '#AAAAB2' : '#5d5c70'
					}}>
					{currencyFormatter(card.price)}
				</h4>
				{subscriptionPlanPrice && subscriptionPlanPrice !== 0 ? (
					<h4 className="fw-500 fs-5">
						{currencyFormatter(card.price - subscriptionPlanPrice)}
					</h4>
				) : (
					<></>
				)}
				{!subscriptionPlanPrice && subscriptionPlanPrice !== 0 && (
					<span className="text-gray-3 fw-500">
						{`${card.durationDays} ${t('title.days')}`}
					</span>
				)}
				<div
					className="pt-2 lh-base my-3"
					dangerouslySetInnerHTML={{
						__html: card.shortDescription
					}}
				/>
				{/* <div
					className="lh-base mb-4"
					dangerouslySetInnerHTML={{
						__html: card.description
					}}
				/> */}
				{loadingPlansFeature && <LoadingSpinner />}
				{!loadingPlansFeature && card?.featureData && (
					<div className="px-2 pt-3 pb-2">
						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon="briefcase"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('menu.job')}
							</span>
						</div>

						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon={
									card?.featureData?.jobSetting
										?.publishJobEnabled &&
									card?.featureData?.jobSetting
										?.maxPublishJobs !== 0
										? 'check'
										: 'close'
								}
								size="1x"
								className={`flex-shrink-0  ${
									card?.featureData?.jobSetting
										?.publishJobEnabled &&
									card?.featureData?.jobSetting
										?.maxPublishJobs !== 0
										? 'text-green'
										: 'text-red'
								}`}
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-job-enabled')}
								{card?.featureData?.jobSetting
									?.maxPublishJobs !== 0 &&
									` (${t('label.max')}: ${
										card?.featureData?.jobSetting
											?.maxPublishJobs !== null
											? card?.featureData?.jobSetting
													?.maxPublishJobs
											: t('label.unlimited')
									})`}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-2 pt-3">
							<FontAwesome
								icon="chalkboard-user"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('text.internship')}
							</span>
						</div>

						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon={
									card?.featureData?.internshipSetting
										?.publishInternshipEnabled &&
									card?.featureData?.internshipSetting
										?.maxPublishInternships !== 0
										? 'check'
										: 'close'
								}
								size="1x"
								className={`flex-shrink-0  ${
									card?.featureData?.internshipSetting
										?.publishInternshipEnabled &&
									card?.featureData?.internshipSetting
										?.maxPublishInternships !== 0
										? 'text-green'
										: 'text-red'
								}`}
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-internship-enabled')}
								{card?.featureData?.internshipSetting
									?.maxPublishInternships !== 0 &&
									` (${t('label.max')}: ${
										card?.featureData?.internshipSetting
											?.maxPublishInternships !== null
											? card?.featureData
													?.internshipSetting
													?.maxPublishInternships
											: t('label.unlimited')
									})`}
							</div>
						</div>
						<div className="d-flex align-items-center gap-2 mb-2 pt-3">
							<FontAwesome
								icon="memo-circle-info"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('title.other')}
							</span>
						</div>

						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon={
									card?.featureData?.userAccountSetting
										?.maxUserAccounts &&
									card?.featureData?.userAccountSetting
										?.maxUserAccounts !== 0
										? 'check'
										: 'close'
								}
								size="1x"
								className={`flex-shrink-0  ${
									card?.featureData?.userAccountSetting
										?.maxUserAccounts &&
									card?.featureData?.userAccountSetting
										?.maxUserAccounts !== 0
										? 'text-green'
										: 'text-red'
								}`}
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.user-accounts')}
								{card?.featureData?.userAccountSetting
									?.maxUserAccounts !== 0 &&
									` (${t('label.max')}: ${
										card?.featureData?.userAccountSetting
											?.maxUserAccounts !== null
											? card?.featureData
													?.userAccountSetting
													?.maxUserAccounts
											: t('label.unlimited')
									})`}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon={
									card?.featureData?.jobSetting
										?.jobsGoogleIndex
										? 'check'
										: 'close'
								}
								size="1x"
								className={`flex-shrink-0  ${
									card?.featureData?.jobSetting
										?.jobsGoogleIndex
										? 'text-green'
										: 'text-red'
								}`}
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.jobs-google-index')}
							</div>
						</div>
					</div>
				)}
			</div>
			<div>
				{card.price !== 0 && subscriptionCalculate?.totalAmount !== 0 && (
					<>
						{!isLoading && (
							<div className="mt-4 mb-3">
								<div className="d-flex flex-column">
									<div className="d-flex gap-2 pe-3 me-3 text-primary">
										<h5
											className="h5 mb-0"
											style={{
												textDecoration:
													subscriptionPlanPrice
														? 'line-through'
														: 'none',
												color: subscriptionPlanPrice
													? '#AAAAB2'
													: '#96bd68'
											}}>
											{currencyFormatter(
												subscriptionCalculate?.totalAmount ??
													0
											)}
										</h5>
										<span
											className="fw-300"
											style={{
												color: subscriptionPlanPrice
													? '#AAAAB2'
													: '#96bd68'
											}}>
											{t('title.included')}
										</span>
									</div>
									{subscriptionPlanPrice &&
									subscriptionCalculate &&
									subscriptionPlanPrice !== 0 ? (
										<div className="d-flex gap-2 pe-3 me-3 text-primary">
											<h5 className="h5 mb-0">
												{currencyFormatter(
													subscriptionPlanPrice
														? subscriptionCalculate?.totalAmount -
																subscriptionPlanPrice
														: 0
												)}
											</h5>
											<span className="fw-300">
												{t('title.included')}
											</span>
										</div>
									) : (
										<></>
									)}
									{!subscriptionPlanPrice &&
										subscriptionPlanPrice !== 0 && (
											<div className="text-gray-3 text-start d-flex gap-2 text-primary">
												{/* <span className="fw-300 lh-base text-primary">
													{t('title.per')}
												</span> */}
												<span className="fs-base text-primary">
													{`${
														subscriptionCalculate?.subscriptionDuration ??
														''
													} ${t('title.days')}`}
												</span>
											</div>
										)}
								</div>
							</div>
						)}
						{isLoading && (
							<div className="d-flex flex-column gap-2 mt-4 mb-2">
								<div
									className="bg-gray-2"
									style={{
										width: '150px',
										height: '17px'
									}}></div>
								<div
									className="bg-gray-2"
									style={{
										width: '100px',
										height: '15px'
									}}></div>
							</div>
						)}
					</>
				)}
				<Button
					color={Colors.primary}
					className="w-100"
					onClick={handleSelectPlan}>
					{t('button.select')}
				</Button>
				<div className="mt-2 mb-0">
					<Button
						color={Colors.empty}
						className="w-100"
						onClick={toggleModalPlanInfo}>
						{t('button.more-details')}
					</Button>
				</div>
			</div>
			{card && shouldRenderModal && (
				<ModalBoxPlanInf
					continueButton={handleSelectPlan}
					isOpen={openModalPlanInfo}
					onClose={toggleModalPlanInfo}
					plan={card as SubscriptionPlan}
				/>
			)}
		</>
	);
};
