import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, CommunityPartnerMe } from 'Types';
import { Badge, FontAwesome } from 'Elements';
import { getFilterByValue } from 'Utils';
import { communityPartnerStatus } from 'src/helpers/FilterBy';

interface Props {
	profile: CommunityPartnerMe;
}

export const ProfileDecorationCommunityPartnerBadge: FC<Props> = ({
	profile
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Badge
				color={
					profile
						? getFilterByValue(
								communityPartnerStatus,
								profile?.status?.toString()
						  )?.color ?? Colors.primary
						: Colors.primary
				}>
				{profile
					? t(
							getFilterByValue(
								communityPartnerStatus,
								profile?.status?.toString()
							)?.label ?? ''
					  )
					: '...'}
			</Badge>
			{profile?.publishInfo?.status !== 0 && (
				<Badge
					color={
						profile?.publishInfo?.status === 1
							? Colors.green
							: Colors.yellow
					}>
					{profile?.publishInfo?.status === 1
						? t('badge.published')
						: t('badge.not-published')}
				</Badge>
			)}
			{profile?.commissionGroupInfo && (
				<Badge
					color={
						profile?.commissionGroupInfo?.isPremium
							? Colors.premium
							: Colors['tertiary-dark']
					}
					className="gap-2">
					<FontAwesome
						icon={
							profile?.commissionGroupInfo?.isPremium
								? 'medal'
								: 'trophy-star'
						}
						size="lg"
					/>
					{profile?.commissionGroupInfo.premiumLabel}
				</Badge>
			)}
			{profile?.partnerClass === 2 && (
				<Badge
					title={t('title.association-type')}
					color={Colors.association}
					className="gap-2">
					{t('title.association-type')}
				</Badge>
			)}
		</>
	);
};
