import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Colors, Job } from 'Types';
import { api, clx, formatLocaleDateTime, config } from 'Utils';
import { useDelayUnmount, useJobQrCode, useModal, useSelector } from 'Hooks';
import {
	Button,
	FontAwesome,
	ModalBoxQrCode,
	ModalBoxUnPublishJob
} from 'Elements';
import styles from 'partials/job/job.module.scss';

interface Props {
	className?: string;
	job: Job;
}

export const JobPublishBar: FC<Props> = ({ job, className }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { job_id } = useParams() as { job_id: string };
	const [openQrModal, toggleQrModal] = useModal();
	const [openUnPublishModal, toggleUnPublishModal] = useModal();
	const shouldRenderQrhModal = useDelayUnmount(openQrModal, 350);
	const shouldRenderUnPublishModal = useDelayUnmount(openUnPublishModal, 350);
	const previewUrl = `${config.publicUrl}/job/${job?.id}/${job?.slugUrl}`;
	const { assetConfig } = useSelector((state) => state.marketing);
	const { data, isLoading } = useJobQrCode(job_id, {
		enabled: shouldRenderQrhModal
	});
	const redirectToPreview = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	return (
		<div
			className={clx(
				styles.publishbar,
				className,
				'd-flex align-items-md-center flex-column flex-md-row'
			)}>
			<div className="d-flex align-items-md-center flex-column flex-md-row flex-wrap flex-grow-1 mb-3 mb-md-0 pe-3 gap-3">
				<div className="d-flex align-items-center me-2">
					<FontAwesome
						icon="code-branch"
						size="lg"
						className="me-2"
					/>
					<span className="text-gray-3 fw-400">
						{t('card.published-version')}:
					</span>
					<span className="ms-1 text-gray-5">
						{job.publishedVersion ?? '...'}
					</span>
				</div>
				<div className="d-flex align-items-center">
					<FontAwesome
						icon="calendar-days"
						size="lg"
						className="me-2"
					/>
					<span className="text-gray-3">
						{t('card.published-date')}:
					</span>
					<span className="ms-1 text-gray-5">
						{job?.auditInfo?.updatedAt
							? formatLocaleDateTime(job?.auditInfo?.updatedAt)
							: '...'}
					</span>
				</div>
			</div>
			<div className="ms-md-auto d-flex align-items-center">
				<div className="d-flex me-auto me-md-0">
					{/* <Button
						color={Colors['white-primary']}
						title={t('title.download-qrcode')}
						className={styles.action}
						onClick={toggleQrModal}>
						<FontAwesome icon="qrcode" size="xl" />
					</Button> */}
					{/* {job && job?.publishedInfo.downloadUrl && (
						<a
							href={job?.publishedInfo.downloadUrl}
							rel="noreferrer"
							target="_blank"
							title={t('title.download-pdf')}
							download={`jobbiplace-job_${job?.title}.pdf`}
							className={clx(styles.action, styles.downloadPdf)}>
							<FontAwesome icon="download" size="xl" />
						</a>
					)} */}
					<Button
						color={Colors['white-primary']}
						title={t('title.job.view-job-page')}
						className={styles.action}
						onClick={redirectToPreview}>
						<FontAwesome icon="eye" size="xl" />
					</Button>
				</div>
				{(assetConfig?.partnerType === 1 ||
					location.pathname.includes('visibility-settings')) && (
					<Button
						color={Colors['white-red']}
						onClick={toggleUnPublishModal}>
						<FontAwesome
							icon="triangle-exclamation"
							size="lg"
							className="me-2"
						/>
						{t('button.unPublish')}
					</Button>
				)}
			</div>
			{shouldRenderUnPublishModal && (
				<ModalBoxUnPublishJob
					type={
						assetConfig?.partnerType === 2
							? 'businessPartner'
							: 'communityPartner'
					}
					isOpen={openUnPublishModal}
					onClose={toggleUnPublishModal}
				/>
			)}
			{shouldRenderQrhModal && (
				<ModalBoxQrCode
					isOpen={openQrModal}
					onClose={toggleQrModal}
					title={job.title}
					data={data}
					isLoading={isLoading}
					downloadQrcodeApi={api.downloadJobQrCode(job_id)}
					namespace="job"
				/>
			)}
		</div>
	);
};
